import React from "react";
import { HelmetHeader } from "../../components/HelmetHeader";
import { LocationSearchInput } from "../../components/LocationSearchInput";
import {
  createToast,
  googlePlaceToAddress,
  PageLink
} from "../../lib";
import Pages from '../';
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/client-add-shul.css";
import "../../assets/css/pages/contact.css";
import "react-toastify/dist/ReactToastify.css";
import { LocationWarningDialog } from "../../components/LocationWarningDialog";

export default class ClientAddShul extends React.PureComponent {
  state = {
    triedSubmitting: false,
    shulCreated: false,
    requestDispatched: false,
    existingShulNames: [],
    temp: false,
    temp_shul_confirmed: false,
    notes: '',
    start_date: '',
    end_date: '',
    first_name: '',
    last_name: '',
    email: '',
    name: '',
    website_url: '',
    shulEmail: '',
    shulPhone: '',
    shulExtension: '',
    address: '',
    shul_nusach: '',
    phone: '',
    has_sefer_torah: false,
    showLocationWarningDialog: false,
    emailFailed: false
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.pageRoute.query.temp) {
      this.setState({ temp: true });
    }
  }

  handleInputChange = e => {
    let { target: { value, name, checked, type } } = e;

    if (value === "true" || value === "false") {
      value = JSON.parse(value);
    }

    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  }

  handleAddressResults = (googlePlace) => {
    const addressInfo = googlePlaceToAddress(googlePlace[0]);
    const { streetNumber, streetName } = addressInfo;
    addressInfo.address = (streetName && streetNumber) || (this.state.temp && streetName)
      ? `${streetNumber ? `${streetNumber} ` : ''}${streetName}`
      : this.state.temp ? '' : null;
    delete addressInfo.streetNumber;
    delete addressInfo.streetName;
    this.setState({ address: addressInfo });
  }

  handleSubmit = async (_e, createWithExistingLocation = false) => {
    this.setState({ triedSubmitting: true, showLocationWarningDialog: false });
    const {
      temp,
      name,
      website_url,
      shulEmail,
      shulPhone,
      shulExtension,
      address,
      shul_nusach,
      first_name,
      last_name,
      email,
      phone,
      start_date,
      end_date,
      notes,
      temp_shul_confirmed,
      has_sefer_torah
    } = this.state;
    if (!name || !address || !first_name || !last_name || !email || (temp && (!start_date || !end_date))) {
      createToast(null, 'Please fill in all required fields!');
      return;
    }
    if (!temp && !address.address) {
      createToast(null, 'A full address is required!');
      return;
    }

    this.setState({ requestDispatched: true });

    const shul = {
      name,
      website_url: website_url ? website_url : null,
      email: shulEmail ? shulEmail : null,
      phone: shulPhone ? shulPhone : null,
      extension: shulExtension ? shulExtension : null,
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
      notes: notes ? notes : null,
      shul_nusach: shul_nusach ? shul_nusach : null,
      temp_shul_confirmed,
      has_sefer_torah
    };
    const location = { ...address };
    const account = {
      first_name,
      last_name,
      email,
      phone: phone ? phone : null
    };
    const postObj = { shul, account, location, createWithExistingLocation };
    const response = await this.props.actions.addShulWithSubmitter(postObj);
    if (response.showLocationExistsWarning) {
      this.setState({ existingShulNames: response.existingShulNames, showLocationWarningDialog: true });
    } else if (response.shulCreated) {
      this.setState({
        shulCreated: true,
        emailFailed: response.emailFailed
      });
    }
    this.setState({ requestDispatched: false });
  }

  cancelDialog = () => {
    this.setState({ showLocationWarningDialog: false });
  }

  resubmitShul = () => {
    this.handleSubmit(null, true);
  }

  render() {
    const {
      state: {
        triedSubmitting,
        shulCreated,
        requestDispatched,
        showLocationWarningDialog,
        existingShulNames,
        website_url,
        name,
        address,
        shulEmail,
        shulPhone,
        shulExtension,
        temp,
        temp_shul_confirmed,
        notes,
        shul_nusach,
        start_date,
        end_date,
        first_name,
        last_name,
        email,
        phone,
        has_sefer_torah,
        emailFailed
      }
    } = this;
    const nusachs = {
      '': 'Select a Nusach',
      sefard: 'Sefard',
      ashkenaz: 'Ashkenaz',
      ari: 'Ari',
      edut_mizrach: 'Edut Mizrach',
      temani: 'Temani'
    };

    return (
      <React.Fragment>
        <HelmetHeader />

        {showLocationWarningDialog &&
          <LocationWarningDialog
            existingShulNames={existingShulNames}
            cancelDialog={this.cancelDialog}
            resubmitRequest={this.resubmitShul}
          >
          </LocationWarningDialog>}

        <div className="flex-justify-center client-add-shul-page">
          <div className="mobile-full-width">
            {!shulCreated ?
              (<div className="client-add-shul-container mobile-full-width">
                <div className="relative mobile-block">
                  <h1 className="client-add-shul-title">New Minyan</h1>
                </div>

                <div className="client-shul-info">
                  <div className="client-shul-title mob-large-text">
                    Minyan
                  </div>

                  <div className="flex-right-center mb-12">
                    <label className="temp-minyan-checkbox-label flex-right-center bold-text medium-text mob-xs-text uppercase-text">
                      <input
                        type="checkbox"
                        checked={temp === true}
                        value={temp}
                        name="temp"
                        onChange={this.handleInputChange}
                      />
                      <span className="custom-checkbox-square flex-align-center"></span>
                      temporary minyan
                  </label>
                  </div>

                  {temp &&
                    <div>
                      <div className="medium-text mob-xs-text temp-minyan-text">
                        <p className="mb-16">
                          Please follow these instructions carefully
                          to ensure that your minyan will be accurate and run smoothly.
                        </p>
                        <p>
                          <span className="bold-text">1) </span>
                          If you don't have a physical address at this point,
                          put in an approximate address now and then update it when the details are finalized.
                        </p>
                        <p>
                          <span className="bold-text">2) </span>
                          You need to enter whether you currently have a minyan or not.
                          If you do not have a minyan yet, the temporary minyan will display as "pending".
                        </p>
                        <p className="mb-24">
                          <span className="bold-text">3) </span>
                          It is also important for people to be able to contact you,
                          so please make sure to enter a phone number or email <span className="bold-text">on the Minyan </span>
                          where people can reach you,
                          which will give you the best chance of actually having a minyan.
                        </p>
                      </div>

                      <div className="flex-align-center mb-8">
                        <label className="client-shul-label mob-medium-text">Start Date</label>
                        <input
                          type="date"
                          className={`text-18 mob-xs-text ${triedSubmitting && !start_date ? 'required' : ''}`}
                          value={start_date}
                          name="start_date"
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div className="flex-align-center mb-8">
                        <label className="client-shul-label mob-medium-text">End Date</label>
                        <input
                          type="date"
                          className={`text-18 mob-xs-text ${triedSubmitting && !end_date ? 'required' : ''}`}
                          value={end_date}
                          name="end_date"
                          onChange={this.handleInputChange}
                        />
                      </div>

                      <div className="text-18 mob-medium-text mb-16 mt-24">Do you have a minyan yet?</div>

                      <label className="text-18 mob-xs-text temp-minyan-radio-label flex-left-center">
                        <input
                          type="radio"
                          value="true"
                          name="temp_shul_confirmed"
                          checked={temp_shul_confirmed === true}
                          onChange={this.handleInputChange}
                        />
                        <span className="temp-radio-square"></span>
                        Yes
                  </label>

                      <label className="text-18 mob-xs-text temp-minyan-radio-label flex-left-center">
                        <input
                          type="radio"
                          value="false"
                          name="temp_shul_confirmed"
                          checked={temp_shul_confirmed === false}
                          onChange={this.handleInputChange}
                        />
                        <span className="temp-radio-square"></span>
                        No
                  </label>

                      <div className="text-18 mob-medium-text mt-24 mb-16">Do you have a Sefer Torah?</div>

                      <label className=" text-18 mob-xs-text temp-minyan-radio-label flex-left-center">
                        <input
                          type="radio"
                          value="true"
                          name="has_sefer_torah"
                          checked={has_sefer_torah}
                          onChange={this.handleInputChange}
                        />
                        <span className="temp-radio-square"></span>
                        Yes
                    </label>

                      <label className="text-18 mob-xs-text temp-minyan-radio-label flex-left-center">
                        <input
                          type="radio"
                          value="false"
                          name="has_sefer_torah"
                          checked={!has_sefer_torah}
                          onChange={this.handleInputChange}
                        />
                        <span className="temp-radio-square"></span>
                        No
                  </label>
                    </div>}

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Minyan Name</label>
                    <input
                      className={`custom-input flex-1 text-18 client-input-color mobile-client-input
                        ${triedSubmitting && !name ? 'required' : ''}`}
                      type="text"
                      value={name}
                      name="name"
                      onChange={this.handleInputChange}
                      placeholder={temp ? 'Pesach in the Rockies' : ''}
                    />
                  </div>

                  <div className="flex mb-8">
                    <label className="flex-align-center client-shul-label mob-medium-text">Address</label>
                    <LocationSearchInput
                      handleAddressResults={this.handleAddressResults}
                      ClientAddShulClassName={`client-add-shul-address-input
                      ${triedSubmitting && (!address || (!temp && !address.address)) ? 'required' : ''}`}
                    />
                  </div>

                  {!temp &&
                    <div className="flex-align-center mb-8">
                      <label className="client-shul-label mob-medium-text">Website</label>
                      <input
                        className="custom-input flex-1 text-18 client-input-color mobile-client-input"
                        type="text"
                        //placeholder="optional"
                        value={website_url}
                        name="website_url"
                        onChange={this.handleInputChange}
                      />
                    </div>}

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Email</label>
                    <input
                      className="custom-input flex-1 text-18 client-input-color mobile-client-input"
                      type="text"
                      //placeholder="optional"
                      value={shulEmail}
                      name="shulEmail"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Phone</label>
                    <input
                      className="custom-input flex-1 text-18 client-input-color mobile-client-input"
                      type="text"
                      //placeholder="optional"
                      value={shulPhone}
                      name="shulPhone"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Extension</label>
                    <input
                      className="custom-input flex-1 text-18 client-input-color mobile-client-input"
                      type="text"
                      //placeholder="optional"
                      value={shulExtension}
                      name="shulExtension"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Nusach</label>
                    <select
                      className="custom-input flex-1 text-18 client-input-color mobile-client-input"
                      value={shul_nusach}
                      name="shul_nusach"
                      onChange={this.handleInputChange}
                    >
                      {Object.keys(nusachs).map(nusach =>
                        <option
                          key={nusach}
                          value={nusach}
                          disabled={nusach === ''}
                        >
                          {nusachs[nusach]}
                        </option>)}
                    </select>
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Notes</label>
                    <textarea
                      className={`custom-input flex-1 text-18 client-input-color mobile-client-input`}
                      type="text"
                      value={notes}
                      name="notes"
                      onChange={this.handleInputChange}
                    />
                  </div>

                </div>

                <div className="client-contact-info">
                  <div className="client-shul-title mob-large-text">
                    Account Info
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">First Name</label>
                    <input
                      type="text"
                      className={`custom-input flex-1 text-18 client-input-color mobile-client-input
                        ${triedSubmitting && !first_name ? 'required' : ''}`}
                      value={first_name}
                      name="first_name"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Last Name</label>
                    <input
                      type="text"
                      className={`custom-input flex-1 text-18 client-input-color mobile-client-input
                        ${triedSubmitting && !last_name ? 'required' : ''}`}
                      value={last_name}
                      name="last_name"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Email</label>
                    <input
                      type="text"
                      className={`custom-input flex-1 text-18 client-input-color mobile-client-input
                        ${triedSubmitting && !email ? 'required' : ''}`}
                      value={email.email}
                      name="email"
                      onChange={this.handleInputChange}
                    />
                  </div>

                  <div className="flex-align-center mb-8">
                    <label className="client-shul-label mob-medium-text">Phone</label>
                    <input
                      type="text"
                      className="custom-input flex-1 text-18 client-input-color mobile-client-input"
                      value={phone}
                      name="phone"
                      onChange={this.handleInputChange}
                    />
                  </div>

                </div>
                <div className="flex-justify-center">
                  <button
                    className="client-add-shul-submit"
                    onClick={!requestDispatched ? this.handleSubmit : null}
                  >
                    Submit
                  </button>
                </div>
              </div>
              )
              : (
                <div>
                  <div className="text-center xl-text mob-large-text client-add-shul-success">
                    <p className="mb-24">Thank you for submitting a new shul.</p>
                    {emailFailed ? (
                      <div className="danger mb-12">
                        <p>The Email to set your login failed.</p>
                        <p>Please contact support for help.</p>
                      </div>
                    )
                      : (<div>
                        <p className="mb-12">
                          Please check your inbox, we sent you an email to
                            <span className="mobile-hidden"> create a password.</span>
                        </p>
                        <p className="desktop-hidden">create a password.</p>
                      </div>)}

                  </div>
                  <PageLink to={Pages.main.home} className="flex-justify-center client-add-shul-home">
                    <button
                      className="client-add-shul-submit"
                    >
                      Home
                    </button>
                  </PageLink>
                </div>
              )}
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

// function formatNumber(num) {
//   if (!num.length) return '';
//   let formattedNum = `(${num.slice(0, 3)}`;
//   formattedNum += num.length > 3 ? `)${num.slice(3, 6)}` : '';
//   formattedNum += num.length > 6 ? `-${num.slice(6)}` : '';
//   return formattedNum;
// }
