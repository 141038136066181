import React, { Fragment } from "react";
import { HelmetHeader } from "../../components/HelmetHeader";
import { Navigation, APIClient, createToast } from "../../lib";
import "./form.css";
import Banner from "./Banner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Form extends React.PureComponent {
  state = {
    missingCount: 0,
    input: "",
    id: null,
    name: "",
    address: "",
    contactFirstName: "",
    contactLastName: "",
    contactTitle: "rabbi",
    contactRole: "gabbai",
    contactEmail: "",
    contactPhone: "",
    submitterFirstName: "",
    submitterLastName: "",
    submitterEmail: "",
    submitterPhone: "",
    submitting: false
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    const { id, name, address } = this.props.pageRoute.query;
    if (!id || !name || !address) {
      return Navigation.redirect("/chezky/missing-info-list");
    }
    this.setState({ id, name, address });
    const response = await APIClient.get(`/campaign/missing-count`).catch(
      console.error
    );
    if (response && response.data) {
      this.setState({ missingCount: response.data.count });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async goBackAfter => {
    this.setState({ submitting: true });
    const {
      id,
      contactFirstName,
      contactLastName,
      contactTitle,
      contactRole,
      contactEmail,
      contactPhone,
      submitterFirstName,
      submitterLastName,
      submitterEmail,
      submitterPhone
    } = this.state;
    // if (
    //   !contactFirstName ||
    //   !contactLastName ||
    //   !contactTitle ||
    //   !contactRole
    // ) {
    //   return createToast(null, "please fill in all required fields");
    // }
    // if (!contactEmail && !contactPhone) {
    //   return createToast(null, "please enter a contact email or phone");
    // }
    // if (
    //   contactEmail &&
    //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(contactEmail)
    // ) {
    //   return createToast(null, "please enter a valid email");
    // }
    const postObj = {
      id,
      contactFirstName,
      contactLastName,
      contactTitle,
      contactRole,
      contactEmail,
      contactPhone,
      submitterFirstName,
      submitterLastName,
      submitterEmail,
      submitterPhone
    };
    const response = await APIClient.post(
      `/campaign/submission`,
      postObj
    ).catch(e => {
      console.error(e);
      return createToast(
        null,
        "an unexpected error ocurred, please try again later"
      );
    });
    if (response && response.data && response.data.success) {
      if (goBackAfter) {
        return Navigation.redirect("/chezky/missing-info-list");
      }
      return Navigation.redirect("/chezky/thank-you");
    }
  };

  render() {
    const {
      missingCount,
      submitting,
      name,
      address,
      contactFirstName,
      contactLastName,
      contactTitle,
      contactRole,
      contactEmail,
      contactPhone,
      submitterFirstName,
      submitterLastName,
      submitterEmail,
      submitterPhone
    } = this.state;
    return (
      <Fragment>
        <Banner />
        <div className="c-form-back" onClick={Navigation.history.goBack}>
          <i className="material-icons">chevron_left</i>
          Back to Results
        </div>
        <div className="c-form-container">
          <div className="c-form-txt">
            GoDaven has data on over{" "}
            <span className="bold-text">7,200 Shuls across the world.</span> We
            need your help to fill in the missing contact information. When a
            user needs to verify Minyan times and locations, it is crucial for
            them to have a{" "}
            <span className="bold-text">
              point person to be able to communicate with.
            </span>
          </div>
          <div className="c-form-ins">
            Please fill in the relevant information below:
          </div>
          <div className="c-form-shul">{name}</div>
          <div className="c-form-shul-add">{address}</div>

          {/*  */}
          <div className="c-form-box">
            <div className="c-form-header">Shul Contact Information:</div>
            <div className="c-input-row">
              <div className="c-input-label">
                Shul Contact Person First Name:
              </div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="contactFirstName"
                value={contactFirstName}
              />
            </div>
            <div className="c-input-row">
              <div className="c-input-label">
                Shul Contact Person Last Name:
              </div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="contactLastName"
                value={contactLastName}
              />
            </div>
            <div className="c-input-row">
              <div className="c-input-label">Title:</div>
              <select
                onChange={this.handleChange}
                className="c-input"
                name="contactTitle"
                value={contactTitle}
              >
                <option value="rabbi">Rabbi</option>
                <option value="mr">Mr</option>
                <option value="dr">Dr</option>
                <option value="mrs">Mrs</option>
                <option value="ms">Ms</option>
              </select>
            </div>
            <div className="c-input-row">
              <div className="c-input-label">Connection to Shul:</div>
              <select
                onChange={this.handleChange}
                className="c-input"
                name="contactRole"
                value={contactRole}
              >
                 <option value="gabbai">Gabbai</option>
                <option value="rabbi">Rabbi</option>
                <option value="assistant_rabbi">Assistant Rabbi</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="c-input-row">
              <div className="c-input-label">Email:</div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="contactEmail"
                value={contactEmail}
              />
            </div>
            <div className="c-input-row">
              <div className="c-input-label">Phone Number:</div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="contactPhone"
                value={contactPhone}
              />
            </div>
            <div className="c-form-header">
              Your Personal info in case we need to verify data:
            </div>
            <div className="c-input-row">
              <div className="c-input-label">First Name:</div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="submitterFirstName"
                value={submitterFirstName}
              />
            </div>
            <div className="c-input-row">
              <div className="c-input-label">Last Name:</div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="submitterLastName"
                value={submitterLastName}
              />
            </div>
            <div className="c-input-row">
              <div className="c-input-label">Email:</div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="submitterEmail"
                value={submitterEmail}
              />
            </div>
            <div className="c-input-row">
              <div className="c-input-label">Phone Number:</div>
              <input
                onChange={this.handleChange}
                className="c-input"
                name="submitterPhone"
                value={submitterPhone}
              />
            </div>
          </div>
          {/*  */}
          <div className="flex-justify-center">
            <div
              className="c-form-left-button"
              onClick={!submitting ? () => this.handleSubmit(true) : null}
            >
              <div>SUBMIT & RETURN</div>
              <div>TO RESULTS PAGE</div>
            </div>
            <div
              className="c-form-right-button"
              onClick={!submitting ? () => this.handleSubmit(false) : null}
            >
              SUBMIT
            </div>
          </div>
          {/*  */}
          <div className="c-form-remaining-txt">
            ONLY&nbsp;<span className="c-form-remaining">{missingCount}</span>
            &nbsp;REMAINING!
          </div>
        </div>
        <ToastContainer />
        <HelmetHeader />
      </Fragment>
    );
  }
}
