import React from "react";
import { PageLink } from "../lib";
import Pages from "../pages/index";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import godavenMarker from "../assets/images/GodavenMapPin.png";
import hoverMarker from "../assets/images/GodavenMapPinHover.png";
import "../assets/css/components/map-component.css";

export const MapComponent = withGoogleMap(props => {
  const {
    locations,
    searchLat,
    searchLng,
    infoWindowToggle,
    infoWindowArray,
    shulHoverId,
    updateLatLng,
    newSearch,
    newSearchFunc
  } = props;
  const { REACT_APP_BIGDATACLOUD_API_KEY } = process.env;
  const markerDragFunc = e => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    updateSearch(newLat, newLng);
  };

  const updateSearch = async (newLat, newLng) => {
    try {
      const res = await fetch(
        `https://api.bigdatacloud.net/data/reverse-geocode?latitude=${newLat}&longitude=${newLng}&localityLanguage=en&key=${REACT_APP_BIGDATACLOUD_API_KEY}`
      );
      const data = await res.json();
      updateLatLng(newLat, newLng, data.locality);
    } catch (err) {
      updateLatLng(newLat, newLng, "Showing locations on map area");
    }
  };

  if (
    locations &&
    window.google &&
    window.google.maps &&
    window.google.maps.LatLngBounds
  ) {
    let ids = Object.keys(locations);
    let bounds = null;

    if (newSearch) {
      bounds = new window.google.maps.LatLngBounds();

      // note: if this is from a next minyan search then id is minyan id not shul id because there can be multiple minyanim per shul

      ids.forEach(id => {
        if (locations[id].location_point) {
          const [longitude, latitude] = locations[
            id
          ].location_point.coordinates;
          const latLng = new window.google.maps.LatLng(latitude, longitude);
          bounds.extend(latLng);
        }
      });
      if (searchLat && searchLng) {
        const latLng = new window.google.maps.LatLng(+searchLat, +searchLng);
        bounds.extend(latLng);
      }

      if (
        bounds &&
        bounds.getNorthEast &&
        bounds.getNorthEast() &&
        typeof bounds.getNorthEast().equals === "function" &&
        bounds.getNorthEast().equals(bounds.getSouthWest())
      ) {
        const extendPoint1 = new window.google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.002,
          bounds.getNorthEast().lng() + 0.002
        );
        const extendPoint2 = new window.google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.002,
          bounds.getNorthEast().lng() - 0.002
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
    }

    let ref;
    return (
      <GoogleMap
        onTilesLoaded={() => newSearchFunc()}
        zoom={searchLat && searchLng ? 14 : 2}
        ref={map => {
          ref = map;
          if (map && bounds) return map.fitBounds(bounds);
        }}
        onDragEnd={() => {
          const newLat = ref.getCenter().lat([0]);
          const newLng = ref.getCenter().lng([0]);
          updateSearch(newLat, newLng);
        }}
        center={
          searchLat && searchLng ? { lat: +searchLat, lng: +searchLng } : null
        }
        maxZoom={5}
      >
        {searchLat && searchLng && (
          <Marker
            position={{ lat: +searchLat, lng: +searchLng }}
            draggable={true}
            onDragEnd={markerDragFunc}
          />
        )}

        {ids.length > 0 &&
          ids.map(id => {
            if (locations[id].location_point) {
              const {
                location_point: {
                  coordinates: [lng, lat]
                },
                name,
                formatted_address,
                id: shulId
              } = locations[id];
              return (
                <Marker
                  style={{ width: "16px", height: "auto" }}
                  icon={shulHoverId === +id ? hoverMarker : godavenMarker}
                  key={id}
                  name={id}
                  position={{ lat, lng }}
                  onClick={() => infoWindowToggle(id)}
                >
                  {" "}
                  {infoWindowArray.includes(id) && (
                    <InfoWindow onCloseClick={() => infoWindowToggle(id)}>
                      <PageLink
                        to={Pages.main.shulDetails}
                        params={{ shulId }}
                        query={{
                          latitude: lat,
                          longitude: lng,
                          from_search: true
                        }}
                      >
                        <p className="map-window-shul-name">{name}</p>
                        <p className="map-window-shul-address">
                          {formatted_address.slice(
                            0,
                            formatted_address.lastIndexOf(",")
                          )}
                        </p>
                      </PageLink>
                    </InfoWindow>
                  )}
                </Marker>
              );
            }
            return null;
          })}
      </GoogleMap>
    );
  } else {
    let ref;
    return (
      <GoogleMap
        ref={map => (ref = map)}
        center={
          searchLat && searchLng
            ? { lat: +searchLat, lng: +searchLng }
            : { lat: 0, lng: 0 }
        }
        zoom={searchLat && searchLng ? 14 : 2}
        minZoom={2}
        onDragEnd={() => {
          const newLat = ref.getCenter().lat([0]);
          const newLng = ref.getCenter().lng([0]);
          updateSearch(newLat, newLng);
        }}
      >
        {searchLat && searchLng && (
          <Marker
            position={{ lat: +searchLat, lng: +searchLng }}
            draggable={true}
            onDragEnd={markerDragFunc}
          />
        )}
      </GoogleMap>
    );
  }
});

export class GoogleMapsComponent extends React.PureComponent {
  render() {
    const {
      locations,
      searchLat,
      searchLng,
      infoWindowToggle,
      infoWindowArray,
      shulHoverId,
      containerElement,
      mapElement,
      updateLatLng,
      newSearch,
      newSearchFunc
    } = this.props;
    return (
      <MapComponent
        containerElement={containerElement}
        mapElement={mapElement}
        locations={locations}
        searchLat={searchLat}
        searchLng={searchLng}
        infoWindowToggle={infoWindowToggle}
        infoWindowArray={infoWindowArray}
        shulHoverId={shulHoverId}
        updateLatLng={updateLatLng}
        newSearchFunc={newSearchFunc}
        newSearch={newSearch}
      />
    );
  }
}
