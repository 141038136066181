import React from 'react';
import '../assets/css/components/locationSearchInput.css';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

export class LocationSearchInput extends React.PureComponent {
  state = {
    addressDisplay: '',
    prevAddress: '',
    focus: false
  };

  componentDidMount() {
    const { prevAddress, focus } = this.props;
    if (prevAddress) {
      this.setState({ addressDisplay: prevAddress, prevAddress });
    }

    setTimeout(() => {
      if (focus && document.getElementById('location')) {
        document.getElementById('location').focus();
      }
    }, 300);
  }

  componentDidUpdate(prevProps) {
    const { prevAddress } = this.props;

    if (prevAddress !== null && prevAddress !== undefined && prevAddress !== prevProps.prevAddress) {
      this.setState({ addressDisplay: prevAddress });
      if (document.getElementById('location')) {
       // document.getElementById('location').focus();
      }
    }
  }

  handleChange = addressDisplay => {
    this.setState({ addressDisplay });
    if (this.props.handleLocationInputChange) {
      this.props.handleLocationInputChange(addressDisplay);
    }
  };

  handleSelect = addressDisplay => {
    this.setState({ addressDisplay });
    geocodeByAddress(addressDisplay)
      .then(results => {
        this.props.handleAddressResults(results);
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    const {
      props: { customClass, ClientAddShulClassName, placeHolder, forHomePage, forSearchPage, forFrontLayout, handleKeyPress },
      state: { addressDisplay }
    } = this;

    return (
      <PlacesAutocomplete
        id="location"
        value={addressDisplay}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        googleCallbackName="placesAutoComplete"
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={`max-100  ${forHomePage || forFrontLayout ? 'home-page-places-container' : ''} 
          ${forSearchPage ? 'search-page-places-container' : ''} flex-center-columns 
           ${ClientAddShulClassName ? 'flex-columns flex-1' : ''} `}>
            <input
              ref="location"
              id="location"
              {...getInputProps({
                autoFocus: true,
                placeholder: placeHolder ? placeHolder : 'Search locations...',
                className: `capitalize-text google-places-input ${forSearchPage ? 'search-page-location-input' : ''}
                  ${ClientAddShulClassName ? ClientAddShulClassName : ''} 
                  ${customClass ? customClass : 'custom-input'}`,
              })}
              onKeyDown={handleKeyPress ? handleKeyPress : null}
            />
            <div className={`autocomplete-container ${forHomePage ? 'home-page-places-autocomplete-container' : ''}
            ${forFrontLayout ? 'front-layout-places-autocomplete-container' : ''}
            ${suggestions.length && forSearchPage ? 'search-autocomplete-container' : ''}`}>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = `${suggestion.active ? 'suggestion-item active' : 'suggestion-item'}
                 ${forHomePage || forFrontLayout ? 'home-ac-active' : ''}`;

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}