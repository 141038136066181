import {
  APIClient,
} from '../../lib';

export const SponsorActions = {
  DAILY_SPONSOR_GET_TODAYS: 'DAILY_SPONSOR_GET_TODAYS',
  WEEKLY_SPONSOR_GET_CURRENT: 'WEEKLY_SPONSOR_GET_CURRENT',

  getTodaysDailySponsor() {
    return async (dispatch, getState) => {
      const response = await APIClient.get('/daily-sponsors/todays-sponsor').catch(console.error);
      
      if (response && response.data) {
        dispatch({
          type: SponsorActions.DAILY_SPONSOR_GET_TODAYS,
          payload: response.data
        });
      }
    };
  },

  getCurrentWeeklySponsor() {
    return async (dispatch, getState) => {
      const response = await APIClient.get('/weekly-sponsors/current-sponsor').catch(console.error);
      
      if (response && response.data) {
        dispatch({
          type: SponsorActions.WEEKLY_SPONSOR_GET_CURRENT,
          payload: response.data
        });
      }
    };
  },

};
