/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  APIClient,
  PageLink,
  Navigation,
} from '../lib';
import Pages from '../pages/index';
import Axios from 'axios';
import HeaderLogo from '../assets/images/Logo_Header.svg';
import FooterLogo from '../assets/images/Logo_Footer.svg';
import KlalGovoahLogo from '../assets/images/KlalGovoahLogo.png';
import "../assets/css/components/frontlayout.css";
import { Login } from '../components';
import { MobileMenu } from '../components';
import { SponsorSlider } from '../components/SponsorSlider';
import { ShulSpaceBanner } from '../components/ShulSpaceBanner';
import { withStyles } from '@material-ui/core/styles';
import { LocationSearchInput } from "../components/LocationSearchInput";
import { Switch, ClickAwayListener, Dialog } from '@material-ui/core';
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import Loader from "../components/Loader";
const CustomSwitch = withStyles({
  icon: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  iconChecked: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  bar: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  switchBase: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  checked: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
})(Switch);


export class FrontLayout extends React.PureComponent {

  state = {
    displayLogin: false,
    mobileMenuOpen: false,
    noSearchBar: false,
    forHomePage: false,
    dailySponsor: null,
    weeklySponsor: null,
    query: '',
    byLocation: true,
    autoComplete: [],
    searchAddress: '',
    showDialog: false,
    geoLocationErrorCode: null,
    isNextMinyan: false,
    newUser: false,
    pwResetJwt: null,
  }

  componentDidMount() {
    const {page, events} = Navigation;
    const {
      jwt: pwResetJwt = null,
      new_user: newUser = false,
      displayLogin = false
    } = Navigation.query;
    if(!page){
      return;
    }
    this.setState({
      noSearchBar: !!page.noSearchBar,
      forHomePage: page.type === "PAGE_HOME" ? true : false,
      pwResetJwt,
      newUser,
      displayLogin: pwResetJwt || newUser || displayLogin ? true : false
    });

    this.removeRouteChangedHandler = events.routeChanged.handle(
      this.routeChanged,
    );


    if (pwResetJwt || displayLogin) {
      this.setState({ displayLogin: true });
    }

    this.getSponsors();

    const interval = setInterval(this.getSponsors, 3600000);
    this.setState({ interval });
  }

  getSponsors = async () => {
    /*** If sponsor API wasn't called yet then sponsors are null
      once it is called it is always returns a object,
      if there are no sponsors then it is empty
      so dailySponsor.message will be undefined and we set it to null
    **/
    const {
      getTodaysDailySponsor,
      getCurrentWeeklySponsor
    } = this.props.sponsorActions;

    await getTodaysDailySponsor();
    if (this.props.dailySponsor) {
      this.setState({ dailySponsor: this.props.dailySponsor.message || null });
    }

    await getCurrentWeeklySponsor();
    if (this.props.weeklySponsor) {
      this.setState({ weeklySponsor: this.props.weeklySponsor.message || null });
    }
  }

  /** @param {import("../lib").ExtendedRouteProps} route The current page
   * route.
   */
  routeChanged = route => {
    // NOTE: Don't put the route in state because it can't be serialized.
    // Instead, get what you need here and put that in state.
    const {
      page: {
        noSearchBar,
        type
      },
      query: {
        jwt: pwResetJwt = null,
        new_user: newUser = false,
        displayLogin = false
      }
    } = route;

    this.setState({
      noSearchBar: !!noSearchBar,
      forHomePage: type === "PAGE_HOME" ? true : false,
      pwResetJwt,
      newUser,
      displayLogin: pwResetJwt || newUser || displayLogin ? true : false
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.interval);
    this.removeRouteChangedHandler();
  }

  componentDidUpdate(prevProps) {
    if (this.props.displayLogin !== prevProps.displayLogin) {
      this.setState({ displayLogin: this.props.displayLogin });
    }
  }

  displayLogin = () => {
    this.setState({ displayLogin: true });
  }

  hideLogin = () => {
    this.setState({ displayLogin: false });
    this.props.closeLogin && this.props.closeLogin();
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      mobileMenuOpen: !prevState.mobileMenuOpen
    }));
  }

  handleGetUserPosition = async (isNextMinyan = false) => {
    this.setState(() => ({ showDialog: true, isNextMinyan }));
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        this.dispatchUserPositionSearch(latitude, longitude, isNextMinyan);
      },
      e => { this.setState({ geoLocationErrorCode: e.code }); },
      { enableHighAccuracy: true, timeout: 10000 }
    );
  }

  handleGeoLocationFailure = async () => {
    const res = await Axios.get(`https://geoip-db.com/json/`)
      .catch((e) => console.error(e));

    if (res && res.data && res.data.latitude) {
      const { latitude, longitude } = res.data;
      this.dispatchUserPositionSearch(latitude, longitude);
    } else {
      this.setState(() => ({ showDialog: false }));
      Navigation.go(`/search?mode=location`);
    }
  }

  dispatchUserPositionSearch = async (latitude, longitude) => {
    const isNextMinyan = this.state.isNextMinyan;
    const response = await APIClient.get(`/general/address-from-lat-lng?lat=${latitude}&lng=${longitude}`)
      .catch((e) => console.error(e));
    this.setState(() => ({ showDialog: false }));
    Navigation.go(`/search?mode=location${isNextMinyan === true ? '&next_minyan=true' : ''}` +
      `&address=${response && response.data && response.data.formattedAddress ? response.data.formattedAddress : 'current location'}` +
      `&lat=${latitude}&lng=${longitude}`);
  }

  handleAddressResults = (addressResults) => {
    const searchAddress = addressResults[0].formatted_address;
    getLatLng(addressResults[0])
      .then((res) => {
        Navigation.go(`/search?mode=location&address=${searchAddress}&lat=${res.lat}&lng=${res.lng}`);
      })
      .catch(error => console.error('Error', error));
  };

  handleLocationInputChange = (locationInput) => {
    this.setState({ searchAddress: locationInput });
  }

  handleSearch = async () => {
    const { byLocation, query, searchAddress } = this.state;
    if (!byLocation) {
      return Navigation.go(`/search?mode=all&q=${query}`);
    }

    if (searchAddress) {
      geocodeByAddress(searchAddress).then(res => {
        getLatLng(res[0]).then((r) => {
          return Navigation.go(`/search?mode=location&address=${res[0].formatted_address}&lat=${r.lat}&lng=${r.lng}`);
        })
          .catch(error => {
            Navigation.go(`/search?mode=location`);
            console.error('Error', error);
          });
      });
      return;
    }

    Navigation.go(`/search?mode=location`);
  }

  handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13) {
      this.handleSearch();
    }
  }

  navigateToShulDetails = (id, locationPoint) => {
    let latitude = '';
    let longitude = '';
    if (locationPoint) {
      const [lng, lat] = locationPoint.coordinates;
      latitude = lat;
      longitude = lng;
    }
    this.setState({ autoComplete: [] });
    Navigation.go(`/shul-details/${id}?latitude=${latitude}&longitude=${longitude}`);
  }

  handleAutoComplete = async (e) => {
    const { value } = e.target;
    this.setState({ query: value });
    if (value.length < 1) {
      this.setState({ autoComplete: [] });
      return;
    }
    const { lat, lng } = this.state;
    let apiString = `/shuls/auto-complete?type=all&query=${encodeURIComponent(value)}`;
    if (typeof lat === 'number' && typeof lng === 'number') {
      apiString += `&lat=${lat}&lng=${lng}`;
    }
    const res = await APIClient.get(apiString).catch(() => { return null; });
    if (res) {
      this.setState({ autoComplete: res.data });
    }
  }


  toggleSearchMode = (mode) => {
    this.setState(prevState => ({
      byLocation: mode === 'all' ? false : mode === 'location' ? true : !prevState.byLocation,
      autoComplete: [],
      query: '',
      searchAddress: ''
    }),
      () => {
        const byLocation = this.state.byLocation;
        if (byLocation && document.getElementById('location')) {
          setTimeout(() => {
            document.getElementById('location').focus();
          }, 200);
        }
      });
  }

  handleClickAway = () => {
    this.setState({ autoComplete: [] });
  }


  render() {
    const {
      props: {
        children,
        roles,
        shulIds,
      },
      state: {
        displayLogin,
        mobileMenuOpen,
        noSearchBar,
        forHomePage,
        dailySponsor,
        weeklySponsor,
        query,
        byLocation,
        autoComplete,
        searchAddress,
        showDialog,
        geoLocationErrorCode,
        newUser,
        pwResetJwt,
      }
    } = this;

    return (
      <div className="">
        {mobileMenuOpen && (
          <MobileMenu
            displayLogin={this.displayLogin}
            toggleMobileMenu={this.toggleMobileMenu}
          />
        )}
        {displayLogin && (
          <Login
            hideLogin={this.hideLogin}
            pwResetJwt={pwResetJwt}
            newUser={newUser}
          />
        )}
        {showDialog && (
          <Dialog
            open={true}
            PaperProps={{
              style: {
                backgroundColor: !geoLocationErrorCode
                  ? "transparent"
                  : "white",
                boxShadow: "none"
              }
            }}
          >
            <div className="geo-dialog">
              {!geoLocationErrorCode && (
                <div className="flex-align-middle">
                  <div className="flex-align-middle">
                    <Loader />
                  </div>
                </div>
              )}

              {geoLocationErrorCode && (
                <div className="geo-error-div">
                  <i className="material-icons dialog-warning-icon">
                    error_outline
                  </i>
                  {geoLocationErrorCode === 1 ? (
                    <div className="flex-center-columns">
                      <div className="geo-warning-header">
                        Oops. Your location is blocked.
                      </div>
                      <div className="geo-message mb-12">
                        To get more accurate results please allow location.
                      </div>
                    </div>
                  ) : (
                    <div className="geo-warning-header">
                      Oops. Your location was not detected.
                    </div>
                  )}

                  <div className="geo-message mb-12">
                    We will try to estimate your location based on your IP
                    which is less accurate.{" "}
                  </div>
                  <div className="geo-message">
                    You can manually change the estimated location to your
                    current one at any time.{" "}
                  </div>
                  <div
                    className="geo-dialog-ok-button"
                    onClick={this.handleGeoLocationFailure}
                  >
                    CONTINUE
                  </div>
                </div>
              )}
            </div>
          </Dialog>
        )}
        <header className="max-100" id="front-layout-header">
          {/* CORONA VIRUS ALERT */}
          {/* <div
          style={{
            width: "100%",
            minHeight: "40px",
            backgroundColor: "red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#ffffff",
            padding: "10px",
            textAlign: "center"
          }}
          >
            Due to the current COVID-19/Coronavirus situation, most minyanim are in various stages of reopening. Contact your individual minyan directly to ensure the most current details about times, locations and restrictions.
          </div> */}
          {/* END CORONA VIRUS ALERT */}
          <div className="todays-sponsor-banner max-100 flex-align-middle">
            {dailySponsor ? (
              <div className="">
                {dailySponsor && dailySponsor.includes("<") ? (
                  <div dangerouslySetInnerHTML={{ __html: dailySponsor }} />
                ) : (
                  `Today’s Sponsor: ${dailySponsor || ""}`
                )}
              </div>
            ) : (
              <a
                href="https://campaign.godaven.com/"
                target="_blank"
                rel="noopener"
              >
                Today’s Sponsor: sponsorship available.
              </a>
            )}
          </div>

          {!forHomePage && (
            <div className="mobile-hidden">
              <div className="front-layout-header-content max-100">
                <div className="flex-align-middle">
                  <PageLink to={Pages.main.home}>
                    <img
                      className="front-layout-header-logo"
                      src={HeaderLogo}
                      alt="logo"
                    />
                  </PageLink>

                  {!noSearchBar && (
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                      <div className="front-layout-search-bar flex-align-middle">
                        <div className="mobile-hidden front-layout-mode-toggle">
                          <span
                            onClick={() =>
                              this.toggleSearchMode("location")
                            }
                            className={`link front-layout-mode-toggle-label ${
                              byLocation
                                ? "front-layout-mode-toggle-label-active"
                                : ""
                            }`}
                          >
                            By Location
                          </span>
                          <div>
                            <CustomSwitch
                              className=""
                              checked={!byLocation}
                              onChange={this.toggleSearchMode}
                              color="default"
                            />
                          </div>
                          <span
                            onClick={() => this.toggleSearchMode("all")}
                            className={`link front-layout-mode-toggle-label ${
                              !byLocation
                                ? "front-layout-mode-toggle-label-active"
                                : ""
                            }`}
                          >
                            By Name
                          </span>
                          <span className="front-layout-mode-toggle-line" />
                        </div>

                        <div className="front-layout-input-box flex">
                          {!byLocation && (
                            <div className="front-layout-input-div">
                              <input
                                autoComplete="off"
                                placeholder="Enter name of Shul or Rabbi"
                                className="front-layout-input max-100"
                                value={query}
                                name="query"
                                onChange={this.handleAutoComplete}
                                autoFocus={true}
                                onKeyDown={this.handleKeyPress}
                              />

                              {autoComplete.length > 0 && (
                                <div className="front-layout-shul-auto-complete-container">
                                  {autoComplete.map(shul => {
                                    const {
                                      id,
                                      name,
                                      address,
                                      rabbi,
                                      location_point
                                    } = shul;
                                    return (
                                      <div
                                        className="ac-ref"
                                        key={shul.id}
                                        onClick={() =>
                                          this.navigateToShulDetails(
                                            id,
                                            location_point
                                          )
                                        }
                                      >
                                        <div className="shul-auto-complete-row">
                                          {`${name} | ${address} ${
                                            rabbi ? `| ${rabbi}` : ""
                                          }`}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}

                          {byLocation && (
                            <LocationSearchInput
                              id="location"
                              customClass="front-layout-input max-100"
                              placeHolder={
                                "Enter Address, City, Locale or Place"
                              }
                              prevAddress={searchAddress}
                              forFrontLayout={true}
                              handleLocationInputChange={
                                this.handleLocationInputChange
                              }
                              handleAddressResults={
                                this.handleAddressResults
                              }
                              handleKeyPress={this.handleKeyPress}
                            />
                          )}
                          <div className="relative">
                            <i
                              className="material-icons front-layout-use-location-icon link"
                              onClick={this.handleGetUserPosition}
                            >
                              gps_fixed
                            </i>
                          </div>
                        </div>

                        <div
                          className=" front-layout-search-button link flex-align-middle"
                          onClick={this.handleSearch}
                        >
                          Search
                        </div>
                      </div>
                    </ClickAwayListener>
                  )}
                </div>

                <div className="flex-align-middle">
                  {!noSearchBar && (
                    <div
                      className="front-layout-next-minyan flex-align-middle"
                      onClick={() => this.handleGetUserPosition(true)}
                    >
                      Next Minyan Near Me
                    </div>
                  )}
                  <PageLink
                    to={Pages.main.clientAddShul}
                    className="mobile-hidden home-add-minyan"
                  >
                    <span>
                      <i className="material-icons">add</i>
                    </span>
                    <span>Add Minyan</span>
                  </PageLink>
                </div>
              </div>
              <div className="front-layout-header-border" />
            </div>
          )}

          {!forHomePage && (
            <div>
              <div className="desktop-hidden mobile-front-layout-header-content">
                <PageLink to={Pages.main.home}>
                  <img
                    className="front-layout-header-logo"
                    src={HeaderLogo}
                    alt="logo"
                  />
                </PageLink>
                <i
                  className="material-icons mobile-menu-icon"
                  onClick={this.toggleMobileMenu}
                >
                  menu
                </i>
              </div>
              <div className="front-layout-header-border" />
            </div>
          )}
        </header>

        <div className="main-content">{children}</div>

        <footer
          className="front-layout-footer max-100"
          id="front-layout-footer"
        >
          <div className="footer-sponsors">
            <div className="footer-sponsor-colunm">
              <div className="footer-sponsor-title">Today’s Sponsor</div>
              <div>
                {dailySponsor && dailySponsor.includes("<") ? (
                  <div dangerouslySetInnerHTML={{ __html: dailySponsor }} />
                ) : (
                  `Today’s Sponsor: ${dailySponsor || ""}`
                )}
              </div>
            </div>

            <div className="footer-sponsor-colunm">
              <div className="footer-sponsor-title">
                This Week's Sponsor
              </div>
              <div>
                {weeklySponsor ? weeklySponsor : "sponsorship available."}{" "}
              </div>
            </div>

            <div className="footer-sponsor-colunm">
              <div className="footer-sponsor-title">Become a sponsor</div>
              <a
                href="https://campaign.godaven.com/"
                target="_blank"
                rel="noopener"
                className="footer-dedication-opportunities"
              >
                View Dedication Opportunities
              </a>
            </div>
          </div>

          <div className="corporate-sponsors">Corporate Sponsors</div>
          <SponsorSlider />
          <ShulSpaceBanner/>
          {/* <a href="https://campaign.godaven.com/" target="_blank" rel="noopener" className="corporate-sponsor-link">
            To become a corporate sponsor click here
          </a> */}
          {/* <div className="this-weeks-sponsor flex-align-middle max-100">
            {weeklySponsor ?
              (<div className="">
                {`This week's sponsor: ${weeklySponsor}`}
              </div>)
              : (<a href="https://campaign.godaven.com/" target="_blank" rel="noopener">
                This week's sponsor: sponsorship available.
                </a>)}
          </div> */}

          <div className="footer-main-content-container max-100">
            <div className="mobile-hidden flex-justify-center">
              <div className="godaven-footer-logo">
                <img
                  src={FooterLogo}
                  alt="go-daven"
                  className="front-layout-footer-logo"
                />
              </div>
            </div>

            <div className="mobile-hidden flex-align-center">
              <div className="footer-colunm footer-page-links-column">
                <PageLink to={Pages.main.home}>Home</PageLink>
                <PageLink to={Pages.main.clientAddShul}>
                  Add a minyan
                </PageLink>
                <PageLink
                  to={Pages.main.clientAddShul}
                  query={{ temp: true }}
                >
                  Add a temporary minyan
                </PageLink>
                <PageLink to={Pages.main.tempShuls}>
                  Temporary minyanim
                </PageLink>
                <a href="/list-shuls/countries">Browse by Country</a>
                <PageLink to={Pages.main.about}>About Us</PageLink>
                <PageLink to={Pages.main.contact}>Contact Us</PageLink>
                <div className="">
                  {roles.includes("application_admin") ||
                  (roles.includes("shul_admin") && shulIds.length) ? (
                    <PageLink
                      className=""
                      to={
                        roles.includes("application_admin")
                          ? Pages.admin.dashboard
                          : Pages.shulAdmin.myShulsView
                      }
                    >
                      Admin
                    </PageLink>
                  ) : (
                    <div className="" onClick={this.displayLogin}>
                      Admin Login
                    </div>
                  )}
                </div>
                <PageLink to={Pages.main.termsOfService}>
                  Terms of service
                </PageLink>
              </div>
            </div>

            <div className="flex-align-center">
              <div className="footer-colunm footer-support-gd-column">
                <div className="desktop-hidden godaven-footer-logo">
                  <img
                    src={FooterLogo}
                    alt="go-daven"
                    className="front-layout-footer-logo"
                  />
                </div>

                <p className="support-gd-title">Support GoDaven</p>
                <div className="support-gd-text">
                  <p className="mobile-hidden">
                    GoDaven was revamped to help people like
                  </p>
                  <p className="mobile-hidden">
                    you find a minyan no matter where they are.
                  </p>
                  <p className="desktop-hidden">
                    GoDaven was revamped to help people like you find a
                    minyan no matter where they are.
                  </p>
                  <p className="mt-8">Help us help you “go daven”! </p>
                </div>
                <a
                  className="support-gd-button flex-align-middle"
                  href="https://campaign.godaven.com/"
                  target="_blank"
                  rel="noopener"
                >
                  Support GoDaven
                </a>
              </div>
            </div>

            <div className="flex-align-center">
              <div className="footer-colunm flex">
                <div className="footer-external-links-column">
                  <a
                    href="http://www.fishkinvision.com/"
                    target="_blank"
                    rel="noopener"
                    className="yosi-fishkin-text"
                  >
                    <p>Founded and</p>
                    <p>maintained by</p>
                    <p className="yosi-fishkin-name">Yosi Fishkin, MD</p>
                  </a>

                  <a
                    href="https://www.klalgovoah.org/"
                    target="_blank"
                    rel="noopener"
                    className="desktop-hidden klal-govoah flex-align-middle"
                  >
                    <img
                      src={KlalGovoahLogo}
                      alt="klal govoah"
                      className=""
                    />
                  </a>

                  <a
                    href="https://www.bitbean.com/"
                    target="_blank"
                    rel="noopener nofollow"
                    className="bitbean-link"
                  >
                    Powered by Bitbean
                  </a>
                </div>

                <a
                  href="https://www.klalgovoah.org/"
                  target="_blank"
                  rel="noopener"
                  className="mobile-hidden klal-govoah"
                >
                  <img
                    src={KlalGovoahLogo}
                    alt="klal govoah"
                    className=""
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}