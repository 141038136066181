import {
  APIClient,
} from '../../lib';


export const HomeActions = {
  USER_LOCATION_SAVE: 'USER_LOCATION_SAVE',
  CONTACT_FORM_SUBMIT: 'CONTACT_FORM_SUBMIT',
  CONTACT_FORM_RESET: 'CONTACT_FORM_RESET',
  SAVE_LAST_SEARCH: 'SAVE_LAST_SEARCH',

  saveUserLocation(lat, lng) {
    return (dispatch, getState) => {
      dispatch({
        type: HomeActions.USER_LOCATION_SAVE,
        payload: {
          lat,
          lng
        }
      });
    };
  },

  saveLastSearchDetails(search) {
    return (dispatch, getState) => {
      dispatch({
        type: HomeActions.SAVE_LAST_SEARCH,
        payload: search
      });
    };
  },

  submitForm(formData) {
    return async (dispatch, getState) => {
      const response = await APIClient.post(`/contact-form/`, formData).catch(console.error);

      if (response && response.data) {
        dispatch({
          type: HomeActions.CONTACT_FORM_SUBMIT,
        });
      }
    };
  },

  resetContactForm() {
    return { type: HomeActions.CONTACT_FORM_RESET };
  }


};
