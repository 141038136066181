import React from "react";
import { DebounceInput } from "react-debounce-input";
import { PageLink, Navigation } from "../../lib";
// import magnifyImg from "../../assets/images/search_24px_rounded.svg";
import { Layout } from "../../components";
import Loader from "../../components/Loader";
import "../../assets/css/pages/dashboard.css";
import Pages from "../";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Dashboard extends React.PureComponent {

  toggleSidebar = () => {
    this.props.uiActions.toggleSidebar();
  }

  state = {
    activeIndex: 1,
    query: '',
    searchType: 'shul'
  }

  async componentDidMount() {
    const {
      actions: {
        adminGetPending,
      },
      accountActions: {
        checkUsersAuth
      },
      pageRoute: {
        query: { q = '', pagenumber: page = 1, mode = 'shul' },
      },
    } = this.props;

    await checkUsersAuth(true);
    if (!this.props.authorized) {
      Navigation.redirect('/?displayLogin=true');
      return;
    }
    this.setState(() => ({ query: q, activeIndex: +page, searchType: mode }), this.dispatchSearch);
    adminGetPending();
  }

  dispatchSearch = () => {
    const {
      props: {
        actions: {
          adminSearchShuls,
          adminSearchContacts,
          adminSearchShulsById

        },
      },
      state: {
        query,
        searchType,
        activeIndex
      }
    } = this;
    searchType === 'id' ? adminSearchShulsById(query)
      : searchType === 'contacts' ? adminSearchContacts(query, activeIndex)
        : adminSearchShuls(searchType, query, activeIndex);
  }

  adminShulSearch = e => {
    const query = e.target.value;
    if (query) {
      this.setState(() => ({
        activeIndex: 1,
        query: query
      }),
        this.dispatchSearch
      );
    }
  };

  toogleSearchPages = (e) => {
    const attrib = e.target.attributes['data-pn'] ||
      e.target.parentElement.attributes['data-pn'];
    const pageNumber = parseInt(attrib.value, 10);

    this.setState(() => ({ activeIndex: pageNumber }), this.dispatchSearch);
  }

  toogleSearchType = e => {
    const { value } = e.target;
    this.setState(() => ({ searchType: value, activeIndex: 1, query: '' }),
      this.dispatchSearch
    );
  }

  clearShulFromRedux = () => {
    this.props.actions.clearShulFromRedux();
  };

  render() {

    const {
      props: {
        actions,
        loading,
        total,
        num_of_pages,
        results,
        pendingShuls,
        pageRoute: {
          query: { q: query, pagenumber: page = 1 },
        }
      },
      state: {
        searchType,
        activeIndex
      }
    } = this;
    const searchTypes = { shul: 'Shuls', rabbi: 'Rabbi', id: 'ID', contacts: 'Contacts' };
    let searchPages = [];
    const paginationStart = activeIndex < 7 || num_of_pages < 11 ? 1
      : num_of_pages - activeIndex < 5 ? (activeIndex - 9) + (num_of_pages - activeIndex) : activeIndex - 5;
    const paginationEnd = num_of_pages < activeIndex + 4 ? num_of_pages
      : activeIndex > 5 ? activeIndex + 4
        : paginationStart + 9;
    for (let i = paginationStart; i <= paginationEnd; i++) {
      searchPages.push(<li
        key={i}
        className={activeIndex === i ? 'active' : null}
        onClick={this.toogleSearchPages} data-pn={i}>{i}</li>
      );
    }

    return (
      <Layout onDashboard={true}>
        <div className="dashboard-outer-wraper">
          <header>
            <div className="dashboard-left-header">
              <div onClick={this.toggleSidebar}>
                <i className="material-icons desktop-hidden">menu</i>
              </div>
              <h1 className="mb-24">Dashboard</h1>
            </div>
            <div className="dashboard-right-header">
              <PageLink to="/admin/add-shul">
                <button className="btn btn-accent mr-6 desktop-hidden" onClick={this.clearShulFromRedux}>Add Shul</button>
              </PageLink>
              <PageLink to={Pages.admin.addSponsor}>
                <button className="btn btn-accent desktop-hidden">Sponsors</button>
              </PageLink>
            </div>
          </header>
          <div className="dashboard page">
            <div className="dashboard-content">
              <div className="find-a-shul-header desktop-hidden">
                <h2 className="text-500">Find a Shul</h2>
              </div>

              <div className="flex-justify-space-center">
                <div className="flex-left-center">
                  <select
                    className="dashboard-search-types-dropdown"
                    value={searchType}
                    onChange={this.toogleSearchType}
                  >
                    {Object.keys(searchTypes).map(type => {
                      return (
                        <option key={type} value={type}>
                          {searchTypes[type]}
                        </option>
                      );
                    })}
                  </select>
                  <div className="dashboard-searchbox flex-justify-space-center mr-16">
                    <DebounceInput
                      className="searchbox"
                      minLength={searchType !== 'id' ? 3 : 1}
                      debounceTimeout={300}
                      value={query}
                      onChange={this.adminShulSearch}
                    />
                    <i className="material-icons small-search-icon">search</i>
                  </div>
                </div>
                <div className="adm-buttons">
                  <PageLink to="/admin/add-shul">
                    <button className="btn btn-accent mobile-hidden" onClick={this.clearShulFromRedux}>Add Shul</button>
                  </PageLink>
                  <PageLink to={Pages.admin.addSponsor}>
                    <button className="btn btn-accent mobile-hidden">Sponsors</button>
                  </PageLink>
                </div>
              </div>

              <div className="flex-justify-space flex-align-center">



              </div>

              {loading ? (
                <div className="dashboard-table-loader">
                  <Loader />
                </div>
              ) : total > 0 ? (
                <React.Fragment>
                  <div className="table dashboard-table">
                    {Object.keys(results).map(id => {
                      const { id: shulId, name, rabbi, formatted_address } = results[id];
                      return (
                        <div key={id} className="table-row">
                          <p className="bold-text capitalize-text flex-left-center">{name}</p>
                          <p className="accent-text capitalize-text flex-left-center">{rabbi ? `rabbi ${rabbi}` : ''}</p>
                          <p className="accent-text capitalize-text flex-left-center">{formatted_address}</p>
                          <div className="text-right flex-left-center">
                            <PageLink
                              to={Pages.admin.viewShul}
                              params={{ shulId }}
                            >View</PageLink>
                            <PageLink
                              to={Pages.admin.editShul}
                              params={{ shulId }}
                            >Edit</PageLink>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <ul className="pagination">
                    <li className="pagination-arrow" data-pn={Math.max(1, parseInt(page, 10) - 1)} onClick={this.toogleSearchPages}>
                      <i className="material-icons">keyboard_arrow_left</i>
                    </li>
                    {searchPages}
                    <li className="pagination-arrow" data-pn={Math.min(parseInt(page, 10) + 1, num_of_pages)} onClick={this.toogleSearchPages}>
                      <i className="material-icons">keyboard_arrow_right</i>
                    </li>
                  </ul>
                </React.Fragment>
              ) : (
                    <div className="dashboard-empty-state">
                      <i className="material-icons">search</i>
                      <p>No Search Results</p>
                    </div>
                  )}
            </div>
            <div className="pending-approval-panel">
              <div className="pending-approval-header">
                <h2 className="text-500">Shuls Pending Approval</h2>
              </div>
              <div className="pending-approval-panel-list">
                {Object.keys(pendingShuls).length
                  ? Object.keys(pendingShuls).map(id => pendingShuls[id]).map((shul) =>
                    <PendingShul key={shul.id} shul={shul} actions={actions} />
                  )
                  : <p className="bold-text text-center">NO PENDING SHULS</p>}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function PendingShul(props) {
  const {
    actions: {
      updateShulStatus,
    },
    shul,
  } = props;
  function onClickUpdateStatus(e) {
    const attrib = e.target.attributes;
    updateShulStatus({
      id: parseInt(attrib['data-id'].value, 10),
      status: attrib['data-status'].value
    });
  }
  return (
    <div key={shul.id} className="pending-approval-list-item">
      <p className="medium-text text-500">{shul.name}</p>
      <p className="accent-text mt-8">{shul.fullAddress}</p>
      <div className="pending-approval-actions mt-16 flex-justify-space flex-align-center">
        <div>
          <i
            className="material-icons"
            data-id={shul.id}
            data-status="approved"
            style={{ color: "#17CD90" }}
            onClick={onClickUpdateStatus}>
            check
          </i>
          <i
            className="material-icons ml-8"
            data-id={shul.id}
            data-status="denied"
            style={{ color: "#EF6A6E" }}
            onClick={onClickUpdateStatus}>
            close
          </i>
        </div>
        <PageLink
          to={Pages.admin.viewShul}
          params={{ shulId: shul.id }}
          query={{ pending: true }}
        >
          <i className="material-icons pending-approval-open-icon">open_in_new</i>
        </PageLink>

      </div>
      <ToastContainer />
    </div>
  );
}
