import React from "react";
import Slider from "react-slick";
import "../assets/css/components/sponsor-slider.css";
import { corporateImages } from "../lib/corporateImagesArray.js";

export class SponsorSlider extends React.PureComponent {
  render() {
    const settings = {
      // className: "slider variable-width",
      //infinite: true,
      arrows: false,
      //autoplay: true,
      //autoplay: window.innerWidth < 700 ? true : false,
      slidesToShow: corporateImages.length,
      dots: false,
      //slidesToScroll: 1,
      // speed: 15000,
      // autoplaySpeed: 1000,
      // cssEase: "linear",
      //variableWidth: true,
      //rtl: true,
      responsive: [
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            autoplay: true,
            speed: 3000,
            cssEase: "linear",
            autoplaySpeed: 2000
          }
        }
      ]
    };

    return (
      <Slider {...settings}>
        {corporateImages.map(({ image, link }) => {
          if (link) {
            return (
              <a key={image} href={link}>
                <img src={image} alt="sponsor"  className="sponsor-img"/>
              </a>
            );
          }
          return <img key={image} src={image} alt="sponsor" />;
        })}
      </Slider>
    );
  }
}
