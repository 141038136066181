import React from "react";
import {
  PageLink,
} from "../../lib";
import Pages from '../';

import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/add-edit-shul.css";
import "../../assets/css/pages/suggest-minyanim.css";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from '@material-ui/core';
import { offsetCalculationTypes } from "../../lib/constants";
export default class SuggestMinyanim extends React.PureComponent {
  state = {
    showDialog: false,
    minyanToDelete: null,
    minyan_location_id: ''
  }

  componentDidMount() {
    const {
      actions: {
        getCurrentMinyanim,
        getSuggestedMinyanim,
      },
      pageRoute: { params: { shulId } }
    } = this.props;
    getCurrentMinyanim(+shulId);
    getSuggestedMinyanim(+shulId);
    this.setState({ minyan_location_id: +shulId });
  }

  showDeleteDialog = minyanToDelete => {
    this.setState({ showDialog: true, minyanToDelete });
  }

  cancelDeleteDialog = () => {
    this.setState({ showDialog: false, minyanToDelete: null });
  }

  handleDeleteMinyan = () => {
    this.setState({ showDialog: false });
    const minyanToDelete = JSON.parse(JSON.stringify(this.state.minyanToDelete));
    minyanToDelete.minyan_id = minyanToDelete.id;
    delete minyanToDelete.id;
    this.props.actions.suggestMinyanDeletion({ ...minyanToDelete, action: 'delete' });
  }

  render() {
    const {
      props: {
        pageRoute: {
          params: { shulId }
        },
        currentMinyanim: {
          name = '',
          minyanims: currentMinyanim = {}
        },
        minyanSuggestions
      },
      state: {
        showDialog,
        //minyan_location_id
      }
    } = this;
    const minyanTables = [
      { type: 'shachris', text: 'Current Shacharis Minyanim', isCurrentMinyan: true },
      { type: 'shachris', text: 'Suggested Shacharis Minyanim', isCurrentMinyan: false },
      { type: 'mincha', text: 'Current Mincha Minyanim', isCurrentMinyan: true },
      { type: 'mincha', text: 'Suggested Mincha Minyanim', isCurrentMinyan: false },
      { type: 'mariv', text: 'Current Maariv Minyanim', isCurrentMinyan: true },
      { type: 'mariv', text: 'Suggested Maariv Minyanim', isCurrentMinyan: false },
    ];
    const currentMinyanimHeaders = ['Minyan ID', 'Nusach', 'Days', 'Time', 'Status', 'Notes'];
    const suggestedMinyanimHeaders = ['Minyan ID', 'Action', 'Nusach', 'Days', 'Time', 'Status', 'Notes'];

    return (
      <React.Fragment>
        {showDialog &&
          <Dialog open={true}>
            <div className="delete-warning-dialog">
              <div className="delete-waning-icon">
                <i className="material-icons delete-waning-icon-x">close</i>
              </div>
              <div className="are-you-sure">Are you sure?</div>
              <div className="delete-warning-message">Do you really want to suggest deleting this Minyan?</div>
              <div className="flex-align-middle">
                <div className="delete-warning-cancel" onClick={this.cancelDeleteDialog}>CANCEL</div>
                <div className="delete-warning-delete" onClick={this.handleDeleteMinyan}>DELETE</div>
              </div>
            </div>
          </Dialog>}


        <header className="suggest-minyanim-header">
          <div className="container flex-align-center">
            <PageLink
              className="flex-align-middle back-to-shul-details"
              to={Pages.main.shulDetails}
              params={{ shulId }}
            >
              <i className="material-icons back-to-shul-details-icon">arrow_back</i>
              <span className="mobile-hidden">Shul Details</span>
            </PageLink>
            <h1 className="suggest-minyan-shul-name">{name}</h1>
          </div>
        </header>

        <div className="container suggest-minyanim-content">
          {minyanTables.map(table => {
            const isCurrentMinyan = table.isCurrentMinyan;
            const headersToUse = isCurrentMinyan ? currentMinyanimHeaders : suggestedMinyanimHeaders;
            return (
              <div key={table.text} className="card full-width mt-40 mobile-full-width">
                <p className="large-text text-500 uppercase-text mb-16 mob-medium-text">
                  {table.text}
                </p>
                <div className="">
                  <div className={`${isCurrentMinyan ? 'current-minyan-row' : 'suggested-minyan-row'} minyanim-table-header mobile-hidden`}>
                    {headersToUse.map(header => {
                      return (
                        <p key={header} className="medium-text accent-text-light flex-left-center">{header}</p>
                      );
                    })}
                  </div>
                  {isCurrentMinyan && currentMinyanim[table.type] && currentMinyanim[table.type].map((minyan) => {
                    return renderMinyan(minyan, isCurrentMinyan, this.showDeleteDialog);
                  })}
                  {!table.isCurrentMinyan && minyanSuggestions[table.type] && minyanSuggestions[table.type].map((minyan, index) => {
                    return renderMinyan(minyan, isCurrentMinyan, null, index);
                  })}
                </div>
                {isCurrentMinyan &&
                  <PageLink
                    to={Pages.main.addNewMinyanSuggestion}
                    params={{ shulId }}
                    query={{ type: table.type }}
                  >
                    <button className="btn btn-accent btn-small mt-32">
                      Add Minyan
                </button>
                  </PageLink>}
              </div>
            );
          })}
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

function renderMinyan(minyan, isCurrentMinyan, showDeleteDialog, index) {
  const {
    id,
    minyan_id,
    action,
    start_date,
    end_date,
    type,
    minyan_location_id,
    nusach,
    days = [],
    special_days_included,
    special_days_only,
    time_at,
    zman_type,
    offset_minutes,
    offset_calculated_by,
    offset_rounded_by,
    notes,
    not_later_than,
    not_earlier_than
  } = minyan;
  const isDelete = action === 'delete' ? true : false;
  const status = start_date && end_date
    ? `${formatDate(start_date)} - ${formatDate(end_date)}`
    : isDelete ? ''
      : "All year round";

  return (
    <div key={id || index} className={`${isCurrentMinyan ? 'current-minyan-row' : 'suggested-minyan-row'}`}>
      <div className="flex-justify-space-center">
        <div><span className="desktop-hidden">{isCurrentMinyan || minyan_id ? 'Minyan ID:' : ''}</span> {isCurrentMinyan ? id : minyan_id ? minyan_id : ''}</div>
        {isCurrentMinyan &&
          <div className="desktop-hidden flex-align-center">
            <PageLink
              to={Pages.main.minyanUpdateSuggestion}
              params={{ shulId: minyan_location_id, minyanId: id }}
              query={{ type }}
            >
              <i className="material-icons edit-icon mob-text-18">
                edit
            </i>
            </PageLink>
            <i
              data-minyan-id={id}
              className="material-icons mob-text-18"
              onClick={() => showDeleteDialog(minyan)}
            >
              delete
          </i>
          </div>}
      </div>
      {!isCurrentMinyan &&
        <div className="capitalize-text flex-left-center"><span className="desktop-hidden">Action:&nbsp;</span>  {action}</div>}
      {!isDelete && <p className="capitalize-text flex-left-center">{nusach ? nusach.replace(/_/, ' ') : ''}</p>}
      {!isDelete && <div className="flex-left-center-columns">
        <p className="flex-left-center capitalize-text">{days.join(", ")}</p>
        {special_days_included.length > 0 && (
          <p className="xs-text accent-text flex-left-center">
            Includes: {special_days_included.join(", ")}
          </p>
        )}
        {special_days_only.length > 0 && (
          <p className="xs-text accent-text flex-left-center">
            Only: {special_days_only.join(", ")}
          </p>
        )}
      </div>}
      {!isDelete && <div className="flex-left-center">
        {time_at ? getMinyanTime(time_at) : zman_type
          ? <div>
            <span>{getOffestText(offset_minutes)}</span>
            <span className="capitalize-text">{zman_type.name.replace(/_/, ' ')}</span>
            {offset_calculated_by ? renderCalculatedBy(offset_calculated_by) : ''}
            {offset_rounded_by ? renderRoundedBy(offset_rounded_by) : ''}
            {not_earlier_than ? renderNotEarlierAndLater('earlier', not_earlier_than) : ''}
            {not_later_than ? renderNotEarlierAndLater('later', not_later_than) : ''}
          </div>
          : ''}
      </div>}
      {!isDelete && <p className="flex-left-center">{status}</p>}
      {!isDelete && <p className="flex-left-center mobile-minyan-notes">{notes}</p>}
      {isCurrentMinyan &&
        <div className="shul-info-table-actions flex-right-center mobile-hidden">
          <PageLink
            to={Pages.main.minyanUpdateSuggestion}
            params={{ shulId: minyan_location_id, minyanId: id }}
            query={{ type }}
          >
            <i className="material-icons edit-icon">
              edit
          </i>
          </PageLink>
          <i
            className="material-icons"
            onClick={() => showDeleteDialog(minyan)}
          >
            delete
        </i>
        </div>}
    </div>
  );
}

function formatDate(date) {
  const months = {
    '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June',
    '07': 'July', '08': 'Aug', '09': 'Sept', '10': 'Oct', '11': 'Nov', '12': 'Dec'
  };
  const day = date.slice(8, 10);
  const month = months[date.slice(5, 7)];
  //const year = date.slice(0, 4);
  return `${month} ${day}`;
}

function getMinyanTime(time) {
  const suffix = time.slice(0, 2) >= 12 ? "PM" : "AM";
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  return hours + minutes + ' ' + suffix;
}

function getOffestText(offsetMinutes) {
  let offsetText;
  switch (true) {
    case offsetMinutes === 0:
      offsetText = `at `;
      break;
    case offsetMinutes > 0:
      offsetText = `${offsetMinutes} minutes after `;
      break;
    default:
      offsetText = `${-offsetMinutes} minutes before `;
  }
  return offsetText;
}

function renderCalculatedBy(calculatedBy) {
  return <p className="xs-text accent-text">{`calculated by ${offsetCalculationTypes[calculatedBy]}`}</p>;
}

function renderRoundedBy(roundedBy) {
  const roundedByOptions = {
    on_time: 'at the exact time',
    earlier_5: 'rounded to earlier 5 minutes',
    later_5: 'rounded to later 5 minutes'
  };

  return (
    <p className="xs-text accent-text">
      {roundedByOptions[roundedBy]}
    </p>
  );
}

function renderNotEarlierAndLater(beforeOrAftertext, time) {
  return <p className="xs-text accent-text">{`not ${beforeOrAftertext} than ${getMinyanTime(time)}`}</p>;
}