import EditShul from "../admin/EditShul.vm";
import ViewShul from "../admin/ViewShul.vm";
import Minyan from "../admin/Minyan.vm";
import Contact from "../admin/Contact.vm";
import EditAccount from "../admin/EditAccount.vm";
import AddAccount from "../admin/AddAccount.vm";
import MinyanSuggestions from "../admin/MinyanSuggestions.vm";
import Event from "../admin/Event.vm";
import { addPageLayouts } from "../../lib";

export const ShulAdminPages = addPageLayouts("shulAdmin", {
  myShulsView: {
    anon: false,
    path: "/my-shuls/view",
    pathExact: true,
    type: 'MY_SHULS',
    view: ViewShul,
    title: 'My Shuls'
  },
  myShulsEditShul: {
    anon: false,
    path: "/my-shuls/shul/:shulId/edit",
    pathExact: true,
    type: 'MY_SHULS_EDIT_SHUL',
    view: EditShul,
    title: "Edit Shul"
  },
  myShulsAddMinyan: {
    anon: false,
    path: "/my-shuls/shul/:shulId/add-minyan",
    pathExact: true,
    type: "MY_SHULS_ADD_MINYAN",
    view: Minyan,
    title: "Add Minyan",
    layout: null
  },
  myShulsAddEvent: {
    anon: false,
    path: "/my-shuls/shul/:shulId/add-event",
    pathExact: true,
    type: "MY_SHULS_ADD_EVENT",
    view: Event,
    title: "Add Event",
    layout: null
  },
  myShulsEditEvent: {
    anon: false,
    path: "/my-shuls/shul/:shulId/edit-event/:eventId",
    pathExact: true,
    type: "MY_SHULS_EDIT_EVENT",
    view: Event,
    title: "Edit Event",
    layout: null
  },
  myShulsEditMinyan: {
    anon: false,
    path: "/my-shuls/shul/:shulId/minyan/:minyanId/edit",
    pathExact: true,
    type: 'MY_SHULS_EDIT_MINYAN',
    view: Minyan,
    title: 'Edit Minyan',
    layout: null
  },
  myShulsAddContact: {
    anon: false,
    path: "/my-shuls/shul/:shulId/add-contact",
    pathExact: true,
    view: Contact,
    type: 'MY_SHULS_ADD_CONTACT',
    title: 'Add Contact',
    layout: null
  },
  myShulsEditContact: {
    anon: false,
    path: "/my-shuls/shul/:shulId/contact/:contactId/edit",
    pathExact: true,
    view: Contact,
    type: 'MY_SHULS_EDIT_CONTACT',
    title: 'Edit Contact',
    layout: null
  },
  myShulsAddAccount: {
    anon: false,
    path: "/my-shuls/shul/:shulId/add-account",
    pathExact: true,
    view: AddAccount,
    type: 'MY_SHULS_ADD_ACCOUNT',
    title: 'Add Shul Admin',
    layout: null
  },
  myShulsEditAccount: {
    anon: false,
    path: "/my-shuls/edit-account",
    pathExact: true,
    type: 'MY_SHULS_EDIT_ACCOUNT',
    view: EditAccount,
    title: 'Edit Account',
    layout: null
  },
  myShulsMinyanSuggestions: {
    anon: false,
    path: "/my-shuls/minyan-suggestions",
    pathExact: true,
    type: 'MY_SHULS_MINYAN_SUGGESTIONS',
    view: MinyanSuggestions,
    title: 'Minyan Suggestions'
  },
});
export default ShulAdminPages;

export const ShulAdminArea = {
  pages: ShulAdminPages,
};
