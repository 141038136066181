import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MinyanActions } from '../../state';
import { AccountActions } from '../../state';
import Minyan from './Minyan';

export default connect(function mapState(state, ownProps) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    minyan: {
      minyanFailed,
      activeMinyan
    },
    account: {
      authorized
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    activeMinyan,
    minyanFailed,
    authorized
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(MinyanActions, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
})(Minyan);
