import {
  APIClient,
  createToast,
} from '../../lib';

export const EventActions = {
  EVENT_GET_BY_ID: 'EVENT_GET_BY_ID',
  UPDATE_SUBMISSION_STATUS: 'UPDATE_SUBMISSION_STATUS',
  LOAD_EVENTS: 'LOAD_EVENTS',
  UPDATE_LOADING: 'UPDATE_LOADING',

  getEvents(shulId) {
    return async (dispatch, getState) => {
      const { data, status } = await APIClient.get(`/shul/${shulId}/events`);
      if (status === 200) {
        dispatch({
          type: EventActions.LOAD_EVENTS,
          data
        });
      } else {
        createToast(null, `Oops something went wrong`, 'warning', true);
      }
    };
  },
  getEvent(shulId, id) {
    return async (dispatch, getState) => {
      dispatch({
        type: EventActions.UPDATE_LOADING,
        loading: true
      });
      const { data, status } = await APIClient.get(`/shul/${shulId}/events/${id}`).catch(error => {
        console.error(error);
        return { status: 500 };
      });
      if (status === 200) {
        dispatch({
          type: EventActions.EVENT_GET_BY_ID,
          payload: data
        });
        dispatch({
          type: EventActions.UPDATE_LOADING,
          loading: false
        });
      } else {
        dispatch({
          type: EventActions.UPDATE_SUBMISSION_STATUS,
          eventFailed: true
        });
        dispatch({
          type: EventActions.UPDATE_LOADING,
          loading: false
        });
      }
    };
  },
  addEvent(shulId, formData) {
    return async (dispatch, getState) => {
      const { data, status } = await APIClient.post(`/shul/${shulId}/events`, formData);
      if (status === 200) {
        dispatch({
          type: EventActions.LOAD_EVENTS,
          data
        });
        dispatch({
          type: EventActions.UPDATE_SUBMISSION_STATUS,
          eventFailed: false
        });
      } else {
        createToast(null, `Oops something went wrong`, 'warning', true);
        dispatch({
          type: EventActions.UPDATE_SUBMISSION_STATUS,
          eventFailed: true
        });
      }
    };
  },
  updateEvent(shulId, id, formData) {
    return async (dispatch, getState) => {
      const { data, status } = await APIClient.put(`/shul/${shulId}/events/${id}`, formData);
      if (status === 200) {
        dispatch({
          type: EventActions.LOAD_EVENTS,
          data
        });
        dispatch({
          type: EventActions.UPDATE_SUBMISSION_STATUS,
          eventFailed: false
        });
      } else {
        createToast(null, `Oops something went wrong`, 'warning', true);
        dispatch({
          type: EventActions.UPDATE_SUBMISSION_STATUS,
          eventFailed: true
        });
      }
    };
  },
  deleteEvent(shulId, id) {
    return async (dispatch, getState) => {
      const { data, status } = await APIClient.delete(`/shul/${shulId}/events/${id}`);
      if (status === 200) {
        dispatch({
          type: EventActions.LOAD_EVENTS,
          data
        });
      } else {
        createToast(null, `Oops something went wrong`, 'warning', true);
      }
    };
  },
};
