import React from 'react';
import Modal from 'react-modal';
import '../assets/css/pages/modal.css';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.65)';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none'
  }
};

export class DeleteModal extends React.PureComponent {

  deleteRecord = async() => {
    await this.props.deleteRecord();
    this.closeModal();
  }

  closeModal = () => {
    this.props.uiActions.toggleDeleteModal(false);
  }
  render() {
    return (<Modal
      isOpen={this.props.confirmDelete}
      onRequestClose={this.closeModal}
      style={customStyles}
      contentLabel="Warning"
    >
      <div className="overlay">
        <div className="x-container">
          <i className="material-icons x">
            highlight_off
            </i>
        </div>
        <div className="message-container">
          <h1>Are You Sure?</h1>
          <div className="message">
            <h2>{this.props.message}</h2>
          </div>
        </div>
        <div className="buttons-container flex-justify-space">
          <button
            onClick={this.closeModal}
            className="button cancel">
            Cancel</button>
          <button
            onClick={this.deleteRecord}
            className="button delete"
          >Delete</button>
        </div>
      </div>
    </Modal>);
  }
}
