import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MinyanActions } from '../../state';
import SuggestMinyanim from './SuggestMinyanim';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    minyan: {
      currentMinyanim,
      minyanSuggestions
    },
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    currentMinyanim,
    minyanSuggestions
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(MinyanActions, dispatch)
  };
})(SuggestMinyanim);
