import Campaign from "./Campaign";
import MissingInfoList from "./MissingInfoList";
import Form from "./Form";
import ThankYou from "./ThankYou";
import NoResults from "./NoResults";

export const CampaignPages = {
  campaign: {
    anon: true,
    path: "/chezky",
    pathExact: true,
    type: "CAMPAIGN",
    view: Campaign,
    title: "Campaign",
    noSearchBar: true
  },
  campaignDummy: {
    anon: true,
    path: "/campaign",
    pathExact: true,
    type: "CAMPAIGN_DUMMY",
    view: Campaign,
    title: "Campaign",
    noSearchBar: true
  },
  form: {
    anon: true,
    path: "/chezky/form",
    pathExact: true,
    type: "CAMPAIGN_FORM",
    view: Form,
    title: "Campaign Form",
    noSearchBar: true
  },
  MissingInfoList: {
    anon: true,
    path: "/chezky/missing-info-list",
    pathExact: true,
    type: "MISSING_INFO_LIST",
    view: MissingInfoList,
    title: "Missing Info List",
    noSearchBar: true
  },
  thankYou: {
    anon: true,
    path: "/chezky/thank-you",
    pathExact: true,
    type: "CAMPAIGN_THANK_YOU",
    view: ThankYou,
    title: "Campaign thank You",
    noSearchBar: true
  },
  noResults: {
    anon: true,
    path: "/chezky/no-results",
    pathExact: true,
    type: "CAMPAIGN_NO_RESULTS",
    view: NoResults,
    title: "Campaign no results",
    noSearchBar: true
  }
};
export default CampaignPages;

export const CampaignArea = {
  pages: CampaignPages
};
