import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { EventActions } from '../../state';
import { AccountActions } from '../../state';
import Event from './Event';

export default connect(function mapState(state, ownProps) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    event: {
      activeEvent,
      loading
    },
    account: {
      authorized
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    loading,
    activeEvent,
    authorized
  };
}, function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(EventActions, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
})(Event);
