import React from 'react';

import {
  APIClient,
  createToast
} from "../../lib";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Unsubscribe extends React.PureComponent {
  async componentDidMount(){
    const{email, jwt} = this.props.pageRoute.query;
    const res = await APIClient.post('/unsubscribed-emails', {email, jwt})
    .catch((e)=> console.error(e));
    if(!res || !res.data || !res.data.emailRemoved){
      return createToast(null, 'Something went wrong unsubscribing your email, please try again later or contact support fro help');
    }
  }


  render() {
    return(
      <React.Fragment>
        <div
          style={{
            height: 'calc(100vh - 280px)',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '24px',
            paddingTop: '36px'
          }}
        >
          Sorry to see you go!
        </div>
        <ToastContainer/>
      </React.Fragment>
    );
  }
}