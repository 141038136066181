import {
  APIClient,
  Navigation,
  arrayToObjectById,
  arrayToObject,
  createToast,
} from '../../lib';

export const ShulActions = {
  SHUL_SEARCH_FETCHING: 'SHUL_SEARCH_FETCHING',
  SHUL_SEARCH_RESULTS: 'SHUL_SEARCH_RESULTS',
  SHUL_CLEAR_SEARCH_RESULTS_FROM_REDUX: 'SHUL_CLEAR_SEARCH_RESULTS_FROM_REDUX',
  SHUL_GET: 'SHUL_GET',
  SHUL_DETAILS: 'SHUL_DETAILS',
  SHUL_GET_PENDING: 'SHUL_GET_PENDING',
  SHUL_REMOVE_PENDING: 'SHUL_REMOVE_PENDING',
  SHUL_UPDATE: 'UPDATE_SHUL',
  SHUL_FAILURE: 'SHUL_FAILURE',
  SHUL_ADD: 'SHUL_ADD',
  SHUL_DELETE: 'SHUL_DELETE',
  SHUL_CLEAR_FROM_REDUX: 'SHUL_CLEAR_FROM_REDUX',
  SHUL_GET_MINE: 'SHUL_GET_MINE',
  SHUL_RESET_NEW_END_TIME: 'SHUL_RESET_NEW_END_TIME',
  SHUL_RESET_RECURSIVE_SEARCHING_NEXT_DAY: 'SHUL_RESET_RECURSIVE_SEARCHING_NEXT_DAY',
  SHUL_RESET_NEW_DISTANCE: 'SHUL_RESET_NEW_DISTANCE',
  GET_COVID_POLICY_OPTIONS: 'GET_COVID_POLICY_OPTIONS',
  SHUL_COVID_POLICY: 'SHUL_COVID_POLICY',

  getCovidPolicies() {
    return async (dispatch, getState) => {
      const response = await APIClient.get(`/covid-policies`)
        .catch(e => {
          console.error(e);
          if (e.response && e.response.status === 404) {
            createToast(null, `Oops something went wrong`, 'warning', true);
          }
        });

      if (response && response.data) {
        dispatch({
          type: ShulActions.GET_COVID_POLICY_OPTIONS,
          payload: response.data
        });
      }
    };
  },

  updateCovidPolicy({ shulId, policy_id }) {
    return async (dispatch, getState) => {
      APIClient.put(`/minyanim/${shulId}/covid-policy`, { policy_id })
        .then(r => {
          createToast(null, `Updated Successfully`, 'success');
        })
        .catch(e => {
          console.error(e);
          createToast(null, `Update Failed`, 'warning', true);
        });
    };
  },

  getShulCovidPolicy(shulId) {
    return async (dispatch, getState) => {
      const response = await APIClient.get(`/minyanim/${shulId}/covid-policy`)
        .catch(e => {
          console.error(e);
        });

      if (response && response.data) {
        dispatch({
          type: ShulActions.SHUL_COVID_POLICY,
          payload: response.data
        });
      }
    };
  },

  addShul(shul) {
    return async () => {
      const response = await APIClient.post(`/shuls`, shul).catch(console.error);

      if (response && response.data) {
        const { showLocationExistsWarning, existingShulNames, shulId } = response.data;
        if (showLocationExistsWarning) {
          return { showLocationExistsWarning: true, existingShulNames };
        } else if (shulId) {
          return { shulId };
        }
      }

      return {};
    };
  },

  addShulWithSubmitter(postObj) {
    return async () => {
      const response = await APIClient.post(`/shuls/with-submitter`, postObj).catch(console.error);

      if (response && response.data) {
        const { showLocationExistsWarning, existingShulNames } = response.data;
        if (showLocationExistsWarning) {
          return { showLocationExistsWarning: true, existingShulNames };
        } else {
          return {
            shulCreated: true,
            emailFailed: response.data.status === 'email not sent' ? true : false
          };
        }
      }

      return {};
    };
  },

  deleteShul(shulId) {
    return async (dispatch, getState) => {
      const response = await APIClient.delete(`/shuls/${shulId}`)
        .catch(e => {
          console.error(e);
          if (e.response && e.response.status === 404) {
            createToast(null, `We can't find the Shul you are trying to delete, please contact support`, 'warning', true);
          }
        });

      if (response && response.data) {
        dispatch({
          type: ShulActions.SHUL_DELETE,
        });
        return true;
      }
      return false;
    };
  },

  resetShulWithSubmitter() {
    return { type: ShulActions.SHUL_WITH_SUBMITTER_RESET };
  },

  updateShul(updates) {
    return async () => {
      const response = await APIClient.put(`/shuls/${updates.id}`, updates).catch(console.error);

      if (response && response.data) {
        return { shulUpdated: true };
      }
      return {};
    };
  },

  updateShulLocation(shulId, shulLocationObj) {
    return async () => {
      const response = await APIClient.put(`/shuls/${shulId}/location`, shulLocationObj).catch(console.error);

      if (response && response.data) {
        const { showLocationExistsWarning, existingShulNames } = response.data;
        if (showLocationExistsWarning) {
          return { showLocationExistsWarning: true, existingShulNames };
        } else {
          return { locationUpdated: true };
        }
      }
      return {};
    };
  },

  updateShulStatus(update) {
    return async (dispatch, getState) => {
      const response = await APIClient.put(`/shuls/${update.id}`, update).catch(console.error);

      if (response && response.data) {
        dispatch({
          type: ShulActions.SHUL_REMOVE_PENDING,
          payload: update.id
        });
      } else {
        dispatch({ type: ShulActions.SHUL_FAILURE });
      }
    };
  },

  searchShulsByNameOrRabbi(fromHistory, query = '', pageNumber, mode, nusach = '', tefillah = '', day = '', currentTime = '', todaysDayOfWeek = '', usersDate = '') {
    if (!fromHistory) {
      Navigation.go(
        `/search?mode=${mode}&q=${encodeURIComponent(query)}&pagenumber=${pageNumber}&nusach=${nusach}&tefillah=${tefillah}&day=${day}`
      );
    }

    if (query.length < 1) {
      return {
        type: ShulActions.SHUL_SEARCH_RESULTS,
        payload: {
          total: 0,
          num_of_pages: 0,
          results: {},
        },
      };
    }
    return async (dispatch, getState) => {
      dispatch({ type: ShulActions.SHUL_SEARCH_FETCHING });

      let apiString = `/shuls/search?type=${mode}&query=${encodeURIComponent(query)}&pagenumber=${pageNumber}&users_date=${usersDate}` +
        `&nusach=${nusach}&tefillah=${tefillah}&day=${day}&current_time=${currentTime}&todays_day=${todaysDayOfWeek}`;

      const response = await APIClient.get(apiString).catch(console.error);
      if (response && response.data) {
        const {
          total,
          num_of_pages,
          shuls,
          pins,
          isRoshChodesh
        } = response.data;
        const sortIds = [];
        const markers = [];

        dispatch({
          type: ShulActions.SHUL_SEARCH_RESULTS,
          payload: {
            total,
            num_of_pages,
            sortIds,
            results: arrayToObjectById(shuls, sortIds),
            pins: arrayToObjectById(pins, markers),
            isRoshChodesh
          }
        });
      }
    };
  },

  searchShulsByAll(fromHistory, query = '', pageNumber, nusach, tefillah, day, currentTime, todaysDayOfWeek, usersDate = '') {
    if (!fromHistory) {
      Navigation.go(
        `/search?mode=all&q=${encodeURIComponent(query)}&pagenumber=${pageNumber}` +
        `&nusach=${nusach}&tefillah=${tefillah}&day=${day}`
      );
    }

    if (query.length < 1) {
      return {
        type: ShulActions.SHUL_SEARCH_RESULTS,
        payload: {
          total: 0,
          num_of_pages: 0,
          results: {},
        },
      };
    }
    return async (dispatch, getState) => {
      dispatch({ type: ShulActions.SHUL_SEARCH_FETCHING });

      let apiString = `/shuls/search-all?&query=${encodeURIComponent(query)}&pagenumber=${pageNumber}&users_date=${usersDate}` +
        `&nusach=${nusach}&tefillah=${tefillah}&day=${day}&current_time=${currentTime}&todays_day=${todaysDayOfWeek}`;

      const response = await APIClient.get(apiString).catch(console.error);
      if (response && response.data) {
        const {
          total,
          num_of_pages,
          shuls,
          pins,
          isRoshChodesh
        } = response.data;
        const sortIds = [];
        const markers = [];

        dispatch({
          type: ShulActions.SHUL_SEARCH_RESULTS,
          payload: {
            total,
            num_of_pages,
            sortIds,
            results: arrayToObjectById(shuls, sortIds),
            pins: arrayToObjectById(pins, markers),
            isRoshChodesh
          }
        });
      }
    };
  },

  searchShulsWithInRadius(fromHistory, address = '', lat, lng, pageNumber = 1, distance = 10, nusach = '', tefillah = '', day = '', currentTime, todaysDayOfWeek, usersDate = '') {
    if (!lat) return;
    if (!fromHistory) {
      Navigation.go(
        `/search?mode=location&address=${encodeURIComponent(address)}&lat=${lat}&lng=${lng}&pagenumber=${pageNumber}` +
        `&distance=${distance}&nusach=${nusach}&tefillah=${tefillah}&day=${day}`
      );
    }

    return async (dispatch, getState) => {
      dispatch({ type: ShulActions.SHUL_SEARCH_FETCHING });

      const response = await APIClient.get(
        `/shuls/radius-search?lat=${lat}&lng=${lng}&distance=${distance}&pagenumber=${pageNumber}&nusach=${nusach}` +
        `&tefillah=${tefillah}&day=${day}&current_time=${currentTime}&todays_day=${todaysDayOfWeek}&users_date=${usersDate}`
      ).catch(console.error);

      if (response && response.data) {
        const {
          total,
          num_of_pages,
          shuls,
          pins,
          isRoshChodesh,
          newDistance,
        } = response.data;
        const sortIds = [];
        const markers = [];

        dispatch({
          type: ShulActions.SHUL_SEARCH_RESULTS,
          payload: {
            total,
            num_of_pages,
            sortIds,
            results: arrayToObjectById(shuls, sortIds),
            pins: arrayToObjectById(pins, markers),
            isRoshChodesh,
            newDistance,
          }
        });
      }
    };
  },

  searchShulsByNextMinyan(fromHistory, address, dayOfWeek, start, end, lat, lng, pageNumber = 1, distance = 10, nusach = '', tefillah = '', usersDate = '', isTomorrowSearch = false) {
    if (!lat) return;

    if (!fromHistory) {
      Navigation.go(
        `/search?mode=location&address=${encodeURIComponent(address)}&next_minyan_day=${dayOfWeek}&start=${start}&end=${end}` +
        `&lat=${lat}&lng=${lng}&pagenumber=${pageNumber}&distance=${distance}&nusach=${nusach}&tefillah=${tefillah}`
      );
    }

    return async (dispatch, getState) => {
      dispatch({ type: ShulActions.SHUL_SEARCH_FETCHING });

      const response = await APIClient.get(
        `/minyanim/search?day=${dayOfWeek}&start=${start}&end=${end}&lat=${lat}&lng=${lng}&tomorrow_search=${isTomorrowSearch}` +
        `&pagenumber=${pageNumber}&distance=${distance}&nusach=${nusach}&tefillah=${tefillah}&users_date=${usersDate}`
      ).catch(console.error);

      if (response && response.data) {
        const {
          total,
          num_of_pages,
          shuls,
          pins,
          isRoshChodesh,
          newEndTime,
          recursiveSearchingNextDay,
        } = response.data;
        const sortIds = [];
        const markers = [];

        dispatch({
          type: ShulActions.SHUL_SEARCH_RESULTS,
          payload: {
            total,
            num_of_pages,
            sortIds,
            results: arrayToObject(shuls, 'minyan_id', sortIds),
            pins: arrayToObjectById(pins, markers),
            isRoshChodesh,
            newEndTime,
            recursiveSearchingNextDay,
          }
        });
      }
    };
  },

  adminSearchShuls(type, query, pageNumber) {
    Navigation.redirect(
      `/admin?mode=${type}&q=${query}&pagenumber=${pageNumber}`
    );

    if (query.length < 1) {
      return {
        type: ShulActions.SHUL_SEARCH_RESULTS,
        payload: {
          total: 0,
          num_of_pages: 0,
          results: {},
        },
      };
    }

    return async (dispatch, getState) => {
      dispatch({ type: ShulActions.SHUL_SEARCH_FETCHING });

      const response = await APIClient.get(`/shuls/admin-search-shuls?type=${type}&query=${query}&pagenumber=${pageNumber}`).catch(console.error);

      if (response && response.data) {
        const {
          total,
          num_of_pages,
          shuls,
        } = response.data;
        const sortIds = [];

        dispatch({
          type: ShulActions.SHUL_SEARCH_RESULTS,
          payload: {
            total,
            num_of_pages,
            sortIds,
            results: arrayToObjectById(shuls, sortIds),
          }
        });
      }
    };
  },

  adminSearchContacts(query, pageNumber) {
    Navigation.redirect(
      `/admin?mode=contacts&q=${query}&pagenumber=${pageNumber}`
    );

    if (query.length < 1) {
      return {
        type: ShulActions.SHUL_SEARCH_RESULTS,
        payload: {
          total: 0,
          num_of_pages: 0,
          results: {},
        },
      };
    }

    return async (dispatch, getState) => {
      dispatch({ type: ShulActions.SHUL_SEARCH_FETCHING });

      const response = await APIClient.get(`/shuls/admin-search-contacts?query=${query}&pagenumber=${pageNumber}`).catch(console.error);

      if (response && response.data) {
        const {
          total,
          num_of_pages,
          shuls,
        } = response.data;
        const sortIds = [];

        dispatch({
          type: ShulActions.SHUL_SEARCH_RESULTS,
          payload: {
            total,
            num_of_pages,
            sortIds,
            results: arrayToObjectById(shuls, sortIds),
          }
        });
      }
    };
  },

  adminSearchShulsById(queryId) {
    Navigation.redirect(
      `/admin?mode=id&q=${queryId}`
    );

    if (queryId.length < 1) {
      return {
        type: ShulActions.SHUL_SEARCH_RESULTS,
        payload: {
          total: 0,
          num_of_pages: 0,
          results: {},
        },
      };
    }

    return async (dispatch, getState) => {
      dispatch({ type: ShulActions.SHUL_SEARCH_FETCHING });

      const response = await APIClient.get(`/shuls/search-by-id?query=${queryId}`).catch(console.error);
      if (response && response.data) {
        const {
          total,
          num_of_pages,
          shuls,
        } = response.data;
        const sortIds = [];

        dispatch({
          type: ShulActions.SHUL_SEARCH_RESULTS,
          payload: {
            total,
            num_of_pages,
            sortIds,
            results: arrayToObjectById(shuls, sortIds),
          }
        });
      }
    };
  },

  getShulById(shulId) {
    return async (dispatch, getState) => {

      const response = await APIClient.get(`/shuls/${shulId}`)
        .catch(e => {
          console.error(e);
          if (e.response && e.response.status === 404) {
            createToast(null, `We can't find the Shul you are looking for`, 'warning', true);
          }
        });

      const shacharisSortedIds = [];
      const minchaSortedIds = [];
      const maarivSortedIds = [];

      if (response && response.data) {
        const {
          minyanims: {
            shachris,
            mincha,
            mariv
          },
          display_contacts
        } = response.data;

        const shulInfo = {
          ...response.data,
          shacharisMinyanims: shachris ? arrayToObjectById(shachris, shacharisSortedIds) : {},
          shacharisSortedIds,
          minchaMinyanims: mincha ? arrayToObjectById(mincha, minchaSortedIds) : {},
          minchaSortedIds,
          maarivMinyanims: mariv ? arrayToObjectById(mariv, maarivSortedIds) : {},
          maarivSortedIds,
          contacts: arrayToObjectById(display_contacts)
        };

        dispatch({
          type: ShulActions.SHUL_GET,
          results: shulInfo
        });
      } else {
        //if request failed remove previous results from redux
        dispatch({
          type: ShulActions.SHUL_GET,
          results: {
            shacharisMinyanims: {},
            shacharisSortedIds,
            minchaMinyanims: {},
            minchaSortedIds,
            maarivMinyanims: {},
            maarivSortedIds,
            contacts: {}
          }
        });
      }
    };
  },

  getShulDetails(shulId) {
    return async (dispatch, getState) => {
      const response = await APIClient.get(`/shuls/${shulId}/details`)
        .catch(e => {
          console.error(e);
          if (e.response && e.response.status === 404) {
            createToast(null, `We can't find the Shul you are looking for`, 'warning', true);
            Navigation.go("/not-found");
          }
        });
      if (response && response.data) {
        const {
          groupedByDayMinyanim,
          groupedContacts
        } = response.data;
        const shulInfo = {
          ...response.data,
          groupedByDayMinyanim,
          groupedContacts
        };

        dispatch({
          type: ShulActions.SHUL_DETAILS,
          results: shulInfo
        });
        return true;
      }
    };
  },

  adminGetPending() {
    return async (dispatch, getState) => {
      const response = await APIClient.get('/shuls/pending').catch(console.error);

      if (response && response.data) {
        dispatch({
          type: ShulActions.SHUL_GET_PENDING,
          results: arrayToObjectById(response.data)
        });
      }
    };
  },

  clearShulFromRedux() {
    return { type: ShulActions.SHUL_CLEAR_FROM_REDUX };
  },

  clearSearchResultsFromRedux() {
    return { type: ShulActions.SHUL_CLEAR_SEARCH_RESULTS_FROM_REDUX };
  },

  resetNewEndTime() {
    return { type: ShulActions.SHUL_RESET_NEW_END_TIME };
  },

  resetRecursiveSearchingNextDay() {
    return { type: ShulActions.SHUL_RESET_RECURSIVE_SEARCHING_NEXT_DAY };
  },

  resetNewDistance() {
    return { type: ShulActions.SHUL_RESET_NEW_DISTANCE };
  },

  getMyShuls() {
    return async (dispatch, getState) => {
      const response = await APIClient.get('/shuls/mine').catch(console.error);

      if (response && response.data) {
        dispatch({
          type: ShulActions.SHUL_GET_MINE,
          results: response.data
        });
      }
    };
  },
};
