import React from "react";
import "./banner.css";

export default class Banner extends React.PureComponent {
  render() {
    return (
        <div className="c-banner">
         <div className="">
         THIS CAMPAIGN IS IN MEMORY OF 
        </div>
        &nbsp;
        <div className="c-bergman">
        YECHEZKEL SHRAGA ZT”L BEN AVROHOM YEHUDA BERGMAN 
        </div>
        </div>
    );
  }
}
