import React from "react";
import { createToast, Navigation } from "../../lib";
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/add-edit-shul.css";
import "../../assets/css/pages/contact.css";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  add: true,
  shul_id: '',
  minyan_role: '',
  title: '',
  first_name: '',
  last_name: '',
  display_phones: [],
  display_emails: [],
  submitted: false,
  contactSuccess: false
};

export default class Contact extends React.PureComponent {

  state = { ...initialState }

  async componentDidMount() {
    await this.props.accountActions.checkUsersAuth();

    if (!this.props.authorized) {
      Navigation.redirect('/?displayLogin=true');
      return;
    }

    window.scrollTo(0, 0);
    // TODO if trying to edit with no id
    this.loadProps();
  }

  handleAdd = type => {
    let stateArray = [...this.state[type]];
    stateArray.push("");

    this.setState({
      [type]: stateArray
    });
  }

  handlePhoneEmail = (e, property, index) => {
    let { value, name } = e.target;

    if (value && property === 'number') {
      value = value.replace(/[()-]/g, '');
    }

    const arrayCopy = [...this.state[name]];
    arrayCopy[index] = { ...arrayCopy[index], [property]: value };

    this.setState({ [name]: arrayCopy });
  }

  removeEmailOrPhone = (field, index) => {
    this.setState({ [field]: this.state[field].filter((_, i) => i !== index) });
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = async mode => {
    if (this.state.contactSuccess) return;
    this.setState({ submitted: true });
    const {
      shul_id,
      id,
      first_name,
      last_name,
      minyan_role,
      title,
      display_phones,
      display_emails
    } = this.state;

    if (!minyan_role || !title || !first_name || !last_name) {
      createToast(null, 'Please fill in all required fields!');
      return;
    }
    this.setState({ contactSuccess: true });
    const contactObject = {
      shul_id,
      first_name,
      last_name,
      minyan_role,
      title
    };

    contactObject.display_phones = display_phones.filter(element => element.number);
    contactObject.display_emails = display_emails.filter(element => element.email);

    if (this.state.add) {
      await this.props.actions.addContact(contactObject);
    } else {
      contactObject.id = id;
      await this.props.actions.editContact(contactObject);
    }

    if (!this.props.contactFailed) {
      this.setState({ submitted: false });
      setTimeout(() => this.handleRedirect(mode), 1500);
    } else {
      this.setState({ contactSuccess: false });
    }
  }

  handleRedirect = (mode) => {
    if (mode === 'save') {
      this.props.pageRoute.history.goBack();
    } else if (mode === 'add') {
      this.setState(prevSate => ({ ...initialState, shul_id: prevSate.shul_id }));
    }
  }

  loadProps = async () => {
    const { contactId, shulId } = this.props.pageRoute.params;
    if (contactId) {
      await this.props.actions.getContactById(+contactId);
      if (this.props.activeContact && Object.keys(this.props.activeContact).length) {
        const { id, first_name, last_name, minyan_role, title, display_phones, display_emails } = this.props.activeContact;
        this.setState({
          id,
          add: false,
          first_name: first_name ? first_name : '',
          last_name: last_name ? last_name : '',
          minyan_role: minyan_role ? minyan_role : '',
          title: title ? title : '',
          display_phones,
          display_emails,
        });
      } else {
        this.setState({ add: false });
      }
    }
    this.setState({ shul_id: +shulId });
  }

  render() {
    const {
      add,
      minyan_role,
      title,
      first_name,
      last_name,
      display_emails,
      display_phones,
      submitted
    } = this.state;
    const roles = {
      '': 'Select Role',
      rabbi: 'Rabbi',
      assistant_rabbi: 'Assistant Rabbi',
      gabbai: 'Gabbai',
      other: 'Other',
    };
    const titles = {
      '': 'Select Title',
      rabbi: 'Rabbi',
      mr: 'Mr',
      dr: 'Dr',
      mrs: 'Mrs',
      ms: 'Ms'
    };

    // create array with empty value to display input first time when mapping
    const phoneInputArray = display_phones.length ? display_phones : [{}];
    const emailInputArray = display_emails.length ? display_emails : [{}];

    return (
      <div className="full-page-modal">
        <h1 className="xl-text mob-large-text text-500 bordered-title desktop-hidden">
          {add ? "Add Contact" : "Edit Contact"}
        </h1>
        <div
          className="full-page-modal-close link"
          onClick={this.props.pageRoute.history.goBack}>
          <i className="material-icons">close</i>
        </div>
        <div className="container">
          <h1 className="xl-text mob-large-text text-500 bordered-title mobile-hidden">
            {add ? "Add Contact" : "Edit Contact"}
          </h1>

          <div className="mb-16">
            <label className="input-label">Role</label>
            <select
              className={`custom-input ${submitted && !minyan_role ? 'required' : ''}`}
              name="minyan_role"
              value={minyan_role}
              onChange={this.handleChange}
            >
              {Object.keys(roles).map(role =>
                <option
                  key={role}
                  value={role}
                  disabled={role === ''}
                >
                  {roles[role]}
                </option>)}
            </select>
          </div>

          <div className="mb-16">
            <label className="input-label">Title</label>
            <select
              className={`custom-input ${submitted && !title ? 'required' : ''}`}
              name="title"
              value={title}
              onChange={this.handleChange}
            >
              {Object.keys(titles).map(title =>
                <option
                  key={title}
                  value={title}
                  disabled={title === ''}
                >
                  {titles[title]}
                </option>)}
            </select>
          </div>

          <div className="mb-16">
            <label className="input-label">First Name</label>
            <input
              type="text"
              className={`custom-input capitalize-text ${submitted && !first_name ? 'required' : ''}`}
              value={first_name}
              name="first_name"
              onChange={this.handleChange}
            />
          </div>

          <div className="mb-32">
            <label className="input-label">Last Name</label>
            <input
              type="text"
              className={`custom-input capitalize-text ${submitted && !last_name ? 'required' : ''}`}
              value={last_name}
              name="last_name"
              onChange={this.handleChange}
            />
          </div>
          <hr className="desktop-hidden" />
          <div className="mb-40">
            {phoneInputArray.map((phone, index) => {
              return (
                <div key={index}>
                  <label className="desktop-hidden input-label flex-align-center mobile-block">
                    {index === 0 ? 'Phone' : ''}
                  </label>
                  <div className="flex-align-center phone-input-div">
                    <label className="mobile-hidden input-label flex-align-center">{index === 0 ? 'Phone' : ''}</label>
                    <input
                      type="text"
                      className="custom-input"
                      id="phone-number-input"
                      name="display_phones"
                      value={formatNumber(phone.number) || ""}
                      onChange={(e) => this.handlePhoneEmail(e, 'number', index)}
                    />

                    <input
                      type="text"
                      className="custom-input"
                      placeholder="Ext"
                      id="phone-ext-input"
                      name="display_phones"
                      value={phone.extension || ""}
                      onChange={(e) => this.handlePhoneEmail(e, 'extension', index)}
                      disabled={!phone.number}
                    />

                    <div
                      className="delete-phone-email-icon"
                      onClick={() => this.removeEmailOrPhone('display_phones', index)}
                    >
                      <i className="material-icons">
                        delete
                    </i>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className="phone-email-add"
              onClick={() => this.handleAdd("display_phones")}
            >
              <span className="plus-icon">&#43;</span>
              <span> Add Another Phone</span>
            </div>
          </div>

          <hr className="desktop-hidden" />
          <div className="mb-40">
            {emailInputArray.map((email, index) => {
              return (
                <div key={index}>
                  <label className="desktop-hidden input-label flex-align-center mobile-block">
                    {index === 0 ? 'Email' : ''}
                  </label>
                  <div className="flex-align-center email-input-div">
                    <label className="mobile-hidden input-label flex-align-center">{index === 0 ? 'Email' : ''}</label>
                    <input
                      type="text"
                      id="email-input"
                      className="custom-input"
                      value={email.email || ""}
                      name="display_emails"
                      onChange={(e) => this.handlePhoneEmail(e, 'email', index)}
                    />

                    <div
                      className="delete-phone-email-icon"
                      onClick={() => this.removeEmailOrPhone('display_emails', index)}
                    >
                      <i className="material-icons">
                        delete
                    </i>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className="phone-email-add"
              onClick={() => this.handleAdd("display_emails")}
            >
              <span className="plus-icon">&#43;</span>
              <span> Add Another Email</span>
            </div>
          </div>
          <hr />
          <div className="contact-buttons-div">
            <button className="btn btn-light uppercase-text contact-button" onClick={this.props.pageRoute.history.goBack}>Cancel</button>
            <button
              className="btn btn-accent contact-button uppercase-text"
              onClick={() => this.handleSubmit('save')}
            >
              Save
            </button>
            {add ?
              (<button
                className="btn btn-accent uppercase-text save-and-add contact-button"
                onClick={() => this.handleSubmit('add')}
              >
                Save & Add Another
            </button>) : null}
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

function formatNumber(num = '') {
  if (!num.length) return '';
  let formattedNum = `(${num.slice(0, 3)}`;
  formattedNum += num.length > 3 ? `)${num.slice(3, 6)}` : '';
  formattedNum += num.length > 6 ? `-${num.slice(6)}` : '';
  return formattedNum;
}