import React from "react";
import {
  PageLink,
  createToast,
  googlePlaceToAddress,
  Navigation,
  APIClient
} from "../../lib";
import { Layout } from "../../components";
import { LocationSearchInput } from "../../components/LocationSearchInput";
import Pages from "../";
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/add-edit-shul.css";
import "react-toastify/dist/ReactToastify.css";
import { DeleteModal, EventsCard } from "../../components";
import { LocationWarningDialog } from "../../components/LocationWarningDialog";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { offsetCalculationTypes } from "../../lib/constants";
export default class EditShul extends React.PureComponent {
  state = {
    name: "",
    website_url: "",
    fullAddress: "",
    email: "",
    phone: "",
    extension: "",
    id: "",
    notes: "",
    shul_nusach: "",
    start_date: "",
    end_date: "",
    temp_shul: false,
    temp_shul_confirmed: false,
    images: [],
    apiSuccess: null,
    showLocationWarningDialog: false,
    existingShulNames: [],
    admins: []
  };

  async componentDidMount() {
    const { accountActions, actions, eventActions } = this.props;
    const shulId = parseInt(this.props.pageRoute.params.shulId, 10);
    eventActions.getEvents(shulId);
    await Promise.all([
      accountActions.checkUsersAuth(),
      actions.getCovidPolicies()
    ]);

    if (!this.props.authorized) {
      Navigation.redirect("/?displayLogin=true");
      return;
    }

    window.scrollTo(0, 0);
    await this.props.actions.getShulById(shulId);
    const shulInfo = this.props.shulInfo;
    if (shulInfo && Object.keys(shulInfo).length) {
      this.setStateWithShulInfo(shulInfo);

      const images = [];
      if (shulInfo.minyan_location_images.length) {
        shulInfo.minyan_location_images.forEach(image => {
          images.push({
            imageName: image.image_name,
            url: `https://godaven-assets.sfo2.digitaloceanspaces.com/shul-images/${encodeURI(
              image.image_name
            )}`
          });
        });
        this.setState({ images });
      }
      // await actions.getShulCovidPolicy(shulInfo.id);
      // if (this.props.shulCovidPolicy.covid_policy_id) {
      //   this.setState({
      //     covid_policy: this.props.shulCovidPolicy.covid_policy_id
      //   });
      // }
    }
  }

  // TODO: see if this code is still necessary
  componentDidUpdate(prevProps) {
    const shulInfo = this.props.shulInfo;
    if (
      shulInfo &&
      Object.keys(shulInfo).length &&
      shulInfo !== prevProps.shulInfo
    ) {
      this.setStateWithShulInfo(shulInfo);
    }
  }

  setStateWithShulInfo = shulInfo => {
    const {
      name,
      website_url,
      id,
      notes,
      shul_nusach,
      start_date,
      end_date,
      temp_shul_confirmed,
      formatted_address,
      email,
      phone,
      extension,
      account_shul_roles
    } = shulInfo;
    this.setState({
      name,
      website_url: website_url ? website_url : "",
      fullAddress: formatted_address,
      id,
      notes: notes ? notes : "",
      email: email ? email : "",
      phone: phone ? phone : "",
      extension: extension ? extension : "",
      shul_nusach: shul_nusach ? shul_nusach : "",
      start_date: start_date ? start_date : "",
      end_date: end_date ? end_date : "",
      temp_shul: start_date && end_date ? true : false,
      temp_shul_confirmed,
      admins: account_shul_roles
    });
  };

  updateShulChanges = async e => {
    const {
      shulInfo: { id },
      actions: { updateShul }
    } = this.props;
    const { name, value } = e.target;
    // bandaid to make sure they don't try to erase name ///// may not need any more because we have swagger validation now
    if (!value && name === "name") return;
    const response = await updateShul({ id, [name]: value });
    this.setState(
      () => ({ apiSuccess: response.shulUpdated ? true : false }),
      () => setTimeout(() => this.setState({ apiSuccess: null }), 5000)
    );
  };

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAddressResults = googlePlace => {
    let locationObj = googlePlaceToAddress(googlePlace[0]);
    const { streetNumber, streetName } = locationObj;

    if (!streetNumber || !streetName) {
      return createToast(null, "A full address is required!");
    }
    locationObj.address = `${locationObj.streetNumber} ${locationObj.streetName
      }`;
    delete locationObj.streetNumber;
    delete locationObj.streetName;
    this.setState({ locationObj });
    this.updateShulLocation(locationObj);
  };

  handleCovidUpdate = e => {
    const { actions } = this.props;
    const { value } = e.target;
    if (value === 0 || value === "0") return; //Nothing was selected
    actions.updateCovidPolicy({
      shulId: this.state.id,
      policy_id: parseInt(value, 10)
    });
  };

  updateShulLocation = async (location, createWithExistingLocation = false) => {
    const { id, start_date } = this.state;
    const tempShul = start_date ? true : false;

    const response = await this.props.actions.updateShulLocation(id, {
      location,
      tempShul,
      createWithExistingLocation
    });
    if (response.showLocationExistsWarning) {
      this.setState({
        existingShulNames: response.existingShulNames,
        showLocationWarningDialog: true
      });
    } else {
      this.setState(
        () => ({ apiSuccess: response.locationUpdated ? true : false }),
        () => setTimeout(() => this.setState({ apiSuccess: null }), 5000)
      );
    }
  };

  deleteContact = async () => {
    const contactId = this.props.record.id;
    await this.props.contactActions.deleteContactById(contactId);
  };

  delete = async () => {
    const {
      shulInfo: { id },
      eventActions
    } = this.props;
    const eventId = this.props.record.id;
    eventActions.deleteEvent(id, eventId);
  };

  deleteMinyan = async () => {
    const { id, name: minyanType } = this.props.record;
    await this.props.minyanActions.deleteMinyan(id, minyanType);
  };

  deleteAdmin = async () => {
    const accountShulRoleId = this.props.record.id;
    const deleted = await this.props.accountActions.deleteShulAdmin(
      accountShulRoleId
    );
    if (deleted) {
      this.setState(prevState => ({
        admins: prevState.admins.filter(a => a.id !== accountShulRoleId)
      }));
    }
  };

  deleteShul = async () => {
    const {
      shulInfo: { id },
      actions: { deleteShul },
      accountActions: { logoutUser },
      roles
    } = this.props;
    const deleted = await deleteShul(id);
    if (deleted) {
      if (!roles.includes("application_admin")) {
        logoutUser();
        Navigation.redirect("/");
        return;
      }
      Navigation.redirect("/admin");
    }
  };

  toggleHasMinyan = () => {
    this.setState(
      prevState => ({ temp_shul_confirmed: !prevState.temp_shul_confirmed }),
      () => {
        const {
          shulInfo: { id },
          actions: { updateShul }
        } = this.props;
        updateShul({ id, temp_shul_confirmed: this.state.temp_shul_confirmed });
      }
    );
  };

  onDrop = async files => {
    // Push all the axios request promise into a single array
    const formData = new FormData();

    formData.append("shul_id", this.state.id);

    files.forEach((file, i) => {
      formData.append(`file_${i}`, file);
      i++;
    });

    const response = await Axios.post(`/upload-images`, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    }).catch(e => console.error(e));
    if (response && response.data && response.data.uploaded) {
      createToast(null, `Your Image(s) where uploaded successfully`, "success");
      const newImages = [];
      response.data.imageNames.forEach(i => {
        newImages.push({
          imageName: i,
          url: `https://godaven-assets.sfo2.digitaloceanspaces.com/shul-images/${encodeURI(
            i
          )}`
        });
      });
      this.setState(prevState => ({
        images: [...newImages, ...prevState.images]
      }));
      return;
    } else {
      createToast(null, "An Error happend while uploading your Image(s)");
      return;
    }
  };

  deleteImage = async imageName => {
    const response = await APIClient.delete(
      `/images/${this.state.id}/${imageName}`
    );
    if (response && response.data && response.data.rowsDeleted) {
      this.setState(prevState => ({
        images: prevState.images.filter(i => i.imageName !== imageName)
      }));
      createToast(null, `Your Image was successfully deleted`, "success");
    } else {
      createToast(null, "An Error happend while deleting your Image");
    }
  };

  cancelDialog = () => {
    this.setState({ showLocationWarningDialog: false });
  };

  resubmitLocation = () => {
    this.setState({ showLocationWarningDialog: false });
    this.updateShulLocation(this.state.locationObj, true);
  };

  render() {
    const {
      props: {
        pageRoute: {
          params: { shulId },
          key
        },
        contacts,
        recordType,
        uiActions: { toggleDeleteModal },
        // covidPolicies,
        // shulCovidPolicy
      },
      state: {
        name,
        website_url,
        fullAddress,
        id,
        notes,
        email,
        phone,
        extension,
        shul_nusach,
        // covid_policy,
        start_date,
        end_date,
        admins,
        images,
        temp_shul,
        temp_shul_confirmed,
        apiSuccess,
        showLocationWarningDialog,
        existingShulNames
      }
    } = this;
    const minyanTypes = {
      shachris: {
        name: "Shacharis",
        minyanims: "shacharisMinyanims",
        sortedIds: "shacharisSortedIds"
      },
      mincha: {
        name: "Mincha",
        minyanims: "minchaMinyanims",
        sortedIds: "minchaSortedIds"
      },
      mariv: {
        name: "Maariv",
        minyanims: "maarivMinyanims",
        sortedIds: "maarivSortedIds"
      }
    };
    const nusachs = {
      "": "Select a Nusach",
      sefard: "Sefard",
      ashkenaz: "Ashkenaz",
      ari: "Ari",
      edut_mizrach: "Edut Mizrach",
      temani: "Temani"
    };

    return (
      <Layout>
        {id && (
          <React.Fragment>
            {showLocationWarningDialog && (
              <LocationWarningDialog
                existingShulNames={existingShulNames}
                cancelDialog={this.cancelDialog}
                resubmitRequest={this.resubmitLocation}
              />
            )}
            <div className="add-edit-shul-outer-wraper">
              <header className="header-fixed">
                <div className="container flex-justify-space flex-align-center">
                  <div className="mobile-hidden flex-align-middle">
                    <h1 className="edit-shul-name-header mobile-hidden">
                      {name}
                    </h1>
                    {temp_shul && (
                      <div className="temp-minyan-box flex-align-middle">
                        Temporary
                      </div>
                    )}
                  </div>
                  <div className="desktop-hidden text-500 mob-large-text flex-left-center">
                    <PageLink
                      to={
                        key.includes("my-shuls")
                          ? Pages.shulAdmin.myShulsView
                          : Pages.admin.viewShul
                      }
                      params={key.includes("my-shuls") ? {} : { shulId }}
                      query={
                        key.includes("my-shuls") ? { activeShulId: id } : {}
                      }
                    >
                      <i className="material-icons mobile-back-arrow">
                        arrow_back
                      </i>
                    </PageLink>
                    Edit Shul
                  </div>
                  <PageLink
                    to={
                      key.includes("my-shuls")
                        ? Pages.shulAdmin.myShulsView
                        : Pages.admin.viewShul
                    }
                    params={key.includes("my-shuls") ? {} : { shulId }}
                    query={key.includes("my-shuls") ? { activeShulId: id } : {}}
                  >
                    <button className="btn btn-accent">Finish Editing</button>
                  </PageLink>
                </div>
              </header>
              <div className="header-fixed-page">
                <div className="add-shul page">
                  <div className="container">
                    <div className="flex-justify-space mobile-block">
                      <div className="card column-half column-half-left shul-info-card mobile-full-width">
                        <div className="flex-align-center">
                          <p className="large-text mob-medium-text uppercase-text text-500">
                            Shul Info
                          </p>
                          {renderUpdateMessage(apiSuccess)}
                        </div>
                        <div className="flex-align-center mobile-block">
                          <p className="medium-text mob-small-text capitalize-text">
                            Shul Name
                          </p>
                          <input
                            className="custom-input capitalize-text"
                            type="text"
                            name="name"
                            value={name}
                            onChange={this.handleInputChange}
                            onBlur={this.updateShulChanges}
                          />
                          {temp_shul && (
                            <div className="temp-minyan-box flex-align-middle desktop-hidden">
                              Temporary
                            </div>
                          )}
                        </div>

                        <div className="flex-align-center mobile-block">
                          <p className="medium-text mob-small-text">Website</p>
                          <input
                            className="custom-input"
                            type="text"
                            name="website_url"
                            value={website_url}
                            onChange={this.handleInputChange}
                            onBlur={this.updateShulChanges}
                          />
                        </div>

                        <div className="flex-align-center mobile-block">
                          <p className="medium-text mob-small-text">Address</p>
                          <LocationSearchInput
                            prevAddress={fullAddress}
                            handleAddressResults={this.handleAddressResults}
                          />
                        </div>

                        <div className="flex-align-center mobile-block">
                          <p className="medium-text mob-small-text">Email</p>
                          <input
                            className="custom-input"
                            type="text"
                            name="email"
                            value={email}
                            onChange={this.handleInputChange}
                            onBlur={this.updateShulChanges}
                          />
                        </div>

                        <div className="flex-align-center mobile-block">
                          <p className="medium-text mob-small-text">phone</p>
                          <input
                            className="custom-input"
                            type="text"
                            name="phone"
                            value={phone}
                            onChange={this.handleInputChange}
                            onBlur={this.updateShulChanges}
                          />
                        </div>

                        <div className="flex-align-center mobile-block">
                          <p className="medium-text mob-small-text">
                            Extension
                          </p>
                          <input
                            className="custom-input"
                            type="number"
                            step="1"
                            min="0"
                            name="extension"
                            value={extension}
                            onChange={this.handleInputChange}
                            onBlur={this.updateShulChanges}
                          />
                        </div>

                        <div className="flex-align-center  mobile-block">
                          <p className="medium-text mob-small-text">Nusach</p>
                          <select
                            className="custom-input mobile-mt-10"
                            value={shul_nusach}
                            name="shul_nusach"
                            onChange={this.handleInputChange}
                            onBlur={this.updateShulChanges}
                          >
                            {Object.keys(nusachs).map(nusach => (
                              <option
                                key={nusach}
                                value={nusach}
                                disabled={nusach === ""}
                              >
                                {nusachs[nusach]}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* {shulCovidPolicy.loading === false && (
                          <div className="flex-align-center  mobile-block">
                            <p className="medium-text mob-small-text">
                              Covid Policy
                            </p>
                            <select
                              className="custom-input mobile-mt-10"
                              value={covid_policy}
                              name="covid_policy"
                              onChange={this.handleInputChange}
                              onBlur={this.handleCovidUpdate}
                            >
                              {!shulCovidPolicy.covid_policy_id && (
                                <option key={0} value={0}>
                                  Please Select ...
                                </option>
                              )}
                              {covidPolicies.map(p => (
                                <option key={p.id} value={p.id}>
                                  {p.display_text}
                                </option>
                              ))}
                            </select>
                          </div>
                        )} */}

                        {temp_shul && (
                          <div>
                            <div className="flex mobile-block max-100 temp-shul-dates-div">
                              <div className="max-100">
                                <div className="flex-align-center edit-shul-start-date-div">
                                  <p className="medium-text mob-small-text edit-shul-start-date max-100">
                                    Start Date
                                  </p>
                                  <input
                                    className="custom-input edit-shul-date-picker"
                                    type="date"
                                    name="start_date"
                                    value={start_date}
                                    onChange={this.handleInputChange}
                                    onBlur={this.updateShulChanges}
                                  />
                                </div>
                              </div>
                              <div className="max-100">
                                <div className="flex-align-center max-100">
                                  <p className="medium-text mob-small-text edit-shul-end-date max-100">
                                    End Date
                                  </p>
                                  <input
                                    className="custom-input edit-shul-date-picker max-100"
                                    type="date"
                                    name="end_date"
                                    value={end_date}
                                    onChange={this.handleInputChange}
                                    onBlur={this.updateShulChanges}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="flex-align-center">
                              {temp_shul_confirmed ? (
                                <i
                                  className="material-icons link has-minyan-checkbox"
                                  onClick={this.toggleHasMinyan}
                                >
                                  check_box
                                </i>
                              ) : (
                                <i
                                  className="material-icons link has-minyan-checkbox"
                                  onClick={this.toggleHasMinyan}
                                >
                                  check_box_outline_blank
                                </i>
                              )}
                              <p>Shul has a minyan of 10 people</p>
                            </div>
                          </div>
                        )}

                        <div
                          className="delete-shul-div"
                          onClick={() =>
                            openModal(
                              toggleDeleteModal,
                              { id, name: "this shul with all it's minyanim" },
                              "shul"
                            )
                          }
                        >
                          <i className="material-icons delete-shul-icon">
                            delete
                          </i>
                          <span className="link">DELETE SHUL</span>
                        </div>
                      </div>
                      <div className="card column-half column-half-right mobile-full-width">
                        <p className="large-text uppercase-text text-500 mb-24">
                          Images
                        </p>
                        <div className="upload-box">
                          <Dropzone onDrop={this.onDrop}>
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive
                            }) => {
                              return (
                                <div
                                  {...getRootProps()}
                                  className={`dropzone dropzone-custom ${isDragActive ? "dropzone--isActive" : ""
                                    }`}
                                >
                                  <input {...getInputProps()} />
                                  {isDragActive ? (
                                    <p className="flex-align-middle">
                                      Drop files here...
                                    </p>
                                  ) : (
                                    <p className="flex-align-middle">
                                      Try dropping some images here, or click to
                                      select images to upload.
                                    </p>
                                  )}
                                </div>
                              );
                            }}
                          </Dropzone>
                        </div>
                        <div className="image-thumb-nails-container max-100">
                          {images.length > 0 &&
                            images.map(i => {
                              return (
                                <div
                                  key={i.imageName}
                                  className="max-100 image-thumb-nail-div"
                                >
                                  <div className="image-thumb-nail-delete-overlay">
                                    <i
                                      className="material-icons image-thumb-nail-delete-icon"
                                      onClick={() =>
                                        this.deleteImage(i.imageName)
                                      }
                                    >
                                      delete
                                    </i>
                                  </div>
                                  <img
                                    className="image-thumb-nail"
                                    src={i.url}
                                    alt="shul"
                                  />
                                  {/* <i className="material-icons">delete</i> */}
                                </div>
                              );
                            })}
                        </div>

                        {/* </div> */}
                      </div>
                    </div>

                    {Object.keys(minyanTypes).map(type => {
                      const minyanim = this.props[minyanTypes[type].minyanims];
                      const sortedIds = this.props[minyanTypes[type].sortedIds];
                      return (
                        <div
                          key={type}
                          className="card full-width mt-40 mobile-full-width"
                        >
                          <p className="large-text text-500 uppercase-text mb-16 mob-medium-text">
                            {`${minyanTypes[type].name} Minyanim`}
                          </p>
                          <div className="shul-info-table minyanim-table">
                            <div className="table-row table-header-row mobile-hidden">
                              <p className="medium-text accent-text-light flex-left-center">
                                Tefillah
                              </p>
                              <p className="medium-text accent-text-light flex-left-center">
                                Nussach
                              </p>
                              <p className="medium-text accent-text-light flex-left-center">
                                Days
                              </p>
                              <p className="medium-text accent-text-light flex-left-center">
                                Time
                              </p>
                              <p className="medium-text accent-text-light flex-left-center">
                                Status
                              </p>
                              <p className="medium-text accent-text-light flex-left-center">
                                Notes
                              </p>
                            </div>
                            {sortedIds && sortedIds.length
                              ? sortedIds.map(id => {
                                return renderMinyan(
                                  minyanim[id],
                                  toggleDeleteModal,
                                  key
                                );
                              })
                              : ""}
                          </div>
                          <PageLink
                            to={
                              key.includes("my-shuls")
                                ? Pages.shulAdmin.myShulsAddMinyan
                                : Pages.admin.addMinyan
                            }
                            params={{ shulId }}
                            query={{ type, shul_nusach }}
                          >
                            <button className="btn btn-accent btn-small mt-32">
                              Add Minyan
                            </button>
                          </PageLink>
                        </div>
                      );
                    })}

                    <EventsCard />
                    <div className="card full-width mt-40 mobile-full-width">
                      <p className="large-text mob-medium-text text-500 uppercase-text mb-24">
                        Display Contacts
                      </p>
                      <div className="shul-info-table contacts-table">
                        <div className="table-row table-header-row mobile-hidden">
                          <p className="medium-text accent-text-light flex-left-center">
                            Name
                          </p>
                          <p className="medium-text accent-text-light flex-left-center">
                            Role
                          </p>
                          <p className="medium-text accent-text-light flex-left-center">
                            Phone
                          </p>
                          <p className="medium-text accent-text-light flex-left-center">
                            Email
                          </p>
                          <p />
                        </div>
                        {Object.keys(contacts).length > 0 &&
                          Object.keys(contacts).map(contactId => {
                            return renderContact(
                              contacts[contactId],
                              id,
                              toggleDeleteModal,
                              key
                            );
                          })}
                      </div>
                      <PageLink
                        to={
                          key.includes("my-shuls")
                            ? Pages.shulAdmin.myShulsAddContact
                            : Pages.admin.addContact
                        }
                        params={{ shulId }}
                      >
                        <button className="btn btn-accent btn-small mt-32">
                          Add Contact
                        </button>
                      </PageLink>
                    </div>

                    <div className="card full-width mt-40 mobile-full-width">
                      <p className="large-text mob-medium-text text-500 uppercase-text mb-24">
                        Shul administrators
                      </p>
                      <div className="shul-info-table admins-table">
                        <div className="table-row table-header-row mobile-hidden">
                          <p className="medium-text accent-text-light flex-left-center">
                            Name
                          </p>
                          <p className="medium-text accent-text-light flex-left-center">
                            Role
                          </p>
                          <p />
                        </div>
                        {admins.map(admin => {
                          const {
                            id,
                            role_name: { name: role = "" },
                            account: { first_name, last_name }
                          } = admin;
                          return (
                            <div key={id} className="table-row">
                              <div className="flex-justify-space-center capitalize-text mobile-bold-text">
                                {first_name} {last_name}
                                <div className="desktop-hidden">
                                  <i
                                    className="material-icons mob-text-18"
                                    onClick={() =>
                                      openModal(
                                        toggleDeleteModal,
                                        {
                                          id,
                                          name: `${first_name} ${last_name}`
                                        },
                                        "admin"
                                      )
                                    }
                                  >
                                    delete
                                  </i>
                                </div>
                              </div>
                              <p className="capitalize-text flex-left-center">
                                {role.replace(/_/, " ")}
                              </p>
                              <div className="shul-info-table-actions mobile-hidden flex-right-center">
                                <i
                                  className="material-icons"
                                  onClick={() =>
                                    openModal(
                                      toggleDeleteModal,
                                      {
                                        id,
                                        name: `${first_name} ${last_name}`
                                      },
                                      "admin"
                                    )
                                  }
                                >
                                  delete
                                </i>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <PageLink
                        to={
                          key.includes("my-shuls")
                            ? Pages.shulAdmin.myShulsAddAccount
                            : Pages.admin.addShulAdminAccount
                        }
                        params={{ shulId }}
                      >
                        <button className="btn btn-accent btn-small mt-32">
                          Add Admin
                        </button>
                      </PageLink>
                    </div>

                    <div className="card mt-40 mobile-full-width mobile-mb-40">
                      <p className="large-text mob-medium-text text-500 uppercase-text mb-24">
                        Notes
                      </p>
                      <div className="notes-table">
                        <textarea
                          name="notes"
                          className="custom-input notes-text-area"
                          rows="4"
                          placeholder="Enter notes on Shul (optional)"
                          value={notes}
                          onChange={this.handleInputChange}
                          onBlur={this.updateShulChanges}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <DeleteModal
                message={`Are you sure you want to delete ${this.props.record ? this.props.record.name : ""
                  }`}
                deleteRecord={
                  recordType === "contact"
                    ? this.deleteContact
                    : recordType === "admin"
                      ? this.deleteAdmin
                      : recordType === "minyan"
                        ? this.deleteMinyan
                        : recordType === "event"
                          ? this.delete
                          : recordType === "shul"
                            ? this.deleteShul
                            : this.deleteShul
                }
              />
            </div>
          </React.Fragment>
        )}
        <ToastContainer />
      </Layout>
    );
  }
}

function renderUpdateMessage(apiStatus) {
  if (apiStatus === null) return;

  let m = {};
  if (apiStatus) {
    m.className = "shul-update-message success-message";
    m.icon = "check_circle";
    m.text = "All changes saved";
  } else {
    m.className = "shul-update-message error-message";
    m.icon = "error_outline";
    m.text = "Error occurred";
  }
  return (
    <div className={m.className}>
      <i
        className="material-icons"
        style={{ fontSize: "18px", marginRight: "14px" }}
      >
        {m.icon}
      </i>
      <p className="medium-text">{m.text}</p>
    </div>
  );
}

function renderMinyan(minyan, toggleDeleteModal, key) {
  const minyanTypes = {
    shachris: "Shacharis",
    mincha: "Mincha",
    mariv: "Maariv"
  };
  const {
    id,
    start_date,
    end_date,
    type,
    minyan_location_id,
    nusach,
    days,
    special_days_included,
    special_days_only,
    time_at,
    zman_type,
    offset_minutes,
    offset_calculated_by,
    offset_rounded_by,
    notes,
    not_later_than,
    not_earlier_than
  } = minyan;
  const status =
    start_date && end_date
      ? `${formatDate(start_date)} - ${formatDate(end_date)}`
      : "All year round";

  return (
    <div key={id} className="table-row">
      <div className="minyan-type flex-justify-space-center">
        {minyanTypes[type]}
        <div className="desktop-hidden flex-align-center">
          <PageLink
            to={
              key.includes("my-shuls")
                ? Pages.shulAdmin.myShulsEditMinyan
                : Pages.admin.editMinyan
            }
            params={{ shulId: minyan_location_id, minyanId: id }}
            query={{ type }}
          >
            <i className="material-icons edit-icon mob-text-18">edit</i>
          </PageLink>
          <i
            data-minyan-id={id}
            className="material-icons mob-text-18"
            onClick={() =>
              openModal(toggleDeleteModal, { id: id, name: type }, "minyan")
            }
          >
            delete
          </i>
        </div>
      </div>
      <p className="capitalize-text flex-left-center">
        {nusach ? nusach.replace(/_/, " ") : ""}
      </p>
      <div className="flex-left-center-columns">
        <p className="flex-left-center capitalize-text">{days.join(", ")}</p>
        {special_days_included.length > 0 && (
          <p className="xs-text accent-text mt-4 mobile-margin-0 flex-left-center minyan-days">
            Includes: {special_days_included.join(", ")}
          </p>
        )}
        {special_days_only.length > 0 && (
          <p className="xs-text accent-text mt-4 mobile-margin-0 flex-left-center minyan-days">
            Only: {special_days_only.join(", ")}
          </p>
        )}
      </div>
      <div className="flex-left-center mob-xs-text">
        {time_at ? (
          getMinyanTime(time_at)
        ) : zman_type ? (
          <div>
            <span>{getOffestText(offset_minutes)}</span>
            <span className="capitalize-text">
              {zman_type.name.replace(/_/, " ")}
            </span>
            {offset_calculated_by
              ? renderCalculatedBy(offset_calculated_by)
              : ""}
            {offset_rounded_by ? renderRoundedBy(offset_rounded_by) : ""}
            {not_earlier_than
              ? renderNotEarlierAndLater("earlier", not_earlier_than)
              : ""}
            {not_later_than
              ? renderNotEarlierAndLater("later", not_later_than)
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
      <p className="flex-left-center">{status}</p>
      <p className="flex-left-center mobile-minyan-notes">{notes}</p>
      <div className="shul-info-table-actions flex-right-center mobile-hidden">
        <PageLink
          to={
            key.includes("my-shuls")
              ? Pages.shulAdmin.myShulsEditMinyan
              : Pages.admin.editMinyan
          }
          params={{ shulId: minyan_location_id, minyanId: id }}
          query={{ type }}
        >
          <i className="material-icons edit-icon">edit</i>
        </PageLink>
        <i
          data-minyan-id={minyan.id}
          className="material-icons"
          onClick={() =>
            openModal(toggleDeleteModal, { id: id, name: type }, "minyan")
          }
        >
          delete
        </i>
      </div>
    </div>
  );
}

function formatDate(date) {
  const months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sept",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
  };
  const day = date.slice(8, 10);
  const month = months[date.slice(5, 7)];
  //const year = date.slice(0, 4);
  return `${month} ${day}`;
}

function getMinyanTime(time) {
  const suffix = time.slice(0, 2) >= 12 ? "PM" : "AM";
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  return hours + minutes + " " + suffix;
}

function getOffestText(offsetMinutes) {
  let offsetText;
  switch (true) {
    case offsetMinutes === 0:
      offsetText = `at `;
      break;
    case offsetMinutes > 0:
      offsetText = `${offsetMinutes} minutes after `;
      break;
    default:
      offsetText = `${-offsetMinutes} minutes before `;
  }
  return offsetText;
}

function renderCalculatedBy(calculatedBy) {
  return (
    <p className="xs-text accent-text">{`calculated by ${offsetCalculationTypes[calculatedBy]
      }`}</p>
  );
}

function renderRoundedBy(roundedBy) {
  const roundedByOptions = {
    on_time: "at the exact time",
    earlier_5: "rounded to earlier 5 minutes",
    later_5: "rounded to later 5 minutes"
  };

  return <p className="xs-text accent-text">{roundedByOptions[roundedBy]}</p>;
}

function renderNotEarlierAndLater(beforeOrAftertext, time) {
  return (
    <p className="xs-text accent-text">{`not ${beforeOrAftertext} than ${getMinyanTime(
      time
    )}`}</p>
  );
}

function renderContact(contact, shulId, toggleDeleteModal, key) {
  const {
    id,
    title,
    first_name,
    last_name,
    minyan_role,
    display_phones = [],
    display_emails = []
  } = contact;
  return (
    <div key={contact.id} className="table-row">
      <div className="flex-justify-space-center capitalize-text mobile-bold-text">
        {title} {first_name} {last_name}
        <div className="desktop-hidden">
          <PageLink
            to={
              key.includes("my-shuls")
                ? Pages.shulAdmin.myShulsEditContact
                : Pages.admin.editContact
            }
            params={{ shulId: shulId, contactId: contact.id }}
          >
            <i className="material-icons edit-icon mob-text-18">edit</i>
          </PageLink>
          <i
            className="material-icons mob-text-18"
            onClick={() =>
              openModal(
                toggleDeleteModal,
                { id, name: `${first_name}  ${last_name}` },
                "contact"
              )
            }
          >
            delete
          </i>
        </div>
      </div>
      <p className="capitalize-text mob-xs-text flex-left-center">
        {minyan_role.replace(/_/, " ")}
      </p>
      <div className="flex-left-center-columns">
        {display_phones.map(phone => {
          return (
            <p key={phone.id} className="phone-email-column flex-left-center">
              {phone.number ? formatPhone(phone.number, phone.extension) : ""}
            </p>
          );
        })}
      </div>
      <div className="flex-left-center-columns">
        {display_emails.map(email => {
          return (
            <p key={email.id} className="phone-email-column flex-left-center">
              {email.email}
            </p>
          );
        })}
      </div>
      <div className="shul-info-table-actions mobile-hidden flex-right-center">
        <PageLink
          to={
            key.includes("my-shuls")
              ? Pages.shulAdmin.myShulsEditContact
              : Pages.admin.editContact
          }
          params={{ shulId: shulId, contactId: contact.id }}
        >
          <i className="material-icons edit-icon">edit</i>
        </PageLink>
        <i
          className="material-icons"
          onClick={() =>
            openModal(
              toggleDeleteModal,
              {
                id: contact.id,
                name: contact.first_name + " " + contact.last_name
              },
              "contact"
            )
          }
        >
          delete
        </i>
      </div>
    </div>
  );
}

function openModal(toggleDeleteModal, record, recordType) {
  toggleDeleteModal(true, record, recordType);
}

function formatPhone(number, ext) {
  const strippedNum = number.replace(/-/g, "");
  return ` (${strippedNum.slice(0, 3)}) ${strippedNum.slice(
    3,
    6
  )}-${strippedNum.slice(6)} ${ext ? `ext ${ext}` : ""}`;
}
