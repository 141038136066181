import React from 'react';
import { HelmetHeader } from "../../components/HelmetHeader";
import {
  APIClient,
  //createToast,
  PageLink,
} from '../../lib';
import Pages from "../";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import '../../assets/css/pages/temp-shuls.css';


export default class TempShuls extends React.PureComponent {
  state = {
    tempShuls: []
  };

  async componentDidMount() {
    const response = await APIClient.get('/shuls/temp');
    if (response && response.data && response.data.length) {
      this.setState({ tempShuls: response.data });
    }
  }

  render() {
    const tempShuls = this.state.tempShuls;

    return (
      <React.Fragment>
        <HelmetHeader />
        <div className="temp-shuls-page">
          <div className="temp-shuls-header">
            Browse Temporary Minyanim
          </div>

          {tempShuls.length > 0 ? (
            <div className="flex-align-middle">
              <div className="temp-shuls-container">
                {tempShuls.map(shul => {
                  const { id, name, start_date, end_date, shul_nusach, formatted_address, email, phone, extension, has_sefer_torah, temp_shul_confirmed } = shul;
                  return (
                    <PageLink
                      to={Pages.main.shulDetails}
                      key={id} className="temp-shul-card"
                      params={{ shulId: id }}
                    >
                      <div className="flex-justify-space">
                        <div className="temp-shul-name">{name}</div>
                        <div className="temp-shul-nusach">{shul_nusach ? shul_nusach.replace(/_/, ' ') : 'unspecified'}</div>
                      </div>

                      <div className="flex-justify-center-columns temp-shul-info">
                        <div className="capitalize-text">{formatted_address}</div>
                        {phone && <div className="flex">{formatPhone(phone, extension)}</div>}
                        {email && <div className="flex">{email}</div>}
                        <div className="minyan-torah-message">{temp_shul_confirmed ? 'Currently have a minyan of 10 people' : 'No minyan of 10 people yet'}</div>
                        <div className="minyan-torah-message">{has_sefer_torah ? 'Have a Sefer Torah' : 'No Sefer Torah'}</div>
                      </div>

                      <div className="temp-shul-dates">{`${formatDate(start_date)} - ${formatDate(end_date)}`}</div>
                    </PageLink>
                  );
                })}
              </div>
            </div>
          ) :
            (
              <div className="no-temp-minyanim-message"> There are no temporary Minyanim at this time</div>
            )}

        </div>

        {/* <ToastContainer /> */}
      </React.Fragment>
    );
  }
}

function formatPhone(number, ext) {
  const strippedNum = number.replace(/[^0-9]+/g, '');
  return `(${strippedNum.slice(0, 3)}) ${strippedNum.slice(3, 6)}-${strippedNum.slice(6)}${ext ? ` ext ${ext}` : ''}`;
}

function formatDate(date) {
  const months = {
    '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June',
    '07': 'July', '08': 'Aug', '09': 'Sept', '10': 'Oct', '11': 'Nov', '12': 'Dec'
  };
  const day = date.slice(8, 10);
  const month = months[date.slice(5, 7)];
  const year = date.slice(0, 4);
  return `${month} ${day}, ${year}`;
}