import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShulActions } from '../../state';
import { MinyanActions } from '../../state';
import { ContactActions } from '../../state';
import { UIAction } from '../../state';
import { AccountActions } from '../../state';
import { HomeActions, EventActions } from '../../state';
import EditShul from './EditShul';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    shul: {
      loading,
      shulInfo,
      shulApiResponse,
      covidPolicies,
      shulCovidPolicy
    },
    minyan: {
      shacharisMinyanims,
      minchaMinyanims,
      maarivMinyanims,
      shacharisSortedIds,
      minchaSortedIds,
      maarivSortedIds,
    },
    contact: {
      contacts
    },
    ui: {
      record,
      recordType
    },
    account: {
      roles,
      shulIds,
      authorized
    },
    home: {
      formSubmitted
    },
    event: {
      events
    }

  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    loading,
    shulInfo,
    shulApiResponse,
    shacharisMinyanims,
    minchaMinyanims,
    maarivMinyanims,
    shacharisSortedIds,
    minchaSortedIds,
    maarivSortedIds,
    record,
    recordType,
    contacts,
    roles,
    shulIds,
    authorized,
    formSubmitted,
    covidPolicies,
    shulCovidPolicy,
    events
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(ShulActions, dispatch),
    minyanActions: bindActionCreators(MinyanActions, dispatch),
    eventActions: bindActionCreators(EventActions, dispatch),
    contactActions: bindActionCreators(ContactActions, dispatch),
    uiActions: bindActionCreators(UIAction, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch),
    homeActions: bindActionCreators(HomeActions, dispatch)
  };
})(EditShul);
