import { ShulActions } from '../shul/actions';
import { MinyanActions } from '../minyan/actions';

export const MinyanState = {
  name: 'minyan',
  persist: false,
  defaults: {
    shacharisMinyanims: {},
    minchaMinyanims: {},
    maarivMinyanims: {},
    groupedByDayMinyanim: {},
    shacharisSortedIds: [],
    minchaSortedIds: [],
    maarivSortedIds: [],
    minyanFailed: false,
    currentMinyanim: {},
    minyanSuggestions: {},
    activeMinyan: {}
  },
  handlers: {
    
    [MinyanActions.MINYAN_GET_BY_ID]: (state, action) => {
      return {
        ...state,
        activeMinyan: action.payload
      };
    },

    [ShulActions.SHUL_GET]: (state, action) => {
      const {
        shacharisMinyanims,
        minchaMinyanims,
        maarivMinyanims,
        shacharisSortedIds,
        minchaSortedIds,
        maarivSortedIds
      } = action.results;
      return {
        ...state,
        loading: false,
        shacharisMinyanims,
        minchaMinyanims,
        maarivMinyanims,
        shacharisSortedIds,
        minchaSortedIds,
        maarivSortedIds
      };
    },

    [ShulActions.SHUL_DETAILS]: (state, action) => {
      const {
        groupedByDayMinyanim
      } = action.results;
      return {
        ...state,
        loading: false,
        groupedByDayMinyanim
      };
    },

    [MinyanActions.MINYAN_UPDATE]: (state, action) => {
      const { minyan, minyanims } = action.payload;
      return {
        ...state,
        [minyanims]: {
          ...state[minyanims],
          [minyan.id]: minyan,
        },
      };
    },

    [MinyanActions.MINYAN_ADD]: (state, action) => {
      const { minyan, minyanims, sortedIds } = action.payload;
      return {
        ...state,
        [minyanims]: {
          ...state[minyanims],
          [minyan.id]: minyan,
        },
        [sortedIds]: [minyan.id, ...state[sortedIds]]
      };
    },

    [MinyanActions.MINYAN_DELETE]: (state, action) => {
      const { minyanId, minyanims, sortedIds } = action.payload;
      const {
        ['' + minyanId]: value,
        ...newMinyanims
      } = state[minyanims];
      const filterdSortIds = state[sortedIds].filter(id => id !== minyanId);
      return {
        ...state,
        [minyanims]: {
          ...newMinyanims,
        },
        [sortedIds]: filterdSortIds
      };
    },

    [ShulActions.SHUL_ADD]: (state, action) => {
      return {
        ...state,
        shacharisMinyanims: {},
        minchaMinyanims: {},
        maarivMinyanims: {},
        shacharisSortedIds: [],
        minchaSortedIds: [],
        maarivSortedIds: [],
      };
    },

    [ShulActions.SHUL_CLEAR_FROM_REDUX]: (state, action) => {
      return {
        ...state,
        shacharisMinyanims: {},
        minchaMinyanims: {},
        maarivMinyanims: {},
        shacharisSortedIds: [],
        minchaSortedIds: [],
        maarivSortedIds: [],
      };
    },

    [MinyanActions.MINYAN_FAILED]: (state, action) => {
      return {
        ...state,
        minyanFailed: true
      };
    },

    [MinyanActions.MINYAN_FAILURE_RESET]: (state, action) => {
      return {
        ...state,
        minyanFailed: false
      };
    },

    [MinyanActions.MINYANIM_CURRENT]: (state, action) => {
      return {
        ...state,
        currentMinyanim: action.payload
      };
    },

    [MinyanActions.MINYANIM_SUGGESTIONS]: (state, action) => {
      return {
        ...state,
        minyanSuggestions: action.payload
      };
    },

    [MinyanActions.MINYAN_DELETION_SUGGESTION]: (state, action) => {
      const {minyan_id, type, action: crudAction} = action.payload;
      return {
        ...state,
        minyanSuggestions:{
          ...state.minyanSuggestions,
          [type]: state.minyanSuggestions[type] ? [...state.minyanSuggestions[type], {minyan_id, action: crudAction}] : [{minyan_id, action: crudAction}]

        }
      };
    },

    [MinyanActions.MINYAN_CLEAR_ACTIVE]: (state, action) => {
      return {
        ...state,
        activeMinyan: {}
      };
    },

  },
};
