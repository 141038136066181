export * from "./AppRouter";
export * from "./constants";
export * from "./Navigation";
export * from "./PageLink";
export * from "./PageNavLink";
export * from "./PageLoader";
export * from "./PageURL";
export * from "./URLBuilder";

/** Temp function to add the same layout to all given pages.
 * TODO: Remove this when the routing system allows each area to define it's
 * own default layout...
 * @param {string} layoutName
 * @param {{[x:string]:any}} pages
 */
export function addPageLayouts(layoutName, pages) {
  Object.keys(pages).forEach(key => {
    const page = pages[key];
    const { layout } = page;
    if (!layout && layout !== null) {
      page.layout = layoutName;
    }
  });
  return pages;
}
