import { toast } from 'react-toastify';

export function createToast(errCode, msg, type, shouldNotClose = false) {
  let message = '';
  if (msg) {
    message = msg;
  } else {
    message = 'Something went wrong. Please try again later.';
    if (errCode === 400) {
      message = "Error. This resource already exists.";
    } else if (errCode === 101) {
      message = "You are not authorized";
    } else if (errCode === 105) {
      message = "Invalid Current Password";
    } else if (errCode === 109) {
      message = "Invalid request please check your payload";
    } else if (errCode === 110) {
      message = "You are missing required fields or you have duplicate info";
    } else if (errCode === 111) {
      message = "Email already in use, it must be unique";
    } else if (errCode === 112) {
      message = "Incomplete Address, please try again or contact us if you are still having trouble";
    } else if (errCode === 113) {
      message = "The link you are using has expired, please click on 'back to Login' and then click on the 'forgot password' link";
    }
  }

  const options = {
    type: type === 'success' ? toast.TYPE.SUCCESS : type === 'warning' ? toast.TYPE.WARNING : toast.TYPE.ERROR,
    hideProgressBar: true,
    pauseOnHover: true,
    autoClose: !shouldNotClose ? 6000 : false
  };

  toast(message, options);
}