import React, { Fragment } from "react";
import "./thank-you.css";
import Banner from "./Banner";
import Bottom from "./Bottom";
import thankYouImg from "./ThankYou.jpg";
import thankYouMobileImg from "./ThankYouMobile.jpg";
import { Navigation } from "../../lib";

export default class ThankYou extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Fragment>
        <Banner />
        <div className="c-ty-container">
          <img src={thankYouImg} alt="thank you" className="mobile-hidden" />
          <img src={thankYouMobileImg} alt="thank you" className="desktop-hidden" />
          <div className="ty-thank-you">THANK YOU FOR YOUR SUBMISSION!</div>
          <div className="ty-we-are-wrapper">
            <div className="ty-we-are">
              We are now one step closer to providing over
            </div>
            <div className="ty-we-are">
              25,000 people with more accurate information.
            </div>
          </div>

          <div className="ty-would-you">
            Would you like to be so kind to add contact
            <span className="mobile-hidden">&nbsp;information</span>
          </div>
          <div className="ty-would-you">
            <span className="desktop-hidden">information</span> to another
            search result?
          </div>

          <div
            className="ty-search-button"
            onClick={() => Navigation.go("/chezky/missing-info-list")}
          >
            YES! Take me back!
          </div>
        </div>
        <Bottom />
      </Fragment>
    );
  }
}
