import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ContactActions } from '../../state';
import { AccountActions } from '../../state';
import Contact from './Contact';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    contact: {
      loading,
      activeContact,
      contactFailed
    },
    account: {
      authorized
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    loading,
    activeContact,
    contactFailed,
    authorized
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(ContactActions, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
})(Contact);
