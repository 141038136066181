import {
  APIClient,
  createToast,
} from '../../lib';

export const ContactActions = {
  CONTACT_ADD: 'CONTACT_ADD',
  CONTACT_EDIT: 'CONTACT_EDIT',
  CONTACT_GET: 'CONTACT_GET',
  CONTACT_DELETE: 'CONTACT_DELETE',
  CONTACT_FAILED: 'CONTACT_FAILED',
  CONTACT_FAILURE_RESET: 'CONTACT_FAILURE_RESET',

  addContact(contact) {
    return async (dispatch, getState) => {
      dispatch({
        type: ContactActions.CONTACT_ADD,
        loading: true,
      });
      const response = await APIClient.post(`/display-contacts`, contact).catch(console.error);

      if (response && response.data) {
        createToast(null, 'Contact added successfully', 'success');
        dispatch({
          loading: false,
          type: ContactActions.CONTACT_ADD,
          payload: response.data,
        });
      } else {
        dispatch({ type: ContactActions.CONTACT_FAILED });
      }
    };
  },

  editContact(contact) {
    return async (dispatch, getState) => {
      dispatch({
        type: ContactActions.CONTACT_EDIT,
        loading: true,
      });
      const response = await APIClient.put(`/display-contacts/${contact.id}`, contact).catch(console.error);

      if (response && response.data) {
        createToast(null, 'Contact updated successfully', 'success');
        dispatch({
          loading: false,
          type: ContactActions.CONTACT_EDIT,
          payload: response.data,
        });
        dispatch({ type: ContactActions.CONTACT_FAILURE_RESET });
      } else {
        dispatch({ type: ContactActions.CONTACT_FAILED });
      }
    };
  },

  getContactById(id) {
    return async (dispatch, getState) => {
      dispatch({
        type: ContactActions.CONTACT_GET,
        loading: true,
        payload: {},
      });
      const response = await APIClient.get(`/display-contacts/${id}`)
        .catch(e => {
          console.error(e);
          if (e.response && e.response.status === 404) {
            createToast(null, `We can't find the Contact you are looking for`, 'warning', true);
          }
        });

      if (response && response.data) {
        dispatch({
          loading: false,
          type: ContactActions.CONTACT_GET,
          payload: response.data,
        });
      }
    };
  },

  deleteContactById(contactId) {
    return async (dispatch, getState) => {
      dispatch({
        type: ContactActions.CONTACT_DELETE,
        loading: true,
      });
      const response = await APIClient.delete(`/display-contacts/${contactId}`).catch(console.error);

      if (response && response.data) {
        dispatch({
          loading: false,
          type: ContactActions.CONTACT_DELETE,
          payload: contactId,
        });
      }
    };
  },

};
