import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import querySerializer from "query-string";
import { CommonPageTypes } from "./constants";
import { Navigation } from "./Navigation";
import { PageLoader } from "./PageLoader";
import { PageURL } from "./PageURL";

const history = Navigation.init().history;
/** Page definitions by PAGE_TYPE string. Populated by `configurePageArea`
 * where each page in each area of the site are processed.
 *
 * **The global PageURL.types is altered when adding a page to this
 * collection.** This allows `PageURL` to create a URL for a page given
 * just that pages PAGE_TYPE string.
 *
 * @type {{[PAGE_TYPE:string]: PageDefinition}}
 */
const pagesByType = PageURL.types;
/** @type {{[urlpath_pattern:string]: PageDefinition}} */
const pagesByPath = PageURL.paths;

let NotFoundPageView;
let isAuthenticated = () => false;
let loginRedirectURL = "/login?after=";
/** @type {AppRouterPageOptions} */
const pageOptions = {
  anon: false,
  pathExact: true
};

/** @type {RouteDefinition} */
let defaultRoute;
/** @type {{[urlpath:string]: RouteDefinition}} */
const routesByPath = {};
/** @type {{[layoutName:string]: RouteDefinition}} */
const routesByLayoutName = {};
/** @type {RouteDefinition[]} */
const routes:Record<string, any>[] = [];

export class AppRouter extends React.PureComponent {
  /** Configures the `AppRouter`
   * @param {AppRouterOptions} options
   */
  static configure(options) {
    const {
      loginCheck = isAuthenticated,
      loginPath = "/login",
      loginRedirectParam = "after",
      pageOptions: { anon = true, pathExact = true },
      rootArea,
      configurePage
    } = options;
    isAuthenticated = loginCheck;
    loginRedirectURL = `${loginPath}?${loginRedirectParam}=`;
    pageOptions.anon = anon;
    pageOptions.pathExact = pathExact;
    // Build routes. Pages without layouts MUST be first and layout routes last.
    // Also, layout routes should put the default "/" path last...
    const layoutRoutes = configureLayouts(rootArea)
      .sort((a:any, b:any) => (a.path < b.path ? -1 : a.path > b.path ? 1 : 0))
      .reverse();
    configurePageArea(rootArea, { configurePage });
    routes.push(...layoutRoutes);
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          {routes.map(renderRoute)}
          <Route path="*" component={NotFoundPageView} />
        </Switch>
      </Router>
    );
  }
}
export default AppRouter;

function configureLayouts(area, layoutRoutes = []) {
  const { areas: subAreas, defaultLayout: defaultLayoutName, layouts } = area;
  function mapLayout(layout, layoutName) {
    let { path } = layout;
    if (!path || (routesByPath[path] && routesByPath[path].layout)) {
      throw new Error(`Missing or duplicate layout path found: ${path}`);
    }
    addLayoutRoute(layoutRoutes, layoutName, path, {
      path,
      layout,
      pages: []
    });
  }
  if (layouts) {
    Object.keys(layouts).forEach(layoutName => {
      const layout = layouts[layoutName];
      mapLayout(layout, layoutName);
      if (layoutName === defaultLayoutName || layoutName === "default") {
        if (defaultRoute) {
          throw new Error("Default layout already defined!");
        }
        // TODO: Allow on default layout per area instead of one for the app.
        // CONSIDER: Maybe
        defaultRoute = routesByPath[layout.path];
      }
    });
  }
  if (subAreas) {
    subAreas.forEach(subArea => configureLayouts(subArea, layoutRoutes));
  }
  return layoutRoutes;
}

function configurePageArea(area, options = ({} as any)) {
  const { areas: subAreas, pages } = area;
  if (subAreas) {
    subAreas.forEach(subArea => {
      configurePageArea(subArea, options);
    });
  }
  if (!pages) {
    return;
  }
  const { configurePage = () => undefined } = options;
  Object.keys(pages).forEach(function mapPage(keyForPage) {
    const page = pages[keyForPage];
    let { path, type } = page;
    if (!type || pagesByType[type]) {
      throw new Error(`Missing or duplicate page type found: ${type}`);
    }
    pagesByPath[path] = page;
    pagesByType[type] = page;
    if (type === CommonPageTypes.NOT_FOUND) {
      NotFoundPageView = page.view;
    }
    if (path) {
      if (!page.getRouteKey) {
        /** MODIFYING: page is modified to ensure required props.
         * Instead of copying, we apply updates to the original page.
         * This was done so that code outside of this module which already has a
         * reference to a given page can access these modifications.
         */
        page.getRouteKey = getRouteKeyWithPage(page);
      }
    }
    mapPageToRoute(page);
    configurePage(page);
  });
}

function addLayoutRoute(layoutRoutes, layoutName, path, route) {
  routesByPath[path] = route;
  routesByLayoutName[layoutName] = route;
  layoutRoutes.push(route);
}

function addRoute(path, route) {
  routesByPath[path] = route;
  routes.push(route);
}

function mapPageToRoute(page) {
  const { layout: layoutPathOrName } = page;
  if (layoutPathOrName) {
    const layoutRoute =
      routesByLayoutName[layoutPathOrName] || routesByPath[layoutPathOrName];
    if (!layoutRoute) {
      throw new Error(`Layout not found: ${layoutPathOrName}`);
    }
    layoutRoute.pages.push(page);
  } else if (layoutPathOrName === null) {
    addRoute(page.path, {
      path: page.path,
      page
    });
  } else {
    defaultRoute.pages.push(page);
  }
}
/** @param {RouteDefinition} route */
function renderRoute(route) {
  const { path, layout = {}, page, pages } = route;
  const { view: LayoutView } = layout;
  if (page) {
    return renderRouteForPage(page);
  }
  function renderLayout(props) {
    return (
      <LayoutView {...props}>
        <Switch>
          {pages.map(renderRouteForPage)}
          <Route path="*" component={NotFoundPageView} />
        </Switch>
      </LayoutView>
    );
  }
  return <Route key={path} path={path} render={renderLayout} />;
}
/** @param {PageDefinition} page */
function renderRouteForPage(page) {
  const {
    anon = pageOptions.anon,
    path,
    pathExact: exact = pageOptions.pathExact
  } = page;
  const RouteComponent = anon ? Route : PrivateRoute;
  const pageProps = {
    exact,
    path
  };
  function renderPageLoader(props) {
    return <PageLoader page={page} {...props} />;
  }
  return <RouteComponent key={path} {...pageProps} render={renderPageLoader} />;
}

export class PrivateRoute extends React.PureComponent {
  render() {
    const props:any = this.props;
    return isAuthenticated() ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={
          loginRedirectURL +
          encodeURIComponent(props.location.pathname + props.location.search)
        }
      />
    );
  }
}

// #region Route Keys - Uniquely identify pages based on parts of the URL

/** Returns a function that, when called, returns the route key of the page. */
function getRouteKeyWithPage(page) {
  const { key: keySpec } = page;
  function getRouteKeyForPage(params) {
    // NOTE: We only want the path, not a query string, so only pass params.
    return PageURL.to(page, { params }, undefined);
  }
  if (keySpec) {
    return getRouteKeyWithSpec(page, keySpec, getRouteKeyForPage);
  }
  return getRouteKeyForPage;
}
/** Returns a function that, when called, returns the route key of the page. */
function getRouteKeyWithSpec(page, keySpec, defaultImpl) {
  // TODO: Use keySpec.params to create pathnames like '/prop/value/...'
  // so that we can share view state among different urls (think wizards).
  // This way the uniquness of the key can be independant of the pathname.
  const { query: querySpec } = keySpec;
  if (!querySpec) {
    return defaultImpl;
  }
  if (querySpec === true) {
    return function getRouteKeyIncludingEntireQuery(params, query) {
      return PageURL.to(page, { params, query }, undefined);
    };
  }
  if (!Array.isArray(querySpec)) {
    return defaultImpl;
  }
  const len = querySpec.length;
  return function getRouteKeyIncludingQuery(params, query) {
    // NOTE: We only want the path, not a query string, so only pass params.
    let pathname = PageURL.to(page, { params }, undefined);
    if (!query) {
      return pathname;
    }
    const keyValues = {};
    let hasKeyValues = false;
    for (var i = 0; i < len; i++) {
      let prop = querySpec[i];
      if (prop in query) {
        keyValues[prop] = query[prop];
        hasKeyValues = true;
      }
    }
    if (!hasKeyValues) {
      return pathname;
    }
    return pathname + "?" + querySerializer.stringify(keyValues);
  };
}
