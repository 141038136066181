import ShulDetails from './ShulDetails.vm';

export const IframePages = {
  shulDetails: {
    anon: true,
    path: '/iframe/shul-details/:shulId/:shulName?',
    pathExact: true,
    type: 'SHUL_DETAILS_IFRAME',
    view: ShulDetails,
    title: "Shul Details Iframe",
    layout: null
  },
};
export default IframePages;

export const IframeArea = {
  pages: IframePages,
};