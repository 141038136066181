import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShulActions } from '../../state';
import { AccountActions } from '../../state';
import { UIAction } from '../../state';
import Dashboard from './Dashboard';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    shul: {
      loading,
      num_of_pages,
      pendingShuls,
      results,
      total,
    },
    account: {
      authorized
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    loading,
    num_of_pages,
    pendingShuls,
    results,
    total,
    authorized
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  //return bindActionCreators(ShulActions, dispatch);

  return {
    actions: bindActionCreators(ShulActions, dispatch),
    uiActions: bindActionCreators(UIAction, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };

  // return {
  //   searchShuls: (query) => {
  //     return dispatch(ShulActions.searchShuls(query));
  //   },
  // };

  // return {
  //   actions: {
  //     ...bindActionCreators(ShulActions, dispatch),
  //     ...bindActionCreators(UIActions, dispatch),
  //   }
  // };
})(Dashboard);
