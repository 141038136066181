import React from "react";
import { PageLink, Navigation } from '../../lib';
import Pages from "../";
// import { CovidPolicy } from "../../components";

import { HelmetHeader } from "../../components/HelmetHeader";
import Carousel from 'react-image-carousel';
import "../../assets/css/pages/shul-details.css";
import "../../assets/css/pages/shul-images-carousel.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import { corporateImages } from '../../lib/corporateImagesArray.js';
import Tooltip from '@material-ui/core/Tooltip';
import { Card } from "@material-ui/core";
import { offsetCalculationTypes } from "../../lib/constants";
const randomImageNum = Math.floor(Math.random() * corporateImages.length);

export default class ShulDetails extends React.PureComponent {

  state = {
    specialDays: false,
    hasImages: false,
    showImages: false,
    latitude: '',
    longitude: '',
    fromSearch: false,
    activeView: "regular_minyanim"
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const {
      clearShulFromRedux,
      getShulDetails,
      // getShulCovidPolicy,
      pageRoute: {
        params: { shulId },
        query: {
          latitude,
          longitude,
          from_search
        }
      }
    } = this.props;
    clearShulFromRedux();
    const shulDetails = await getShulDetails(parseInt(shulId, 10));
    // getShulCovidPolicy(shulId);
    if (shulDetails) {
      const { minyan_location_images } = this.props.shulInfo || [];
      if (minyan_location_images && minyan_location_images.length) {
        this.setState({ hasImages: true });
      }

      this.setState({ latitude, longitude, fromSearch: from_search });
    }
  }

  togglePopUp = e => {
    const id = e.target.id;
    this.setState(prevState => ({ [id]: prevState[id] === true ? false : true }));
  }

  closePopUp = e => {
    const id = e.target.id;
    this.setState({ [id]: false });
  }

  toggleMinyanMode = specialDaysMode => {
    this.setState({ specialDays: specialDaysMode });
    this.setState({ activeView: specialDaysMode === true ? "special_days" : "regular_minyanim" });
  }

  toggleImages = () => {
    this.setState(prevState => ({ showImages: !prevState.showImages }));
  }

  searchMinyanimNearby = (fullAddress, lat, lng) => {
    if (lat) {
      Navigation.go(`/search?mode=location&address=${fullAddress}&lat=${lat}&lng=${lng}`);
    } else {
      geocodeByAddress(fullAddress).then(res => {
        return getLatLng(res[0]).then((r) => {
          return Navigation.go(`/search?mode=location&address=${fullAddress}&lat=${r.lat}&lng=${r.lng}`);
        })
          .catch(error => Navigation.go(`/search?mode=location&address=${fullAddress}`));
      });
    }
  }

  render() {
    const {
      props: {
        pageRoute: { params: { shulId } },
        shulInfo: {
          id = '',
          name = '',
          website_url = '',
          email = '',
          phone = '',
          extension = '',
          shul_nusach = '',
          minyan_location_images = '',
          notes = '',
          shiurim = '',
          start_date = '',
          end_date = '',
          shul_last_updated_on = '',
          temp_shul_confirmed = false,
          has_sefer_torah = false,
          formatted_address = '',
          location_status = '',
          events = []
        },
        groupedByDayMinyanim,
        groupedContacts: {
          rabbi = [],
          assistantRabbi = [],
          rest = []
        } = {},
        weeklySponsor
      },
      state: {
        specialDays,
        hasImages,
        showImages,
        latitude,
        longitude,
        fromSearch,
        activeView
      }
    } = this;
    let timeId = 1;
    const rabbiName = rabbi.length ? `| Rabbi ${rabbi[0].first_name} ${rabbi[0].last_name}` : '';
    const minyanTypes = ['shachris', 'mincha', 'mariv'];
    const days = { sun: 'Sunday', mon: 'Monday', tues: 'Tuesday', wed: 'Wednesday', thurs: 'Thursday', fri: 'Friday', sat: 'Shabbos' };
    const contacts = [...rabbi, ...assistantRabbi, ...rest];
    const images = [];
    if (minyan_location_images.length) {
      minyan_location_images.forEach(image => {
        images.push(`https://godaven-assets.sfo2.digitaloceanspaces.com/shul-images/${encodeURI(image.image_name)}`);
      });
    }

    return (
      <React.Fragment>
        <HelmetHeader
          customTitle={`Godaven | ${name.replace(/\b\w/g, l => l.toUpperCase())}`}
          customDescription={`Shul info and minyanim times (zmanim) for - ${name.replace(/\b\w/g, l => l.toUpperCase())} | ${formatted_address} ${rabbiName.replace(/\b\w/g, l => l.toUpperCase())}`}
        />
        {/* <PageLink
          className="mobile-hidden tablet-hidden feedback-div flex-align-middle"
          to={Pages.main.contact}
        >
          <p className="feedback">Feedback</p>
        </PageLink> */}
        {showImages &&
          <div className="flex-align-middle">
            <div
              tabIndex="0"
              className="image-carousel"
              onBlur={() => this.toggleImages(false)}
            >
              <Carousel
                images={images}
                thumb={true}
                loop={true}
                autoplay={5000}
              />
            </div>
          </div>}

        <div className="flex-justify-center shul-details-outer-wrapper">
          <div className="shul-details-container max-100">
            <div className="flex-justify-space-center mb-16">
              {fromSearch &&
                <div
                  className="back-to-results link"
                  onClick={() => Navigation.history.goBack()}
                >
                  <i className="material-icons">navigate_before</i>Back to results
                </div>}

              <div className="mobile-hidden shul-details-featured-sponsor">
                <img src={corporateImages[randomImageNum].image} alt="Corporate Sponsor" className="featured-sponsor-img" />
                <p>Corporate Sponsor</p>
              </div>

              {shul_last_updated_on && <div className="desktop-hidden last-updated">last updated: {formatDate(shul_last_updated_on, true)}</div>}
            </div>
            <div className="shul-details-warning">
                This is the latest information we have, but always verify minyan information in advance prior to attending a new minyan for the first time.
            </div>
            <div className={`shul-name-contacts-box`}>
              <div className="shul-box">
                <div className="shul-details-info-column">
                  <div className="shul-name capitalize-text">{name}</div>
                  <div className="capitalize-text flex-align-center">
                    <div className="inline">
                      {formatted_address}
                      {location_status === 'flagged' &&
                        <Tooltip enterTouchDelay={10} title="Address may not be accurate"><i className="material-icons location-status-warning">warning</i></Tooltip>}
                    </div>
                  </div>
                  {website_url && <a className="shul-details-color" href={website_url} target="_blank">{website_url}</a>}
                  {phone &&
                    <a className="shul-details-color" href={`tel:${phone}${extension ? ',' + extension : ''}`}>
                      {formatPhone(phone, extension)}
                    </a>}
                  {email && <a className="shul-details-color" href={`mailto:${email}`}>{email}</a>}
                  {start_date &&
                    <div className="shul-details-temp-info">
                      <div className="flex">{temp_shul_confirmed ? 'Currently have a minyan of 10 people' : 'No minyan of 10 people yet'}</div>
                      <span className="mobile-hidden flex">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                      <div className="flex">{has_sefer_torah ? 'Have a Sefer Torah' : 'No Sefer Torah'}</div>
                    </div>}
                </div>



                <div className="shul-details-other-info-column">
                  <div className="">
                    <div className="flex-right-center">
                      {shul_last_updated_on && <div className="mobile-hidden last-updated">last updated: {formatDate(shul_last_updated_on, true)}</div>}
                      <div className="shul-details-nusach-temp-div">
                        {start_date &&
                          <div className="shul-details-temp-minyan-flag">
                            Temporary
                      </div>}
                        <div className="shul-details-nusach">
                          {shul_nusach ? shul_nusach.replace(/_/, ' ') : 'unspecified'}
                        </div>
                      </div>
                    </div>
                    {start_date && end_date &&
                      <div className="mobile-hidden shul-details-temp-dates">{`${formatDate(start_date, true)} - ${formatDate(end_date, true)}`}</div>}
                  </div>

                  <div className="shul-details-icons-div">
                    {hasImages &&
                      <div className="flex-center-columns mr-24 mobile-mr-12" onClick={() => this.toggleImages(true)}>
                        <i className="material-icons shul-details-icons flex-align-middle">image</i>
                        <p className="shul-details-icons-text">Images</p>
                      </div>}

                    <a
                      className="shul-details-color flex-center-columns"
                      href={`https://www.google.com/maps?q=${encodeURI(formatted_address)}`}
                      target="_blank"
                    >
                      <i className="material-icons shul-details-icons flex-align-middle">directions</i>
                      <p className="shul-details-icons-text">Directions</p>
                    </a>
                  </div>
                </div>

              </div>


              {contacts.length > 0 &&
                <div className="shul-contacts-box">
                  {contacts.map((c) => {
                    const { id, title, first_name, last_name, minyan_role, display_emails = [], display_phones = [] } = c;
                    return (
                      <div key={id} className={`shul-contacts-box-row`}>
                        <div className="desktop-hidden flex">
                          <div className="capitalize-text bold-text flex-align-center mr-8">
                            {`${title} ${first_name} ${last_name}`}
                          </div>
                          <div className="capitalize-text flex-align-center">{minyan_role.replace(/_/, ' ')}</div>

                        </div>
                        <div className="mobile-hidden capitalize-text bold-text flex-align-center">
                          {`${title} ${first_name} ${last_name}`}
                        </div>
                        <div className="mobile-hidden capitalize-text flex-align-center">{minyan_role.replace(/_/, ' ')}</div>


                        <div className=" mobile-hidden flex-left-center-columns">
                          {display_phones.map(phone => {
                            const { id, number, extension } = phone;
                            return (
                              <a className="shul-details-color" key={id} href={`tel:${number}${extension ? ',' + extension : ''}`}>
                                {formatPhone(number, extension)}
                              </a>
                            );
                          })}
                        </div>
                        <div className="mobile-hidden flex-left-center-columns">
                          {display_emails.map(e => {
                            const { id, email } = e;
                            return (
                              <a className="shul-details-color" key={id} href={`mailto:${email}`}>{email}</a>
                            );
                          })}
                        </div>

                        <div className="desktop-hidden flex-columns">

                          <div className="flex-left-center-columns">
                            {display_phones.map(phone => {
                              const { id, number, extension } = phone;
                              return (
                                <a className="shul-details-color" key={id} href={`tel:${number}${extension ? ',' + extension : ''}`}>
                                  {formatPhone(number, extension)}
                                </a>
                              );
                            })}
                          </div>
                          <div className="flex-left-center-columns">
                            {display_emails.map(e => {
                              const { id, email } = e;
                              return (
                                <a className="shul-details-color" key={id} href={`mailto:${email}`}>{email}</a>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>}

            </div>

            <div className="shul-details-week-sponsor">
              <div className="mr-16">This Week's Sponsor: {weeklySponsor && weeklySponsor.message ? weeklySponsor.message : 'sponsorship available'}</div>
              <a href="https://campaign.godaven.com/"
                target="_blank" // eslint-disable-line react/jsx-no-target-blank
                rel="noopener"
                className="dedication-oppor-link">
                <span className="mobile-hidden">View &nbsp;</span>Dedication Opportunities
              </a>
            </div>

            <div className="minyan-table-legend">
              <i className="material-icons mr-4">info_outline</i>
              Please click highlighted minyanim for more information on the specific minyan.
              </div>

            <div className="flex-justify-space-center">
              <div className="flex-align-center">
                <div className={`reg-minyanim ${activeView === 'regular_minyanim' ? 'reg-minyanim-active' : ''}`} onClick={() => this.toggleMinyanMode(false)}>
                  Regular Minyanim
              </div>
                <div className={`special-minyanim ${activeView === 'special_days' ? 'special-minyanim-active' : ''}`} onClick={() => this.toggleMinyanMode(true)}>
                  Special Days*
              </div>
                <div className={`events-tab ${activeView === 'events' ? 'events-tab-active' : ''}`} onClick={() => this.setState({ activeView: "events" })}>
                  Shiurim / Events
              </div>
              </div>
              <div className="mobile-hidden special-days-text">
                *Special days includes Rosh Chodesh, Fast Days, Legal Holidays, Selichos, Chol Hamoed
              </div>
            </div>

            <div className="desktop-hidden special-days-text mt-16">
              *Special days includes Rosh Chodesh, Fast Days, Legal Holidays, Selichos, Chol Hamoed
              </div>

            {activeView !== 'events' ?
              <div className="minyanim-box mobile-mt-12 max-100">
                <div className="minyanim-header max-100">
                  <div className="flex-align-middle">Day</div>
                  <div className="flex-align-middle">Shacharis</div>
                  <div className="flex-align-middle">Mincha</div>
                  <div className="flex-align-middle">Maariv</div>
                </div>

                {Object.keys(days).map(d => {
                  return (
                    <div key={d} className={`minyan-row  max-100`}>
                      <div className="minyan-day-name">{days[d]}</div>
                      {minyanTypes.map(type => {
                        return (
                          <div key={type} className={`minyan-times mob-xs-text`}>
                            <div className={`minyan-time-column`}>
                              {groupedByDayMinyanim && groupedByDayMinyanim[d] && groupedByDayMinyanim[d][type] &&
                                groupedByDayMinyanim[d][type].map((minyan, index) => {
                                  const hasPopup = checkIfShouldDisplayPopup(minyan, shul_nusach);
                                  const {
                                    id,
                                    special_days_only,
                                    special_days_included,
                                    displayTime,
                                  } = minyan;
                                  if ((!specialDays && special_days_only.length) || (specialDays && !special_days_only.length && !special_days_included.length)) {
                                    return null;
                                  }
                                  return (
                                    <div
                                      style={{}}
                                      key={id}
                                      className={`${hasPopup ? 'link' : ''}`}
                                    >
                                      <div
                                        id={++timeId}
                                        onClick={hasPopup ? this.togglePopUp : null}
                                        tabIndex={timeId}
                                        className={`minyan-time-display ${hasPopup ? 'clickable-time' : ''}`}
                                        onBlur={hasPopup ? this.closePopUp : null}
                                      >
                                        {formatTime(displayTime)}
                                      </div>
                                      {this.state[timeId] === true && renderPopup(minyan, shul_nusach)}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              : <div className="events-cards-container">
                {events.map(e => (
                  <Card className="event-card" key={e.id}>
                    <div className="flex-justify-space">
                      <div className="temp-shul-name">{e.title}</div>
                      <div className="event-card-lang">{e.language}</div>

                    </div>
                    <div className="capitalize-text event-type-name">{e.event_types[0].name}</div>
                    <div className="flex-justify-center-columns temp-shul-info">
                      <div className="capitalize-text">{e.speaker}</div>
                      <div className="capitalize-text">{e.days.join(", ")}</div>
                      <div className="event-speaker">{e.displayTimeBeginning === e.displayTimeEnd ?
                        formatTime(e.displayTimeEnd, true) :
                        `${formatTime(e.displayTimeBeginning, true)} - ${formatTime(e.displayTimeEnd, true)}`}</div>
                      <div className="minyan-torah-message">{e.notes}</div>
                    </div>
                  </Card>
                ))}
              </div>
            }

            <div className="notes-box">
              <div className="notes-header">Notes</div>
              <div className="notes-text">{notes ? notes : ''}</div>
              {shiurim &&
                <div className="shiurim-link-div">
                  <a
                    href={`http://${process.env.REACT_APP_SHIURIM_BASE_URL}${id}`}
                    target="_blank"
                    rel="noopener nofollow"
                    className="shiurim-link"
                  >
                    See Shiurim
                </a>
                </div>}
            </div>

            <div className="mt-24 flex-align-middle mobile-flex-center-columns">
              <div
                className="shul-details-link mr-16 flex-align-middle link"
                onClick={() => this.searchMinyanimNearby(formatted_address, latitude, longitude)}
              >
                Other Minyanim Nearby
              </div>
              <PageLink
                className="shul-details-link flex-align-middle"
                to={Pages.main.suggestShulUpdate}
                params={{ shulId }}
              >
                Suggest Minyan Update
              </PageLink>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}


function checkIfShouldDisplayPopup(minyan, shul_nusach) {
  const {
    special_days_only,
    special_days_included,
    start_date,
    end_date,
    nusach,
    time_at,
    zman_type,
    notes
  } = minyan;

  if ((nusach && nusach !== shul_nusach)
    || (start_date && end_date)
    || (!time_at && zman_type)
    || notes
    || special_days_only.length || special_days_included.length) {
    return true;
  }
  return false;
}

function renderPopup(minyan, shul_nusach) {
  const {
    special_days_only,
    special_days_included,
    start_date,
    end_date,
    offset_minutes,
    nusach,
    time_at,
    zman_type,
    offset_calculated_by,
    offset_rounded_by,
    not_earlier_than,
    not_later_than,
    notes,
    type
  } = minyan;
  return (
    <div className="expanded-time-display-container">
      <div className={`expanded-time-display
        ${type === 'shachris' ? 'schacharis-popup' : type === 'mincha' ? 'mincha-popup' : 'maariv-popup'}`}>
        {nusach && nusach !== shul_nusach ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Nusach:</div>
            <div className="expanded-time-row-info flex-align-center  capitalize-text">
              {nusach.replace('_', ' ')}
            </div>
          </div>) : ''}

        {start_date && end_date ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Seasonal:</div>
            <div className="expanded-time-row-info flex-align-center">
              {`${formatDate(start_date)} - ${formatDate(end_date)}`}
            </div>
          </div>) : ''}

        {!time_at && zman_type ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Time:</div>
            <div className="expanded-time-row-info flex-left-center-columns">
              <div>
                {`${offset_minutes !== 0 ? Math.abs(offset_minutes) + ' minutes' : ''}
                ${offset_minutes > 0 ? 'after' : offset_minutes < 0 ? 'before' : 'at'} `}
                <span className="capitalize-text">{zman_type.name.replace(/_/, ' ')}</span>
                {offset_calculated_by ? renderCalculatedBy(offset_calculated_by) : ''}
                {offset_rounded_by ? renderRoundedBy(offset_rounded_by) : ''}
                {not_earlier_than ? renderNotEarlierAndLater('earlier', not_earlier_than) : ''}
                {not_later_than ? renderNotEarlierAndLater('later', not_later_than) : ''}
              </div>
            </div>
          </div>) : ''}

        {(special_days_only.length || special_days_included.length) ?
          (<div className="expanded-time-row ">
            <div className="expanded-time-row-name"> Special Days:</div>
            <div className="expanded-time-row-info flex-align-center">
              {special_days_only.length ? `only for ${special_days_only.join(', ')}`
                : `includes ${special_days_included.join(', ')}`}
            </div>
          </div>) : ''}

        {notes ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Notes:</div>
            <div className="expanded-time-row-info flex-align-center">{notes}</div>
          </div>) : ''}
      </div>
    </div>
  );
}


function formatPhone(number, ext) {
  const strippedNum = number.replace(/[^0-9]+/g, '');
  return ` (${strippedNum.slice(0, 3)}) ${strippedNum.slice(3, 6)}-${strippedNum.slice(6)} ${ext ? `ext ${ext}` : ''}`;
}

function formatTime(time, showSuffix) {
  if (!time) return 'no time';
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  const suffix = time.slice(0, 2) >= 12 ? " PM" : " AM";
  return `${hours}${minutes}${showSuffix ? suffix : ''}`;
}

function formatDate(date, showYear) {
  const months = {
    '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June',
    '07': 'July', '08': 'Aug', '09': 'Sept', '10': 'Oct', '11': 'Nov', '12': 'Dec'
  };
  const day = date.slice(8, 10);
  const month = months[date.slice(5, 7)];
  const year = date.slice(0, 4);
  return `${month} ${day}, ${showYear ? year : ''}`;
}


function renderCalculatedBy(calculatedBy) {
  return <p className="xs-text mob-text-6 accent-text">{`calculated by ${offsetCalculationTypes[calculatedBy]}`}</p>;
}

function renderRoundedBy(roundedBy) {
  const roundedByOptions = {
    on_time: 'at the exact time',
    earlier_5: 'rounded to earlier 5 minutes',
    later_5: 'rounded to later 5 minutes'
  };

  return (
    <p className="xs-text mob-text-6 accent-text">
      {roundedByOptions[roundedBy]}
    </p>
  );
}

function renderNotEarlierAndLater(beforeOrAftertext, time) {
  return <p className="xs-text mob-text-6 accent-text">{`not ${beforeOrAftertext} than ${formatTime(time, true)}`}</p>;
}