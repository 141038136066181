import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AccountActions } from '../state';
import { Login } from './Login';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const { userNotFound } = state.account;
  // The returned object becomes properties of the connected Component...
  return {
    userNotFound
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  //return bindActionCreators(ShulActions, dispatch);

  return {
    actions: bindActionCreators(AccountActions, dispatch)
  };

})(Login);
