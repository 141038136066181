import React from 'react';
import '../assets/css/components/login.css';
import { ToastContainer } from "react-toastify";

// MODES:  login, forgotPassword, resetPassword, resetMessage

export class Login extends React.PureComponent {
  state = {
    email: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
    mode: 'login',
    pwResetJwt: null,
    triedSubmitting: false,
    newUser: false
  };

  componentDidMount() {
    const { pwResetJwt, newUser } = this.props;
    if (pwResetJwt) {
      this.setState({ mode: 'resetPassword', pwResetJwt, newUser: newUser ? true : false });
    }
  }

  componentWillUnmount() {
    this.props.actions.resetLoginFailure();
  }


  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = () => {
    this.setState({ triedSubmitting: true });
    const { login, forgotPassword, resetPassword } = this.props.actions;
    const {
      email,
      password,
      mode,
      newPassword,
      confirmNewPassword,
      pwResetJwt
    } = this.state;
    if (mode === 'login') {
      if (email && password) {
        login({ email, password });
      }
    } else if (mode === 'forgotPassword') {
      if (email) {
        this.setState({ mode: 'resetMessage' });
        forgotPassword({ email });
      }
    } else if (mode === 'resetPassword') {
      if (newPassword !== confirmNewPassword) {
        this.setState({ newPassword: '', confirmNewPassword: '' });
      } else if (newPassword && confirmNewPassword) {
        resetPassword({ jwt: pwResetJwt, new_password: newPassword });
      }
    }
  }

  hideLogin = () => {
    this.props.hideLogin();
  }

  changeMode = (newMode) => {
    this.props.actions.resetLoginFailure();
    this.setState({
      mode: newMode,
      triedSubmitting: false,
      passwordChanged: false,
      password: ''

    });
  }

  handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13) {
      this.handleSubmit();
    }
  }

  render() {
    const {
      state: {
        mode,
        triedSubmitting,
        newUser
      },
      props: {
        userNotFound
      }
    } = this;
    const modes = {
      login: {
        input1Name: 'email',
        input2Name: 'password',
        input1PH: 'Email',
        input2PH: 'Password',
        buttonText: 'Log In',
        link: 'Forgot Password',
        nextMode: 'forgotPassword',
        subTitle: ''
      },
      forgotPassword: {
        input1Name: 'email',
        input1PH: 'Email',
        buttonText: 'Reset Password',
        link: 'Back to Login',
        nextMode: 'login',
        subTitle: 'Reset Password'
      },
      resetPassword: {
        input1Name: 'newPassword',
        input2Name: 'confirmNewPassword',
        input1PH: newUser ? 'Password' : 'New Password',
        input2PH: newUser ? 'Confirm Password' : 'Confirm New Password',
        link: newUser ? 'Save Password' : 'Save New Password',
        subTitle: newUser ? 'Please set a Password' : 'Reset Password'
      },
      resetMessage: {
        link: 'Back to Login',
        nextMode: 'login',
      }
    };

    return (
      <div className="login-container">
        <i
          className="material-icons x-icon"
          onClick={this.hideLogin}
        >
          close
        </i>
        {mode !== 'resetMessage' ?
          (<div>
            <h1 className="login-title">Shul Admin Login</h1>
            <div className="login-message-box">
              {userNotFound &&
                <div className="user-not-found-text-div">
                  <p className="login-message-text">Wrong Email or</p>
                  <p className="login-message-text">password, Try again.</p>
                </div>}
              <div className="login-sub-titile">{modes[mode].subTitle}</div>
            </div>
            <input
              type={mode === 'resetPassword' ? 'password' : 'email'}
              name={modes[mode].input1Name}
              value={this.state[modes[mode].input1Name]}
              className={`login-input ${triedSubmitting && this.state[modes[mode].input1Name] < 1 ? 'login-required-field' : null}`}
              placeholder={modes[mode].input1PH}
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyPress}
            //ref={(el) => el && el.focus()}
            />
            {mode !== 'forgotPassword' &&
              <input
                type="password"
                name={modes[mode].input2Name}
                value={this.state[modes[mode].input2Name]}
                className={`login-input ${triedSubmitting && this.state[modes[mode].input2Name] < 1 ? 'login-required-field' : null}`}
                placeholder={modes[mode].input2PH}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyPress}
              />}
            {(mode === 'login' || mode === 'forgotPassword') &&
              <div className="submit-button-div">
                <button
                  className="login-submit-button"
                  onClick={this.handleSubmit}
                >
                  {modes[mode].buttonText}
                </button>
              </div>}
          </div>)
          : (<div className="reset-message">
            <p>If your Email is in our system</p>
            <p>We sent you a link to reset</p>
            <p>your password</p>
          </div>)}
        <div
          className={`link-text ${mode === 'resetPassword' || mode === 'resetMessage' ? 'link-text-blue' : ''}`}
          onClick={mode !== 'resetPassword' ?
            () => this.changeMode(modes[mode].nextMode)
            : this.handleSubmit}
        >
          {modes[mode].link}
        </div>

        {mode === 'resetPassword' &&
          <div className="link-text mt-32" onClick={() => this.changeMode('login')}>
            Back to Login
          </div>}
        <ToastContainer />
      </div >
    );
  }

}