import React, { Fragment } from "react";
import { APIClient, PageLink, Navigation } from "../../lib";
import Pages from "..";
import "./shuls-missing-info.css";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByAddress
} from "react-places-autocomplete";
import Banner from "./Banner";

export default class MissingInfoList extends React.PureComponent {
  state = {
    shuls: [],
    input: ""
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    const { address } = this.props.pageRoute.query;
    if (address) {
      this.loadResults(address);
      this.setState({ input: address });
    }
  }

  loadResults = async address => {
    try {
      const geocodeRes = await geocodeByAddress(address);
      const { cityNames, state } = getCityStateNames(geocodeRes[0].address_components);
      const { lat, lng } = await getLatLng(geocodeRes[0]);
      const results = await APIClient.post(`campaign/missing-info`, {
        lat,
        lng,
        cityNames,
        state
      });
      if (results && results.data) {
        if (!results.data.length) {
          return Navigation.redirect(`/chezky/no-results`);
        }
        this.setState({ shuls: results.data });
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  handleChange = value => this.setState({ input: value });

  handleSelect = address => {
    this.setState({ input: address });
    this.loadResults(address);
    Navigation.go(`/chezky/missing-info-list?address=${address}`);
  };

  render() {
    const { shuls, input } = this.state;
    return (
      <Fragment>
        <Banner />
        <div className="flex-align-middle">
          <div className="missing-info-container">
            <PlacesAutocomplete
              value={input}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              googleCallbackName="placesAutoComplete"
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div className="mi-places-container">
                  <i
                    className="material-icons mi-clear"
                    onClick={() => this.setState({ input: "" })}
                  >
                    close
                  </i>
                  <input
                    {...getInputProps({
                      autoFocus: true,
                      placeholder: "Your Address, City",
                      className: "mi-input"
                    })}
                  />
                  <div
                    className={
                      suggestions && suggestions.length
                        ? "mi-places-autocomplete-container"
                        : ""
                    }
                  >
                    {loading && <div className="mi-loading">Loading...</div>}
                    {suggestions.map(suggestion => {
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className: "mi-ac-active"
                          })}
                        >
                          <span className="">{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <div className="mi-card-container">
              {!shuls.length && <div className="mi-empty-msg">please enter a city or address</div>}
              {shuls.map(shul => {
                const {
                  id,
                  name,
                  location: { formatted_address }
                } = shul;
                return (
                  <div className="missing-info-card" key={id}>
                    <div className="mi-info-column">
                      <div className="mi-name">{name}</div>
                      <div className="missing-info-address">
                        {formatted_address}
                      </div>
                    </div>
                    <div className="flex-align-center">
                      <PageLink
                        to={Pages.campaign.form}
                        query={{ id, name, address: formatted_address }}
                        className="missing-info-update"
                      >
                        UPDATE CONTACT INFO
                      </PageLink>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
function getCityStateNames(addressComponents) {
  const cityNames = [];
  let state = null;
  addressComponents.forEach(function(c) {
    for (let i = 0; i < 3; i++) {
      switch (c.types[i]) {
        case "neighborhood":
          cityNames.push(rmvTownship(c.short_name));
          break;
        case "locality":
          cityNames.push(rmvTownship(c.short_name));
          break;
        case "sublocality":
          cityNames.push(rmvTownship(c.short_name));
          break;
        case "administrative_area_level_3":
          cityNames.push(rmvTownship(c.short_name));
          break;
        case "postal_town":
          cityNames.push(rmvTownship(c.short_name));
          break;
        case "administrative_area_level_1":
          state = c.short_name;
          break;
        default:
      }
    }
  });
  return { cityNames, state };
}

function rmvTownship(str) {
  return str.replace(" Township", "");
}