import 'babel-polyfill';
// Frameworks
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
// Internal libs
import { AppRouter, Navigation } from './lib';
// Project files
import { AppArea } from './pages';
import {
  activateStore,
  store,
} from './state/store';
import registerServiceWorker from './registerServiceWorker';
import './assets/css/reset.css';
import './assets/css/index.css';
import { ErrorSentry } from './components';
import ReactGA from 'react-ga';
const {
  REACT_APP_GA_ACCOUNT_ID,
  //NODE_ENV = 'development',
  //REACT_APP_GA_COOKIE_DOMAIN,
} = process.env;

/*
// Uncomment for testing
ReactGA.initialize(REACT_APP_GA_ACCOUNT_ID,{
  gaOptions: {
    cookieName: 'none',
  }
});
*/
ReactGA.initialize(REACT_APP_GA_ACCOUNT_ID!);

ReactGA.pageview(window.location.pathname + window.location.search);

Navigation.history.listen((location, _action) => {
  ReactGA.pageview(`${location.pathname}${location.search}${location.hash}`);
});

AppRouter.configure({
  loginCheck: function fakeLoginCheck() {
    return store.getState().account.roles.length;
  },
  loginPath: '/?displayLogin=true',
  loginRedirectParam: 'after',  // Param added to login path to redirect after login.
  pageOptions: {
    anon: true,      // Pages are Anonymous unless otherwise specified.
    pathExact: true,  // Pages must match the URL path exactly by default.
  },
  rootArea: AppArea as any,   // Provides all pages for the entire site to the router.
  configurePage: (page) => {
    page.title = `Godaven | ${page.title}`;
  }
});

//TODO Add check if logged in. Handle for all routes when not logged in
/*
async function main() {
  await activateStore();
  const user = await AuthAPI.getSignedInUser();
  if (user) {
    store.dispatch(UserActions.signedIn(user));
  }
  render();
}
*/

activateStore();

ReactDOM.render(
  <ErrorSentry>
    <ReduxProvider store={store}>
      <AppRouter />
    </ReduxProvider>
  </ErrorSentry>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'production') {
  registerServiceWorker();
}
