import { MainArea } from './main';
import { AdminArea } from './admin';
import { ShulAdminArea } from './shulAdmin';
import { CampaignArea } from './campaign';
import { IframeArea } from './iFrame';

import { FrontLayout, Layout } from '../components';

export const AppPages = {
  main: MainArea.pages,
  admin: AdminArea.pages,
  shulAdmin: ShulAdminArea.pages,
  campaign: CampaignArea.pages,
  iframe: IframeArea.pages
};
export default AppPages;

export const AppArea = {
  areas: [
    MainArea,
    AdminArea,
    ShulAdminArea,
    CampaignArea,
    IframeArea
  ],
  layouts: {
    admin: {
      path: "/admin",
      view: Layout,
    },
    shulAdmin: {
      path: "/my-shuls",
      view: Layout,
    },
    default: {
      path: "/",
      view: FrontLayout,
    },
  },
};
