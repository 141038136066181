import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ShulActions } from '../../state';
import { HomeActions } from '../../state';
import Search from './Search';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    shul: {
      num_of_pages,
      results,
      pinData,
      sortIds,
      total,
      newEndTime,
      recursiveSearchingNextDay,
      loading,
      isRoshChodesh,
      newDistance,
      pins
    },
    home: {
      lat,
      lng,
      lastSearch
    },
    sponsor: {
      weeklySponsor
    },
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    num_of_pages,
    results,
    pinData,
    sortIds,
    total,
    newEndTime,
    recursiveSearchingNextDay,
    loading,
    lat,
    lng,
    lastSearch,
    isRoshChodesh,
    newDistance,
    weeklySponsor,
    pins
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    shulActions: bindActionCreators(ShulActions, dispatch),
    homeActions: bindActionCreators(HomeActions, dispatch)
  };
})(Search);
