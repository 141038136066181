import { SponsorActions } from '../sponsor/actions';

export const SponsorState = {
  name: 'sponsor',
  persist: false,
  defaults: {
    dailySponsor: null,
    weeklySponsor: null
  },
  handlers: {
    [SponsorActions.DAILY_SPONSOR_GET_TODAYS]: (state, action) => {
      return {
        ...state,
        dailySponsor: action.payload,
      };
    },

    [SponsorActions.WEEKLY_SPONSOR_GET_CURRENT]: (state, action) => {
      return {
        ...state,
        weeklySponsor: action.payload,
      };
    },

  },
};
