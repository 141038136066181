import React from 'react';
import { HelmetHeader } from "../../components/HelmetHeader";
import {
  createToast,
  PageLink,
} from '../../lib';
import Pages from "../";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../../assets/css/pages/contact-form.css';


export default class ContactForm extends React.PureComponent {
  state = {
    full_name: '',
    email: '',
    subject: '',
    preFilledSubject: false,
    message: '',
    triedSubmitting: false,
    formDispatched: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const subject = this.props.pageRoute.query.subject;
    if (subject) {
      this.setState({ subject, preFilledSubject: true });
    }
  }

  componentWillUnmount() {
    this.props.actions.resetContactForm();
  }

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleSubmit = e => {
    this.setState({ triedSubmitting: true });
    const { full_name, email, subject, message } = this.state;

    if (!validateEmail(email)) {
      createToast(null, 'please enter a valid Email');
      return;
    }

    if (!full_name || !email || !subject || !message) {
      createToast(null, 'please fill in all required fields');
      return;
    }
    this.setState({formDispatched: true});
    this.props.actions.submitForm({ full_name, email, subject, message });
  }

  render() {
    const {
      state: {
        full_name,
        email,
        subject,
        preFilledSubject,
        message,
        triedSubmitting,
        formDispatched
      },
      props: { formSubmitted }
    } = this;

    return (
      <React.Fragment>
        <HelmetHeader />
        <div className="contact-form-page flex-justify-center">
          <div>
            <div className="contact-form">
              <h1 className="contact-form-title mb-24">Contact Us</h1>
              {!formSubmitted &&
                <div>
                  <input
                    type="text"
                    className={`contact-form-input mb-12 ${triedSubmitting && !full_name ? 'required' : ''}`}
                    value={full_name}
                    name="full_name"
                    placeholder="Full Name"
                    onChange={this.handleInputChange}
                  />

                  <input
                    type="email"
                    className={`contact-form-input mb-12 ${triedSubmitting && !email ? 'required' : ''}`}
                    value={email}
                    name="email"
                    placeholder="Email Address"
                    onChange={this.handleInputChange}
                  />

                  <input
                    type="text"
                    className={`contact-form-input mb-12 ${triedSubmitting && !subject ? 'required' : ''} ${preFilledSubject ? 'capitalize-text' : ''}`}
                    value={subject}
                    name="subject"
                    placeholder="Subject line"
                    onChange={this.handleInputChange}
                  />

                  <textarea
                    placeholder="Message"
                    className={`contact-form-textarea ${triedSubmitting && !message ? 'required' : ''}`}
                    value={message}
                    name="message"
                    onChange={this.handleInputChange}
                  />
                  <div className="flex-justify-center">
                    <button
                      className="contact-form-submit mb-40"
                      onClick={!formDispatched ? this.handleSubmit : null}
                    >
                      Submit
                  </button>
                  </div>
                </div>}
              {formSubmitted &&
                <div className="form-success-messgae">
                  <p className="flex-align-middle">Thank you for your email,</p>
                  <p className="flex-align-middle mb-32">We will respond as soon as possible.</p>

                  <PageLink to={Pages.main.home} className="flex-align-middle">
                    <button className="contact-form-submit mb-40">Home</button>
                  </PageLink>
                </div>}
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

function validateEmail(email) {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  return expression.test(String(email).toLowerCase());
}
