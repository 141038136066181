import {
  APIClient,
  createToast,
} from '../../lib';

const minyanTypes = {
  shachris: { minyanims: 'shacharisMinyanims', sortedIds: 'shacharisSortedIds' },
  mincha: { minyanims: 'minchaMinyanims', sortedIds: 'minchaSortedIds' },
  mariv: { minyanims: 'maarivMinyanims', sortedIds: 'maarivSortedIds' },
};

export const MinyanActions = {
  MINYAN_GET_BY_ID: 'MINYAN_GET_BY_ID',
  MINYAN_DELETE: 'MINYAN_DELETE',
  MINYAN_UPDATE: 'MINYAN_UPDATE',
  MINYAN_ADD: 'MINYAN_ADD',
  MINYAN_FAILED: 'MINYAN_FAILED',
  MINYAN_FAILURE_RESET: 'MINYAN_FAILURE_RESET',
  MINYANIM_CURRENT: 'MINYANIM_CURRENT',
  MINYANIM_SUGGESTIONS: 'MINYANIM_SUGGESTIONS',
  MINYAN_DELETION_SUGGESTION: 'MINYAN_DELETION_SUGGESTION',
  MINYAN_CLEAR_ACTIVE: 'MINYAN_CLEAR_ACTIVE',

  getMinyanById(minyanId) {
    return async (dispatch, getState) => {
      const response = await APIClient.get(`/minyanim/${minyanId}`)
        .catch(e=>{
          console.error(e);
          if(e.response && e.response.status === 404){
            createToast(null, `We can't find the Minyan you are looking for`, 'warning', true);
          }
        });

      if (response && response.data) {
        dispatch({
          type: MinyanActions.MINYAN_GET_BY_ID,
          payload: response.data
        });
      }
    };
  },

  deleteMinyan(minyanId, minyanType) {
    return async (dispatch, getState) => {
      const response = await APIClient.delete(`/minyanim/${minyanId}`).catch(console.error);

      if (response && response.data) {
        dispatch({
          type: MinyanActions.MINYAN_DELETE,
          payload: {
            minyanId,
            minyanims: minyanTypes[minyanType].minyanims,
            sortedIds: minyanTypes[minyanType].sortedIds
          }
        });
      }
    };
  },

  updateMinyan(minyan, minyanType) {
    return async (dispatch, getState) => {
      const response = await APIClient.put(`/minyanim/${minyan.id}`, minyan).catch(console.error);
      
      if (response && response.data) {
        dispatch({
          type: MinyanActions.MINYAN_UPDATE,
          payload: {
            minyan,
            minyanims: minyanTypes[minyanType].minyanims,
          }
        });

        dispatch({
          type: MinyanActions.MINYAN_FAILURE_RESET
        });

        createToast(null, 'Minyan updated successfully', 'success');
      }else{
        dispatch({ type: MinyanActions.MINYAN_FAILED });
      }
    };
  },

  addMinyan(minyan) {
    return async (dispatch, getState) => {
      const response = await APIClient.post('/minyanim', minyan).catch(console.error);

      if (response && response.data) {
        dispatch({
          type: MinyanActions.MINYAN_ADD,
          payload: {
            minyan: response.data,
            minyanims: minyanTypes[minyan.type].minyanims,
            sortedIds: minyanTypes[minyan.type].sortedIds
          }
        });

        dispatch({
          type: MinyanActions.MINYAN_FAILURE_RESET
        });

        createToast(null, 'Minyan added successfully', 'success');
      }else{
        dispatch({ type: MinyanActions.MINYAN_FAILED });
      }
    };
  },

  addMinyanSuggestion(minyan) {
    return async (dispatch, getState) => {
      const response = await APIClient.post('/minyan-suggestions', minyan).catch(console.error);
      
      if (response && response.data) {
        createToast(null, 'Minyan submitted successfully', 'success');
      }
    };
  },

  getCurrentMinyanim(shulId) {
    return async (dispatch, getState) => {
      const response = await APIClient.get(`/shul/${shulId}/minyanim`).catch(console.error);
      
      if (response && response.data) {
        dispatch({
          type: MinyanActions.MINYANIM_CURRENT,
          payload: response.data,
        });
      }
    };
  },

  getSuggestedMinyanim(shulId) {
    return async (dispatch, getState) => {
      const response = await APIClient.get(`/shul/${shulId}/minyan-suggestions`).catch(console.error);
       
      if (response && response.data) {
        dispatch({
          type: MinyanActions.MINYANIM_SUGGESTIONS,
          payload: response.data,
        });
      }
    };
  },

  suggestMinyanDeletion(minyan){
    return async (dispatch, getState) => {
      const response = await APIClient.post('/minyan-suggestions', minyan).catch(console.error);
      
      if (response && response.data) {
        dispatch({
          type: MinyanActions.MINYAN_DELETION_SUGGESTION,
          payload: minyan
        });

        createToast(null, 'Minyan deletion submitted successfully', 'success');
      }
    };
  },

  denyMinyanSuggestion(shulId, minyanSuggestionId){
    return async () =>{
      const response = await APIClient.post(`/shuls/${shulId}/minyan-suggestions/${minyanSuggestionId}/deny`)
      .catch(e=>{
        console.error(e);
        if(e.response && e.response.status === 404){
          createToast(null, `We can't find this Minyan suggestion. \n It has probably been acted upon already`, 'warning', true);
        }
      });
      
      if (response && response.data) {
        createToast(null, 'Minyan suggestion has been disregarded', 'success');
        return true;
      }else{
        return false;
      }
    };
  },

  approveMinyanSuggestion(shulId, minyanSuggestionId){
    return async () =>{
      const response = await APIClient.post(`/shuls/${shulId}/minyan-suggestions/${minyanSuggestionId}/approve`)
      .catch(e=>{
        console.error(e);
        if(e.response && e.response.status === 404){
          createToast(null, `We can't find this Minyan. \n either the current Minyan has been removed or this suggestion has been acted upon already`, 'warning', true);
        }
      });
      
      if (response && response.data) {
        createToast(null, 'Minyan suggestion has been added to your Minyanim', 'success');
        return true;
      }else{
        return false;
      }
    };
  },

  clearActiveMinyan(){
    return { type: MinyanActions.MINYAN_CLEAR_ACTIVE };
  },

};
