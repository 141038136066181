import React from 'react';
import { HelmetHeader } from "../../components/HelmetHeader";
import {
  APIClient,
  Navigation,
  PageLink,
} from '../../lib';
import Pages from '../';
import Axios from 'axios';
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import { MobileMenu, Login } from "../../components";
import { LocationSearchInput } from "../../components/LocationSearchInput";
import "../../assets/css/pages/home.css";
//import Tooltip from '@material-ui/core/Tooltip';
import Loader from "../../components/Loader";
import HomeHeaderLogo from '../../assets/images/Logo_Home.svg';
import { withStyles } from '@material-ui/core/styles';
import { Switch, ClickAwayListener, Dialog } from '@material-ui/core';
const CustomSwitch = withStyles({
  icon: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  iconChecked: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  bar: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  switchBase: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  checked: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
})(Switch);

export default class Home extends React.PureComponent {
  state = {
    byLocation: true,
    showDialog: false,
    geoLocationErrorCode: null,
    query: '',
    searchAddress: '',
    autoComplete: [],
    mobileMenuOpen: false,
    displayLogin: false,
    isNextMinyan: false
  }

  async componentDidMount() {
    const {
      shulActions,
      pageRoute: { query: { magic_login = null, minyan_suggestions_login } },
      accountActions: { passwordlessLogin }
    } = this.props;

    if (magic_login) {
      //wait 1 second so that we don't have another xhr call reset the cookie to a non-logged in user
      setTimeout(async () => {
        await passwordlessLogin(magic_login);
        if (this.props.shulIds.length) {
          return Navigation.redirect("/my-shuls/view");
        }
      }, 1000);
    }

    if (minyan_suggestions_login) {
      await passwordlessLogin(minyan_suggestions_login);
      if (this.props.shulIds.length) {
        return Navigation.redirect('/my-shuls/minyan-suggestions');
      }
    }

    shulActions.clearSearchResultsFromRedux();
  }

  handleGetUserPosition = async (isNextMinyan = false) => {
    this.setState(() => ({ showDialog: true, isNextMinyan }));
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        this.dispatchUserPositionSearch(latitude, longitude, isNextMinyan);
      },
      e => { this.setState({ geoLocationErrorCode: e.code }); },
      { enableHighAccuracy: true, timeout: 10000 }
    );
  }

  handleGeoLocationFailure = async () => {
    const { homeActions: { saveUserLocation } } = this.props;
    const res = await Axios.get(`https://geoip-db.com/json/`)
      .catch((e) => console.error(e));

    if (res && res.data && res.data.latitude) {
      const { latitude, longitude } = res.data;
      saveUserLocation(latitude, longitude);
      this.dispatchUserPositionSearch(latitude, longitude);
    } else {
      Navigation.go(`/search?mode=location`);
    }
  }

  dispatchUserPositionSearch = async (latitude, longitude) => {
    const isNextMinyan = this.state.isNextMinyan;
    this.props.homeActions.saveUserLocation(latitude, longitude);
    const response = await APIClient.get(`/general/address-from-lat-lng?lat=${latitude}&lng=${longitude}`)
      .catch((e) => console.error(e));

    Navigation.go(`/search?mode=location${isNextMinyan === true ? '&next_minyan=true' : ''}` +
      `&address=${response && response.data && response.data.formattedAddress ? response.data.formattedAddress : 'current location'}` +
      `&lat=${latitude}&lng=${longitude}`);
  }

  handleAddressResults = (addressResults) => {
    const searchAddress = addressResults[0].formatted_address;
    getLatLng(addressResults[0])
      .then((res) => {
        Navigation.go(`/search?mode=location&address=${searchAddress}&lat=${res.lat}&lng=${res.lng}`);
      })
      .catch(error => console.error('Error', error));
  };

  handleLocationInputChange = (locationInput) => {
    this.setState({ searchAddress: locationInput });
  }

  handleSearch = async () => {
    const { byLocation, query, searchAddress } = this.state;
    if (!byLocation) {
      return Navigation.go(`/search?mode=all&q=${query}`);
    }

    if (searchAddress) {
      geocodeByAddress(searchAddress).then(res => {
        getLatLng(res[0]).then((r) => {
          return Navigation.go(`/search?mode=location&address=${res[0].formatted_address}&lat=${r.lat}&lng=${r.lng}`);
        })
          .catch(error => {
            Navigation.go(`/search?mode=location`);
            console.error('Error', error);
          });
      });
      return;
    }

    Navigation.go(`/search?mode=location`);
  }

  handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13) {
      this.handleSearch();
    }
  }

  navigateToShulDetails = (id, locationPoint) => {
    let latitude = '';
    let longitude = '';
    if (locationPoint) {
      const [lng, lat] = locationPoint.coordinates;
      latitude = lat;
      longitude = lng;
    }
    Navigation.go(`/shul-details/${id}?latitude=${latitude}&longitude=${longitude}`);
  }

  handleAutoComplete = async (e) => {
    const { value } = e.target;
    this.setState({ query: value });
    if (value.length < 1) {
      this.setState({ autoComplete: [] });
      return;
    }

    let apiString = `/shuls/auto-complete?type=all&query=${encodeURIComponent(value)}`;
    /** not using now */
    // const { lat, lng } = this.state;
    // if (typeof lat === 'number' && typeof lng === 'number') {
    //   apiString += `&lat=${lat}&lng=${lng}`;
    // }
    const res = await APIClient.get(apiString).catch(() => { return null; });
    if (res) {
      this.setState({ autoComplete: res.data });
    }
  }


  toggleSearchMode = (mode) => {
    this.setState(prevState => ({
      byLocation: mode === 'all' ? false : mode === 'location' ? true : !prevState.byLocation,
      autoComplete: []
    }),
      () => {
        if (this.state.byLocation) {
          setTimeout(() => {
            if (document.getElementById('location')) {
              document.getElementById('location').focus();
            }
          }, 200);
        } else if (window.innerWidth < 561) {
          window.scrollTo(0, 300);
        }
      });
  }

  handleClickAway = () => {
    this.setState({ autoComplete: [] });
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      mobileMenuOpen: !prevState.mobileMenuOpen
    }));
  }

  displayLogin = () => this.setState({ displayLogin: true })

  hideLogin = () => this.setState({ displayLogin: false })

  render() {
    const {
      state: {
        query,
        autoComplete,
        searchAddress,
        byLocation,
        showDialog,
        geoLocationErrorCode,
        mobileMenuOpen,
        displayLogin
      }
    } = this;

    return (
      <React.Fragment>
        <HelmetHeader />
        {mobileMenuOpen && <MobileMenu displayLogin={this.displayLogin} toggleMobileMenu={this.toggleMobileMenu} />}
        {displayLogin && <Login hideLogin={this.hideLogin}></Login>}
        {showDialog &&
          <Dialog open={true}
            PaperProps={{
              style: {
                backgroundColor: !geoLocationErrorCode ? 'transparent' : 'white',
                boxShadow: 'none',
              },
            }}>
            <div className="geo-dialog">

              {!geoLocationErrorCode &&
                <div className="flex-align-middle"><div className="flex-align-middle"><Loader /></div></div>}

              {geoLocationErrorCode &&
                <div className="geo-error-div">
                  <i className="material-icons dialog-warning-icon">error_outline</i>
                  {geoLocationErrorCode === 1 ?
                    (<div className="flex-center-columns">
                      <div className="geo-warning-header">Oops. Your location is blocked.</div>
                      <div className="geo-message mb-12">To get more accurate results please allow location.</div>
                    </div>)
                    : (
                      <div className="geo-warning-header">Oops. Your location was not detected.</div>
                    )}


                  <div className="geo-message mb-12">We will try to estimate your location based on your IP which is less accurate. </div>
                  <div className="geo-message">You can manually change the estimated location to your current one at any time. </div>
                  <div className="geo-dialog-ok-button" onClick={this.handleGeoLocationFailure}>CONTINUE</div>
                </div>}


            </div>
          </Dialog>}

        <div className="home-container">

          <div className="home-headers relative">
            <i
              className="desktop-hidden material-icons mobile-home-menu-icon"
              onClick={this.toggleMobileMenu}
            >
              menu
              </i>
            <img className="home-header-logo" src={HomeHeaderLogo} alt="logo" />
            <PageLink to={Pages.main.clientAddShul} className="mobile-hidden home-add-minyan">
              <span><i className="material-icons">add</i></span>
              <span>Add Minyan</span>
            </PageLink>
          </div>

          <div className="flex-center-columns">

            <h1 className="home-title flex-align-middle max-100">
              The Worldwide Minyan Database
            </h1>

            <div className="home-next-minyan flex-align-middle" onClick={() => this.handleGetUserPosition(true)}>
              Next Minyan Near Me
            </div>

            <div className="home-search-minyanim flex-align-middle">Search Minyanim</div>

            <div className="desktop-hidden mobile-home-mode-toggle">
              <span
                onClick={() => this.toggleSearchMode('location')}
                className={`home-mode-toggle-label ${byLocation ? 'home-mode-toggle-label-active' : ''}`}
              >
                By Location
              </span>
              <div>
                <CustomSwitch
                  className=""
                  checked={!byLocation}
                  onChange={this.toggleSearchMode}
                  color="default"
                />
              </div>
              <span
                onClick={() => this.toggleSearchMode('all')}
                className={`home-mode-toggle-label ${!byLocation ? 'home-mode-toggle-label-active' : ''}`}
              >
                By Name
              </span>
            </div>

            <ClickAwayListener onClickAway={this.handleClickAway}>
              <div className="home-search-bar flex-align-middle">

                <div className="mobile-hidden home-mode-toggle">
                  <span
                    onClick={() => this.toggleSearchMode('location')}
                    className={`link home-mode-toggle-label ${byLocation ? 'home-mode-toggle-label-active' : ''}`}
                  >
                    By Location
                  </span>
                  <div>
                    <CustomSwitch
                      className=""
                      checked={!byLocation}
                      onChange={this.toggleSearchMode}
                      color="default"
                    />
                  </div>
                  <span
                    onClick={() => this.toggleSearchMode('all')}
                    className={`link home-mode-toggle-label ${!byLocation ? 'home-mode-toggle-label-active' : ''}`}
                  >
                    By Name
                  </span>
                  <span className="home-mode-toggle-line"></span>
                </div>


                <div className="home-input-box flex">
                  {!byLocation &&
                    <div className="home-input-div">
                      <input
                        autoComplete="off"
                        placeholder="Enter name of Shul or Rabbi"
                        className="home-input max-100"
                        value={query}
                        name="query"
                        onChange={this.handleAutoComplete}
                        autoFocus={true}
                        onKeyDown={this.handleKeyPress}
                      />

                      {autoComplete.length > 0 &&
                        <div className="shul-auto-complete-container">
                          {autoComplete.map(shul => {
                            const { id, name, address, rabbi, location_point } = shul;
                            return (
                              <div
                                className="ac-ref"
                                key={shul.id}
                                onClick={() => this.navigateToShulDetails(id, location_point)}
                              >
                                <div className="shul-auto-complete-row">
                                  {`${name} | ${address} ${rabbi ? `| ${rabbi}` : ''}`}
                                </div>
                              </div>
                            );
                          })}
                        </div>}
                    </div>}



                  {byLocation &&
                    <LocationSearchInput
                      id="location"
                      customClass="home-input max-100"
                      placeHolder={'Your Address, City, Locale or Place'}
                      prevAddress={searchAddress}
                      forHomePage={true}
                      handleLocationInputChange={this.handleLocationInputChange}
                      handleAddressResults={this.handleAddressResults}
                      handleKeyPress={this.handleKeyPress}
                    />}
                  <div className="relative">
                    <i className="material-icons use-location-icon link" onClick={this.handleGetUserPosition}>gps_fixed</i>
                    <div className="desktop-hidden home-mobile-search link" onClick={this.handleSearch}>
                      <i className="material-icons home-mobile-search-icon">
                        search
                      </i>
                    </div>
                  </div>
                </div>

                <div className="mobile-hidden home-search-button link flex-align-middle" onClick={this.handleSearch}>Search</div>

              </div>
            </ClickAwayListener>
          </div>

        </div>
      </React.Fragment>
    );
  }
}
