import { HomeActions } from './actions';


export const HomeState = {
  name: 'home',
  persist: false,
  defaults: {
    lat: null,
    lng: null,
    formSubmitted: false,
    lastSearch: {}
  },
  handlers: {
    [HomeActions.USER_LOCATION_SAVE]: (state, action) => {
      const { lat, lng } = action.payload;
      return {
        ...state,
        lat,
        lng,
      };
    },

    [HomeActions.CONTACT_FORM_SUBMIT]: (state, action) => {
      return {
        ...state,
        formSubmitted: true,
      };
    },

    [HomeActions.CONTACT_FORM_RESET]: (state, action) => {
      return {
        ...state,
        formSubmitted: false,
      };
    },

    [HomeActions.SAVE_LAST_SEARCH]: (state, action) => {

      return {
        ...state,
        lastSearch: action.payload
      };
    },

  },
};
