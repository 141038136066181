import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { UIAction } from '../../state';
import { HomeActions } from '../../state';
import { ShulActions } from '../../state';
import { AccountActions } from '../../state';
import Home from './Home';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    ui: {
      isThingVisible = false,
    },
    home: {
      lat,
      lng
    },
    account:{
      shulIds,
      roles
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    isThingVisible,
    lat,
    lng,
    shulIds,
    roles
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(UIAction, dispatch),
    homeActions: bindActionCreators(HomeActions, dispatch),
    shulActions: bindActionCreators(ShulActions, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
})(Home);
