import React from 'react';


export default class NotFound extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="mt-24 mb-24" style={{minHeight:"300px"}}>
          <h1 className="text-36 text-center">404 - Page Not Found</h1>
        </div>
      </React.Fragment>
    );
  }
}
