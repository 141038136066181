import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShulActions, AccountActions } from '../state';
import { CovidPolicy } from './CovidPolicy';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    shul: {
      covidPolicies,
      shulCovidPolicy,
      shulInfo,
    },
    account: {
      showCovidModal
    }

  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    covidPolicies,
    shulCovidPolicy,
    showCovidModal,
    shulInfo
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    ShulActions: bindActionCreators(ShulActions, dispatch),
    AccountActions: bindActionCreators(AccountActions, dispatch),
  };
})(CovidPolicy);
