import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SponsorActions } from '../../state';
import { UIAction } from '../../state';
import { AccountActions } from '../../state';
import ViewSponsor from './ViewSponsor';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    sponsor: {
      sponsors,
      sponsorsToDisplay,
    },
    ui: {
      record,
      recordType
    },
    account: {
      authorized
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    sponsors,
    sponsorsToDisplay,
    record,
    recordType,
    authorized
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(SponsorActions, dispatch),
    uiActions: bindActionCreators(UIAction, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
})(ViewSponsor);
