import React from "react";
import { createToast, Navigation } from "../../lib";
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/contact.css";
import "react-toastify/dist/ReactToastify.css";

export default class EditAccount extends React.PureComponent {
  state = {
    old_password: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    new_password: "",
    confirm_password: "",
    submitted: false,
    userId: null
  };

  async componentDidMount() {
    const {
      actions: { checkUsersAuth, getUserById },
      userId
    } = this.props;
    await checkUsersAuth();

    if (!this.props.authorized) {
      Navigation.redirect("/?displayLogin=true");
      return;
    }

    const account = await getUserById(userId);
    if (account) {
      const { first_name, last_name, email, phone } = account;
      this.setState({
        first_name,
        last_name,
        email,
        phone: phone ? phone : "",
        userId
      });
    }
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  handleSubmit = async () => {
    this.setState({ submitted: true });
    const {
      old_password,
      new_password,
      confirm_password,
      first_name,
      last_name,
      email,
      phone,
      userId
    } = this.state;

    if (!old_password) {
      createToast(null, "Current password is required to make changes");
      return;
    }

    if (new_password !== confirm_password) {
      createToast(null, "Passwords must match!");
      return;
    }

    const postData = {
      old_password,
      first_name,
      last_name,
      email,
      phone: phone ? phone : null
    };

    if (new_password) postData.new_password = new_password;

    await this.props.actions.editAccount(userId, postData);
  };

  render() {
    const {
      old_password,
      new_password,
      confirm_password,
      submitted,
      first_name,
      last_name,
      email,
      phone
    } = this.state;

    return (
      <div className="full-page-modal">
        <h1 className="xl-text mob-large-text text-500 bordered-title desktop-hidden">
          Edit My Account
        </h1>
        <div
          className="full-page-modal-close link"
          onClick={this.props.pageRoute.history.goBack}
        >
          <i className="material-icons">close</i>
        </div>
        <div className="container">
          <h1 className="xl-text mob-large-text text-500 bordered-title mobile-hidden">
            Edit My Account
          </h1>

          <div className={`mb-16 mt-32`}>
            <label className="input-label">Current Password</label>
            <input
              type="password"
              className={`custom-input ${
                submitted && !old_password ? "required" : ""
              }`}
              placeholder="required to make changes"
              value={old_password}
              name="old_password"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-16`}>
            <label className="input-label">First Name</label>
            <input
              type="text"
              className={`custom-input ${
                submitted && !first_name ? "required" : ""
              }`}
              value={first_name}
              name="first_name"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-16`}>
            <label className="input-label">Last Name</label>
            <input
              type="text"
              className={`custom-input ${
                submitted && !last_name ? "required" : ""
              }`}
              value={last_name}
              name="last_name"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-16`}>
            <label className="input-label">Email</label>
            <input
              type="email"
              className={`custom-input ${
                submitted && !email ? "required" : ""
              }`}
              value={email}
              name="email"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-16`}>
            <label className="input-label">Phone</label>
            <input
              type="text"
              className="custom-input"
              value={phone}
              name="phone"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-16`}>
            <label className="input-label">New Password</label>
            <input
              type="password"
              className={`custom-input ${
                submitted && confirm_password && !new_password ? "required" : ""
              }`}
              value={new_password}
              name="new_password"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-32`}>
            <label className="input-label">Confirm Password</label>
            <input
              type="password"
              className={`custom-input ${
                submitted && new_password && !confirm_password ? "required" : ""
              }`}
              value={confirm_password}
              name="confirm_password"
              onChange={this.handleChange}
            />
          </div>
          <hr />

          <div className="contact-buttons-div">
            <button
              className="btn btn-light uppercase-text contact-button"
              onClick={this.props.pageRoute.history.goBack}
            >
              Cancel
            </button>
            <button
              className="btn btn-accent contact-button uppercase-text"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
