/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { PageLink, Navigation } from '../../lib';
import Pages from "../";

import { HelmetHeader } from "../../components/HelmetHeader";
import "../../assets/css/pages/shul-details.css";
import "../../assets/css/pages/shul-images-carousel.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import { Card } from "@material-ui/core";
import { offsetCalculationTypes } from "../../lib/constants";
const VIEWS = { REGULAR_MINYANIM: "REGULAR_MINYANIM", SPECIAL_DAYS: "SPECIAL_DAYS", EVENTS: "EVENTS" };

export default class ShulDetails extends React.PureComponent {

  state = {
    specialDays: false,
    hasImages: false,
    showImages: false,
    latitude: '',
    longitude: '',
    fromSearch: false,
    color1: '#11b6d7',
    color2: '#11b6d7',
    activeView: VIEWS.REGULAR_MINYANIM
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const {
      clearShulFromRedux,
      getShulDetails,
      pageRoute: {
        params: { shulId },
        query: {
          latitude,
          longitude,
          from_search,
          color1,
          color2
        }
      }
    } = this.props;
    clearShulFromRedux();
    const shulDetails = await getShulDetails(parseInt(shulId, 10));
    if (shulDetails) {
      const { minyan_location_images } = this.props.shulInfo || [];
      if (minyan_location_images && minyan_location_images.length) {
        this.setState({ hasImages: true });
      }

      this.setState({ latitude, longitude, fromSearch: from_search });
    }

    if(color1 && color2){
      this.setState({ color1, color2 });
    }
  }

  togglePopUp = e => {
    const id = e.target.id;
    this.setState(prevState => ({ [id]: prevState[id] === true ? false : true }));
  }

  closePopUp = e => {
    const id = e.target.id;
    this.setState({ [id]: false });
  }

  toggleActiveView = view => {
    // eslint-disable-next-line default-case
    switch (view) {
      case VIEWS.REGULAR_MINYANIM:
        this.setState({ specialDays: false, activeView: view });
        break;
        case VIEWS.SPECIAL_DAYS:
          this.setState({ specialDays: true, activeView: view });
        break;
        case VIEWS.EVENTS:
        this.setState({ specialDays: false, activeView: view });
        break;
    }
  }

  toggleImages = () => {
    this.setState(prevState => ({ showImages: !prevState.showImages }));
  }

  searchMinyanimNearby = (fullAddress, lat, lng) => {
    if (lat) {
      Navigation.go(`/search?mode=location&address=${fullAddress}&lat=${lat}&lng=${lng}`);
    } else {
      geocodeByAddress(fullAddress).then(res => {
        return getLatLng(res[0]).then((r) => {
          return Navigation.go(`/search?mode=location&address=${fullAddress}&lat=${r.lat}&lng=${r.lng}`);
        })
          .catch(error => Navigation.go(`/search?mode=location&address=${fullAddress}`));
      });
    }
  }

  render() {
    const {
      props: {
        pageRoute: { params: { shulId } },
        shulInfo: {
          id = '',
          name = '',
          shul_nusach = '',
          minyan_location_images = '',
          notes = '',
          shiurim = '',
          formatted_address = '',
          events
        },
        groupedByDayMinyanim,
        groupedContacts: {
          rabbi = [],

        } = {},
      },
      state: {
        specialDays,
        color1,
        color2,
        activeView
      }
    } = this;
    let timeId = 1;
    const rabbiName = rabbi.length ? `| Rabbi ${rabbi[0].first_name} ${rabbi[0].last_name}` : '';
    const minyanTypes = ['shachris', 'mincha', 'mariv'];
    const days = { sun: 'Sunday', mon: 'Monday', tues: 'Tuesday', wed: 'Wednesday', thurs: 'Thursday', fri: 'Friday', sat: 'Shabbos' };
    const images = [];
    if (minyan_location_images.length) {
      minyan_location_images.forEach(image => {
        images.push(`https://godaven-assets.sfo2.digitaloceanspaces.com/shul-images/${encodeURI(image.image_name)}`);
      });
    }

    return (
      <React.Fragment>
        <HelmetHeader
          customTitle={`Godaven | ${name.replace(/\b\w/g, l => l.toUpperCase())}`}
          customDescription={`Shul info and minyanim times (zmanim) for - ${name.replace(/\b\w/g, l => l.toUpperCase())} | ${formatted_address} ${rabbiName.replace(/\b\w/g, l => l.toUpperCase())}`}
        />

        <div className="flex-justify-center shul-details-outer-wrapper">
          <div className="shul-details-container max-100">

            <div className="flex-justify-space-center">
              <div className="flex-align-center">
                <div className={`reg-minyanim`} onClick={() => this.toggleActiveView(VIEWS.REGULAR_MINYANIM)} style={activeView === VIEWS.REGULAR_MINYANIM ? {color: color2, borderBottom: `2px solid ${color2}` } : null}>
                  Regular Minyanim
                </div>
                <div className={`special-minyanim`} onClick={() => this.toggleActiveView(VIEWS.SPECIAL_DAYS)} style={activeView === VIEWS.SPECIAL_DAYS ? {color: color2, borderBottom: `2px solid ${color2}` } : null}>
                  Special Days*
                </div>
                <div className={`events-tab`} onClick={() => this.toggleActiveView(VIEWS.EVENTS)} style={activeView === VIEWS.EVENTS ? {color: color2, borderBottom: `2px solid ${color2}` } : null}>
                  Shiurim / Events
                </div>
              </div>
              <div className="mobile-hidden special-days-text">
                *Special days includes Rosh Chodesh, Fast Days, Legal Holidays, Selichos, Chol Hamoed
              </div>
            </div>

            <div className="desktop-hidden special-days-text mt-16">
              *Special days includes Rosh Chodesh, Fast Days, Legal Holidays, Selichos, Chol Hamoed
              </div>


            {activeView !== VIEWS.EVENTS && <div className="minyanim-box mobile-mt-12 max-100">
              <div className="minyanim-header max-100" style={{backgroundColor: color1}} >
                <div className="flex-align-middle">Day</div>
                <div className="flex-align-middle">Shacharis</div>
                <div className="flex-align-middle">Mincha</div>
                <div className="flex-align-middle">Maariv</div>
              </div>

              {Object.keys(days).map(d => {
                return (
                  <div key={d} className={`minyan-row  max-100`}>
                    <div className="minyan-day-name">{days[d]}</div>
                    {minyanTypes.map(type => {
                      return (
                        <div key={type} className={`minyan-times mob-xs-text`}>
                          <div className={`minyan-time-column`}>
                            {groupedByDayMinyanim && groupedByDayMinyanim[d] && groupedByDayMinyanim[d][type] &&
                              groupedByDayMinyanim[d][type].map((minyan, index) => {
                                const hasPopup = checkIfShouldDisplayPopup(minyan, shul_nusach);
                                const {
                                  id,
                                  special_days_only,
                                  special_days_included,
                                  displayTime,
                                } = minyan;
                                if ((!specialDays && special_days_only.length) || (specialDays && !special_days_only.length && !special_days_included.length)) {
                                  return null;
                                }
                                return (
                                  <div
                                    style={{}}
                                    key={id}
                                    className={`${hasPopup ? 'link' : ''}`}
                                  >
                                    <div
                                      id={++timeId}
                                      onClick={hasPopup ? this.togglePopUp : null}
                                      tabIndex={timeId}
                                      className={`minyan-time-display ${hasPopup ? 'clickable-time' : ''}`}
                                      onBlur={hasPopup ? this.closePopUp : null}
                                      style={hasPopup ? {color: color2} : null }
                                    >
                                      {formatTime(displayTime)}
                                    </div>
                                    {this.state[timeId] === true && renderPopup(minyan, shul_nusach, color1)}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>}

            {activeView === VIEWS.EVENTS &&
              <div className="events-cards-container">
                {Array.isArray(events) && events.map(e => (
                  <Card className="event-card" key={e.id}>
                    <div className="flex-justify-space">
                      <div className="temp-shul-name" style={{color: color1}}>{e.title}</div>
                      <div className="event-card-lang">{e.language}</div>
                    </div>
                    <div className="capitalize-text event-type-name">{e.event_types[0].name}</div>
                    <div className="flex-justify-center-columns temp-shul-info">
                      <div className="capitalize-text">{e.speaker}</div>
                      <div className="capitalize-text">{e.days.join(", ")}</div>
                      <div className="event-speaker">{e.displayTimeBeginning === e.displayTimeEnd ?
                        formatTime(e.displayTimeEnd, true) :
                        `${formatTime(e.displayTimeBeginning, true)} - ${formatTime(e.displayTimeEnd, true)}`}</div>
                      <div className="minyan-torah-message">{e.notes}</div>
                    </div>
                  </Card>
                ))}
              </div>
            }

            <div className="notes-box">
              <div className="notes-header" style={{color: color2}} >Notes</div>
              <div className="notes-text">{notes ? notes : ''}</div>
              {shiurim &&
                <div className="shiurim-link-div">
                  <a
                    href={`http://${process.env.REACT_APP_SHIURIM_BASE_URL}${id}`}
                    target="_blank"
                    rel="noopener nofollow"
                    className="shiurim-link"
                    style={{color: color2}}
                  >
                    See Shiurim
                </a>
                </div>}
            </div>

            <div className="mt-24 flex-align-middle mobile-flex-center-columns">
              <PageLink
                className="shul-details-link flex-align-middle"
                target="_blank"
                to={Pages.main.suggestShulUpdate}
                params={{ shulId }}
                style={{color: color2, border: `1px solid ${color2}`}}
              >
                Suggest Minyan Update
              </PageLink>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}


function checkIfShouldDisplayPopup(minyan, shul_nusach) {
  const {
    special_days_only,
    special_days_included,
    start_date,
    end_date,
    nusach,
    time_at,
    zman_type,
    notes
  } = minyan;

  if ((nusach && nusach !== shul_nusach)
    || (start_date && end_date)
    || (!time_at && zman_type)
    || notes
    || special_days_only.length || special_days_included.length) {
    return true;
  }
  return false;
}

function renderPopup(minyan, shul_nusach, color1) {
  const {
    special_days_only,
    special_days_included,
    start_date,
    end_date,
    offset_minutes,
    nusach,
    time_at,
    zman_type,
    offset_calculated_by,
    offset_rounded_by,
    not_earlier_than,
    not_later_than,
    notes,
    type,
  } = minyan;
  return (
    <div className="expanded-time-display-container">
      <div className={`expanded-time-display
        ${type === 'shachris' ? 'schacharis-popup' : type === 'mincha' ? 'mincha-popup' : 'maariv-popup'}`}
        style={{border: `2px solid ${color1}`}}
        >
        {nusach && nusach !== shul_nusach ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Nusach:</div>
            <div className="expanded-time-row-info flex-align-center  capitalize-text">
              {nusach.replace('_', ' ')}
            </div>
          </div>) : ''}

        {start_date && end_date ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Seasonal:</div>
            <div className="expanded-time-row-info flex-align-center">
              {`${formatDate(start_date)} - ${formatDate(end_date)}`}
            </div>
          </div>) : ''}

        {!time_at && zman_type ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Time:</div>
            <div className="expanded-time-row-info flex-left-center-columns">
              <div>
                {`${offset_minutes !== 0 ? Math.abs(offset_minutes) + ' minutes' : ''}
                ${offset_minutes > 0 ? 'after' : offset_minutes < 0 ? 'before' : 'at'} `}
                <span className="capitalize-text">{zman_type.name.replace(/_/, ' ')}</span>
                {offset_calculated_by ? renderCalculatedBy(offset_calculated_by) : ''}
                {offset_rounded_by ? renderRoundedBy(offset_rounded_by) : ''}
                {not_earlier_than ? renderNotEarlierAndLater('earlier', not_earlier_than) : ''}
                {not_later_than ? renderNotEarlierAndLater('later', not_later_than) : ''}
              </div>
            </div>
          </div>) : ''}

        {(special_days_only.length || special_days_included.length) ?
          (<div className="expanded-time-row ">
            <div className="expanded-time-row-name"> Special Days:</div>
            <div className="expanded-time-row-info flex-align-center">
              {special_days_only.length ? `only for ${special_days_only.join(', ')}`
                : `includes ${special_days_included.join(', ')}`}
            </div>
          </div>) : ''}

        {notes ?
          (<div className="expanded-time-row">
            <div className="expanded-time-row-name">Notes:</div>
            <div className="expanded-time-row-info flex-align-center">{notes}</div>
          </div>) : ''}
      </div>
    </div>
  );
}


function formatTime(time, showSuffix) {
  if (!time) return 'no time';
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  const suffix = time.slice(0, 2) >= 12 ? " PM" : " AM";
  return `${hours}${minutes}${showSuffix ? suffix : ''}`;
}

function formatDate(date, showYear) {
  const months = {
    '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June',
    '07': 'July', '08': 'Aug', '09': 'Sept', '10': 'Oct', '11': 'Nov', '12': 'Dec'
  };
  const day = date.slice(8, 10);
  const month = months[date.slice(5, 7)];
  const year = date.slice(0, 4);
  return `${month} ${day}, ${showYear ? year : ''}`;
}


function renderCalculatedBy(calculatedBy) {
  return <p className="xs-text mob-text-6 accent-text">{`calculated by ${offsetCalculationTypes[calculatedBy]}`}</p>;
}

function renderRoundedBy(roundedBy) {
  const roundedByOptions = {
    on_time: 'at the exact time',
    earlier_5: 'rounded to earlier 5 minutes',
    later_5: 'rounded to later 5 minutes'
  };

  return (
    <p className="xs-text mob-text-6 accent-text">
      {roundedByOptions[roundedBy]}
    </p>
  );
}

function renderNotEarlierAndLater(beforeOrAftertext, time) {
  return <p className="xs-text mob-text-6 accent-text">{`not ${beforeOrAftertext} than ${formatTime(time, true)}`}</p>;
}