import React, { Fragment } from "react";
import { HelmetHeader } from "../../components/HelmetHeader";
import { Navigation, APIClient } from "../../lib";
import PlacesAutocomplete from "react-places-autocomplete";
import "./campaign.css";
import Banner from "./Banner";

export default class Campaign extends React.PureComponent {
  state = {
    input: "",
    missingCount: 0
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    
    const response = await APIClient.get(`/campaign/missing-count`).catch(
      console.error
    );
    if (response && response.data) {
      this.setState({ missingCount: response.data.count });
    }
  }

  handleChange = value => this.setState({ input: value });

  handleSelect = address => {
    Navigation.go(`/chezky/missing-info-list?address=${address}`);
  };

  render() {
    const {
      state: { input, missingCount }
    } = this;
    return (
      <Fragment>
        <Banner />
        <div className="campaign-container">
          <div className="c-home-header">
            <div>
              Help <span className="c-home-users">25,000</span> monthly users
              worldwide<span className="mobile-hidden">&nbsp;find</span>
            </div>
            <div><span className="desktop-hidden">find</span>&nbsp;the shul contact info they need.</div>
          </div>

          <div className="c-home-sub">
            <div>Only {missingCount} of 7,200 Shuls </div>
            <div>are still missing information!</div>
          </div>

          <div className="c-home-sub-sub">
            <div>
              Enter your address or city and the 10 closest shuls<span className="mobile-hidden">&nbsp;within</span>
            </div>
            <div><span className="desktop-hidden">within</span>&nbsp;your city with missing information will appear.</div>
          </div>

          <div className="c-search-bar">
            <div className="campaign-input-wrapper">
              <PlacesAutocomplete
                value={input}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                googleCallbackName="placesAutoComplete"
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
                }) => (
                  <div className="campaign-places-container">
                    <input
                      {...getInputProps({
                        autoFocus: true,
                        placeholder: "Your Address, City",
                        className: "campaign-input"
                      })}
                    />
                    <div className="campaign-places-autocomplete-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className: "campaign-ac-active"
                            })}
                          >
                            <span className="campaign-suggestion">
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <div
                className="desktop-hidden campaign-mobile-search"
                onClick={this.handleSearch}
              >
                <i className="material-icons campaign-mobile-search-icon">
                  search
                </i>
              </div>
            </div>

            <div className="mobile-hidden campaign-search-button flex-align-middle">
              Search
            </div>
          </div>
        </div>

        <HelmetHeader />
      </Fragment>
    );
  }
}
