import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { HomeActions } from '../../state';
import ContactForm from './ContactForm';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const { formSubmitted } = state.home;

  // The returned object becomes properties of the connected Component...
  return {
    formSubmitted
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(HomeActions, dispatch)
  };
})(ContactForm);
