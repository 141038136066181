import React from "react";
import {
  APIClient,
  createToast,
  PageLink,
  Navigation
} from "../../lib";
import Pages from '../';
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/add-edit-shul.css";
import "../../assets/css/pages/view-shul.css";
import "react-toastify/dist/ReactToastify.css";

export default class Sponsor extends React.PureComponent {
  state = {
    sponsorId: null,
    addOrEdit: 'add',
    mode: 'daily',
    sponsor_name: '',
    date: '',
    start_date: '',
    end_date: '',
    message: '',
    triedSubmitting: false
  }

  async componentDidMount() {
    await this.props.accountActions.checkUsersAuth(true);

    if(!this.props.authorized){
      Navigation.redirect('/?displayLogin=true');
      return;
    }

    const {
      pageRoute: {
        params: {
          mode,
          sponsorId
        },
      },
    } = this.props;

    // if there is a sponsorId then it's in edit mode if not it's in add mode
    if (sponsorId && mode) {
      this.setState({ addOrEdit: 'edit', mode });
      if (mode === 'daily') {
        return this.getDailySponsor(sponsorId);
      }
      if (mode === 'weekly') {
        return this.getWeeklySponsor(sponsorId);
      }
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSave = (addAnother = false) => {
    this.setState({ triedSubmitting: true });
    const {
      addOrEdit,
      mode,
      sponsorId,
      sponsor_name,
      message,
      date,
      start_date,
      end_date
    } = this.state;

    if (!sponsor_name || !message || (mode === 'daily' && !date) || (mode === 'weekly' && (!start_date || !end_date))) {
      createToast(null, 'Please fill in all required fields!');
      return;
    }

    if (addOrEdit === 'add' && mode === 'daily') {
      return this.addDailySponsor(sponsor_name, date, message, addAnother);
    }

    if (addOrEdit === 'add' && mode === 'weekly') {
      return this.addWeeklySponsor(sponsor_name, start_date, end_date, message, addAnother);
    }

    if (addOrEdit === 'edit' && mode === 'daily') {
      return this.editDailySponsor(sponsorId, sponsor_name, date, message);
    }

    if (addOrEdit === 'edit' && mode === 'weekly') {
      return this.editWeeklySponsor(sponsorId, sponsor_name, start_date, end_date, message);
    }

  }

  getDailySponsor = async (sponsorId) => {
    const response = await APIClient.get(`/daily-sponsors/${+sponsorId}`)
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      return createToast(response.err.response.data.code);
    } else {
      const { sponsor_name, date, message } = response.data;
      this.setState({ sponsorId, sponsor_name, date, message });
    }
  }

  getWeeklySponsor = async (sponsorId) => {
    const response = await APIClient.get(`/weekly-sponsors/${+sponsorId}`)
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      return createToast(response.err.response.data.code);
    } else {
      const { sponsor_name, start_date, end_date, message } = response.data;
      this.setState({ sponsorId, sponsor_name, start_date, end_date, message });
    }
  }

  addDailySponsor = async (sponsor_name, date, message, addAnother) => {
    const response = await APIClient.post('/daily-sponsors',
      { sponsor_name, date, message })
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      if (response.err.response.data.message === 'Validation error') {
        return createToast(null, 'the date you entered is already taken!');
      }
      return createToast(response.err.response.data.code);
    } else {
      createToast(null, 'you have successfully added a daily sponsor', "success");
      if (addAnother) this.resetPage();
    }
  }

  editDailySponsor = async (sponsorId, sponsor_name, date, message) => {
    const response = await APIClient.put(`/daily-sponsors/${sponsorId}`,
      { sponsor_name, date, message })
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      if (response.err.response.data.message === 'Validation error') {
        return createToast(null, 'the date you entered is already taken!');
      }
      return createToast(response.err.response.data.code);
    } else {
      return createToast(null, 'you have successfully edited this daily sponsor', "success");
    }
  }

  addWeeklySponsor = async (sponsor_name, start_date, end_date, message, addAnother) => {
    const response = await APIClient.post('/weekly-sponsors',
      { sponsor_name, start_date, end_date, message })
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      if (response.err.response.data.message === 'Validation error') {
        return createToast(null, 'the date you entered is already taken!');
      }
      return createToast(response.err.response.data.code);
    } else {
      createToast(null, 'you have successfully added a weekly sponsor', "success");
      if (addAnother) this.resetPage();
    }
  }

  editWeeklySponsor = async (sponsorId, sponsor_name, start_date, end_date, message) => {
    const response = await APIClient.put(`/weekly-sponsors/${sponsorId}`,
      { sponsor_name, start_date, end_date, message })
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      if (response.err.response.data.message === 'Validation error') {
        return createToast(null, 'the date you entered is already taken!');
      }
      return createToast(response.err.response.data.code);
    } else {
      return createToast(null, 'you have successfully edited this weekly sponsor', "success");
    }
  }

  toggleSponsorMode = () => {
    this.setState(prevState => ({
      mode: prevState.mode === 'daily' ? 'weekly' : 'daily',
      sponsor_name: '',
      date: '',
      start_date: '',
      end_date: '',
      message: '',
      triedSubmitting: false
    }));
  }

  resetPage = () => {
    this.setState(prevState => ({
      mode: prevState.mode,
      sponsor_name: '',
      date: '',
      start_date: '',
      end_date: '',
      message: '',
      triedSubmitting: false
    }));
  }


  render() {
    const {
      addOrEdit,
      mode,
      sponsor_name,
      message,
      date,
      start_date,
      end_date,
      triedSubmitting
    } = this.state;

    return (
      <div>
        <div className="full-page-modal">
          <PageLink
            className="full-page-modal-close link"
            to={Pages.admin.dashboard}>
            <i className="material-icons sponsor-close">close</i>
          </PageLink>

          <div className="container">

            <h1 className="xl-text text-500 bordered-title">
              {addOrEdit === 'add' ? 'Add Sponsor' : 'Edit Sponsor'}
            </h1>

            <div className="mb-32 flex-align-center">
              <span
                className={`sponsor-mode-toggle-label
                  ${mode === 'daily' ? 'sponsor-mode-toggle-label-active' : ''}`}
              >
                Daily Sponsor
              </span>
              <i
                className="material-icons sponsor-mode-toggle"
                onClick={addOrEdit === 'add' ? this.toggleSponsorMode : null}
              >
                {mode === 'daily' ? 'toggle_off' : 'toggle_on'}
              </i>
              <span
                className={`sponsor-mode-toggle-label
                  ${mode === 'weekly' ? 'sponsor-mode-toggle-label-active' : ''}`}
              >
                Weekly Sponsor
              </span>
            </div>

            <div className="mb-16">
              <label className="input-label">Sponsor Name</label>
              <input
                type="text"
                className={`custom-input ${triedSubmitting && !sponsor_name ? 'required' : ''}`}
                value={sponsor_name}
                name="sponsor_name"
                onChange={this.handleChange}
              />
            </div>

            {mode === 'daily' ?
              (<div className="mb-32">
                <label className="input-label">Date</label>
                <input
                  type="date"
                  className={`custom-input ${triedSubmitting && !date ? 'required' : ''}`}
                  value={date}
                  name="date"
                  onChange={this.handleChange}
                />
              </div>)
              : (<div>
                <div className="mb-16">
                  <label className="input-label">Start Date</label>
                  <input
                    type="date"
                    className={`custom-input ${triedSubmitting && !start_date ? 'required' : ''}`}
                    value={start_date}
                    name="start_date"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="mb-32">
                  <label className="input-label">End Date</label>
                  <input
                    type="date"
                    className={`custom-input ${triedSubmitting && !end_date ? 'required' : ''}`}
                    value={end_date}
                    name="end_date"
                    onChange={this.handleChange}
                  />
                </div>
              </div>)}
            <div className="mb-32">
              <label className="input-label mb-8">Message</label>
              <textarea
                rows="4"
                placeholder="Enter sponsor message"
                className={`custom-input full-width ${triedSubmitting && !message ? 'required' : ''}`}
                value={message}
                name="message"
                onChange={this.handleChange}
              >
              </textarea>
            </div>
            <div className="flex-justify-space-center">
              <button
                className="btn btn-accent uppercase-text flex-align-middle"
                onClick={this.handleSave}
              >
                Save
              </button>

              {addOrEdit === 'add' &&
                <button
                  className="btn btn-accent uppercase-text flex-align-middle"
                  onClick={() => this.handleSave(true)}
                >
                  Save & add another
                </button>}

              {addOrEdit === 'edit' &&
                <PageLink
                  to={Pages.admin.addSponsor}
                  className="btn btn-accent uppercase-text flex-align-middle"
                >
                  add sponsor
              </PageLink>}

              <PageLink
                to={Pages.admin.viewSponsors}
                className="btn btn-accent uppercase-text flex-align-middle"
              >
                View sponsors
              </PageLink>
            </div>

          </div>

        </div>
        <ToastContainer />
      </div>
    );
  }
}
