/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import {
  PageLink,
} from '../lib';
import Pages from '../pages/index';
import "../assets/css/components/mobileMenu.css";
import HeaderLogo from '../assets/images/Logo_Home.svg';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

export class MobileMenu extends React.PureComponent {

  state = {
  }


  render() {
    const { roles, shulIds, displayLogin, toggleMobileMenu } = this.props;

    return (
      <SwipeableDrawer
        open={true}
        onClose={toggleMobileMenu}
        onOpen={toggleMobileMenu}
      >
        <div className="mobile-menu-container">
          <i className="material-icons mobile-menu-close" onClick={toggleMobileMenu}>close</i>
          <div className="mobile-menu-logo-div">
            <img className="flex-align-middle mobile-menu-logo" src={HeaderLogo} alt="logo" />
          </div>

          <div className="mobile-menu">
            <ul className="mobile-menu-links">
              <li>
                <PageLink to={Pages.main.home} onClick={toggleMobileMenu}>
                  Home
               </PageLink>
              </li>
              <li>
                <PageLink to={Pages.main.clientAddShul} onClick={toggleMobileMenu}>
                  Add a minyan
               </PageLink>
              </li>
              <li>
                <PageLink to={Pages.main.clientAddShul} query={{ temp: true }} onClick={toggleMobileMenu}>
                  Add a temporary minyan
             </PageLink>
              </li>
              <li>
                <PageLink to={Pages.main.tempShuls} onClick={toggleMobileMenu}>
                  Temporary minyanim
             </PageLink>
              </li>
              <li><a href="/list-shuls/countries" onClick={toggleMobileMenu}>Browse by Country</a></li>
              <li><PageLink to={Pages.main.about} onClick={toggleMobileMenu}>About Us</PageLink></li>
              <li><PageLink to={Pages.main.contact} onClick={toggleMobileMenu}>Contact Us</PageLink></li>
              <li>
                <div className="" onClick={toggleMobileMenu}>
                  {(roles.includes('application_admin') || (roles.includes('shul_admin') && shulIds.length)) ?
                    (<PageLink
                      className=""
                      to={roles.includes('application_admin') ? Pages.admin.dashboard : Pages.shulAdmin.myShulsView}
                    >
                      Admin
                  </PageLink>)
                    : (<div className="" onClick={displayLogin}>Admin Login</div>)
                  }
                </div>
              </li>
              <li>
                <PageLink to={Pages.main.termsOfService} onClick={toggleMobileMenu}>
                  Terms of service
              </PageLink>
              </li>
            </ul>
          </div>

        </div>
      </SwipeableDrawer>
    );
  }
}