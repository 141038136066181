import Home from './Home.vm';
import About from './About';
import TOS from './TOS';
import ContactForm from './ContactForm.vm';
import NotFound from './NotFound';
import Search from './Search.vm';
import ShulDetails from './ShulDetails.vm';
import ClientAddShul from './ClientAddShul.vm';
import TempShuls from './TempShuls.jsx';
import SuggestMinyanim from './SuggestMinyanim.vm';
import Minyan from "../admin/Minyan.vm";
import Unsubscribe from './Unsubscribe';

export const MainPages = {
  home: {
    anon: true,
    path: '/',
    pathExact: true,
    type: 'PAGE_HOME',
    title: "Home",
    view: Home,
  },
  about: {
    anon: true,
    path: '/about',
    pathExact: true,
    type: 'ABOUT',
    title: "About Us",
    view: About,
  },
  contact: {
    anon: true,
    path: '/contact',
    pathExact: true,
    type: 'CONTACT',
    title: "Contact Us",
    view: ContactForm,
  },
  notFound: {
    anon: true,
    path: '/404',
    type: 'PAGE_NOT_FOUND',
    view: NotFound,
  },
  search: {
    anon: true,
    path: '/search',
    pathExact: true,
    type: 'SEARCH',
    view: Search,
    title: "Search",
    scroll: false,
    noSearchBar: true,
  },
  shulDetails: {
    anon: true,
    path: '/shul-details/:shulId/:shulName?',
    pathExact: true,
    type: 'SHUL_DETAILS',
    view: ShulDetails,
    title: "Shul Details",
    renderTitle: false,
  },
  clientAddShul: {
    anon: true,
    path: '/add-shul',
    pathExact: true,
    type: 'CLIENT_SHUL_ADD',
    view: ClientAddShul,
    title: "Add Shul"
  },
  tempShuls: {
    anon: true,
    path: '/temporary-minyanim',
    pathExact: true,
    type: 'TEMP_SHULS',
    view: TempShuls,
    title: "Temporary Minyanim"
  },
  suggestShulUpdate: {
    anon: true,
    path: '/suggest-shul-update/:shulId',
    pathExact: true,
    type: 'SUGGEST_SHUL_UPDATE',
    view: SuggestMinyanim,
    title: "Suggest Shul Update"
  },
  addNewMinyanSuggestion: {
    anon: true,
    path: '/suggest-shul-update/:shulId/add-minyan',
    pathExact: true,
    type: 'SUGGEST_SHUL_UPDATE_ADD_MINYAN',
    view: Minyan,
    title: "Add New Minyan Suggestion",
    layout: null
  },
  minyanUpdateSuggestion: {
    anon: true,
    path: '/suggest-shul-update/:shulId/minyan/:minyanId/edit',
    pathExact: true,
    type: 'SUGGEST_SHUL_UPDATE_EDIT_MINYAN',
    view: Minyan,
    title: "Update Minyan Suggestion",
    layout: null
  },
  unsubscribe: {
    anon: true,
    path: '/unsubscribe',
    pathExact: true,
    type: 'UNSUBSCRIBE',
    view: Unsubscribe,
    title: "Unsubscribe Email"
  },
  termsOfService: {
    anon: true,
    path: '/terms-of-service',
    pathExact: true,
    type: 'TERMS_OF_SERVICE',
    view: TOS,
    title: "Terms Of Service"
  },
};
export default MainPages;

export const MainArea = {
  pages: MainPages,
};