import React from "react";
import { offsetCalculationTypes } from "./constants";
export function convertTime12hour(time) {
  const suffix = time.slice(0, 2) >= 12 ? "PM" : "AM";
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  return hours + minutes + " " + suffix;
}

export function getOffestText(offsetMinutes) {
  let offsetText;
  switch (true) {
    case offsetMinutes === 0:
      offsetText = `at `;
      break;
    case offsetMinutes > 0:
      offsetText = `${offsetMinutes} minutes after `;
      break;
    default:
      offsetText = `${-offsetMinutes} minutes before `;
  }
  return offsetText;
}

export function renderCalculatedBy(calculatedBy) {
  return (
    <p className="xs-text accent-text">{`calculated by ${offsetCalculationTypes[calculatedBy]
      }`}</p>
  );
}

export function renderRoundedBy(roundedBy) {
  const roundedByOptions = {
    on_time: "at the exact time",
    earlier_5: "rounded to earlier 5 minutes",
    later_5: "rounded to later 5 minutes"
  };

  return <p className="xs-text accent-text">{roundedByOptions[roundedBy]}</p>;
}

export function renderNotEarlierAndLater(beforeOrAftertext, time) {
  return (
    <p className="xs-text accent-text">{`not ${beforeOrAftertext} than ${convertTime12hour(
      time
    )}`}</p>
  );
}