// # Global States
import { UIState } from './ui/state';
import { ShulState } from './shul/state';
import { MinyanState } from './minyan/state';
import { ContactState } from './contact/state';
import { HomeState } from './home/state';
import { SponsorState } from './sponsor/state';
import { AccountState } from './account/state';
import { EventState } from './event/state';

// ## Export Actions
export * from './ui/actions';
export * from './shul/actions';
export * from './minyan/actions';
export * from './account/actions';
export * from './contact/actions';
export * from './home/actions';
export * from './sponsor/actions';
export * from './event/actions';

// ## Export States
export default [
  UIState,
  ShulState,
  MinyanState,
  ContactState,
  HomeState,
  SponsorState,
  AccountState,
  EventState,
];
