import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AccountActions } from '../state';
import { UIAction } from '../state';
import { Layout } from './Layout';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    ui: {
      sidebarOpen
    },
    account: {
      roles,
      shulIds
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    sidebarOpen,
    roles,
    shulIds
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(UIAction, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch),
  };
})(Layout);
