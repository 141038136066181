export const UIAction = {
  SELECTED_TAB: 'SELECTED_TAB',
  TOGGLE_THING: 'TOGGLE_THING',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  RECORD_DELETE_MODAL: 'RECORD_DELETE_MODAL',

  selectedTab(index) {
    return { type: UIAction.SELECTED_TAB, payload: index };
  },

  toggleThing() {
    return { type: UIAction.TOGGLE_THING };
  },

  toggleSidebar() {
    return { type: UIAction.TOGGLE_SIDEBAR };
  },

  closeSidebar() {
    return { type: UIAction.CLOSE_SIDEBAR };
  },

  toggleDeleteModal(confirmDelete, record, recordType) {
    return (dispatch, getState) => {
      dispatch({
        type: UIAction.RECORD_DELETE_MODAL,
        confirmDelete,
        payload: record,
        recordType
      });
    };
  },

  // The following example doesn't belong in the UI state, it would go under a
  // different state area altogether...
  /*
  actionThatCallsAnAsyncFnExample() {

    // Instead of returning a plain ACTION object, it returns a function.
    // That function gets called by the `redux-thunk` middleware, which handles
    // all the async stuff...

    return async (dispatch, getState) => {
      // This action would be handled in state.js to toggle some boolean, to indicate LOADING...
      dispatch({ type: LOADING_STUFF });

      const results = await callMyAPI();

      // This action would be handled in state.js to toggle LOADING and apply results payload....
      dispatch({ type: LOADED_STUFF, payload: results });
    };
  },
  */
};
