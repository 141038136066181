import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShulActions } from '../../state';
import { UIAction } from '../../state';
import { AccountActions, EventActions } from '../../state';
import ViewShul from './ViewShul';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    shul: {
      loading,
      shulInfo,
      myShuls
    },
    minyan: {
      shacharisMinyanims,
      minchaMinyanims,
      maarivMinyanims,
      shacharisSortedIds,
      minchaSortedIds,
      maarivSortedIds,
    },
    contact: {
      contacts
    },
    account: {
      roles,
      shulIds,
      authorized
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    loading,
    shulInfo,
    myShuls,
    shacharisMinyanims,
    minchaMinyanims,
    maarivMinyanims,
    shacharisSortedIds,
    minchaSortedIds,
    maarivSortedIds,
    contacts,
    roles,
    shulIds,
    authorized
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(ShulActions, dispatch),
    eventActions: bindActionCreators(EventActions, dispatch),
    uiActions: bindActionCreators(UIAction, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };

})(ViewShul);
