import { UIAction } from './actions';

export const UIState = {
  name: 'ui',
  persist: true,
  defaults: {
    isThingVisible: false,
    mainTabIndex: 0,
    sidebarOpen: false,
    confirmDelete: false,
  },
  handlers: {
    [UIAction.SELECTED_TAB]: function selectedTab(state, action) {
      return {
        ...state,
        mainTabIndex: action.payload,
      };
    },
    [UIAction.TOGGLE_THING]: function toggleThing(state, action) {
      return {
        ...state,
        isThingVisible: !state.isThingVisible
      };
    },
    [UIAction.TOGGLE_SIDEBAR]: function toggleThing(state, action) {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    },
    [UIAction.CLOSE_SIDEBAR]: (state, action) => {
      return {
        ...state,
        sidebarOpen: false
      };
    },
    [UIAction.RECORD_DELETE_MODAL]: (state, action) => {
      return {
        ...state,
        confirmDelete: action.confirmDelete,
        record: action.payload,
        recordType: action.recordType
      };
    },
  },
};
