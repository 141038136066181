import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MinyanActions } from '../../state';
import { UIAction } from '../../state';
import { AccountActions } from '../../state';
import MinyanSuggestions from './MinyanSuggestions';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
 const{
  account: {
    roles,
    shulIds,
    authorized
  }
 } = state;
  // The returned object becomes properties of the connected Component...
  return {
    roles,
    shulIds,
    authorized
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(MinyanActions, dispatch),
    uiActions: bindActionCreators(UIAction, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
})(MinyanSuggestions);
