import { ShulActions } from '../shul/actions';
import { ContactActions } from "./actions";

export const ContactState = {
  name: 'contact',
  persist: false,
  defaults: {
    contacts: {},
    groupedContacts: {},
    loading: false,
    contactFailed: false,
    activeContact: {}
  },

  handlers: {
    [ShulActions.SHUL_GET]: (state, action) => {
      const { contacts } = action.results;
      return {
        ...state,
        loading: false,
        contacts: contacts
      };
    },

    [ShulActions.SHUL_DETAILS]: (state, action) => {
      const { groupedContacts } = action.results;
      return {
        ...state,
        loading: false,
        groupedContacts
      };
    },

    [ShulActions.SHUL_ADD]: (state, action) => {
      return {
        ...state,
        contacts: {}
      };
    },

    [ShulActions.SHUL_CLEAR_FROM_REDUX]: (state, action) => {
      return {
        ...state,
        contacts: {}
      };
    },

    [ContactActions.CONTACT_ADD]: (state, action) => {
      if (action.loading) {
        return {
          ...state,
          loading: true,
        };
      }
      return {
        ...state,
        contactFailed: false,
        loading: false,
        contacts: {
          ...state.contacts,
          [action.payload.id]: action.payload
        }
      };
    },

    [ContactActions.CONTACT_EDIT]: (state, action) => {
      if (action.loading) {
        return {
          ...state,
          loading: true,
        };
      }
      return {
        ...state,
        loading: false,
        contacts: {
          ...state.contacts,
          [action.payload.id]: action.payload
        }
      };
    },

    [ContactActions.CONTACT_GET]: (state, action) => {
      if (action.loading) {
        return {
          ...state,
          loading: true,
        };
      }
      return {
        ...state,
        loading: false,
        activeContact: action.payload
      };
    },
    [ContactActions.CONTACT_DELETE]: (state, action) => {
      if (action.loading) {
        return {
          ...state,
          loading: true,
        };
      }
      const {
        ['' + action.payload]: deletedContact,
        ...restOfContacts
      } = state.contacts;
      return {
        ...state,
        loading: false,
        contacts: {
          ...restOfContacts
        },
      };
    },

    [ContactActions.CONTACT_FAILED]: (state, action) => {
      return {
        ...state,
        contactFailed: true
      };
    },

    [ContactActions.CONTACT_FAILURE_RESET]: (state, action) => {
      return {
        ...state,
        contactFailed: false
      };
    },

  }
};
