import React from 'react';
import "../assets/css/components/filterSidebar.css";
import { Dialog, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
const DEFAULT_DISTANCE = 5;
const trackStyles = { backgroundColor: '#11B6D7', height: 3 };
const railStyles = { backgroundColor: '#C6C6C6', height: 3 };
const handleStyles = {
  color: '#11B6D7',
  marginTop: -9,
  backgroundColor: '#11B6D7',
  border: 'solid 10px #11B6D7'
};
const CustomTimePicker = withStyles({
  root: {
    width: '125px'
  },
})(TextField);
const CustomNumberPicker = withStyles({
  root: {
    width: '45px'
  },
})(TextField);
const CustomDialog = withStyles({
  container: {
    width: '100%',
    margin: '10px'
  },
})(Dialog);

export class FilterSidebar extends React.PureComponent {

  state = {
    mobileFilterSidebarOpen: false,
    tefillahArray: [],
    tefillahFilter: false,
    nusachArray: [],
    nusachFilter: false,
    dayArray: [],
    dayFilter: false,
    timeFilter: false,
    nextMinyanDay: '',
    distance: 0,
    distanceFilter: false,
    startTime: '',
    endTime: '',
    mode: null,
    todaysDayOfWeek: '',
    tomorrowsDayOfWeek: '',
    modeFilter: false,
    showDialog: false
  }

  componentDidMount() {
    const { activeFilters, mobileTimeFilterOpen, mobileDistanceFilterOpen } = this.props;
    this.setState({ ...activeFilters, timeFilter: mobileTimeFilterOpen, distanceFilter: mobileDistanceFilterOpen });

    if (mobileTimeFilterOpen) {
      window.scrollTo(0, 200);
    }

    if (mobileDistanceFilterOpen) {
      window.scrollTo(0, 240);
    }
  }

  handleChange = (name, value) => {
    const { dayArray } = this.state;
    if (name === 'distance') {
      value = +value;
    }

    if (dayArray.length && (name === 'startTime' || name === 'endTime' || name === 'nextMinyanDay')) {
      this.setState({ showDialog: 'time' });
      return;
    }

    this.setState(() => ({
      [name]: value,
    }));
  }

  handleFilterArrayChange = (name, value) => {
    const { startTime, endTime } = this.state;
    if (name === 'dayArray' && value && startTime && endTime) {
      this.setState({ showDialog: 'day' });
      return;
    }

    this.setState(prevState => ({
      [name]: value === '' ? []
        : prevState[name].includes(value) ? [...prevState[name].filter(v => v !== value)]
          : [...prevState[name], value]
    }));
  }

  toggleSidebar = () => {
    this.props.toggleMobileFilterSidebar(false);
  }

  toggleFilter = (filterName) => {
    this.setState(prevState => ({
      [filterName]: !prevState[filterName]
    }));
  }

  onSliderChange = (value, name) => {
    this.setState({ [name]: value });
  }

  applyFilters = () => {
    let { tefillahArray, nextMinyanDay, startTime, endTime, distance, nusachArray, dayArray, mode } = this.state;
    if (distance < .5) distance = .5;

    this.props.applyMobileFilters({ tefillahArray, nextMinyanDay, startTime, endTime, distance, nusachArray, dayArray, mode });
  }

  clearTimeFilter = () => {
    this.setState(() => ({
      startTime: '',
      endTime: ''
    }), () => {
      this.props.applyMobileFilters({ startTime: '', endTime: '' }, true);
    });
  }

  clearFilters = () => {
    this.setState(prevState => ({
      tefillahArray: [],
      tefillahFilter: false,
      nusachArray: [],
      nusachFilter: false,
      dayArray: [],
      dayFilter: false,
      timeFilter: false,
      nextMinyanDay: '',
      distance: DEFAULT_DISTANCE,
      distanceFilter: false,
      startTime: '',
      endTime: '',
      modeFilter: false,
      mode: prevState.mode !== 'location' ? 'all' : 'location'
    }));
    this.props.clearFilters(true);
  }

  cancelDialog = filterToCancel => {
    if (filterToCancel === 'time') {
      this.setState({
        timeFilter: false,
        startTime: '',
        endTime: '',
        showDialog: false
      });
    } else {
      this.setState({
        dayFilter: false,
        dayArray: [],
        showDialog: false
      });
    }
  }

  okDialog = filterToApply => {
    if (filterToApply === 'time') {
      this.setState(prevState => ({
        showDialog: false,
        dayFilter: false,
        dayArray: [],
      }), this.applyTimeFilter);
    } else {
      this.setState(prevState => ({
        showDialog: false,
        timeFilter: false,
        startTime: '',
        endTime: '',
      }), this.applyMoreFilter);
    }
  }

  render() {
    const {
      state: {
        tefillahArray,
        tefillahFilter,
        nusachArray,
        nusachFilter,
        dayArray,
        dayFilter,
        timeFilter,
        distance,
        distanceFilter,
        nextMinyanDay,
        startTime,
        endTime,
        mode,
        todaysDayOfWeek,
        tomorrowsDayOfWeek,
        modeFilter,
        showDialog
      }
    } = this;


    const nusachs = { '': 'All', ashkenaz: 'Ashkenaz', sefard: 'Sefard', 'edut_mizrach': 'Edut Mizrach', ari: 'Ari', temani: 'Temani' };
    const tefillahs = { '': 'All', shachris: 'Shacharis', mincha: 'Mincha', mariv: 'Maariv' };
    const days = { weekdays: 'Weekdays', shabbos: 'Shabbos', rosh_chodesh: 'Rosh Chodesh' };
    const nextMinyanDays = { [todaysDayOfWeek]: 'Today', [tomorrowsDayOfWeek]: 'Tomorrow' };
    const searchByNameModes = { all: 'All', shul: 'Shul', rabbi: 'Rabbi' };

    return (
      <div className="filter-sidebar-container">
        {showDialog &&
          <CustomDialog open={true}>
            {renderDialog(showDialog, () => this.cancelDialog(showDialog), () => this.okDialog(showDialog))}
          </CustomDialog>}
        <div className="filter-sidebar-top">
          <i className="material-icons" onClick={this.toggleSidebar}>
            close
        </i>
          <div className="mobile-reset-filters" onClick={this.clearFilters}>
            <i className="material-icons small-text mr-4">
              refresh
        </i>
            Reset All
        </div>
        </div>

        <div className="mobile-filter-line"></div>

        <div className="sidebar-filter-section">
          <div className="sidebar-filter-section-header" onClick={() => this.toggleFilter('tefillahFilter')}>
            <div>Tefillah</div>
            {tefillahFilter ? (<i className="material-icons bold-text">expand_less</i>)
              : (<i className="material-icons bold-text">expand_more</i>)}
          </div>

          {tefillahFilter ?
            (<div className="sidebar-filter-content">
              <ul className="mb-8">
                {Object.keys(tefillahs).map(t => {
                  return (
                    <li key={t} className="sidebar-filter-li">
                      <div className="flex-justify-space-center">
                        <div className="flex-align-center">{tefillahs[t]}</div>
                        <div className="flex-align-center" onClick={() => this.handleFilterArrayChange('tefillahArray', t)}>
                          {((t === '' && !tefillahArray.length) || tefillahArray.includes(t)) ?
                            (<i className="material-icons checkbox-checked flex-align-center"
                            >
                              check_box
                          </i>)
                            : (<i className="material-icons checkbox-unchecked flex-align-center"
                            >
                              check_box_outline_blank
                          </i>)}
                        </div>

                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>)
            : (
              <div className="active-filters">
                {tefillahArray.length > 0 ? tefillahArray.join(', ').replace('shachris', 'shacharis').replace('ma', 'maa') : 'All'}
              </div>
            )}
        </div>

        <div className="mobile-filter-line"></div>


        <div className="sidebar-filter-section">
          <div className="sidebar-filter-section-header" onClick={() => this.toggleFilter('nusachFilter')}>
            <div>Nusach</div>
            {nusachFilter ? (<i className="material-icons bold-text">expand_less</i>)
              : (<i className="material-icons bold-text">expand_more</i>)}
          </div>

          {nusachFilter ?
            (<div className="sidebar-filter-content">
              <ul className="mb-8">
                {Object.keys(nusachs).map(n => {
                  return (
                    <li key={n} className="sidebar-filter-li">
                      <div className="flex-justify-space-center">
                        <div className="flex-align-center">{nusachs[n]}</div>
                        <div className="flex-align-center" onClick={() => this.handleFilterArrayChange('nusachArray', n)}>
                          {((n === '' && !nusachArray.length) || nusachArray.includes(n)) ?
                            (<i className="material-icons checkbox-checked flex-align-center"
                            >
                              check_box
                          </i>)
                            : (<i className="material-icons checkbox-unchecked flex-align-center"
                            >
                              check_box_outline_blank
                          </i>)}
                        </div>

                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>)
            : (
              <div className="active-filters">
                {nusachArray.length > 0 ? nusachArray.join(', ').replace(/_/, ' ') : 'All'}
              </div>
            )}
        </div>

        <div className="mobile-filter-line"></div>


        {mode === 'location' &&
          <div>
            <div className="sidebar-filter-section">
              <div className="sidebar-filter-section-header" onClick={() => this.toggleFilter('timeFilter')}>
                <div>Time</div>
                {timeFilter ? (<i className="material-icons bold-text">expand_less</i>)
                  : (<i className="material-icons bold-text">expand_more</i>)}
              </div>
              {timeFilter ?
                (<div className="sidebar-filter-content sidebar-time-content">

                  {/* <p className="">Choose a time frame</p> */}

                  <ul className="mb-8">
                    {Object.keys(nextMinyanDays).map(d => {
                      return (
                        <li key={d} className="sidebar-filter-li flex-align-center">
                          <span onClick={() => this.handleChange('nextMinyanDay', d)}>
                            {d === nextMinyanDay ?
                              (<i className="material-icons radio-checked"
                              >
                                radio_button_checked
                      </i>)
                              : (<i className="material-icons radio-unchecked"
                              >
                                radio_button_unchecked
                      </i>)}
                          </span>
                          <span>{nextMinyanDays[d]}</span>
                        </li>
                      );
                    })}
                  </ul>

                  <div className="flex-align-center mt-8 mb-16">
                    <div className="mr-16">
                      <CustomTimePicker
                        onChange={(e) => this.handleChange('startTime', e.target.value)}
                        name="startTime"
                        id="time"
                        label="Start Time"
                        type="time"
                        value={startTime}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      >
                      </CustomTimePicker>
                    </div>
                    <CustomTimePicker
                      onChange={(e) => this.handleChange('endTime', e.target.value)}
                      name="endTime"
                      id="time"
                      label="End Time"
                      type="time"
                      value={endTime}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    >
                    </CustomTimePicker>
                  </div>
                  <div className="mobile-clear-time" onClick={this.clearTimeFilter}>clear</div>
                </div>)
                : (
                  <div className="active-filters">
                    {startTime && endTime ? `${formatTime(startTime)} - ${formatTime(endTime)}` : 'All Times'}
                  </div>
                )}
            </div>
            <div className="mobile-filter-line"></div>
          </div>}

        {mode === 'location' &&
          <div>
            <div className="sidebar-filter-section">
              <div className="sidebar-filter-section-header" onClick={() => this.toggleFilter('distanceFilter')}>
                <div>Distance</div>
                {distanceFilter ? (<i className="material-icons bold-text">expand_less</i>)
                  : (<i className="material-icons bold-text">expand_more</i>)}
              </div>
              {distanceFilter ?
                (<div className="sidebar-filter-content">
                  <div className="flex-align-middle">
                    <div className="mr-4 ml-4">
                      <CustomNumberPicker
                        onChange={(e) => this.handleChange('distance', e.target.value)}
                        name="distance"
                        id="distance"
                        type="number"
                        value={distance}
                        inputProps={{
                          step: 1,
                          min: 1
                        }}
                      >
                      </CustomNumberPicker>
                    </div>
                    miles
              </div>
                  <div className="flex-align-center sidebar-distance-slider">
                    <Slider
                      min={1}
                      max={distance < 51 ? 50 : distance}
                      value={distance}
                      name="distance"
                      onChange={(value) => this.onSliderChange(value, 'distance')}
                      handleStyle={handleStyles}
                      trackStyle={trackStyles}
                      railStyle={railStyles}
                    />
                  </div>
                </div>) :
                (
                  <div className="active-filters">
                    {`${distance} Miles`}
                  </div>
                )}
            </div>
            <div className="mobile-filter-line"></div>
          </div>}


        <div className="sidebar-filter-section">
          <div className="sidebar-filter-section-header" onClick={() => this.toggleFilter('dayFilter')}>
            <div>Shul has minyanim on</div>
            {dayFilter ? (<i className="material-icons bold-text">expand_less</i>)
              : (<i className="material-icons bold-text">expand_more</i>)}
          </div>

          {dayFilter ?
            (<div className="sidebar-filter-content">
              <ul className="mb-8">
                {Object.keys(days).map(d => {
                  return (
                    <li key={d} className="sidebar-filter-li">
                      <div className="flex-justify-space-center">
                        <div className="flex-align-center">{days[d]}</div>
                        <div className="flex-align-center" onClick={() => this.handleFilterArrayChange('dayArray', d)}>
                          {((d === '' && !dayArray.length) || dayArray.includes(d)) ?
                            (<i className="material-icons checkbox-checked flex-align-center"
                            >
                              check_box
                          </i>)
                            : (<i className="material-icons checkbox-unchecked flex-align-center"
                            >
                              check_box_outline_blank
                          </i>)}
                        </div>

                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>)
            : (
              <div className="active-filters">
                {dayArray.length > 0 ? dayArray.join(', ').replace(/_/, ' ') : 'Any'}
              </div>
            )}
        </div>

        <div className="mobile-filter-line"></div>


        {mode !== 'location' &&
          <div>
            <div className="sidebar-filter-section">
              <div className="sidebar-filter-section-header" onClick={() => this.toggleFilter('modeFilter')}>
                <div>Search term is contained in</div>
                {modeFilter ? (<i className="material-icons bold-text">expand_less</i>)
                  : (<i className="material-icons bold-text">expand_more</i>)}
              </div>

              {modeFilter ?
                (<div className="sidebar-filter-content">
                  <ul className="mb-8">
                    {Object.keys(searchByNameModes).map(m => {
                      return (
                        <li key={m} className="sidebar-filter-li flex-align-center">
                          <span onClick={() => this.handleChange('mode', m)}>
                            {mode === m ?
                              (<i className="material-icons radio-checked"
                              >
                                radio_button_checked
                      </i>)
                              : (<i className="material-icons radio-unchecked"
                              >
                                radio_button_unchecked
                      </i>)}
                          </span>
                          <span>{searchByNameModes[m]}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>)
                : (
                  <div className="active-filters">
                    {mode}
                  </div>
                )}
            </div>

            <div className="mobile-filter-line"></div>
          </div>}

        <div className="mobile-fiter-show-results" onClick={this.applyFilters}>Apply Filters</div>

        <div className="mobile-filter-line"></div>

      </div>

    );
  }
}

function formatTime(time) {
  const suffix = time.slice(0, 2) >= 12 ? "PM" : "AM";
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  return `${hours}${minutes} ${suffix}`;
}

function renderDialog(CurrentFilterName, cancelFunc, okFunc) {
  const incompatiblefilter = CurrentFilterName === 'time' ? 'Shul has minyanim on' : 'Time';
  return (
    <div className="dialog-container">
      <i className="material-icons dialog-warning-icon">error_outline</i>
      <div className="dialog-title">Selected Filters Are Incompatible</div>
      <div className="bold-text dialog-text">{`${incompatiblefilter} Filter `}</div>
      <div className="dialog-text">is incompatible with your current selection.</div>
      <div className="dialog-text">Would you like to clear it and continue?</div>
      <div className="dialog-button-div">
        <div className="dialog-cancel-button" onClick={cancelFunc}>cancel</div>
        <div className="dialog-ok-button" onClick={okFunc}>yes, clear</div>
      </div>
    </div>
  );
}
