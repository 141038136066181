import React from 'react';
import "../assets/css/components/locationWarningDialog.css";
import { Dialog } from '@material-ui/core';

export class LocationWarningDialog extends React.PureComponent {

  render() {
    const { existingShulNames, cancelDialog, resubmitRequest } = this.props;
    return (
      <Dialog open={true}>
        <div className="location-warning-dialog">
          <i className="material-icons location-warning-icon">error_outline</i>
          <div className="location-warning-title">
            <h4>Found other Minyanim (Shuls) at this location.</h4>
            <ul className="location-warning-shul-names">
              {existingShulNames.map(shul => {
                return (
                  <li key={shul.id}>{shul.name}</li>);
              })}
            </ul>
            <h4>Are you sure this is not a duplicate?</h4>
          </div>
          <div className="flex-align-middle">
            <div className="delete-warning-cancel" onClick={cancelDialog}>CANCEL</div>
            <div className="delete-warning-delete" onClick={resubmitRequest}>CONTINUE</div>
          </div>
        </div>
      </Dialog>
    );
  }
}