import artscroll from "../assets/images/artscroll.gif";
import kedem from "../assets/images/kedem.png";
import orlo from "../assets/images/orlo.jpg";
import jack from "../assets/images/jack.png";
import easterUnion from "../assets/images/eastern-union.png";
//import mealMart from '../assets/images/meal-mart.png';
import crossRiver from "../assets/images/cross-river.png";
import rcp from "../assets/images/rcp.jpg";
import iraGroup from "../assets/images/iraGroup.jpeg";
import insuranceSimplified from "../assets/images/insuranceSimplifiedLogo.png";

const corporateImages = [
  { image: easterUnion },
  { image: artscroll },
  { image: kedem },
  { image: orlo },
  { image: jack },
  { image: iraGroup },
  { image: crossRiver },
  { image: rcp },
  { image: insuranceSimplified, link: "mailto:info@718insurance.com" }
];

export { corporateImages };
