
/** Converts an array to an object indexed by id.
 * @param {{id:any}[]} arr
 * @param {object[]} [sortIdArr] Array to save sorted ids.
 */
export function arrayToObjectById(arr, sortIdArr) {
  const obj = {};
  const len = arr.length;
  if (sortIdArr) {
    for (let i = 0; i < len; i++) {
      const item = arr[i];
      const id = item.id;
      obj[id] = item;
      sortIdArr.push(id);
    }
  } else {
    for (let i = 0; i < len; i++) {
      const item = arr[i];
      const id = item.id;
      obj[id] = item;
    }
  }
  return obj;
}

/** Converts an array to an object indexed by `propName`.
 * @param {object[]} arr
 * @param {string} propName
 * @param {object[]} [sortArr] Array to save sorting into.
 */
export function arrayToObject(arr, propName, sortArr) {
  const obj = {};
  const len = arr.length;
  if (sortArr) {
    for (let i = 0; i < len; i++) {
      const item = arr[i];
      const value = item[propName];
      obj[value] = item;
      sortArr.push(value);
    }
  } else {
    for (let i = 0; i < len; i++) {
      const item = arr[i];
      obj[item[propName]] = item;
    }
  }
  return obj;
}
