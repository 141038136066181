import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShulActions } from '../../state';
import { AccountActions } from '../../state';
import AddShul from './AddShul';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    shul: {
      shulApiResponse,
      shulInfo,
      loading,
    },
    minyan: {
      shacharisMinyanims,
      minchaMinyanims,
      maarivMinyanims,
    },
    contact: {
      contacts,
    },
    account: {
      authorized
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    shulApiResponse,
    shulInfo,
    loading,
    shacharisMinyanims,
    minchaMinyanims,
    maarivMinyanims,
    contacts,
    authorized
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(ShulActions, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch)
  };
})(AddShul);
