import React from "react";
import Modal from "react-modal";
import "../assets/css/pages/modal.css";
import mask from "../assets/images/Medical_Mask.png";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");
Modal.defaultStyles.overlay.backgroundColor = "rgba(0, 0, 0, 0.65)";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

export class CovidPolicy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { modalIsOpen: true, modalSelectedOption: 0 };
  }
  componentDidMount() {
    this.props.ShulActions.getCovidPolicies();
  }
  onChange = e => {
    this.setState({modalSelectedOption: e.target.value});
  }
  savePolicy = e => {
    this.setState({modalIsOpen: false});
    this.props.ShulActions.updateCovidPolicy({
      shulId: this.props.shulInfo.id,
      policy_id: parseInt(this.state.modalSelectedOption, 10)
    });
  }
  render() {
    const {
      covidPolicies,
      shulCovidPolicy,
      showCovidModal,
      AccountActions: { blockCovidModal }
    } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <div>
        {!!shulCovidPolicy.covid_policy ? (
          <div
            style={{
              minHeight: 20,
              width: "100%",
              borderRadius: 3,
              backgroundColor: "#FA5858",
              color: "#FFFFFF",
              fontSize: 14,
              marginTop: 14,
              marginBottom: 8,
              display: "flex",
              MsFlexAlign: "center",
              alignItems: "center",
              MsFlexPack: "justify",
              justifyContent: "space-between",
              padding: 15
            }}
            className="covid-policy"
          >
            <div className="mr-16">
              <span
                style={{
                  marginRight: 10,
                  fontWeight: 800,
                  fontSize: 16
                }}
              >
                Covid Policy:
              </span>
              {shulCovidPolicy.covid_policy.display_text}
            </div>
          </div>
        ) : !!shulCovidPolicy.loading ? null : showCovidModal !== false && (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={e => this.setState({ modalIsOpen: false })}
            style={customStyles}
          >
            <div className="overlay">
              <div
                style={{
                  textAlign: "center",
                  margin: 25
                }}
              >
                <img style={{ width: 200 }} src={mask} alt="mask" />
              </div>
              <div className="message-container">
                <h1>Can you update the shul's Covid Policy?</h1>
              </div>
              <div className="mobile-block">
                <select
                  style={{
                    margin: 10,
                    marginBottom: 30
                  }}
                  className="covid custom-input mobile-mt-10"
                  value={this.state.modalSelectedOption}
                  name="covid_policy"
                  onChange={this.onChange}
                >
                  <option key={0} value={0}>
                    Please Select Covid Policy
                  </option>
                  {covidPolicies.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.display_text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="buttons-container flex-justify-space covid">
                <button
                  onClick={e =>
                    blockCovidModal() && this.setState({ modalIsOpen: false })
                  }
                  className="button delete"
                >
                  Stop Showing
                </button>
                <button
                  onClick={e => this.setState({ modalIsOpen: false })}
                  className="button cancel"
                >
                  Don't Know
                </button>
                <button
                  style={{ background: "#11B6D7", color: "#fff" }}
                  onClick={this.savePolicy}
                  className="button default"
                >
                  Save
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
