import { EventActions } from './actions';

export const EventState = {
  name: 'event',
  persist: false,
  defaults: {
    events: [],
    eventFailed: false,
    activeEvent: undefined,
    loading: false
  },
  handlers: {
    [EventActions.EVENT_GET_BY_ID]: (state, { payload }) => {
      return {
        ...state,
        activeEvent: payload
      };
    },
    [EventActions.LOAD_EVENTS]: (state, { data }) => {
      return {
        ...state,
        events: data
      };
    },
    [EventActions.UPDATE_SUBMISSION_STATUS]: (state, { eventFailed }) => {
      return {
        ...state,
        eventFailed
      };
    },
    [EventActions.UPDATE_LOADING]: (state, { loading }) => {
      return {
        ...state,
        loading
      };
    },
  },
};
