import React from 'react';
import { HelmetHeader } from "../../components/HelmetHeader";
import "../../assets/css/pages/about.css";

export default class About extends React.PureComponent {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {

    return (
      <React.Fragment>
        <HelmetHeader/>
        <div className="about-container">
          <div className="about-text-container">
            <div className="mb-32">
              <h2 className="uppercase-text about-paragraph-title">About Us</h2>
              <p>
                GoDaven is the Worldwide Orthodox Minyan Database. It was started in 2001 by Dr. Yosi Fishkin as a way to keep track of all the davening times at all the minyanim in the world. Over the years, with the feedback provided by thousands of minyan-goers across the globe, the depth and accuracy of the database has grown. Over 10,000 people a week now find a minyan by using the service.
              </p>
            </div>

            <div className="mt-40">
              <h2 className="uppercase-text about-paragraph-title">Growth</h2>
              <p>
                Klal Govoah, under the leadership of Ira Zlotowitz, has enabled the expansion and modernization of the GoDaven database and website, along with an upcoming app. Minyan information is updated on a daily basis. It is now quick and easy to search for a minyan by time, location, or other criteria. Shuls, schools, and office-based minyanim can easily log in to modify their own minyan times, and site visitors can submit minyan updates as well.
              </p>
            </div>

            <div className="mt-40">
              <h2 className="uppercase-text about-paragraph-title">Your Involvement</h2>
              <p>
                You can directly help GoDaven in its efforts to increase minyan attendance worldwide! If you come across any new minyan information, be sure to click the button to update the minyan's listing. There are many upcoming enhancements that will make it easier for the tzibbur to find minyanim. The current site development, as well as the implementation of these new enhancements, represent a significant financial undertaking. Help support our efforts by contributing at campaign.godaven.com.
              </p>
              <p className="mt-16">
                GoDaven makes every effort to ensure that the information is as accurate as humanly possible. However, please use the minyan times in this database as a general guide. As minyan times may change on short notice, always call first before attending a new minyan for the the first time.
              </p>
            </div>

            <div className="mt-40 bold-text">
              <p>
                L'Zecher Nishmas Reuven ben HaRav Yechiel Fishkin
              </p>

              <p>
                L'Zecher Nishmas Rav Meir Yaakov ben Harav Ahron Zlotowitz zt”l
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
