import React from "react";
import "./bottom.css";
import Facebook from "./Facebook.png";
import Linkedin from "./Linkedin.png";
import Twitter from "./Twitter.png";
import Whatsapp from "./Whatsapp.png";
import YSB from "./Ysb.jpg";
import YSB_mobile from "./Ysb-mobile.jpg";

export default class Bottom extends React.PureComponent {
  render() {
    const link = `${process.env.REACT_APP_BASE_URL}/chezky`;
    const socialTxt =
    encodeURI('Help Go Daven update their worldwide database!\nGive 3 minutes of your time for Chezky a"h.');
    return (
      <div className="c-tyb-container">
        <div className="tyb-header">Or help us spread the word!</div>

        <div className="tyb-social-links">
          <a
            href={`https://www.facebook.com/sharer.php?u=${link}`}
            target="_blank"
            className="tyb-button typ-facebook"
          >
            <div className="flex-align-middle">
              <img src={Facebook} alt="Facebook" className="tyb-icon" />
              <div className="tyb-social-txt">
                <div>Share via</div>
                <div>Facebook</div>
              </div>
            </div>
          </a>

          <a
            href={`https://twitter.com/intent/tweet?url=${link}&text=${socialTxt}`}
            target="_blank"
            className="tyb-button typ-twitter"
          >
            <div className="flex-align-middle">
              <img src={Twitter} alt="Twitter" className="tyb-icon" />
              <div className="tyb-social-txt">
                <div>Share via</div>
                <div>Twitter</div>
              </div>
            </div>
          </a>

          <a
            href={`https://wa.me/?text=${socialTxt} ${link}`}
            target="_blank"
            className="tyb-button typ-whatsapp"
          >
            <div className="flex-align-middle">
              <img src={Whatsapp} alt="Whatsapp" className="tyb-icon" />
              <div className="tyb-social-txt">
                <div>Share via</div>
                <div>Whatsapp</div>
              </div>
            </div>
          </a>

          <a
            href={`https://www.linkedin.com/shareArticle?url=${link}`}
            target="_blank"
            className="tyb-button typ-linkedin"
          >
            <div className="flex-align-middle">
              <img src={Linkedin} alt="Linkedin" className="tyb-icon" />
              <div className="tyb-social-txt">
                <div>Share via</div>
                <div>Linkedin</div>
              </div>
            </div>
          </a>
        </div>
        {/*  */}
        <div className="typ-memory-box">
          <div className="mb-header1">THIS CAMPAIGN IS IN MEMORY OF</div>
          <div className="mb-header2 mobile-hidden">
            YECHEZKEL SHRAGA ZT”L BEN AVROHOM YEHUDA BERGMAN 
          </div>
          <div className="mb-header2 desktop-hidden">YECHEZKEL SHRAGA ZT”L</div>
          <div className="mb-header2 desktop-hidden">
            BEN AVROHOM YEHUDA BERGMAN
          </div>
          <div className="desktop-hidden flex-justify-center typ-mobile-img">
            <img src={YSB_mobile} alt="YECHEZKEL SHRAGA" />
          </div>

          <div className="flex">
            <img src={YSB} alt="YECHEZKEL SHRAGA" className="mobile-hidden" />

            <div className="mb-txt">
              From his bar mitzvah onward, his careful avoidance of speaking
              while wearing his tefillin influenced many. Young and old
              marvelled at his character strength, of which this was but one
              significant example. While Chezky was taken from us far too early,
              his zechusim will continue to accrue due to the level of mitzvah
              observance he inspired in others.
              <div> .יהי זכרו ברוך</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
