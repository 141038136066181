import { AccountActions } from './actions';

export const AccountState = {
  name: 'account',
  persist: true,
  defaults: {
    userNotFound: false,
    roles: [],
    shulIds: [],
    userId: null,
    showCovidModal: true
  },
  handlers: {
    [AccountActions.LOGIN_FAILURE]: (state, action) => {
      return {
        ...state,
        userNotFound: true
      };
    },

    [AccountActions.LOGIN_SUCCESS]: (state, action) => {
      const { roles, shulIds, userId } = action;
      return {
        ...state,
        userNotFound: false,
        roles,
        shulIds,
        userId
      };
    },

    [AccountActions.LOGIN_FAILURE_RESET]: (state, action) => {
      return {
        ...state,
        userNotFound: false
      };
    },

    [AccountActions.LOGOUT]: (state, action) => {
      return {
        ...state,
        userNotFound: false,
        roles: [],
        shulIds: []
      };
    },

    [AccountActions.USER_CHECK_AUTH]: (state, action) => {
      return {
        ...state,
        authorized: action.authorized
      };
    },
    [AccountActions.BLOCK_COVID_MODAL]: (state, action) => {
      return {
        ...state,
        showCovidModal: false
      };
    },

  }
};
