import React from "react";

const Loader = () => (
  <svg
    width="350"
    height="100"
    viewBox="0 0 400 200"
  >
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="13" result="blur" />
        <feColorMatrix
          type="matrix"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 17 -4"
          result="cm"
        />
      </filter>
    </defs>
    <g filter="url(#goo)" style={{fill: "#FFFFFF"}}>
      <ellipse
        id="drop"
        cx="40"
        cy="90"
        rx="50"
        ry="50"
        opacity="1"
        fill="#AEEEFA"
      >
        <animate
          id="dropcx"
          href="#drop"
          attributeName="cx"
          begin="0s; dropcx2.end"
          from="40"
          to="200"
          dur="1s"
        />
        <animate
          id="dropcx3"
          href="#drop"
          attributeName="cx"
          begin="dropcx.end"
          from="200"
          to="359"
          dur="1s"
        />
        <animate
          id="dropcx4"
          href="#drop"
          attributeName="cx"
          begin="dropcx3.end"
          from="359"
          to="200"
          dur="1s"
        />
        <animate
          id="dropcx2"
          href="#drop"
          attributeName="cx"
          begin="dropcx4.end"
          from="200"
          to="40"
          dur="1s"
        />
        <animate
          id="dropopacity"
          attributeType="CSS"
          attributeName="opacity"
          begin="0s; dropopacity2.end"
          from="1"
          to="0"
          dur="1s"
        />
        <animate
          id="dropopacity2"
          attributeType="CSS"
          attributeName="opacity"
          begin="dropopacity.end"
          from="0"
          to="1"
          dur="1s"
        />
      </ellipse>
      <ellipse
        id="drop3"
        cx="359"
        cy="90"
        rx="50"
        ry="50"
        opacity="1"
        fill="#23D7F2"
      >
        <animate
          id="drop3cx"
          href="#drop3"
          attributeName="cx"
          begin="0s; drop3cx2.end"
          from="359"
          to="200"
          dur="1s"
        />
        <animate
          id="drop3cx3"
          href="#drop3"
          attributeName="cx"
          begin="drop3cx.end"
          from="200"
          to="40"
          dur="1s"
        />
        <animate
          id="drop3cx4"
          href="#drop3"
          attributeName="cx"
          begin="drop3cx3.end"
          from="40"
          to="200"
          dur="1s"
        />
        <animate
          id="drop3cx2"
          href="#drop3"
          attributeName="cx"
          begin="drop3cx4.end"
          from="200"
          to="359"
          dur="1s"
        />
        <animate
          id="drop3opacity"
          attributeType="CSS"
          attributeName="opacity"
          begin="0s; drop3opacity2.end"
          from="1"
          to="0"
          dur="1s"
        />
        <animate
          id="drop3opacity2"
          attributeType="CSS"
          attributeName="opacity"
          begin="drop3opacity.end"
          from="0"
          to="1"
          dur="1s"
        />
      </ellipse>
      <ellipse
        id="drop2"
        cx="200"
        cy="90"
        rx="50"
        ry="50"
        opacity="1"
        fill="#00A0BA"
        style={{zIndex: "9999"}}
      >
        <animate
          id="drop2opacity"
          attributeType="CSS"
          attributeName="opacity"
          begin="0s; drop2opacity2.end"
          from="1"
          to="0.5"
          dur="1s"
        />
        <animate
          id="drop2opacity2"
          attributeType="CSS"
          attributeName="opacity"
          begin="drop2opacity.end"
          from="0.5"
          to="1"
          dur="1s"
        />
        <animate
          id="drop2fill"
          attributeType="CSS"
          attributeName="fill"
          begin="0s; drop2fill2.end"
          from="#00A0BA"
          to="#006873"
          dur="1s"
        />
        <animate
          id="drop2fill2"
          attributeType="CSS"
          attributeName="fill"
          begin="drop2fill.end"
          from="#006873"
          to="#00A0BA"
          dur="1s"
        />
      </ellipse>
    </g>
  </svg>
);

export default Loader;
