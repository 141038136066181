import React from "react";
import { Link } from "react-router-dom";

import { PageURL } from "./PageURL";

export function PageLink(props) {
  const {
    children,
    innerRef,
    replace,
    story,
    to: page,
    params,
    query,
    hash,
    ...otherProps
  } = props;
  const linkTo = PageURL.link(page, params, query, hash);
  const linkProps = {
    innerRef,
    replace,
  };
  return (
    <Link to={linkTo} {...linkProps} {...otherProps}>
      {children}
    </Link>
  );
}
export default PageLink;
