import React, { Fragment } from "react";
import "./no-results.css";
import Banner from "./Banner";
import Bottom from "./Bottom";
import { Navigation } from "../../lib";

export default class NoResults extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {
    return (
      <Fragment>
        <Banner />
        <div className="nr-content">
          {/* desktop */}
          <div className="nr-header mobile-hidden">
            thank you! in your city there are no shuls with
          </div>
          <div className="nr-header mobile-hidden">
            missing contact information.
          </div>
          {/* mobile */}
          <div className="nr-header desktop-hidden">
            thank you! in your city there are
          </div>
          <div className="nr-header desktop-hidden">
            no shuls with missing contact information.
          </div>

          <div
            className="nr-search-button"
            onClick={() => Navigation.go("/chezky")}
          >
            search a new city
          </div>
        </div>

        <Bottom />
      </Fragment>
    );
  }
}
