import React from 'react';
import {
  init as Sentry_init,
  configureScope as Sentry_configureScope,
  withScope as Sentry_withScope,
  captureException as Sentry_captureException,
  showReportDialog as Sentry_showReportDialog,
} from '@sentry/browser';
import "../assets/css/grid.css";

const {
  NODE_ENV = 'development',
  REACT_APP_VERSION = '0.0.0',
} = process.env;

const __DEV__ = NODE_ENV === 'development';

/** See https://docs.sentry.io/learn/configuration */
Sentry_init({
  /** Do not send errors in development. */
  beforeSend: __DEV__ ? function () { return null; } : undefined,
  /** Where to send errors. This is NOT a secret. */
  dsn: "https://bddaadc2ceb44222ba832e9bb89590e3@sentry.io/1376124",
  environment: NODE_ENV,
  release: REACT_APP_VERSION,
});

export class ErrorSentry extends React.Component {

  static setUser(user) {
    Sentry_configureScope(scope => {
      scope.setUser({
        email: user.email,
        id: user.id,
        username: `${user.name_first} ${user.name_last}`,
      });
    });
  }

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry_withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry_captureException(error);
    });
  }

  refreshSite(){
    if(navigator.serviceWorker){
      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
        }
      });
    }
    window.location.reload(true);
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <div className="flex-columns-align-center">
          <div className="mt-32 mb-32">
            <a href="/" onClick={this.showReportDialog}>Report feedback</a>
          </div>
          <div style={{fontSize: 20}} className="mt-32 mb-32">
            <h1 className="mt-32 mb-32">Oops this is embarrassing. Not sure how that happened.</h1>
            <h3>Please report how you got here.</h3>
          </div>
          <div className="mt-32 mb-32">
            <button onClick={this.refreshSite} className="btn btn-accent mob-xs-text">Refresh</button>
          </div>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }

  showReportDialog = (e) => {
    e.preventDefault();
    Sentry_showReportDialog();
  }
}