import React, { useEffect, useState } from "react";
import "../assets/css/pages/modal.css";
import shulSpaceImage from "../assets/images/ShulSpaceImage.png";
import ShulspaceLogo from "../assets/images/ShulspaceLogo.svg";
import { Button } from "@material-ui/core";
import "../assets/css/components/shulspaceBanner.css";

export function ShulSpaceBanner() {
  const { isTablet, isDesktop } = useGetDeviceTypes();
  
  return (
    <div className="ssp-container-wrapper">
      <div className="ssp-container">
        <div className="ssp-image-container">
          <img className="ssp-image" alt="ShulSpace" src={shulSpaceImage} />
        </div>

        <div className="ssp-txt">
          <div className="ssp-txt-top-row">
            <div className="ssp-partners-with">GoDaven partners with</div>
            <div className="ssp-shulspace-logo-row">
              <img
                className="ssp-shulspace-logo"
                alt="ShulSpace"
                src={ShulspaceLogo}
              />
              <div className="ssp-shulspace-word">shulspace</div>
              <div>shul software</div>
            </div>
          </div>
          <div className="ssp-discover-txt">
            Discover the Shul platform loved by over 500 Gabbaim!
          </div>
          {isTablet && (
            <div
              style={{
                height: 10
              }}
            />
          )}
          {isTablet && <LinkButton />}
        </div>
        {isDesktop && <LinkButton />}
      </div>
    </div>
  );
}

function LinkButton() {
  return (
    <Button
      variant="contained"
      href="https://www.shulspace.com/?utm_source=godaven"
      target="_blank"
      style={{
        height: 36,
        borderRadius: 5,
        backgroundColor: "#34A8EA",
        color: "#FFFFFF",
        textTransform: "unset",
        width: 172,
        fontSize: 15,
        fontWeight: "bold",
        letterSpacing: 0
      }}
    >
      Learn more
    </Button>
  );
}

function getDeviceType() {
  const { innerWidth: width } = window;
  const isMobile = width < 930;
  const isTablet = width < 1201;
  const isDesktop = width > 1200;
  return {
    isMobile,
    isTablet,
    isDesktop
  };
}

function useGetDeviceTypes() {
  const [deviceTypes, setDeviceTypes] = useState(getDeviceType());

  useEffect(() => {
    function handleResize() {
      setDeviceTypes(getDeviceType());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return deviceTypes;
}
