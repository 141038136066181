import React from "react";
import { createToast, Navigation } from "../../lib";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/pages/minyan.css";
import { offsetCalculationTypes } from "../../lib/constants";

const initialState = {
  submitted: false,
  mode: 'add',
  special: 'excludes',
  timeByOffset: false,
  offsetMode: '',
  permanent: true,
  type: '',
  nusach: '',
  sun: false,
  mon: false,
  tues: false,
  wed: false,
  thurs: false,
  fri: false,
  sat: false,
  special_days_only: [],
  special_days_included: [],
  notes: '',
  time_at: '',
  zman_offset_type_id: '',
  offset_minutes: '',
  offset_calculated_by: '',
  offset_rounded_by: '',
  not_later_than: '',
  not_earlier_than: '',
  start_date: '',
  end_date: '',
  seasonalPreset: '',
  forMinyanSuggestion: false,
  minyanSuccess: false
};

export default class Minyan extends React.PureComponent {
  state = {
    ...initialState
  }

  async componentDidMount() {
    const {
      pageRoute: {
        key,
        params: {
          shulId,
          minyanId
        },
        query: {
          type: minyanType = '',
          shul_nusach
        }
      },
      actions: {
        clearActiveMinyan,
        getMinyanById
      },
      accountActions: { checkUsersAuth }
    } = this.props;

    clearActiveMinyan();

    if (key.includes('suggest')) {
      this.setState({ forMinyanSuggestion: true });
    } else {
      await checkUsersAuth();
      if (!this.props.authorized) {
        Navigation.redirect('/?displayLogin=true');
        return;
      }
    }

    // if there is a minyanId then it's in edit mode if not it's in add mode
    if (minyanId) {
      await getMinyanById(+minyanId);
      if (this.props.activeMinyan && Object.keys(this.props.activeMinyan).length) {
        const {
          id,
          minyan_location_id,
          type,
          nusach,
          sun,
          mon,
          tues,
          wed,
          thurs,
          fri,
          sat,
          special_days_only,
          special_days_included,
          time_at,
          zman_offset_type_id,
          offset_minutes,
          offset_calculated_by,
          offset_rounded_by,
          not_later_than,
          not_earlier_than,
          notes,
          start_date,
          end_date
        } = this.props.activeMinyan;
        const special = special_days_only.length ? 'only'
          : special_days_included.length ? 'includes' : 'excludes';
        const offsetMode = offset_minutes > 0 ? 'after'
          : offset_minutes < 0 ? 'before'
            : offset_minutes === 0 ? 'at' : '';
        const timeByOffset = time_at ? false : true;
        const permanent = start_date ? false : true;
        this.setState({
          mode: 'edit',
          special,
          offsetMode,
          timeByOffset,
          permanent,
          id,
          minyan_location_id,
          type,
          nusach: nusach ? nusach : '',
          sun: sun ? sun : false,
          mon: mon ? mon : false,
          tues: tues ? tues : false,
          wed: wed ? wed : false,
          thurs: thurs ? thurs : false,
          fri: fri ? fri : false,
          sat: sat ? sat : false,
          special_days_only,
          special_days_included,
          time_at: time_at ? time_at : '',
          zman_offset_type_id: zman_offset_type_id ? zman_offset_type_id : '',
          offset_minutes: typeof offset_minutes === 'number' ? offset_minutes : '',
          offset_calculated_by: offset_calculated_by ? offset_calculated_by : '',
          offset_rounded_by: offset_rounded_by ? offset_rounded_by : '',
          not_later_than: not_later_than ? not_later_than : '',
          not_earlier_than: not_earlier_than ? not_earlier_than : '',
          notes: notes ? notes : '',
          start_date: start_date ? start_date : '',
          end_date: end_date ? end_date : ''
        });
      } else {
        this.setState({ mode: 'edit' });
      }
    } else {
      this.setState({
        minyan_location_id: parseInt(shulId, 10),
        nusach: shul_nusach ? shul_nusach : '',
        type: minyanType
      });
    }
    window.scrollTo(0, 0);
  }

  handleInputChange = e => {
    const { name, value: targetValue, type, checked } = e.target;
    let value = type === 'checkbox' ? checked : targetValue;

    if ((targetValue === "true" || targetValue === "false" || !isNaN(targetValue)) && value !== '') {
      value = JSON.parse(value);
    }

    if (name === 'permanent' && value === true) {
      this.setState({ start_date: '', end_date: '', seasonalPreset: '' });
    }

    if (name === 'start_date' || name === 'end_date') {
      this.setState({ seasonalPreset: '' });

      if (!/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value)) {
        value = '';
      }
    }

    this.setState({ [name]: value });
  }

  updateSpecialState = e => {
    const special = e.target.value;
    if (special === 'only') {
      this.setState({ special, special_days_included: [] });
    } else if (special === 'includes') {
      this.setState({ special, special_days_only: [] });
    } else {
      this.setState({ special, special_days_only: [], special_days_included: [] });
    }
  }

  toggleSpecialDay = (e) => {
    const { special } = this.state;
    const { checked, name } = e.target;
    const field = special === 'only' ? 'special_days_only' : 'special_days_included';

    if (!checked) {
      this.setState(prevState => ({
        [field]: [...prevState[field].filter(d => d !== name)]
      }));
    } else {
      this.setState(prevState => ({
        [field]: [...prevState[field], name]
      }));
    }
  }

  updateOffsetMinutes = (e) => {
    const { value } = e.target;
    let minutes = value ? parseInt(value, 10) : '';
    if (minutes > 200) minutes = 200;
    const { offsetMode } = this.state;

    this.setState(prevState => ({
      offset_minutes: typeof minutes === 'string' ? ''
        : offsetMode === 'before' ? -minutes
          : offsetMode === 'after' || offsetMode === '' ? minutes
            : 0,
      offsetMode: minutes === 0 ? 'at' : prevState.offsetMode
    }));
  }

  updateTimeType = (e) => {
    this.setState({
      offsetMode: '',
      timeByOffset: e.target.value === 'time_at' ? false : true,
      zman_offset_type_id: '',
      offset_minutes: '',
      time_at: '',
      offset_calculated_by: '',
      offset_rounded_by: '',
      not_later_than: '',
      not_earlier_than: ''
    });
  }

  updateOffsetMode = (e) => {
    const { value } = e.target;

    this.setState(prevState => ({
      offsetMode: value,
      offset_minutes: typeof prevState.offset_minutes === 'string' ? ''
        : value === 'before' ? -prevState.offset_minutes
          : value === 'after' ? Math.abs(prevState.offset_minutes) : 0
    }));
  }

  handleDayPresets = (daysObj) => {
    this.setState(daysObj);
  }

  checkIfSelectedDaysEqualsPreset = (daysObj) => {
    const { sun, mon, tues, wed, thurs, fri, sat } = this.state;
    const selectedDays = { sun, mon, tues, wed, thurs, fri, sat };
    for (let day in daysObj) {
      if (daysObj[day] !== selectedDays[day]) return false;
    }
    return true;
  }

  prefillDates = (season) => {
    const date = new Date();
    const month = date.getMonth();
    const thisYear = date.getFullYear();
    date.setFullYear(thisYear + 1);
    const nextYear = date.getFullYear();
    date.setFullYear(thisYear - 1);
    const lastYear = date.getFullYear();

    const DSTstartYear = (season === 'winter' && month > 1) || (season === 'summer' && month > 8) ? nextYear
      : thisYear;
    const DSTendYear = season === 'winter' && month < 2 ? lastYear
      : season === 'summer' && month > 8 ? nextYear
        : thisYear;

    const DSTstartDate = new Date(DSTstartYear, 2, 7);
    DSTstartDate.setDate(7 + (7 - DSTstartDate.getDay()));
    const formattedDSTstartDate = DSTstartDate.toISOString().slice(0, 10);

    const DSTendDate = new Date(DSTendYear, 10, 1);
    const day = DSTendDate.getDay();
    const offset = day ? (7 - day) + 1 : 1;
    DSTendDate.setDate(offset);
    const formattedDSTendDate = DSTendDate.toISOString().slice(0, 10);

    this.setState({
      seasonalPreset: season,
      start_date: season === 'summer' ? formattedDSTstartDate : formattedDSTendDate,
      end_date: season === 'summer' ? formattedDSTendDate : formattedDSTstartDate
    });
  }

  handleSubmit = async (action) => {
    if (this.state.minyanSuccess) return;
    this.setState({ submitted: true });
    const {
      state: {
        mode,
        id,
        minyan_location_id,
        type,
        nusach,
        sun,
        mon,
        tues,
        wed,
        thurs,
        fri,
        sat,
        special_days_only,
        special_days_included,
        time_at,
        zman_offset_type_id,
        offset_minutes,
        offset_calculated_by,
        offset_rounded_by,
        not_later_than,
        not_earlier_than,
        notes,
        start_date,
        end_date,
        timeByOffset,
        permanent,
        forMinyanSuggestion
      },
      props: {
        actions: {
          updateMinyan,
          addMinyan
        },
      }
    } = this;

    if (!type || (!sun && !mon && !tues && !wed && !thurs && !fri && !sat)
      || (!timeByOffset && !time_at)
      || (timeByOffset && (!zman_offset_type_id || typeof offset_minutes === 'string'))
      || (!permanent && (!start_date || !end_date))) {
      createToast(null, 'Please fill in all required fields!');
      return;
    }

    this.setState({ minyanSuccess: true });
    const minyan = {
      minyan_location_id,
      type,
      nusach: nusach ? nusach : null,
      sun,
      mon,
      tues,
      wed,
      thurs,
      fri,
      sat,
      special_days_only,
      special_days_included,
      time_at: time_at ? time_at : null,
      zman_offset_type_id: zman_offset_type_id ? zman_offset_type_id : null,
      offset_minutes: typeof offset_minutes === 'number' ? offset_minutes : null,
      offset_calculated_by: offset_calculated_by ? offset_calculated_by : null,
      offset_rounded_by: offset_rounded_by ? offset_rounded_by : null,
      not_later_than: not_later_than ? not_later_than : null,
      not_earlier_than: not_earlier_than ? not_earlier_than : null,
      notes: notes ? notes : null,
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null
    };

    if (forMinyanSuggestion) {
      this.handleMinyanSuggestionSubmit(minyan, mode, id);
      return;
    }

    // in case a dynamic-time minyan is switched to a regular one
    if (time_at) {
      minyan.todays_dynamic_time = null;
      minyan.dynamic_time_updated_at = null;
    }

    if (mode === 'edit') {
      minyan.id = id;
      await updateMinyan(minyan, type);
    }
    else {
      await addMinyan(minyan);
    }

    if (!this.props.minyanFailed) {
      this.setState({ submitted: false });
      setTimeout(() => this.handleRedirect(action), 1000);
    } else {
      this.setState({ minyanSuccess: false });
    }
  }

  handleRedirect = (action) => {
    if (action === 'save') {
      return this.props.pageRoute.history.goBack();
    } else if (action === 'add') {
      window.scrollTo(0, 0);
      this.setState({ ...initialState });
    } else if (action === "duplicate") {
      window.scrollTo(0, 0);
    }
    else if (action === "duplicate") {
      window.scrollTo(0, 0);
    }
    // re-enable submit
    setTimeout(() => {
      this.setState({ minyanSuccess: false });
    }, 2000);
  }

  handleMinyanSuggestionSubmit = async (minyan, mode, id) => {
    minyan.action = mode === 'add' ? 'create' : 'update';
    minyan.minyan_id = mode === 'edit' ? id : null;
    await this.props.actions.addMinyanSuggestion(minyan);

    if (!this.props.minyanFailed) {
      this.setState({ submitted: false });
      setTimeout(this.props.pageRoute.history.goBack, 1500);
    }
  }

  render() {
    const {
      submitted,
      mode,
      type,
      nusach,
      sun,
      mon,
      tues,
      wed,
      thurs,
      fri,
      sat,
      special_days_only,
      special_days_included,
      time_at,
      zman_offset_type_id,
      offset_minutes,
      offset_calculated_by,
      offset_rounded_by,
      not_later_than,
      not_earlier_than,
      notes,
      start_date,
      end_date,
      offsetMode,
      special,
      timeByOffset,
      permanent,
      seasonalPreset,
      forMinyanSuggestion
    } = this.state;

    const daysObj = { sun: 'Sunday', mon: 'Monday', tues: 'Tuesday', wed: 'Wednesday', thurs: 'Thursday', fri: 'Friday', sat: 'Shabbat' };
    const dayPresets = {
      'All Days': { sun: true, mon: true, tues: true, wed: true, thurs: true, fri: true, sat: true },
      'Sunday - Friday': { sun: true, mon: true, tues: true, wed: true, thurs: true, fri: true, sat: false },
      'Monday - Friday': { sun: false, mon: true, tues: true, wed: true, thurs: true, fri: true, sat: false },
      'Mon, Thurs': { sun: false, mon: true, tues: false, wed: false, thurs: true, fri: false, sat: false },
      'Sun, Tues, Wed, Fri': { sun: true, mon: false, tues: true, wed: true, thurs: false, fri: true, sat: false },
      'Tues, Wed, Fri': { sun: false, mon: false, tues: true, wed: true, thurs: false, fri: true, sat: false }
    };
    const specialDays = { 'rosh chodesh': 'Rosh Chodesh', 'fast day': 'Fast Day', 'chol hamoed': 'Chol Hamoed', selichos: 'Selichos' };
    const tefillos = { '': 'Select Tefillah', shachris: 'Shacharis', mincha: 'Mincha', mariv: 'Maariv' };
    const nusachs = { '': 'Select a Nusach', sefard: 'Sefard', ashkenaz: 'Ashkenaz', ari: 'Ari', edut_mizrach: 'Edut Mizrach', temani: 'Temani' };
    const specialObj = { excludes: 'all special days', only: 'on the selected special days', includes: 'the selected special days' };
    const offsetModes = { '': 'Select Offset', at: 'At', before: 'Minutes before', after: 'Minutes after' };
    // numbers correspond to their ID's in the database
    const offsetTypes = { '': 'Select Zman type', 1: 'Neitz', 2: 'Shkiah', 3: 'Alos', 4: 'Plag Hamincha', 5: 'Candle Lighting', 6: 'Tzeis', 7: 'Mincha Gedolah', 8: 'Sof Shema MA', 9: 'Sof Shema GRA' };
    const roundedByModes = { '': 'Rounded to', on_time: 'Exact time',nearest_5: 'Closest 5 minutes', earlier_5: 'Earlier 5 minutes', later_5: 'Later 5 minutes' };
    const presets = ['winter', 'summer'];

    return (
      <React.Fragment>
        <div className="full-page-modal">
          <h1 className="desktop-hidden xl-text mob-large-text text-500 bordered-title">
            {mode === 'edit' ? 'Edit Minyan' : 'Add Minyan'}
          </h1>
          <div className="full-page-modal-close link" onClick={this.props.pageRoute.history.goBack}>
            <i className="material-icons">close</i>
          </div>
          <div className="container">
            <h1 className="mobile-hidden xl-text mob-large-text text-500 bordered-title">
              {mode === 'edit' ? 'Edit Minyan' : 'Add Minyan'}
            </h1>

            <div className="mb-16 mobile-flex">
              <label className="input-label mobile-flex-align-center mobile-text-500">Tefillah</label>
              <select
                className={`custom-input  mobile-flex-1 ${submitted && !type ? 'required' : ''}`}
                value={type}
                name="type"
                onChange={this.handleInputChange}
              >
                {Object.keys(tefillos).map(tefillah =>
                  <option
                    key={tefillah}
                    value={tefillah}
                    disabled={tefillah === ''}
                  >
                    {tefillos[tefillah]}
                  </option>)}
              </select>
            </div>

            <div className="mb-16 mobile-flex">
              <label className="input-label mobile-flex-align-center mobile-text-500">Nusach</label>
              <select
                className={`custom-input mobile-flex-1`}
                value={nusach}
                name="nusach"
                onChange={this.handleInputChange}
              >
                {Object.keys(nusachs).map(nusach =>
                  <option
                    key={nusach}
                    value={nusach}
                    disabled={nusach === ''}
                  >
                    {nusachs[nusach]}
                  </option>)}
              </select>
            </div>
          </div>
          <hr className="desktop-hidden" />

          <div className="container">
            <div className="mt-32 mobile-mt-0 mb-32 mobile-mb-0 flex mobile-showing">
              <label className="input-label mob-small-text mobile-mb-12 mobile-text-500">Days Included</label>
              <div>
                <p className="accent-text-light medium-text mob-small-text mb-24">
                  Select preset groups of days or any day/days of the week that are included in the Minyan.
                </p>
                <div className="preset-labels-div flex-left-center mb-24">
                  {Object.keys(dayPresets).map(preset => {
                    return (
                      <div key={preset} className="checkbox-label flex-align-middle">
                        <input
                          name="day-preset"
                          type="radio"
                          onChange={() => this.handleDayPresets(dayPresets[preset])}
                          id={preset}
                          checked={this.checkIfSelectedDaysEqualsPreset(dayPresets[preset])}
                        />
                        <label htmlFor={preset} className="capitalize-text">{preset}</label>
                      </div>
                    );
                  })}
                </div>
                <div className={`checkbox-labels-container
                  ${submitted & !sun && !mon && !tues && !wed && !thurs && !fri && !sat ? 'required' : ''}`}>
                  {Object.keys(daysObj).map(d => {
                    return (
                      <div key={d} className="checkbox-label">
                        <input
                          name={d}
                          value={this.state[d]}
                          type="checkbox"
                          onChange={this.handleInputChange}
                          id={daysObj[d]}
                          checked={this.state[d] === true}
                        />
                        <label htmlFor={daysObj[d]}>{daysObj[d]}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <hr className="desktop-hidden" />

          <div className="container">
            <div className="mb-32 mobile-mb-0 flex mobile-showing">
              <label className="input-label mob-small-text mobile-text-500 mobile-mb-12">Special Days</label>
              <div>
                <div className="radio-button-container">


                  {Object.keys(specialObj).map(s => {
                    return (
                      <div key={s} className="mb-8">
                        <input
                          type="radio"
                          id={s}
                          value={s}
                          onChange={this.updateSpecialState}
                          checked={special === s}
                        />
                        <label htmlFor={s} className="mob-small-text">
                          Minyan <span>{s === 'only' ? ' is ' : ''}</span><span className="bold-text">{s}</span> {specialObj[s]}
                        </label>
                      </div>
                    );
                  })}
                </div>
                {special !== 'excludes' &&
                  <div>
                    <p className="accent-text-light medium-text mob-small-text mt-24 mb-16">Select all special days that apply</p>
                    <div className="checkbox-labels-container">
                      {Object.keys(specialDays).map(day => {
                        return (
                          <div key={day} className="checkbox-label">
                            <input
                              type="checkbox"
                              id={specialDays[day]}
                              name={day}
                              onChange={this.toggleSpecialDay}
                              checked={special_days_only.includes(day) || special_days_included.includes(day)}
                            />
                            <label htmlFor={specialDays[day]}>{specialDays[day]}</label>
                          </div>
                        );
                      })}
                    </div>
                  </div>}
              </div>
            </div>
          </div>
          <hr className="desktop-hidden" />

          <div className="container">
            <div className="mb-32 mobile-mb-0 flex mobile-showing">
              <label className="input-label mobile-mb-12 mobile-text-500">Time</label>
              <div className="radio-button-container">
                <div className="mb-8">
                  <input
                    type="radio"
                    id="specified"
                    name="timeType"
                    value="time_at"
                    checked={!timeByOffset}
                    onChange={this.updateTimeType}
                  />
                  <label htmlFor="specified" className="mob-small-text">
                    Time of Minyan is a fixed time of day
                    </label>
                </div>
                {!timeByOffset &&
                  <input
                    className={`minyan-input minyan-time-input ml-32 mb-16 ${submitted && !time_at ? 'required' : ''}`}
                    type="time"
                    name="time_at"
                    value={time_at}
                    onChange={this.handleInputChange}
                  />}
                <div className="mb-8">
                  <input
                    type="radio"
                    id="zman"
                    name="timeType"
                    value="zman"
                    checked={timeByOffset}
                    onChange={this.updateTimeType}
                  />
                  <label htmlFor="zman" className="mob-small-text">
                    Time of Minyan is zmanim based
                  </label>
                </div>

                {timeByOffset &&
                  <div>
                    <div className="desktop-inline mobile-flex">
                      <div className="inline">
                        <input
                          className={`minyan-input minyan-minutes-imput ml-32 mr-8 ${submitted && typeof offset_minutes === 'string' ? 'required' : ''}`}
                          type="number"
                          step="1"
                          min="0"
                          max="200"
                          placeholder="minutes"
                          name="offset_minutes"
                          value={typeof offset_minutes === 'string' ? '' : Math.abs(offset_minutes)}
                          onChange={this.updateOffsetMinutes}
                          autoFocus={typeof offset_minutes === 'string'}
                        />
                      </div>
                      <div className="desktop-inline mobile-flex mobile-flex-1">
                        <select
                          className={`minyan-input minyan-offset-input mr-8 mobile-flex-1 ${submitted && !offsetMode ? 'required' : ''}`}
                          value={offsetMode}
                          onChange={this.updateOffsetMode}
                        >
                          {Object.keys(offsetModes).map(mode =>
                            <option
                              key={mode}
                              value={mode}
                              disabled={mode === ''}
                            >
                              {offsetModes[mode]}
                            </option>)}
                        </select>
                      </div>
                    </div>
                    <div className="desktop-inline mobile-flex">
                      <select
                        className={`minyan-input minyan-half-width-input mobile-flex-1 mobile-mr-8 mobile-ml-32 mobile-mt-8 ${submitted && !zman_offset_type_id ? 'required' : ''}`}
                        name="zman_offset_type_id"
                        value={zman_offset_type_id}
                        onChange={this.handleInputChange}
                      >
                        {Object.keys(offsetTypes).map(typeNum =>
                          <option
                            key={typeNum}
                            value={typeNum}
                            disabled={typeNum === ''}
                          >
                            {offsetTypes[typeNum]}
                          </option>)}
                      </select>
                    </div>
                    <div className="desktop-inline mobile-flex">
                      <select
                        className={`minyan-input minyan-half-width-input mr-8 ml-32 mt-8 mobile-flex-1 mobile-ml-32 mobile-mr-8`}
                        name="offset_calculated_by"
                        value={offset_calculated_by}
                        onChange={this.handleInputChange}
                      >
                        {Object.keys(offsetCalculationTypes).map(calcType =>
                          <option
                            key={calcType}
                            value={calcType}
                            disabled={calcType === ''}
                          >
                            {offsetCalculationTypes[calcType]}
                          </option>)}
                      </select>
                    </div>
                    <div className="desktop-inline mobile-flex">
                      <select
                        className={`minyan-input minyan-half-width-input mt-8 mobile-flex-1 mobile-ml-32 mobile-mr-8 `}
                        name="offset_rounded_by"
                        value={offset_rounded_by}
                        onChange={this.handleInputChange}
                      >
                        {Object.keys(roundedByModes).map(mode =>
                          <option
                            key={mode}
                            value={mode}
                            disabled={mode === ''}
                          >
                            {roundedByModes[mode]}
                          </option>)}
                      </select>
                    </div>
                    <div className="mt-16">
                      <label className="before-after-label mob-small-text">
                        Not before
                      </label>
                      <input
                        className={`minyan-input minyan-time-input`}
                        type="time"
                        name="not_earlier_than"
                        value={not_earlier_than}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="mt-8">
                      <label className="before-after-label mob-small-text">
                        Not after
                      </label>
                      <input
                        className={`minyan-input minyan-time-input`}
                        type="time"
                        name="not_later_than"
                        value={not_later_than}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
          <hr className="desktop-hidden" />

          <div className="container">
            <div className="mb-32 mobile-mb-0 flex mobile-showing">
              <label className="input-label mobile-mb-12 mobile-text-500">Status</label>
              <div className="radio-button-container">
                <div className="mb-8">
                  <input
                    type="radio"
                    name="permanent"
                    id="permanent"
                    value={true}
                    checked={permanent}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="permanent" className="mob-small-text">
                    Permanent Minyan
                    </label>
                </div>
                <div className="mb-8">
                  <input
                    type="radio"
                    name="permanent"
                    id="temporary"
                    value={false}
                    checked={!permanent}
                    onChange={this.handleInputChange}
                  />
                  <label htmlFor="temporary" className="mob-small-text">
                    Seasonal Minyan
                  </label>
                </div>
                {!permanent &&
                  <div>
                    <div className="preset-labels-div flex-left-center ml-32 mb-12">
                      {presets.map(preset => {
                        return (
                          <div key={preset} className="checkbox-label flex-align-middle">
                            <input
                              name="preset"
                              type="radio"
                              onChange={() => this.prefillDates(preset)}
                              id={preset}
                              checked={seasonalPreset === preset}
                            />
                            <label htmlFor={preset} className="capitalize-text">{preset}</label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="mt-4 flex-left-center mobile-showing">
                      <div className="mobile-mb-12">
                        <span className="mobile-ml-32 mr-8 mobile-temp-label">Start Date</span>
                        <input
                          className={`minyan-input minyan-date-input mr-16 ${submitted && !start_date ? 'required' : ''}`}
                          type="date"
                          value={start_date}
                          name="start_date"
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="">
                        <span className="mr-8 mobile-ml-32 mobile-temp-label">End Date</span>
                        <input
                          className={`minyan-input minyan-date-input ${submitted && !end_date ? 'required' : ''}`}
                          type="date"
                          value={end_date}
                          name="end_date"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          </div>
          <hr className="desktop-hidden" />

          <div className="container">
            <div className="mb-24 flex mobile-showing">
              <label className="input-label mobile-mb-12 mobile-text-500">Notes</label>
              <textarea
                className="custom-input"
                rows="4"
                placeholder="Enter notes on minyan (optional)"
                value={notes}
                name="notes"
                onChange={this.handleInputChange}
              >
              </textarea>
            </div>
            <div className="full-page-modal-footer mobile-mb-40">
              <div className="full-page-modal-footer-btns">
                <button
                  className={`btn btn-accent uppercase-text ${mode === 'add' ? 'mobile-minyan-button' : ''}`}
                  onClick={this.props.pageRoute.history.goBack}
                >
                  Cancel
                </button>
                {!forMinyanSuggestion &&
                  <button
                    className={`btn btn-accent uppercase-text ${mode === 'add' ? 'mobile-minyan-button' : ''}`}
                    onClick={() => this.handleSubmit('save')}
                  >
                    Save
                </button>}
                {!forMinyanSuggestion && mode === 'add' &&
                  <button
                    className="btn btn-accent mobile-minyan-button uppercase-text"
                    onClick={() => this.handleSubmit('add')}
                  >
                    Save & Add Another
                  </button>}
                {!forMinyanSuggestion && mode === 'add' &&
                  <button
                    className="btn btn-accent mobile-minyan-button uppercase-text"
                    onClick={() => this.handleSubmit('duplicate')}
                  >
                    Save & Duplicate
                  </button>}
                 {forMinyanSuggestion &&
                  <button
                    className={`btn btn-accent uppercase-text`}
                    onClick={this.handleSubmit}
                  >
                    Submit for review
                </button>}
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }
}