import React from "react";
import { PageLink } from '../../lib';
import Pages from "../";
import "../../assets/css/pages/search.css";
import { HelmetHeader } from "../../components/HelmetHeader";
import { getLatLng } from 'react-places-autocomplete';
import { LocationSearchInput } from "../../components/LocationSearchInput";
import { GoogleMapsComponent } from "../../components/GoogleMapsComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DebounceInput } from "react-debounce-input";
import { FilterSidebar } from "../../components/FilterSidebar";
import { withStyles } from '@material-ui/core/styles';
import {
  Switch,
  ClickAwayListener,
  Radio,
  Tooltip,
  Checkbox,
  TextField,
  Dialog,
} from '@material-ui/core';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import Loader from "../../components/Loader";
const date = new Date();
const todaysDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
const tomorrow = new Date();
tomorrow.setDate(date.getDate() + 1);
const tomorrowsDate = new Date(tomorrow.getTime() - (tomorrow.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
const DEFAULT_DISTANCE = 5;
const trackStyles = { backgroundColor: '#11B6D7', height: 3 };
const railStyles = { backgroundColor: '#C6C6C6', height: 3 };
const handleStyles = {
  color: '#11B6D7',
  marginTop: -9,
  backgroundColor: '#11B6D7',
  border: 'solid 10px #11B6D7'
};
const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: '#11B6D7',
    },
  },
  checked: {},
})(Radio);
const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#11B6D7',
    },
  },
  checked: {},
})(Checkbox);
// const CustomTimePicker = withStyles({
//   // indicator: {
//   //   backgroundColor: '#11B6D7'
//   // }
// })(TextField);

const CustomNumberPicker = withStyles({
  root: {
    width: '45px'
  },
})(TextField);
const CustomSwitch = withStyles({
  icon: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  iconChecked: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  bar: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  switchBase: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
  checked: {
    color: '#11B6D7',
    '& + $bar': {
      backgroundColor: '#11B6D7',
    },
  },
})(Switch);


//////////NOTE RESULTS PROPERTY IN REDUX SHUL FOLDER DEPENDING ON SEARCH TYPE COULD BE SHUL ID OR MINYAN ID////////////
export default class Search extends React.PureComponent {

  state = {
    tefillahDisplay: false,
    tefillahArray: [],
    pending_tefillahArray: [],

    nusachDisplay: false,
    nusachArray: [],
    pending_nusachArray: [],

    distanceDisplay: false,
    distance: DEFAULT_DISTANCE,
    pending_distance: DEFAULT_DISTANCE,

    timeDisplay: false,
    startTime: '',
    pending_startTime: null,
    endTime: '',
    pending_endTime: null,
    nextMinyanDay: '',
    pending_nextMinyanDay: '',

    moreFiltersDisplay: false,
    dayArray: [],
    pending_dayArray: [],
    pending_mode: 'all',

    mode: 'location',
    lat: '',
    lng: '',
    searchAddress: '',
    locationInput: '',
    activeIndex: 1,
    query: '',
    infoWindows: [],
    openFilter: '',
    mobileFilterSidebarOpen: false,
    mapView: false,
    currentTime: '',
    nextMinyanMode: false,
    showDialog: null,
    filteringEmptyInput: false,
    mobileTimeFilterOpen: false,
    mobileDistanceFilterOpen: false,
    shulHoverId: null,
    fixedMap: true,
    marginTop: true,
    todaysDayOfWeek: '',
    tomorrowsDayOfWeek: '',
    newSearch: true
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    this.parseUrlAndSetState();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.onpopstate = () => { };
  }

  componentDidUpdate() {
    window.onpopstate = (e) => {
      this.parseUrlAndSetState();
    };

    const {
      state: { endTime, startTime, nextMinyanDay, tomorrowsDayOfWeek, pending_nextMinyanDay },
      props: { recursiveSearchingNextDay, newEndTime, newDistance, shulActions }
    } = this;

    if (newEndTime && endTime !== newEndTime) {
      const endDateObj = new Date();
      endDateObj.setHours(+newEndTime.slice(0, 2));
      endDateObj.setMinutes(+newEndTime.slice(3, 5));
      this.setState({ endTime: newEndTime, pending_endTime: endDateObj });
      shulActions.resetNewEndTime();
    }

    if (recursiveSearchingNextDay && (startTime !== '00:00' || nextMinyanDay !== tomorrowsDayOfWeek || pending_nextMinyanDay !== tomorrowsDayOfWeek)) {
      const startDateObj = new Date();
      startDateObj.setHours(0, 0, 0);
      this.setState({ startTime: '00:00', pending_startTime: startDateObj, nextMinyanDay: tomorrowsDayOfWeek, pending_nextMinyanDay: tomorrowsDayOfWeek });
      shulActions.resetRecursiveSearchingNextDay();
    }

    if (newDistance) {
      this.setState({ distance: newDistance, pending_distance: newDistance });
      shulActions.resetNewDistance();
    }
  }

  parseUrlAndSetState = () => {
    const {
      q = '',
      mode = 'location',
      next_minyan,
      pagenumber: page = 1,
      address = '',
      lat,
      lng,
      nusach = '',
      tefillah = '',
      day = '',
      next_minyan_day = '',
      distance = 0,
      start = '',
      end = '',
    } = this.props.pageRoute.query;

    const today = new Date();
    //const fiveMin = 5 * 60 * 1000;
    const endTimeRange = 60 * 60 * 1000;
    const currentTime = new Date(today.getTime()).toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
    const endDate = new Date(today.getTime() + endTimeRange);
    const endTime = new Date(today.getTime() + endTimeRange).toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
    let endDateObj;
    if (end) {
      endDateObj = new Date();
      endDateObj.setHours(+end.slice(0, 2));
      endDateObj.setMinutes(+end.slice(3, 5));
    }
    let startDateObj;
    if (start) {
      startDateObj = new Date();
      startDateObj.setHours(+start.slice(0, 2));
      startDateObj.setMinutes(+start.slice(3, 5));
    }

    //const startTime = new Date(today.getTime() - (today.getTime() % fiveMin) - fiveMin).toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
    // const endTime = new Date(today.getTime() + endTimeRange - (today.getTime() % fiveMin) - fiveMin).toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
    let todaysDayOfWeek = today.getDay();
    let tomorrowsDayOfWeek = todaysDayOfWeek < 6 ? todaysDayOfWeek + 1 : 0;
    todaysDayOfWeek = String(todaysDayOfWeek);
    tomorrowsDayOfWeek = String(tomorrowsDayOfWeek);

    this.setState(() => ({
      todaysDateObj: today,
      tomorrowsDateObj: tomorrow,
      lat,
      lng,
      query: q,
      searchAddress: address,
      locationInput: address,
      todaysDayOfWeek,
      tomorrowsDayOfWeek,
      nextMinyanDay: next_minyan_day ? next_minyan_day : todaysDayOfWeek,
      pending_nextMinyanDay: next_minyan_day ? next_minyan_day : todaysDayOfWeek,
      mode,
      pending_mode: mode === 'rabbi' || mode === 'shul' ? mode : 'all',
      nextMinyanMode: (start && end) || next_minyan ? true : false,
      nusachArray: nusach ? nusach.split(',') : [],
      pending_nusachArray: nusach ? nusach.split(',') : [],
      tefillahArray: tefillah ? tefillah.split(',') : [],
      pending_tefillahArray: tefillah ? tefillah.split(',') : [],
      dayArray: day ? day.split(',') : [],
      distance: distance ? parseFloat(distance, 10) : DEFAULT_DISTANCE,
      pending_distance: distance ? parseFloat(distance, 10) : DEFAULT_DISTANCE,
      currentTime,
      startTime: start ? start : next_minyan ? currentTime : '',
      pending_startTime: start ? startDateObj : next_minyan ? today : null,
      endTime: end ? end : next_minyan ? endTime : '',
      pending_endTime: end ? endDateObj : next_minyan ? endDate : null,
      activeIndex: parseInt(page, 10),
      newSearch: true
    }), () => this.dispatchSearch(true));
  }

  dispatchSearch = (fromHistory = false) => {
    const {
      state: {
        activeIndex,
        mode,
        query,
        lat,
        lng,
        searchAddress,
        distance,
        startTime,
        endTime,
        nusachArray,
        tefillahArray,
        dayArray,
        todaysDayOfWeek,
        tomorrowsDayOfWeek,
        nextMinyanDay,
        currentTime,
      },
      props: {
        shulActions: {
          searchShulsWithInRadius,
          searchShulsByNameOrRabbi,
          searchShulsByAll,
          searchShulsByNextMinyan,
        },
      }
    } = this;
    const nusachs = nusachArray.length ? nusachArray.join() : '';
    const tefillahs = tefillahArray.length ? tefillahArray.join() : '';
    const days = dayArray.length ? dayArray.join() : '';

    if ((mode === 'location' && (!lat || !searchAddress))
      || (mode !== 'location' && !query)) return;

    if (mode === 'location') {
      if (startTime && endTime) {
        const dateToUse = nextMinyanDay === tomorrowsDayOfWeek ? tomorrowsDate : todaysDate;
        const isTomorrowSearch = nextMinyanDay === tomorrowsDayOfWeek ? true : false;
        const endTimeToUse = endTime < startTime ? '23:59' : endTime;
        searchShulsByNextMinyan(fromHistory, searchAddress, +nextMinyanDay, startTime, endTimeToUse, lat, lng, activeIndex, distance, nusachs, tefillahs, dateToUse, isTomorrowSearch);
      } else {
        searchShulsWithInRadius(fromHistory, searchAddress, lat, lng, activeIndex, distance, nusachs, tefillahs, days, currentTime, todaysDayOfWeek, todaysDate);
      }
    } else if (mode === 'rabbi' || mode === 'shul') {
      searchShulsByNameOrRabbi(fromHistory, query, activeIndex, mode, nusachs, tefillahs, days, currentTime, todaysDayOfWeek, todaysDate);
    } else if (mode === 'all') {
      searchShulsByAll(fromHistory, query, activeIndex, nusachs, tefillahs, days, currentTime, todaysDayOfWeek, todaysDate);
    }
  }

  rabbiShulSearch = e => {
    const mode = this.state.mode;
    this.setState(() => ({ activeIndex: 1, filteringEmptyInput: false }), this.dispatchSearch);
    if (window.innerWidth < 561) {
      document.getElementById(mode) && document.getElementById(mode).blur();
    }
  };

  handleQueryChange = e => {
    const query = e.target.value;
    this.setState({ query, filteringEmptyInput: false });
  }

  toggleResultsPages = (e) => {
    const attrib = e.target.attributes['data-pn'] ||
      e.target.parentElement.attributes['data-pn'];
    const pageNumber = parseInt(attrib ? attrib.value : 1, 10);

    this.setState(() => ({ activeIndex: pageNumber }), this.dispatchSearch);
  }

  handleAddressResults = (addressResults) => {
    const searchAddress = addressResults[0].formatted_address;
    getLatLng(addressResults[0])
      .then((res) => {
        this.setState(() => ({
          filteringEmptyInput: false,
          activeIndex: 1,
          lat: res.lat,
          lng: res.lng,
          searchAddress,
          newSearch: true
        }),
          () => {
            setTimeout(() => document.getElementById('location') && document.getElementById('location').blur(), 200);
            this.dispatchSearch();
          }
        );
      })
      .catch(error => console.error('Error', error));
    this.setState({ fixedMap: true, marginTop: true });
  };

  handleFilterArrayChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      [name]: value === '' ? []
        : prevState[name].includes(value) ? [...prevState[name].filter(v => v !== value)]
          : [...prevState[name], value],
    }));
  }

  newSearchFunc = () => {
    this.setState({newSearch: false});
  }
  handleChange = (e) => {
    let { name, value } = e.target;
    if (name === 'pending_distance') value = +value;

    this.setState({ [name]: value });
  }

  toggleInfoWindow = (id) => {
    this.setState((prevState) => ({
      infoWindows: prevState.infoWindows.includes(id) ? [...prevState.infoWindows.filter(ID => ID !== id)]
        : [...prevState.infoWindows, id]
    }));
  };
  updateLatLng = (lat, lng, newAddress) => {
    this.setState({
      lat: lat, lng: lng,
      searchAddress: newAddress,
      locationInput: newAddress
    })
    this.dispatchSearch(false);
  }

  filteringEmptInput = () => {
    const { query, searchAddress, mode } = this.state;
    if ((mode === 'location' && !searchAddress) || (mode !== 'location' && !query)) {
      this.setState({
        filteringEmptyInput: true,
        tefillahDisplay: false,
        nusachDisplay: false,
        distanceDisplay: false,
        timeDisplay: false,
        moreFiltersDisplay: false,
      });
      document.getElementById(this.state.mode) && document.getElementById(this.state.mode).focus();
      return true;
    }
    return false;
  }

  onDistanceSliderChange = value => {
    this.setState({ pending_distance: value });
  };

  updateLatLng = (lat, lng, newAddress) => {
    this.setState({
      lat: lat,
      lng: lng,
      locationInput: newAddress,
      searchAddress: newAddress,
    })
    this.dispatchSearch(false)
  }

  onMouseOverFunc = () => this.setState({ newSearch: false })

  clearFilters = (dispatchSearch = false) => {
    this.setState((prevState) => ({
      activeIndex: 1,
      pending_mode: 'all',
      startTime: '',
      pending_startTime: null,
      endTime: '',
      pending_endTime: null,
      nusachArray: [],
      pending_nusachArray: [],
      tefillahArray: [],
      pending_tefillahArray: [],
      dayArray: [],
      pending_dayArray: [],
      nextMinyanDay: prevState.todaysDayOfWeek,
      pending_nextMinyanDay: prevState.todaysDayOfWeek,
      distance: DEFAULT_DISTANCE,
      pending_distance: DEFAULT_DISTANCE,
    }),
      () => {
        if (dispatchSearch) this.dispatchSearch();
      });
  }

  clearInput = () => {
    this.setState({ query: '', searchAddress: '', locationInput: '' });
  }

  toggleMobileFilterSidebar = (isFilterOpen, timeFilter = false, distanceFilter = false) => {
    this.setState({
      mobileFilterSidebarOpen: isFilterOpen,
      mobileDistanceFilterOpen: distanceFilter,
      mobileTimeFilterOpen: timeFilter
    });
  }

  applyMobileFilters = (filtersOBJ, keepFilterSidebarOpen = false) => {
    this.setState(
      () => ({ ...filtersOBJ, activeIndex: 1, mobileFilterSidebarOpen: keepFilterSidebarOpen ? true : false }),
      this.dispatchSearch
    );
  }

  toggleMapView = (isShowMap) => {
    this.setState({
      mapView: isShowMap
    });
  }
 
  handleLocationInputChange = (locationInput) => {
    this.setState(prevState => ({
      locationInput,
      searchAddress: !locationInput ? '' : prevState.searchAddress
    }));
  }

  // toggleNextMinyanMode = isNextMinyan => {
  //   const dayArray = this.state.dayArray;
  //   if (isNextMinyan && dayArray.length) {
  //     this.setState({ showDialog: 'nextMinyanMode' });
  //     return;
  //   }
  //   const today = new Date();
  //   const fiveMin = 5 * 60 * 1000;
  //   const threeHours = 180 * 60 * 1000;
  //   const startTime = !isNextMinyan ? '' : new Date(today.getTime() - (today.getTime() % fiveMin) - fiveMin).toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
  //   const endTime = !isNextMinyan ? '' : new Date(today.getTime() + threeHours - (today.getTime() % fiveMin) - fiveMin).toLocaleTimeString('en-US', { hour12: false }).slice(0, 5);
  //   this.setState(() => ({
  //     nextMinyanMode: isNextMinyan,
  //     startTime,
  //     endTime,
  //     activeIndex: 1
  //   }), this.dispatchSearch);
  // }

  toggleSearchMode = (mode) => {
    this.setState(prevState => ({
      filteringEmptyInput: false,
      mode: mode === 'all' ? 'all' : mode === 'location' ? 'location' : prevState.mode !== 'location' ? 'location' : 'all',
      query: '',
      searchAddress: '',
      locationInput: '',
    }),
      () => {
        this.clearFilters();
        const mode = this.state.mode;
        document.getElementById(mode) && document.getElementById(mode).focus();
      });
  }

  handleClickAway = (e, filterName, displayName) => {
    if (filterName === 'moreFilters') {
      this.setState(prevState => ({
        moreFiltersDisplay: false,
        pending_mode: prevState.mode,
        pending_dayArray: prevState.dayArray
      }));
    } else if (filterName === 'time') {
      if (e && e.target && e.target.className && typeof e.target.className.includes !== 'undefined' && (e.target.className.includes('Mui') || e.target.className.includes('jss'))) {
        return;
      }

      const { startTime, endTime, nextMinyanDay } = this.state;
      let startObj = null;
      let endObj = null;
      if (startTime) {
        startObj = new Date();
        startObj.setHours(+startTime.slice(0, 2));
        startObj.setMinutes(+startTime.slice(3, 5));
      }
      if (endTime) {
        endObj = new Date();
        endObj.setHours(+endTime.slice(0, 2));
        endObj.setMinutes(+endTime.slice(3, 5));
      }

      this.setState(() => ({
        timeDisplay: false,
        pending_startTime: startObj,
        pending_endTime: endObj,
        pending_nextMinyanDay: nextMinyanDay
      }));
    } else {
      this.setState(prevState => ({
        [displayName]: false,
        [`pending_${filterName}`]: prevState[filterName]
      }));
    }
  }

  toggleFilterDisplay = (e, filterName, displayName) => {
    if (e.target === e.currentTarget) {
      this.setState((prevState) => ({
        [displayName]: !prevState[displayName]
      }),
        () => {
          if (!this.state[displayName]) this.handleClickAway(null, filterName, displayName);
        });
    }
  }

  applyFilter = (filterName, displayName) => {
    if (this.filteringEmptInput()) return;
    this.setState(prevState => ({
      [filterName]: filterName === 'distance' && prevState[`pending_${filterName}`] < .5 ? .5 : prevState[`pending_${filterName}`],
      [`pending_${filterName}`]: filterName === 'distance' && prevState[`pending_${filterName}`] < .5 ? .5 : prevState[`pending_${filterName}`],
      [displayName]: false,
      activeIndex: 1,
    }), this.dispatchSearch);
  }

  applyTimeFilter = () => {
    if (this.filteringEmptInput()) return;
    const { pending_startTime, pending_endTime, pending_nextMinyanDay, dayArray } = this.state;
    if ((!pending_startTime && pending_endTime) || (pending_startTime && !pending_endTime)) {
      if (!pending_startTime && document.getElementById('startTime')) {
        document.getElementById('startTime').focus();
      } else if (document.getElementById('endTime')) {
        document.getElementById('endTime').focus();
      }
      return;
    }

    if (dayArray.length) {
      this.setState({ showDialog: 'time', temp_startTime: pending_startTime, temp_endTime: pending_endTime, temp_nextMinyanDay: pending_nextMinyanDay });
      return;
    }

    this.setState((prevState) => ({
      startTime: prevState.pending_startTime ? pending_startTime : '',
      endTime: prevState.pending_endTime ? pending_endTime : '',
      nextMinyanDay: prevState.pending_nextMinyanDay,
      timeDisplay: false,
      activeIndex: 1,
      newSearch: true,
    }), this.dispatchSearch);
  }

  applyMoreFilter = () => {
    if (this.filteringEmptInput()) return;
    const { pending_dayArray, startTime, endTime, mode } = this.state;
    if (pending_dayArray.length && startTime && endTime) {
      this.setState({ showDialog: 'dayArray', temp_dayArray: pending_dayArray });
      return;
    }

    this.setState(prevState => ({
      mode: mode !== 'location' ? prevState.pending_mode : 'location',
      dayArray: prevState.pending_dayArray,
      moreFiltersDisplay: false,
      activeIndex: 1,
      newSearch: true,
    }), this.dispatchSearch);
  }

  clearPendingTimes = () => {
    document.getElementById('startTime').value = "";
    document.getElementById('endTime').value = "";
    this.setState({
      pending_startTime: null,
      pending_endTime: null,
    });
  }

  cancelDialog = filterToCancel => {
    if (filterToCancel === 'time') {
      this.setState(prevState => ({
        timeDisplay: false,
        pending_startTime: null,
        pending_endTime: null,
        pending_nextMinyanDay: prevState.todaysDayOfWeek,
        showDialog: false
      }));
    } else {
      this.setState({
        moreFiltersDisplay: false,
        pending_dayArray: [],
        showDialog: false
      });
    }
  }

  okDialog = filterToApply => {
    if (filterToApply === 'time') {
      this.setState(prevState => ({
        showDialog: false,
        dayArray: [],
        pending_dayArray: [],
        pending_startTime: prevState.temp_startTime,
        pending_endTime: prevState.temp_endTime,
        pending_nextMinyanDay: prevState.temp_nextMinyanDay
      }), this.applyTimeFilter);
    } else {
      this.setState(prevState => ({
        showDialog: false,
        startTime: '',
        pending_startTime: null,
        endTime: '',
        pending_endTime: null,
        pending_nextMinyanDay: prevState.todaysDayOfWeek,
        pending_dayArray: prevState.temp_dayArray
      }), this.applyMoreFilter);
    }
  }

  handleTimeChange = (time, field) => {
    this.setState({ [field]: time });
  }

  highlightShulPin = id => {
    this.setState({ shulHoverId: id });
  }

  unHighlightShulPin = id => {
    this.setState({ shulHoverId: null });
  }

  handleScroll = () => {
    if (document.getElementById('front-layout-header')
      && document.getElementById('front-layout-header').getBoundingClientRect().bottom < 0
      && document.getElementById('front-layout-footer')
      && document.getElementById('front-layout-footer').getBoundingClientRect().top >= (window.innerHeight || document.documentElement.clientHeight)) {
      this.setState({ fixedMap: true });
    } else {
      this.setState({ fixedMap: false });
    }
    this.setState({ marginTop: false });
  }

  render() {
    // if (window.innerWidth < 561) {
    //   window.scrollTo(0, 145);
    // }

    const {
      props: {
        pins,
        results,
        num_of_pages,
        total,
        sortIds,
        loading,
        weeklySponsor,
        //isRoshChodesh
      },
      state: {
        mode,
        activeIndex,
        lat,
        lng,
        locationInput,
        dayArray,
        tefillahDisplay,
        timeDisplay,
        distanceDisplay,
        nusachDisplay,
        todaysDayOfWeek,
        tomorrowsDayOfWeek,
        query,
        nextMinyanDay,
        pending_nextMinyanDay,
        mobileFilterSidebarOpen,
        mapView,
        showDialog,
        filteringEmptyInput,
        pending_dayArray,
        tefillahArray,
        pending_tefillahArray,
        nusachArray,
        pending_nusachArray,
        distance,
        pending_distance,
        startTime,
        endTime,
        moreFiltersDisplay,
        pending_mode,
        mobileTimeFilterOpen,
        mobileDistanceFilterOpen,
        shulHoverId,
        fixedMap,
        marginTop
      }
    } = this;
    const nusachs = { '': 'All', ashkenaz: 'Ashkenaz', sefard: 'Sefard', 'edut_mizrach': 'Edut Mizrach', ari: 'Ari', temani: 'Temani' };
    const tefillahs = { '': 'All', shachris: 'Shacharis', mincha: 'Mincha', mariv: 'Maariv' };
    const days = { weekdays: 'Weekdays', shabbos: 'Shabbos', rosh_chodesh: 'Rosh Chodesh' };
    const nextMinyanDays = { [todaysDayOfWeek]: 'Today', [tomorrowsDayOfWeek]: 'Tomorrow' };
    const searchByNameModes = { all: 'All', shul: 'Shul', rabbi: 'Rabbi' };
    const activeFilters = { mode, tefillahArray, nextMinyanDay, startTime, endTime, distance, nusachArray, dayArray, todaysDayOfWeek, tomorrowsDayOfWeek };
    let activeFilterCount = 0;
    if (tefillahArray.length) activeFilterCount++;
    if (nusachArray.length) activeFilterCount++;
    if (dayArray.length) activeFilterCount++;
    if (mode !== 'location' && mode !== 'all') activeFilterCount++;

    return (
      <React.Fragment>
        <HelmetHeader />
        {mobileFilterSidebarOpen &&
          <FilterSidebar
            mobileTimeFilterOpen={mobileTimeFilterOpen}
            mobileDistanceFilterOpen={mobileDistanceFilterOpen}
            clearFilters={this.clearFilters}
            activeFilters={activeFilters}
            toggleMobileFilterSidebar={this.toggleMobileFilterSidebar}
            applyMobileFilters={this.applyMobileFilters}
          />}
        {showDialog &&
          <Dialog open={true}>
            {renderDialog(showDialog, () => this.cancelDialog(showDialog), () => this.okDialog(showDialog))}
          </Dialog>}
        <div className="search-page-container">
          <div className="search-content-container">

            <div className="search-mode-toggle">
              <span
                onClick={() => this.toggleSearchMode('location')}
                className={`link search-mode-toggle-label ${mode === 'location' ? 'search-mode-toggle-label-active' : ''}`}
              >
                By Location
              </span>
              <div>
                <CustomSwitch
                  className=""
                  checked={mode !== 'location'}
                  onChange={this.toggleSearchMode}
                  color="default"
                />
              </div>
              <span
                onClick={() => this.toggleSearchMode('all')}
                className={`link search-mode-toggle-label ${mode !== 'location' ? 'search-mode-toggle-label-active' : ''}`}
              >
                By Name
              </span>
            </div>

            <div className="search-input-box">
              {mode !== 'location' ?
                (<DebounceInput
                  autoComplete="off"
                  id={mode}
                  placeholder={`Search ${mode === 'shul' ? 'a Shul Name' : mode === 'rabbi' ? "a Rabbi's Name" : 'keywords'}`}
                  className={`search-input max-100 ${filteringEmptyInput ? 'required' : ''}`}
                  minLength={1}
                  debounceTimeout={window.innerWidth < 561 ? 1000 : 500}
                  value={query}
                  onKeyUp={this.handleQueryChange}
                  onChange={this.rabbiShulSearch}
                />)
                : (<LocationSearchInput
                  id="location"
                  placeHolder={'Your Address, City, Locale or Place'}
                  customClass={`search-input max-100 ${filteringEmptyInput ? 'required' : ''}`}
                  prevAddress={locationInput}
                  handleAddressResults={this.handleAddressResults}
                  handleLocationInputChange={this.handleLocationInputChange}
                  forSearchPage={true}
                />)}

              <div className="relative">
                <div className="search-page-icon-cover-up">
                  <Tooltip title="clear input" placement="top">
                    <i
                      className={`material-icons search-page-clear-input-icon
                        ${!query && !locationInput ? 'hide' : ''}`}
                      onClick={this.clearInput}
                    >
                      close
                    </i>
                  </Tooltip>
                </div>
                <div className="icon-cover-up">
                  <i className={`material-icons input-search-icon ${query || locationInput ? 'hide' : ''}`}>
                    search
                  </i>
                </div>
              </div>
            </div>

            {/* desktop filters start */}
            <div className="filters-container mobile-hidden">
              <div>
                <ClickAwayListener onClickAway={(e) => this.handleClickAway(e, 'tefillahArray', 'tefillahDisplay')}>
                  <div className={`filter-box ${tefillahArray.length ? 'filter-box-active' : ''}`} onClick={(e) => this.toggleFilterDisplay(e, 'tefillahArray', 'tefillahDisplay')}>
                    Tefillah {tefillahArray.length > 0 && <span>&nbsp;&middot;&nbsp;{tefillahArray.length}</span>}
                    {tefillahDisplay &&
                      <div className="filter tefillah-filter">
                        <ul>
                          {Object.keys(tefillahs).map(t => {
                            return (
                              <li key={t} className="filter-li">
                                <CustomCheckbox
                                  style={{ height: '24px', width: '35px' }}
                                  checked={((t === '' && !pending_tefillahArray.length) || pending_tefillahArray.includes(t))}
                                  onChange={this.handleFilterArrayChange}
                                  value={t}
                                  color="default"
                                  name="pending_tefillahArray"
                                />
                                <div>{tefillahs[t]}</div>
                              </li>
                            );
                          })}
                        </ul>
                        <div className="apply-filter">
                          <div className="link" onClick={() => this.applyFilter('tefillahArray', 'tefillahDisplay')}>Apply</div>
                        </div>
                      </div>}
                  </div>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={() => this.handleClickAway(null, 'nusachArray', 'nusachDisplay')}>
                  <div className={`filter-box ${nusachArray.length ? 'filter-box-active' : ''}`} onClick={(e) => this.toggleFilterDisplay(e, 'nusachArray', 'nusachDisplay')}>
                    Nusach {nusachArray.length > 0 && <span>&nbsp;&middot;&nbsp;{nusachArray.length}</span>}
                    {nusachDisplay &&
                      <div className="filter nusach-filter">
                        <ul>
                          {Object.keys(nusachs).map(n => {
                            return (
                              <li key={n} className="filter-li">
                                <CustomCheckbox
                                  style={{ height: '24px', width: '35px' }}
                                  checked={((n === '' && !pending_nusachArray.length) || pending_nusachArray.includes(n))}
                                  onChange={this.handleFilterArrayChange}
                                  value={n}
                                  color="default"
                                  name="pending_nusachArray"
                                />
                                <div>{nusachs[n]}</div>
                              </li>
                            );
                          })}
                        </ul>
                        <div className="apply-filter">
                          <div className="link" onClick={() => this.applyFilter('nusachArray', 'nusachDisplay')}>Apply</div>
                        </div>
                      </div>}
                  </div>
                </ClickAwayListener>

                {mode === 'location' &&
                  <ClickAwayListener onClickAway={() => this.handleClickAway(null, 'distance', 'distanceDisplay')}>
                    <div className={`filter-box filter-box-active`} onClick={(e) => this.toggleFilterDisplay(e, 'distance', 'distanceDisplay')}>
                      {`${distance} Miles`}
                      {distanceDisplay &&
                        <div className="filter distance-filter">
                          <div className="distance-title">Distance in miles</div>
                          <div className="flex-align-center mb-24">
                            <CustomNumberPicker
                              onChange={this.handleChange}
                              name="pending_distance"
                              id="distance"
                              type="number"
                              value={pending_distance}
                              inputProps={{
                                step: 1,
                                min: 1
                              }}
                            >
                            </CustomNumberPicker>
                            <div className="ml-4">mi</div>
                          </div>

                          <div className="distance-slider-div">
                            <Slider
                              min={1}
                              max={distance < 51 ? 50 : distance}
                              value={pending_distance}
                              name="pending_distance"
                              onChange={this.onDistanceSliderChange}
                              handleStyle={handleStyles}
                              trackStyle={trackStyles}
                              railStyle={railStyles}
                            />
                          </div>
                          <div className="apply-filter">
                            <div className="link" onClick={() => this.applyFilter('distance', 'distanceDisplay')}>Apply</div>
                          </div>
                        </div>}
                    </div>
                  </ClickAwayListener>}

                {mode === 'location' &&
                  <ClickAwayListener onClickAway={(e) => this.handleClickAway(e, 'time', 'timeDisplay')}>
                    <div className={`filter-box ${startTime && endTime ? 'filter-box-active' : ''}`} onClick={(e) => this.toggleFilterDisplay(e, 'time', 'timeDisplay')}>
                      {startTime && endTime ? `${formatTime(startTime, true)} - ${formatTime(endTime, true)} ${nextMinyanDay === tomorrowsDayOfWeek ? ' tomorrow' : 'today'}` : 'Time'}
                      {timeDisplay &&
                        <div className="filter time-filter">
                          <div className="time-title">Select a time frame</div>
                          <ul>
                            {Object.keys(nextMinyanDays).map(d => {
                              return (
                                <li key={d} className="filter-li">
                                  <CustomRadio
                                    style={{ height: '24px', width: '35px' }}
                                    checked={pending_nextMinyanDay === d}
                                    onChange={this.handleChange}
                                    value={d}
                                    color="default"
                                    name="pending_nextMinyanDay"
                                  />
                                  <div>{nextMinyanDays[d]}</div>
                                </li>
                              );
                            })}
                          </ul>
                          <div className="ml-16">
                            <div className="timeFlex" >
                              <div>
                                <p>Start time</p>
                                <input
                                  type="time"
                                  id="startTime"
                                  defaultValue={this.state.currentTime}
                                  onFocus={(e) => this.setState({ pending_startTime: e.target.value })}
                                  onChange={(e) => this.setState({ pending_startTime: e.target.value })}
                                />
                                &nbsp;
                              </div>
                              <div>
                                <p>End time</p>
                                <input
                                  type="time"
                                  id="endTime"
                                  defaultValue="23:59:00"
                                  onChange={(e) => {
                                    this.setState({ pending_endTime: e.target.value });
                                  }}
                                  onFocus={(e) => {
                                    this.setState({ pending_endTime: e.target.value });
                                  }}></input>
                              </div>&nbsp;<p style={{ paddingTop: '9px', whiteSpace: 'nowrap' }}><br />click on icons</p></div>
                          </div>
                          <div className="time-filter-actions">
                            <div className="clear-times" onClick={this.clearPendingTimes}>Clear</div>
                            <div className="apply-times" onClick={this.applyTimeFilter}>Apply</div>
                          </div>
                        </div>}
                    </div>
                  </ClickAwayListener>}

                <ClickAwayListener onClickAway={() => this.handleClickAway(null, 'moreFilters', 'moreFiltersDisplay')}>
                  <div
                    className={`filter-box ${dayArray.length || mode === 'rabbi' || mode === 'shul' ? 'filter-box-active' : ''}`}
                    onClick={(e) => this.toggleFilterDisplay(e, 'moreFilters', 'moreFiltersDisplay')}
                  >
                    More filters
                  {moreFiltersDisplay &&
                      <div className="filter more-filter">
                        <div className="more-filter-title">Shul has minyanim on</div>
                        <ul>
                          {Object.keys(days).map(d => {
                            return (
                              <li key={d} className="filter-li">
                                <CustomCheckbox
                                  style={{ height: '24px', width: '35px' }}
                                  checked={((d === '' && !pending_dayArray.length) || pending_dayArray.includes(d))}
                                  onChange={this.handleFilterArrayChange}
                                  value={d}
                                  color="default"
                                  name="pending_dayArray"
                                />
                                <div>{days[d]}</div>
                              </li>
                            );
                          })}
                        </ul>
                        {mode !== 'location' &&
                          <div>
                            <div className="more-filter-title">Search term is contained in</div>
                            <ul>
                              {Object.keys(searchByNameModes).map(m => {
                                return (
                                  <li key={m} className="filter-li">
                                    <CustomRadio
                                      style={{ height: '24px', width: '35px' }}
                                      checked={pending_mode === m}
                                      onChange={this.handleChange}
                                      value={m}
                                      color="default"
                                      name="pending_mode"
                                    />
                                    <div>{searchByNameModes[m]}</div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>}
                        <div className="apply-filter">
                          <div className="link" onClick={this.applyMoreFilter}>Apply</div>
                        </div>
                      </div>}
                  </div>
                </ClickAwayListener>
              </div>

              <div className="flex-align-center reset-filters" onClick={() => this.clearFilters(true)}>
                <i className="material-icons reset-filters mr-4">refresh</i>
                Reset All
              </div>


            </div>
            {/* desktop filters end */}


            {/* START mobile filters and list/map toggle */}
            <div className="desktop-hidden mobile-filter-list-map-toggle">
              <div className="flex">
                {mode === 'location' &&
                  <div
                    className={`mobile-filter-button mobile-filter-button-active`}
                    onClick={() => this.toggleMobileFilterSidebar(true, false, true)}
                  >
                    {`${distance} mi`}
                  </div>}

                {mode === 'location' && startTime && endTime &&
                  <div
                    className={`mobile-filter-button mobile-filter-button-active`}
                    onClick={() => this.toggleMobileFilterSidebar(true, true)}
                  >
                    {`${formatTime(startTime, true)} - ${formatTime(endTime, true)}`}
                  </div>}

                <div
                  className={`mobile-filter-button ${activeFilterCount > 0 ? 'mobile-filter-button-active' : ''}`}
                  onClick={() => this.toggleMobileFilterSidebar(true)}
                >
                  Filters {activeFilterCount > 0 && <span>&nbsp;&middot;&nbsp;{activeFilterCount}</span>}
                </div>

              </div>
              <div className="list-map-toggle">
                {!mapView ? (
                  <div className="flex-align-center" onClick={() => {
                    this.toggleMapView(true)
                  }}>
                    <i className="material-icons list-map-icons">map</i>
                    <span className="">MAP</span>
                  </div>
                ) :
                  (
                    <div className="flex-align-center" onClick={() => this.toggleMapView(false)}>
                      <i className="material-icons list-map-icons">list</i>
                      <span className="">LIST</span>
                    </div>
                  )
                }
              </div>
            </div>

            {/* END mobile filters and list/map toggle */}

            <div className="search-content-separator"></div>

            <div className="search-week-sponsor">
              <div className="mr-16">This Week's Sponsor: {weeklySponsor && weeklySponsor.message ? weeklySponsor.message : 'sponsorship available'}</div>
              <a href="https://campaign.godaven.com/"
                target="_blank" // eslint-disable-line react/jsx-no-target-blank
                rel="noopener"
                className="search-dedication-oppor-link">
                <span className="mobile-hidden">View &nbsp;</span>Dedication Opportunities
              </a>
            </div>

            {!mapView &&
              <div className="results-container">

                {total > 0 && sortIds.length > 0 ?
                  (<div>
                    <div className="total-results">{`${total} Results`}</div>
                    <div className="desktop-hidden mobile-content-separator"></div>
                    <div className="result-cards">
                      {sortIds.map((id, index) => {
                        const {
                          todays_time, type, name, rabbi, nusach: minyanNusach, shul_nusach, formatted_address, phone, location_status,
                          extension, distance, id: shulId, location_point, start_date, temp_shul_confirmed, nextMinyan, minyan_id
                        } = results[id];
                        const showRoshChodeshWarning = nextMinyan && nextMinyan.showRoshChodeshWarning ? true : false;
                        const isMinyanSearch = minyan_id ? true : false;
                        let latitude = null;
                        let longitude = null;
                        if (location_point) {
                          const [lng, lat] = location_point.coordinates;
                          latitude = lat;
                          longitude = lng;
                        }

                        return (
                          <div key={id} className="result-card" onMouseEnter={() => this.highlightShulPin(id)} onMouseLeave={() => this.unHighlightShulPin(id)}>
                            <div className="results-shul-info-column">
                              <PageLink to={Pages.main.shulDetails} params={{ shulId: shulId }} query={{ latitude, longitude, from_search: true }} className="results-shul-name">{name}</PageLink>
                              <div className="results-shul-info">
                                <PageLink to={Pages.main.shulDetails} params={{ shulId: shulId }} query={{ latitude, longitude, from_search: true }} className="results-page-color capitalize-text">
                                  <div className="inline">

                                    {/\d/.test(formatted_address) ? formatted_address.slice(0, formatted_address.lastIndexOf(',')) : formatted_address}
                                    {location_status === 'flagged' &&
                                      <Tooltip enterTouchDelay={10} title="Address may not be accurate"><i className="material-icons location-status-warning">warning</i></Tooltip>}

                                    {(phone || rabbi) && <span className="mobile-hidden">&nbsp; &nbsp;|&nbsp; &nbsp;</span>}

                                  </div>

                                </PageLink>

                                {phone &&
                                  <a href={`tel:${phone}${extension ? ',' + extension : ''}`} className="mobile-hidden results-phone">
                                    {formatPhone(phone, extension)} {rabbi && <span>&nbsp; &nbsp;|&nbsp; &nbsp;</span>}
                                  </a>}

                                {rabbi &&
                                  <PageLink to={Pages.main.shulDetails} params={{ shulId: shulId }} query={{ latitude, longitude, from_search: true }} className="mobile-hidden results-page-color">
                                    {`rabbi ${rabbi}`}
                                  </PageLink>}

                                <div className="desktop-hidden flex-align-center mobile-phone-rabbi">
                                  {phone &&
                                    <a href={`tel:${phone}${extension ? ',' + extension : ''}`} className="results-phone">
                                      {formatPhone(phone, extension)} {rabbi && <span>&nbsp; &nbsp;|&nbsp; &nbsp;</span>}
                                    </a>}

                                  {rabbi &&
                                    <PageLink to={Pages.main.shulDetails} params={{ shulId: shulId }} query={{ latitude, longitude, from_search: true }} className="results-page-color">
                                      {`rabbi ${rabbi}`}
                                    </PageLink>}
                                </div>
                              </div>
                              <PageLink to={Pages.main.shulDetails} params={{ shulId: shulId }} query={{ latitude, longitude, from_search: true }}>
                                {start_date && todaysDate < start_date ?
                                  (<div className="result-card-minyan-message temp-minyan-message">Currently not running</div>)
                                  : start_date && todaysDate >= start_date && !temp_shul_confirmed ?
                                    (<div className="result-card-minyan-message temp-minyan-message">Minyan not confirmed</div>)
                                    : todays_time || nextMinyan ?
                                      (<div className={`result-card-minyan-message upcoming-minyan ${showRoshChodeshWarning ? 'temp-minyan-message' : ''}`}>
                                        {`${todays_time ? tefillahs[type] : nextMinyan.type}
                                    ${todays_time ? formatTime(todays_time, true) : formatTime(nextMinyan.time, true)}
                                    ${isMinyanSearch && nextMinyanDay === tomorrowsDayOfWeek ? 'tomorrow' : 'today'}
                                    ${showRoshChodeshWarning ? 'on non Rosh Chodesh days' : ''}`}
                                        <PageLink
                                          className="suggest-update-link flex-align-middle"
                                          to={Pages.main.suggestShulUpdate}
                                          params={{ shulId }}
                                        >
                                          Suggest Minyan Update
                                          </PageLink>
                                      </div>)
                                      : (<div className="result-card-minyan-message no-upcoming-minyan">No upcoming minyan today
                                        <PageLink
                                          className="suggest-update-link flex-align-middle"
                                          to={Pages.main.suggestShulUpdate}
                                          params={{ shulId }}
                                        >
                                          Suggest Minyan Update
                                          </PageLink>
                                      </div>)}
                              </PageLink>
                            </div>

                            <div className="nusach-action-icons-column">
                              <PageLink className="nusach-temp-minyan-boxes" to={Pages.main.shulDetails} params={{ shulId: shulId }} query={{ latitude, longitude, from_search: true }}>
                                {start_date &&
                                  <div className="results-temp-shul-flag">
                                    Temporary
                                  <i className="mobile-hidden material-icons large-text ml-4">info_outline</i>
                                  </div>}
                                <div className="results-nusach">
                                  {minyanNusach ? minyanNusach.replace(/_/, ' ')
                                    : shul_nusach ? shul_nusach.replace(/_/, ' ')
                                      : 'unspecified'}
                                </div>
                              </PageLink>

                              <div className="flex-align-center">
                                <PageLink to={Pages.main.shulDetails} params={{ shulId: shulId }} query={{ latitude, longitude, from_search: true }}
                                  className="flex-align-center flex-no-shrink mobile-flex-center-columns">
                                  <i className="material-icons view-details-icon">
                                    date_range
                                  </i>
                                  <span className="results-view-icon-text">View details</span>
                                </PageLink>
                                {typeof distance === 'number' &&
                                  <a
                                    className="flex-align-center flex-no-shrink mobile-flex-center-columns"
                                    href={`https://www.google.com/maps?q=${encodeURI(formatted_address)}`}
                                    target="_blank"
                                  >
                                    <i className="material-icons directions-icon">directions</i>
                                    <span className="results-directions-icon-text">{`${Number(distance).toFixed(2)} mi`}</span>
                                  </a>}

                              </div>
                            </div>
                          </div>
                        );
                      })}

                    </div>

                    <div>{renderPagination(activeIndex, num_of_pages, this.toggleResultsPages)}</div>
                  </div>)
                  : loading ?
                    (<div className="loading-div">
                      <Loader />
                      <p className="flex-align-middle">Loading...</p>
                    </div>)
                    : (<div className="no-results flex-center-columns">
                      <p>No minyan found.</p>
                      <p>Try moving the map or pin,</p>
                      <p>adjusting the filters,</p>
                      <p>or double-checking your spelling.</p>
                      <PageLink to={Pages.main.clientAddShul} className="search-add-minyan">
                        <span><i className="material-icons">add</i></span>
                        <span>Add A Minyan</span>
                      </PageLink>
                    </div>)}
              </div>}

          </div>

          <div className={`mobile-hidden map-container ${marginTop ? 'margin-top' : ''} ${fixedMap ? 'fixed-map' : ''}`}>
            {total > 0 && sortIds.length > 0 ?
              (<GoogleMapsComponent
                shulHoverId={shulHoverId}
                searchLat={mode === 'location' ? lat : null}
                searchLng={mode === 'location' ? lng : null}
                locations={pins}
                infoWindowToggle={this.toggleInfoWindow}
                infoWindowArray={this.state.infoWindows}
                containerElement={<div style={{ height: '100%', width: '100%' }} />}
                mapElement={<div style={{ height: '100%', width: '100%' }} />}
                updateLatLng={this.updateLatLng}
                newSearch={this.state.newSearch}
                newSearchFunc={(this.newSearchFunc)}
              />)
              : (<GoogleMapsComponent
                searchLat={mode === 'location' ? lat : null}
                searchLng={mode === 'location' ? lng : null}
                containerElement={<div style={{ height: '100%', width: '100%' }} />}
                mapElement={<div style={{ height: '100%', width: '100%' }} />}
                updateLatLng={this.updateLatLng}
                newSearch={this.state.newSearch}
                newSearchFunc={(this.newSearchFunc)}
              />)}
          </div>

          {mapView &&
            <div className="desktop-hidden map-container">
              {total > 0 && sortIds.length > 0 ?
                (<GoogleMapsComponent
                  searchLat={mode === 'location' ? lat : null}
                  searchLng={mode === 'location' ? lng : null}
                  locations={pins}
                  infoWindowToggle={this.toggleInfoWindow}
                  infoWindowArray={this.state.infoWindows}
                  containerElement={<div style={{ height: '100%', width: '100%' }} />}
                  mapElement={<div style={{ height: '100%', width: '100%' }} />}
                  updateLatLng={this.updateLatLng}
                  newSearch={this.state.newSearch}
                  newSearchFunc={(this.newSearchFunc)}
                />)
                : (<GoogleMapsComponent
                  searchLat={mode === 'location' ? lat : null}
                  searchLng={mode === 'location' ? lng : null}
                  containerElement={<div style={{ height: '100%', width: '100%' }} />}
                  mapElement={<div style={{ height: '100%', width: '100%' }} />}
                  updateLatLng={this.updateLatLng}
                  newSearch={this.state.newSearch}
                  newSearchFunc={(this.newSearchFunc)}
                />)}
            </div>}

        </div>

        <ToastContainer />
      </React.Fragment>
    );
  }
}

function formatTime(time, withSuffix = false) {
  const suffix = time.slice(0, 2) >= 12 ? "PM" : "AM";
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  return `${hours}${minutes} ${withSuffix ? suffix : ''}`;
}

function formatPhone(number, ext) {
  const strippedNum = number.replace(/[^0-9]+/g, '');
  return `(${strippedNum.slice(0, 3)}) ${strippedNum.slice(3, 6)}-${strippedNum.slice(6)}${ext ? ` ext ${ext}` : ''}`;
}

function renderPagination(activeIndex, numOfPages, searchFunction) {
  const circles = [];
  const start = activeIndex === 1 || numOfPages < 5 ? 1
    : numOfPages === activeIndex && numOfPages > 2 ? activeIndex - 2
      : activeIndex - 1;
  const end = numOfPages < 5 ? numOfPages
    : activeIndex === numOfPages ? activeIndex
      : activeIndex === 1 ? activeIndex + 2
        : activeIndex + 1;
  for (let i = start; i <= end; i++) {
    circles.push(<span
      key={i}
      className={`pagination-circle ${activeIndex === i ? 'pagination-active' : ''}`}
      onClick={activeIndex !== i ? searchFunction : null} data-pn={i}>{i}</span>
    );
  }
  return (
    <div className="pagination-container">
      <span
        className={`${activeIndex < 2 ? 'pagination-disabled' : 'link'}`}
        onClick={activeIndex > 1 ? searchFunction : null} data-pn={activeIndex - 1}
      >
        {'<<'}
      </span>
      {circles}
      <span>{numOfPages > 4 ? '...' : ''}</span>
      {numOfPages > 4 ?
        (<span
          className="pagination-circle"
          onClick={activeIndex !== numOfPages ? searchFunction : null}
          data-pn={numOfPages}
        >
          {numOfPages}
        </span>)
        : ''}
      <span
        className={`${activeIndex === numOfPages ? 'pagination-disabled' : 'link'}`}
        onClick={activeIndex < numOfPages ? searchFunction : null} data-pn={activeIndex + 1}
      >
        {'>>'}
      </span>
    </div>
  );
}

function renderDialog(CurrentFilterName, cancelFunc, okFunc) {
  const incompatibleFilter = CurrentFilterName === 'time' ? 'Shul has minyanim on' : 'Time';
  return (
    <div className="dialog-container">
      <i className="material-icons dialog-warning-icon">error_outline</i>
      <div className="dialog-title">Selected Filters Are Incompatible</div>
      <div className="dialog-text">
        <span className="bold-text">{`${incompatibleFilter} Filter`}</span> is incompatible with your current selection.
      </div>
      <div className="dialog-text">Would you like to clear it and continue?</div>
      <div className="dialog-button-div">
        <div className="dialog-cancel-button" onClick={cancelFunc}>cancel</div>
        <div className="dialog-ok-button" onClick={okFunc}>yes, clear</div>
      </div>
    </div>
  );
}