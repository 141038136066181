import React from "react";
import {
  APIClient,
  createToast,
  PageLink,
  Navigation
} from "../../lib";
import Pages from '../';
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/add-edit-shul.css";
import "../../assets/css/pages/view-shul.css";
import "../../assets/css/pages/view-sponsors.css";
import "react-toastify/dist/ReactToastify.css";
import { DeleteModal } from "../../components";

export default class ViewSponsor extends React.PureComponent {
  state = {
    mode: 'daily',
    sponsors: []
  }

  async componentDidMount() {
    await this.props.accountActions.checkUsersAuth(true);

    if(!this.props.authorized){
      Navigation.redirect('/?displayLogin=true');
      return;
    }

    this.getDailySponsors();
  }

  getDailySponsors = async () => {
    const response = await APIClient.get('/daily-sponsors/future-sponsors')
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      return createToast(response.err.response.data.code);
    } else {
      this.setState({ sponsors: response.data });
    }
  }

  getWeeklySponsors = async () => {
    const response = await APIClient.get('/weekly-sponsors/future-sponsors')
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      return createToast(response.err.response.data.code);
    } else {
      this.setState({ sponsors: response.data });
    }
  }

  deleteDailySponsor = async (sponsorId) => {
    const response = await APIClient.delete(`/daily-sponsors/${sponsorId}`)
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      return createToast(response.err.response.data.code);
    } else {
      createToast(null, 'you have successfully deleted a daily sponsor', "success");
      this.setState(prevState => ({
        sponsors: prevState.sponsors.filter(s => s.id !== sponsorId)
      }));
    }
  }

  deleteWeeklySponsor = async (sponsorId) => {
    const response = await APIClient.delete(`/weekly-sponsors/${sponsorId}`)
      .catch((err) => ({ err }));

    if (response.err) {
      console.error(response.err);
      return createToast(response.err.response.data.code);
    } else {
      createToast(null, 'you have successfully deleted a weekly sponsor', "success");
      this.setState(prevState => ({
        sponsors: prevState.sponsors.filter(s => s.id !== sponsorId)
      }));
    }
  }

  handleDeleteSponsor = () => {
    const sponsorId = this.props.record.id;

    if (this.state.mode === 'daily') {
      return this.deleteDailySponsor(sponsorId);
    } else {
      return this.deleteWeeklySponsor(sponsorId);
    }
  }

  toggleSponsorMode = () => {
    this.setState(prevState => ({
      mode: prevState.mode === 'daily' ? 'weekly' : 'daily'
    }),
      () => {
        const mode = this.state.mode;
        if(mode === 'daily'){
          return this.getDailySponsors();
        } else {
          return this.getWeeklySponsors();
        }
      }
    );
  }

  openModal = (id) => {
    this.props.uiActions.toggleDeleteModal(true, id, "sponsor");
  }

  render() {
    const {
      state: {
        mode,
        sponsors
      }
    } = this;

    return (
      <React.Fragment>
        <div className="full-page-modal">
          <PageLink
            className="full-page-modal-close link"
            to={Pages.admin.dashboard}>
            <i className="material-icons sponsor-close">close</i>
          </PageLink>
          <div className="container">
            <h1 className="xl-text text-500 bordered-title">
              View Sponsors
              </h1>

            <div className="mb-32 flex-align-center">
              <span
                className={`sponsor-mode-toggle-label
                  ${mode === 'daily' ? 'sponsor-mode-toggle-label-active' : ''}`}
              >
                Daily Sponsors
              </span>
              <i
                className="material-icons sponsor-mode-toggle"
                onClick={this.toggleSponsorMode}
              >
                {mode === 'daily' ? 'toggle_off' : 'toggle_on'}
              </i>
              <span
                className={`sponsor-mode-toggle-label
                  ${mode === 'weekly' ? 'sponsor-mode-toggle-label-active' : ''}`}
              >
                Weekly Sponsors
              </span>
            </div>
            <div className="">
              <div className={`sponsors-table-header ${mode === 'daily' ? 'daily-header' : 'weekly-header'}`}>
                <p className="medium-text accent-text-light flex-left-center">Sponsor Name</p>

                {mode === 'daily' && <p className="medium-text accent-text-light flex-left-center">Date</p>}
                {mode === 'weekly' && <p className="medium-text accent-text-light flex-left-center">Start Date</p>}
                {mode === 'weekly' && <p className="medium-text accent-text-light flex-left-center">End Date</p>}
                <p className="medium-text accent-text-light flex-left-center">Message</p>
              </div>

              {sponsors.length ?
                sponsors.map(sponsor => {
                  const { id, sponsor_name, date, start_date, end_date, message } = sponsor;
                  return (
                    <div key={id} className={`sponsors-table-row ${mode === 'daily' ? 'daily-row' : 'weekly-row'}`}>
                      <p className="flex-left-center">{sponsor_name}</p>
                      {mode === 'daily' && <p className="flex-left-center">{date}</p>}
                      {mode === 'weekly' && <p className="flex-left-center">{start_date}</p>}
                      {mode === 'weekly' && <p className="flex-left-center">{end_date}</p>}
                      <p className="flex-left-center">{message}</p>
                      <div className="link flex-right-center">
                        <PageLink
                          to={Pages.admin.editSponsor}
                          params={{ mode, sponsorId: id  }}
                        >
                          <i className="material-icons sponsor-icons flex-right-center">
                            edit
                        </i>
                        </PageLink>
                        <i
                          className="material-icons sponsor-icons flex-right-center"
                          onClick={() => this.openModal({id})}
                        >
                          delete
                        </i>
                      </div>
                    </div>
                  );
                })
                : "No sponsors to display"}
            </div>

            <div className="flex-align-middle mt-32 mb-32">
              <PageLink
                to={Pages.admin.addSponsor}
                className="btn btn-accent uppercase-text flex-align-middle back-to-add-sponsor"
              >
                back to add sponsor
              </PageLink>
            </div>

            <DeleteModal
              message={'Are you sure you want to delete this sponsor?'}
              deleteRecord={this.handleDeleteSponsor}
            />
            <ToastContainer />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

