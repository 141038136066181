export const cityCountryMaping = {
  Beitar: 'Israel',
  beitar: 'Israel',
  'beitar ilit': 'Israel',
  'Betar Illit': 'Israel',
  [`Hashmona'im`]: 'Israel',
  [`Giv'at Ze'ev`]: 'Israel',
  Kdumim: 'Israel',
  Oranit: 'Israel',
  Jerusalem: 'Israel',
  [`Modi'in Ilit`]: 'Israel',
  [`ביתר עילית`]: 'Israel',
  [`Alon Shvut`]: 'Israel',
  Efrat: 'Israel',
  'Kiryat Arba': 'Israel',
  Elkana: 'Israel',
};