import { cityCountryMaping } from './countrylessCitys';
export function googlePlaceToAddress(place) {
  //console.log(place)
  if (!place.address_components) return place;
  const {
    geometry,
    address_components,
    formatted_address,
    place_id
  } = place;
  const lat = geometry.location.lat();
  const lng = geometry.location.lng();
  const location = {
    lat,
    lng,
    formatted_address,
    google_address_object: place,
    google_place_id: place_id
  };

  let slot = 0;
  while (!location.city && slot < 3) {
    parseAddressConponents(address_components, location, slot);
    slot++;
  }

  delete location.locality;
  delete location.neighborhood;
  delete location.sublocality;

  if (!location.country) {
    const city = location.city;
    if (cityCountryMaping[city]) {
      location.country = cityCountryMaping[city];
    }
  }
  //console.log(location)
  return location;
};

function parseAddressConponents(addressComponents, location, slot) {
  addressComponents.forEach(function (c) {
    switch (c.types[slot]) {
      case 'street_number':
        location.streetNumber = c.short_name;
        break;
      case 'route':
        location.streetName = c.short_name;
        break;
      case 'neighborhood':
        location.neighborhood = c.short_name;
        location.city = c.short_name;
        break;
      case 'locality':
        location.locality = c.short_name;
        location.city = !location.neighborhood ? c.short_name : location.city;
        break;
      case 'sublocality':
        location.sublocality = c.short_name;
        location.city = !location.locality && !location.city && !location.neighborhood ? c.short_name : location.city;
        break;
      case 'administrative_area_level_3':  // North Hollywood or Los Angeles?
        location.city = (!location.neighborhood && !location.locality && !location.sublocality) ? c.short_name : location.city;
        break;
      case 'postal_town':
        location.city = (!location.neighborhood && !location.locality && !location.sublocality) ? c.short_name : location.city;
        break;
      case 'administrative_area_level_1':     //  Note some countries don't have states
        location.state = c.short_name;
        break;
      case 'postal_code':
        location.zip = c.short_name;
        break;
      case 'country':
        location.country = c.long_name === 'United States' ? 'USA' : c.long_name;
        break;
      default:
    }
  });
  return location;
}