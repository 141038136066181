import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ShulActions } from '../../state';
import ShulDetails from './ShulDetails';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    shul: {
      loading,
      shulInfo,
      shulCovidPolicy,
    },
    minyan: {
      groupedByDayMinyanim
    },
    contact: {
      groupedContacts
    },
    sponsor: {
      weeklySponsor
    },
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    loading,
    shulInfo,
    groupedByDayMinyanim,
    groupedContacts,
    weeklySponsor,
    shulCovidPolicy
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return bindActionCreators(ShulActions, dispatch);
})(ShulDetails);
