import {
  APIClient,
  Navigation,
  createToast,
} from '../../lib';

export const AccountActions = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_FAILURE_RESET: 'LOGIN_FAILURE_RESET',
  LOGOUT: 'LOGOUT',
  USER_CHECK_AUTH: 'USER_CHECK_AUTH',
  BLOCK_COVID_MODAL: 'BLOCK_COVID_MODAL',

  login(loginInfo) {
    return async (dispatch, getState) => {

      const response = await APIClient.post('/accounts/login', loginInfo)
        .catch(e => {
          console.error(e);
          if (e.response && e.response.status === 404) {
            dispatch({ type: AccountActions.LOGIN_FAILURE });
          }
        });

      if (response && response.data) {
        const { roles, shulIds, userId } = response.data;
        dispatch({
          type: AccountActions.LOGIN_SUCCESS,
          roles,
          shulIds,
          userId
        });
        if (roles.includes('application_admin')) {
          return Navigation.redirect('/admin');
        } else if (shulIds.length) {
          return Navigation.redirect(`/my-shuls/view`);
        } else {
          return createToast(null, 'We are having trouble finding your Minyanim please contact support for help.');
        }
      }
    };
  },

  passwordlessLogin(jwt) {
    return async (dispatch, getState) => {
      const options = {
        headers: { 'Authorization': `Bearer ${jwt}` }
      };

      const response = await APIClient.get('/accounts/passwordless-login', options).catch(console.error);

      if (response && response.data) {
        const { roles, shulIds, userId } = response.data;
        dispatch({
          type: AccountActions.LOGIN_SUCCESS,
          roles,
          shulIds,
          userId
        });
      }
    };
  },

  forgotPassword(email) {
    return async (dispatch, getState) => {
      await APIClient.post('/accounts/forgot-password', email).catch(console.error);
    };
  },

  resetPassword(resetOBJ) {
    return async (dispatch, getState) => {
      const response = await APIClient.post('/accounts/reset-password', resetOBJ).catch(console.error);

      if (response && response.data) {
        const { roles, shulIds, userId } = response.data;
        dispatch({
          type: AccountActions.LOGIN_SUCCESS,
          roles,
          shulIds,
          userId
        });

        if (roles.includes('application_admin')) {
          Navigation.redirect('/admin');
        } else if (shulIds.length) {
          Navigation.redirect(`/my-shuls/view`);
        } else {
          createToast(null, 'We are having trouble finding your shul, please contact support for help');
        }
      }
    };
  },

  resetLoginFailure() {
    return { type: AccountActions.LOGIN_FAILURE_RESET };
  },

  logoutUser() {
    return async (dispatch, getState) => {
      const response = await APIClient.post('/accounts/logout').catch(console.error);

      if (response && response.data) {
        dispatch({ type: AccountActions.LOGOUT });
      }
    };
  },

  addAccount(account, isAppAdminAccount = false) {
    return async (dispatch, getState) => {
      const response = await APIClient.post(`/accounts?app_admin=${isAppAdminAccount}`, account).catch(console.error);

      if (response && response.data) {
        const status = response.data.status;
        if (status === 'email not sent') {
          createToast(null, 'Account added but email failed to go out, \n please contact support', 'warning');
        } else {
          createToast(null, 'Account added successfully', 'success');
        }
        return true;
      }

      return false;
    };
  },

  editAccount(userId, accountData) {
    return async (dispatch, getState) => {
      const response = await APIClient.put(`/accounts/${userId}`, accountData).catch(console.error);

      if (response && response.data) {
        createToast(null, 'Account successfully updated', 'success');
      }
    };
  },

  getUserById(userId) {
    return async () => {
      const response = await APIClient.get(`/accounts/${userId}`).catch(console.error);

      if (response && response.data) {
        return response.data;
      }
      return null;
    };
  },

  checkUsersAuth(app_admin_clearance = false) {
    return async (dispatch, getState) => {
      const response = await APIClient.get(`/accounts/check-auth?app_admin_clearance=${app_admin_clearance}`).catch(console.error);

      if (response && response.data) {
        dispatch({
          type: AccountActions.USER_CHECK_AUTH,
          authorized: response.data.authorized
        });
      }
    };
  },

  deleteShulAdmin(accountShulRoleId) {
    return async (dispatch, getState) => {
      const response = await APIClient.delete(`/accounts/account-shul-roles/${accountShulRoleId}`).catch(console.error);

      if (response && response.data) {
        return true;
      }
      return false;
    };
  },
  blockCovidModal() {
    return async (dispatch, getState) => {
      dispatch({ type: AccountActions.BLOCK_COVID_MODAL });
    };
  },

};
