import React from 'react';
import { Helmet } from "react-helmet";

export class HelmetHeader extends React.PureComponent {
  render() {
    const {
      customTitle,
      customDescription
    } = this.props;
    const description = customDescription ? customDescription
      : 'The worldwide searchable database of Orthodox Minyan information, including minyan locations (Shuls), times, and contact information.';

    return (
      <Helmet>
        {customTitle && <title>{customTitle}</title>}
        <meta name="description" content={description} />
      </Helmet>
    );
  }
}