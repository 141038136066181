import Dashboard from "./Dashboard.vm";
import AddAccount from "./AddAccount.vm";
import AddShul from "./AddShul.vm";
import EditShul from "./EditShul.vm";
import ViewShul from "./ViewShul.vm";
import Minyan from "./Minyan.vm";
import Contact from "./Contact.vm";
import Sponsor from "./Sponsor.vm";
import ViewSponsor from "./ViewSponsor.vm";
import EditAccount from "./EditAccount.vm";
import MinyanSuggestions from "./MinyanSuggestions.vm";
import Event from "./Event.vm";

import { addPageLayouts } from "../../lib";

export const AdminPages = addPageLayouts("admin", {
  dashboard: {
    anon: false,
    path: "/admin",
    pathExact: true,
    type: 'DASHBOARD',
    view: Dashboard,
    title: 'Dashboard'
  },
  addAppAdminAccount: {
    anon: false,
    path: "/admin/add-app-admin-account",
    pathExact: true,
    type: 'ADD_APP_ADMIN_ACCOUNT',
    view: AddAccount,
    title: 'Add App Admin Account',
    layout: null
  },
  addShul: {
    anon: false,
    path: "/admin/add-shul",
    pathExact: true,
    type: 'ADD_SHUL',
    view: AddShul,
    title: 'Add Shul'
  },
  editShul: {
    anon: false,
    path: "/admin/shul/:shulId/edit",
    pathExact: true,
    type: 'EDIT_SHUL',
    view: EditShul,
    title: "Edit Shul"
  },
  addMinyan: {
    anon: false,
    path: "/admin/shul/:shulId/add-minyan",
    pathExact: true,
    type: "ADD_MINYAN",
    view: Minyan,
    title: "Add Minyan",
    layout: null
  },
  editMinyan: {
    anon: false,
    path: "/admin/shul/:shulId/minyan/:minyanId/edit",
    pathExact: true,
    type: 'EDIT_MINYAN',
    view: Minyan,
    title: 'Edit Minyan',
    layout: null
  },
  addContact: {
    anon: false,
    path: "/admin/shul/:shulId/add-contact",
    pathExact: true,
    view: Contact,
    type: 'ADD_CONTACT',
    title: 'Add Contact',
    layout: null
  },
  editContact: {
    anon: false,
    path: "/admin/shul/:shulId/contact/:contactId/edit",
    pathExact: true,
    view: Contact,
    type: 'EDIT_CONTACT',
    title: 'Edit Contact',
    layout: null
  },
  addShulAdminAccount: {
    anon: false,
    path: "/admin/shul/:shulId/add-shul-admin-account",
    pathExact: true,
    type: 'ADD_SHUL_ADMIN_ACCOUNT',
    view: AddAccount,
    title: 'Add Shul Admin Account',
    layout: null
  },
  viewShul: {
    anon: false,
    path: "/admin/shul/:shulId/view",
    pathExact: true,
    type: 'VIEW_SHUL',
    view: ViewShul,
    title: 'View Shul'
  },
  addSponsor: {
    anon: false,
    path: "/admin/add-sponsor",
    pathExact: true,
    type: 'ADD_SPONSOR',
    view: Sponsor,
    layout: null,
  },
  editSponsor: {
    anon: false,
    path: "/admin/edit-sponsor/:mode/:sponsorId",
    pathExact: true,
    type: 'EDIT_SPONSOR',
    view: Sponsor,
    layout: null,
  },
  viewSponsors: {
    anon: false,
    path: "/admin/view-sponsors",
    pathExact: true,
    type: 'VIEW_SPONSORS',
    view: ViewSponsor,
    layout: null,
  },
  editAccount: {
    anon: false,
    path: "/admin/edit-account",
    pathExact: true,
    type: 'EDIT_ACCOUNT',
    view: EditAccount,
    title: 'Edit Account',
    layout: null
  },
  addEvent: {
    anon: false,
    path: "/admin/shul/:shulId/add-event",
    pathExact: true,
    type: "ADD_EVENT",
    view: Event,
    title: "Add Event",
    layout: null
  },
  editEvent: {
    anon: false,
    path: "/admin/shul/:shulId/edit-event/:eventId",
    pathExact: true,
    type: "EDIT_EVENT",
    view: Event,
    title: "Edit Event",
    layout: null
  },
  minyanSuggestions: {
    anon: false,
    path: "/admin/minyan-suggestions",
    pathExact: true,
    type: 'MINYAN_SUGGESTIONS',
    view: MinyanSuggestions,
    title: 'Minyan Suggestions',
    layout: null
  },
});
export default AdminPages;

export const AdminArea = {
  pages: AdminPages,
};
