import React from "react";
import { Layout, EventsCardCompact } from "../../components";
import "../../assets/css/pages/view-shul.css";
import "../../assets/css/pages/add-edit-shul.css";
import backarrow from "../../assets/images/back_arrow.svg";
import { PageLink, Navigation } from "../../lib";
import Pages from "../";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormControlLabel, Switch } from "@material-ui/core";

export default class ViewShul extends React.PureComponent {
  state = {
    pending: false,
    superAdmin: false,
    activeShulId: null
  };

  async componentDidMount() {
    await this.props.accountActions.checkUsersAuth();
    if (!this.props.authorized) {
      Navigation.redirect("/?displayLogin=true");
      return;
    }

    const {
      pageRoute: {
        params: { shulId },
        query: { activeShulId, pending },
        key
      },
      roles,
      shulIds,
      actions: { getShulById, getMyShuls },
      eventActions
    } = this.props;

    eventActions.getEvents(shulId || this.state.activeShulId || shulIds[0]);
    if (
      roles.includes("application_admin") &&
      !key.includes("my-shuls") &&
      shulId
    ) {
      this.setState({ superAdmin: true });

      getShulById(
        parseInt(shulId || this.state.activeShulId || shulIds[0], 10)
      );

      if (pending) {
        this.setState({ pending: true });
      }
    } else {
      getMyShuls();
      getShulById(
        activeShulId
          ? +activeShulId
          : shulId || this.state.activeShulId || shulIds[0]
      );
      this.setState({
        activeShulId: activeShulId
          ? +activeShulId
          : shulId || this.state.activeShulId || shulIds[0]
      });
    }
    window.scrollTo(0, 0);
  }

  adminUpdateStatus = e => {
    const id =
      e.target.attributes["data-id"] ||
      e.target.parentElement.attributes["data-id"];
    const status =
      e.target.attributes["data-status"] ||
      e.target.parentElement.attributes["data-status"];
    this.props.actions.updateShulStatus({
      id: parseInt(id.value, 10),
      status: status.value
    });

    Navigation.goBack();
  };

  toggleSidebar = () => {
    this.props.uiActions.toggleSidebar();
  };

  changeShulDisplay = shulId => {
    this.props.actions.getShulById(shulId);
    this.setState({ activeShulId: shulId });
    this.props.eventActions.getEvents(shulId);
  };

  render() {
    const {
      props: {
        pageRoute: {
          key,
          params: { shulId: urlShulId }
        },
        shulIds = [],
        myShuls,
        shulInfo = {},
        shulInfo: {
          name = "",
          shul_nusach = "",
          start_date = "",
          end_date = "",
          formatted_address = "",
          website_url = "",
          phone = "",
          extension = "",
          email = ""
        },
        contacts,
        actions
      },
      state: { pending, superAdmin, activeShulId }
    } = this;
    const minyanTypes = {
      shachris: {
        name: "Shacharis",
        minyanims: "shacharisMinyanims",
        sortedIds: "shacharisSortedIds"
      },
      mincha: {
        name: "Mincha",
        minyanims: "minchaMinyanims",
        sortedIds: "minchaSortedIds"
      },
      mariv: {
        name: "Maariv",
        minyanims: "maarivMinyanims",
        sortedIds: "maarivSortedIds"
      }
    };
    const shulId = superAdmin
      ? urlShulId
      : activeShulId
      ? activeShulId
      : shulIds[0];

    return (
      <Layout onDashboard={!superAdmin ? true : false}>
        {shulInfo && Object.keys(shulInfo).length && (
          <React.Fragment>
            <div
              className={`view-shul-outer-wraper ${
                !superAdmin ? "view-shul-outer-wraper-my-shuls" : ""
              }`}
            >
              {!superAdmin && (
                <div className="mobile-hidden my-shuls-sidebar">
                  <div className="my-shuls-sidebar-header flex-align-middle">
                    MY SHULS
                  </div>
                  {myShuls.map(shul => {
                    return (
                      <div
                        key={shul.id}
                        className={`my-shuls-sidebar-shul-box ${
                          activeShulId === shul.id ? "my-shuls-active" : ""
                        }`}
                        onClick={() => this.changeShulDisplay(shul.id)}
                      >
                        {shul.name}
                      </div>
                    );
                  })}
                </div>
              )}

              <div
                className={`${
                  !superAdmin ? "view-shul-my-shuls-info-column" : "full-width"
                }`}
              >
                <header className="view-shul-mobile-header">
                  <div className="container flex-justify-space">
                    <div className="mobile-hidden">
                      <PageLink
                        to={Pages.admin.dashboard}
                        className={!superAdmin ? "hide" : ""}
                      >
                        <div className="back-to-dash link">
                          <img src={backarrow} alt="back-to-dashboard" />
                          <span>Back To Dashboard</span>
                        </div>
                      </PageLink>
                      <div className="flex-align-center">
                        <h1
                          className="mobile-hidden capitalize-text"
                          onClick={() =>
                            window.open(`/shul-details/${shulId}`, "_blank")
                          }
                        >
                          {name}
                        </h1>
                        {shul_nusach && (
                          <div className="capitalize-text temp-minyan-box flex-align-middle">
                            {shul_nusach.replace(/_/, " ")}
                          </div>
                        )}
                        {start_date && end_date && (
                          <div className="temp-minyan-box flex-align-middle">
                            Temporary
                          </div>
                        )}
                        <FormControlLabel
                          control={
                            <Switch
                              defaultChecked={shulInfo.is_public}
                              onClick={e =>
                                actions.updateShul({
                                  id: +shulId,
                                  is_public: e.target.checked
                                })
                              }
                            />
                          }
                          label="Public Search"
                        />
                      </div>

                      <div className="admin-shul-info mobile-hidden">
                        <address className="capitalize-text">
                          {formatted_address}
                        </address>
                        {(website_url || phone || email) && (
                          <div>
                            {website_url && website_url}
                            {website_url && (email || phone) && (
                              <span className="pipe">|</span>
                            )}
                            {email && email}
                            {email && phone && <span className="pipe">|</span>}
                            {phone && formatPhone(phone, extension)}
                          </div>
                        )}

                        {/* implement when we add images capability */}
                        {/* {shulInfo.minyan_location_images && shulInfo.minyan_location_images.length > 0 && (
                            <React.Fragment>
                              <span className="pipe">|</span>
                              <span>View Images</span>
                            </React.Fragment>
                          )} */}
                      </div>
                    </div>

                    {!superAdmin ? (
                      <div onClick={this.toggleSidebar}>
                        <i className="material-icons desktop-hidden">menu</i>
                      </div>
                    ) : (
                      <div className="desktop-hidden text-500 mob-large-text flex-left-center">
                        <PageLink to={Pages.admin.dashboard}>
                          <i className="material-icons mobile-back-arrow">
                            arrow_back
                          </i>
                        </PageLink>
                        Shul Profile
                      </div>
                    )}
                    {!pending && (
                      <PageLink
                        className={`${!superAdmin ? "mobile-hidden" : ""}`}
                        to={
                          key.includes("my-shuls")
                            ? Pages.shulAdmin.myShulsEditShul
                            : Pages.admin.editShul
                        }
                        params={{ shulId: shulId || 0 }}
                      >
                        <button
                          className="btn btn-light"
                          id="view-shul-my-shuls-edit-button"
                        >
                          EDIT
                        </button>
                      </PageLink>
                    )}

                    {pending && (
                      <div className="update-shul-status-btns flex-align-middle">
                        <button
                          className="btn btn-approve flex-align-middle"
                          data-id={shulInfo.id}
                          data-status="approved"
                          onClick={this.adminUpdateStatus}
                        >
                          <i className="material-icons text-18">done</i>
                          approve
                        </button>
                        <button
                          className="btn btn-deny flex-align-middle"
                          data-id={shulInfo.id}
                          data-status="denied"
                          onClick={this.adminUpdateStatus}
                        >
                          <i className="material-icons text-18">clear</i>
                          deny
                        </button>
                      </div>
                    )}

                    {!superAdmin && (
                      <div className="desktop-hidden my-shuls-mobile-title">
                        My Shuls
                      </div>
                    )}
                  </div>
                </header>
                <div
                  className={`container view-shul-cards ${
                    !superAdmin ? "view-shul-cards-my-shuls" : ""
                  }`}
                >
                  {/* start mobile */}
                  {!superAdmin && (
                    <div className="desktop-hidden myshuls-list mobile-full-width">
                      <div className="mobile-my-shuls-message">
                        Select a shul to view
                      </div>
                      {myShuls.map(shul => {
                        return (
                          <div
                            key={shul.id}
                            className={`my-shuls-sidebar-shul-box ${
                              activeShulId === shul.id ? "my-shuls-active" : ""
                            }`}
                            onClick={() => this.changeShulDisplay(shul.id)}
                          >
                            {activeShulId === shul.id && (
                              <i className="material-icons">chevron_right </i>
                            )}
                            {shul.name}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {!superAdmin && (
                    <div className="desktop-hidden">
                      <hr />
                      <PageLink
                        className="desktop-hidden my-shuls-edit-button-mobile"
                        to={Pages.shulAdmin.myShulsEditShul}
                        params={{ shulId: shulId || 0 }}
                      >
                        <button
                          className="btn btn-light"
                          id="view-shul-my-shuls-edit-button"
                        >
                          EDIT
                        </button>
                      </PageLink>
                    </div>
                  )}

                  <div
                    className="desktop-hidden card mobile-full-width"
                    id="mobile-full-width"
                  >
                    <div className="mb-16">
                      <h1 className="mob-text-20 capitalize-text">{name}</h1>
                      {(shul_nusach || (start_date && end_date)) && (
                        <div className="mt-4 flex">
                          {shul_nusach && (
                            <span className="temp-minyan-box flex-align-middle">
                              {shul_nusach.replace(/_/, " ")}
                            </span>
                          )}
                          {start_date && end_date && (
                            <span className="temp-minyan-box flex-align-middle">
                              Temporary
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <div className="flex-left-center mb-6">
                        <i className="material-icons mob-medium-text">
                          location_on
                        </i>
                        <address className="mob-xs-text ml-8 capitalize-text">
                          {formatted_address}
                        </address>
                      </div>
                      {website_url && (
                        <div className="flex-left-center mb-6">
                          <i className="material-icons mob-medium-text">
                            language
                          </i>
                          <div className="mob-xs-text ml-8">
                            {shulInfo.website_url}
                          </div>
                        </div>
                      )}
                      {email && (
                        <div className="flex-left-center mb-6">
                          <i className="material-icons mob-medium-text">
                            email
                          </i>
                          <div className="mob-xs-text ml-8">{email}</div>
                        </div>
                      )}
                      {phone && (
                        <div className="flex-left-center mb-6">
                          <i className="material-icons mob-medium-text">
                            phone
                          </i>
                          <div className="mob-xs-text ml-8">
                            {formatPhone(phone, extension)}
                          </div>
                        </div>
                      )}
                      {/* {shulInfo.minyan_location_images && shulInfo.minyan_location_images.length > 0 && (
                    <div>View Images</div>
                  )} */}
                    </div>
                  </div>
                  {/* end mobile */}

                  <div
                    className={`view-shul-minyanim ${
                      !superAdmin ? "my-shuls" : ""
                    }`}
                  >
                    {Object.keys(minyanTypes).map(type => {
                      const minyanim = this.props[minyanTypes[type].minyanims];
                      const sortedIds = this.props[minyanTypes[type].sortedIds];
                      return (
                        <div
                          key={type}
                          className="card mb-32 mobile-full-width"
                        >
                          <h2 className="large-text mob-medium-text uppercase-text">
                            {`${minyanTypes[type].name} Minyanim`}
                          </h2>

                          {sortedIds && sortedIds.length ? (
                            <div className="">
                              {sortedIds.map(id => renderMinyan(minyanim[id]))}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className={`view-shul-contacts-notes ${
                      !superAdmin ? "my-shuls" : ""
                    }`}
                  >
                    <EventsCardCompact />

                    <div className="view-shul-contacts card mobile-full-width">
                      <h2 className="large-text mob-medium-text uppercase-text">
                        Display Contacts
                      </h2>
                      <div className="">
                        {contacts &&
                          Object.keys(contacts).length > 0 &&
                          Object.keys(contacts).map(contactId => {
                            return renderContact(contacts[contactId]);
                          })}
                      </div>
                    </div>
                    <div className="view-shul-notes card mobile-full-width mobile-mb-40">
                      <h2 className="large-text mob-medium-text uppercase-text">
                        Notes
                      </h2>
                      <div className="view-notes">
                        <p className="accent-text">{shulInfo.notes}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        <ToastContainer />
      </Layout>
    );
  }
}

function renderMinyan(minyan) {
  const {
    id,
    days,
    special_days_included,
    special_days_only,
    time_at,
    offset_minutes,
    zman_type,
    start_date,
    end_date
  } = minyan;
  return (
    <div key={id} className="table-row">
      <div className="flex-left-center-columns capitalize-text accent-text">
        <p className="accent-text mob-xs-text flex-left-center">
          {" "}
          {days.join(", ")}
        </p>
        {special_days_included.length > 0 && (
          <p className="xs-text mob-text-6 mt-4 minyan-days">
            Includes: {special_days_included.join(", ")}
          </p>
        )}
        {special_days_only.length > 0 && (
          <p className="xs-text mob-text-6 mt-4 minyan-days">
            Only: {special_days_only.join(", ")}
          </p>
        )}
      </div>
      <div className="accent-text flex-left-center mob-xs-text">
        {time_at ? (
          getMinyanTime(time_at)
        ) : zman_type ? (
          <div>
            <span>{getOffestText(offset_minutes)}</span>
            <span className="capitalize-text">
              {zman_type.name.replace(/_/, " ")}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="accent-text mob-xs-text flex-left-center">
        {start_date && end_date
          ? `${formatDate(start_date)} - ${formatDate(end_date)}`
          : "All year round"}
      </div>
    </div>
  );
}

function getMinyanTime(time) {
  const suffix = time.slice(0, 2) >= 12 ? "PM" : "AM";
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  return hours + minutes + " " + suffix;
}

function getOffestText(offsetMinutes) {
  let offsetText;
  switch (true) {
    case offsetMinutes === 0:
      offsetText = `at `;
      break;
    case offsetMinutes > 0:
      offsetText = `${offsetMinutes} minutes after `;
      break;
    default:
      offsetText = `${-offsetMinutes} minutes before `;
  }
  return offsetText;
}

function renderContact(contact) {
  const {
    id,
    first_name,
    last_name,
    title,
    minyan_role,
    display_emails = [],
    display_phones = []
  } = contact;
  return (
    <div key={id} className="table-row">
      <div className="flex-left-center-columns capitalize-text mobile-pb-8">
        <p className="bold-text small-text">
          {title} {first_name} {last_name}
        </p>
        <p className="accent-text xs-text">{minyan_role.replace(/_/, " ")}</p>
      </div>
      <div className="flex-left-center-columns small-text accent-text">
        {display_phones.map(phone => {
          return (
            <div key={phone.id} className="flex-left-center">
              <p className="flex-left-center">
                {formatPhone(phone.number, phone.extension)}
              </p>
            </div>
          );
        })}
      </div>
      <div className="flex-left-center-columns small-text accent-text">
        {display_emails.map(email => {
          return (
            <div key={email.id} className="flex-left-center">
              <p className="flex-left-center">{email.email}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function formatPhone(number, ext) {
  const strippedNum = number.replace(/-/g, "");
  return ` (${strippedNum.slice(0, 3)}) ${strippedNum.slice(
    3,
    6
  )}-${strippedNum.slice(6)} ${ext ? `ext ${ext}` : ""}`;
}

function formatDate(date) {
  const months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sept",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
  };
  const day = date.slice(8, 10);
  const month = months[date.slice(5, 7)];
  return `${month} ${day}`;
}
