import React from 'react';
import {
  PageLink,
  APIClient,
} from '../lib';
import Pages from '../pages/index';
import dashboardIcon from '../assets/images/dashboard-icon.png';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
const CustomBadge = withStyles({
  badge: {
    backgroundColor: '#EF6A6E',
    color: '#ffffff'
  },
})(Badge);

export class Layout extends React.PureComponent {
  state = {
    onDashboard: false,
    onMinaynSuggestions: false,
    minyanSuggestionCount: 0
  }

  componentDidMount() {
    this.getMinyanSuggestionCount();
    const { onDashboard, onMinaynSuggestions } = this.props;
    if (onDashboard) this.setState({ onDashboard: true });
    if (onMinaynSuggestions) this.setState({ onMinaynSuggestions: true });
  }

  componentDidUpdate(prevProps) {

    const getNewSuggestionCount = this.props.getNewSuggestionCount;

    if (getNewSuggestionCount && prevProps.getNewSuggestionCount !== getNewSuggestionCount) {
      this.getMinyanSuggestionCount();
    }
  }

  getMinyanSuggestionCount = async () => {
    const response = await APIClient.get('/minyan-suggestions/count')
      .catch(console.error);
    if (response && response.data) {
      this.setState({ minyanSuggestionCount: response.data.minyanSuggestionCount });
    }
  }

  toggleSidebar = () => {
    this.props.actions.toggleSidebar();
  }

  closeSidebar = () => {
    this.props.actions.closeSidebar();
  }

  logoutUser = () => {
    this.props.accountActions.logoutUser();
    this.closeSidebar();
  }

  render() {
    const {
      props: {
        sidebarOpen,
        children,
        roles,
      },
      state: {
        onDashboard,
        onMinaynSuggestions,
        minyanSuggestionCount
      }
    } = this;
    const isAppAdmin = roles.includes('application_admin') ? true : false;
    return (
      <div>
        <aside className={sidebarOpen ? "side-menu" : "side-menu closed mobile-hidden"}>
          <nav>
            <ul>
              <li onClick={this.toggleSidebar}>
                <div className="aside-logo-div">
                  <img src={dashboardIcon} alt="dashboard icon" />
                  <p>GoDaven</p>
                </div>
              </li>
              <li>
                {roles.length > 0 &&
                  <PageLink
                    className={onDashboard ? 'active' : ''}
                    onClick={this.closeSidebar}
                    to={isAppAdmin ? Pages.admin.dashboard : Pages.shulAdmin.myShulsView}
                  >
                    <i className="material-icons">dashboard</i>
                    <p>Dashboard</p>
                  </PageLink>}
              </li>
              <li>
                <PageLink to={""} onClick={this.closeSidebar}>
                  <i className="material-icons">home</i>
                  <p>Home</p>
                </PageLink>
              </li>
              <li>
                <PageLink
                  className={`${onMinaynSuggestions ? 'active' : ''}`}
                  to={isAppAdmin ? Pages.admin.minyanSuggestions : Pages.shulAdmin.myShulsMinyanSuggestions}
                  onClick={this.closeSidebar}
                >
                  {minyanSuggestionCount > 0 ?
                    (<CustomBadge badgeContent={minyanSuggestionCount}>
                      <i className="material-icons">notifications</i>
                    </CustomBadge>)
                    : (<i className="material-icons">notifications</i>)}
                  <p>Minyan Suggestions</p>
                </PageLink>
              </li>
              {isAppAdmin &&
                <li>
                  <PageLink to={Pages.admin.addAppAdminAccount} onClick={this.closeSidebar}>
                    <i className="material-icons">person_add</i>
                    <p>Add App Admin</p>
                  </PageLink>
                </li>}
              <li>
                <PageLink
                  to={isAppAdmin ? Pages.admin.editAccount : Pages.shulAdmin.myShulsEditAccount}
                  onClick={this.closeSidebar}
                >
                  <i className="material-icons">account_circle</i>
                  <p>My Account</p>
                </PageLink>
              </li>
              <li>
                <PageLink to={""} onClick={this.logoutUser}>
                  <i className="material-icons">power_settings_new</i>
                  <p>Sign Out</p>
                </PageLink>
              </li>
            </ul>
          </nav>
        </aside>
        <div className='content'>
          {children}
        </div>
      </div>
    );
  }
}