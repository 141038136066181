import React from "react";
import { PageLink } from "../lib";
import { useSelector, useDispatch } from "react-redux";
import { UIAction } from "../state";
import {
  convertTime12hour,
  getOffestText,
  renderCalculatedBy,
  renderRoundedBy,
  renderNotEarlierAndLater
} from "../lib";

const EventRow = props => {
  const {
    id,
    title,
    speaker,
    language,
    notes,
    shulId,
    editable,
    time_at,
    zman_type,
    offset_minutes,
    offset_calculated_by,
    offset_rounded_by,
    not_earlier_than,
    not_later_than
  } = props;
  const dispatch = useDispatch();
  const days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
  const activeDays = days.reduce((prev, curr) => {
    if (props[curr.toLowerCase()]) {
      prev.push(curr);
    }
    return prev;
  }, []);
  return (
    <div key={id} className="events-table table-row">
      <p>{title}</p>
      <p>{speaker}</p>
      <p className="flex-left-center mob-xs-text">
        {time_at ? (
          convertTime12hour(time_at)
        ) : zman_type ? (
          <div>
            <span>{getOffestText(offset_minutes)}</span>
            <span className="capitalize-text">
              {zman_type.name.replace(/_/, " ")}
            </span>
            {offset_calculated_by
              ? renderCalculatedBy(offset_calculated_by)
              : ""}
            {offset_rounded_by ? renderRoundedBy(offset_rounded_by) : ""}
            {not_earlier_than
              ? renderNotEarlierAndLater("earlier", not_earlier_than)
              : ""}
            {not_later_than
              ? renderNotEarlierAndLater("later", not_later_than)
              : ""}
          </div>
        ) : (
          ""
        )}
      </p>{" "}
      <p>{language}</p>
      <p>{activeDays.join(", ")}</p>
      {editable === true && <p>{notes}</p>}
      {editable === true && (
        <div className="shul-info-table-actions mobile-hidden flex-right-center">
          <PageLink to={`edit-event/${id}`} params={{ shulId }}>
            <i className="material-icons edit-icon">edit</i>
          </PageLink>
          <i
            className="material-icons"
            onClick={e =>
              dispatch(
                UIAction.toggleDeleteModal(
                  true,
                  { id, name: "this event" },
                  "event"
                )
              )
            }
          >
            delete
          </i>
        </div>
      )}
    </div>
  );
};
export const EventsCard = props => {
  const events = useSelector(state => state.event.events);
  const shulInfo = useSelector(state => state.shul.shulInfo);
  return (
    <div className="card full-width mt-40 mobile-full-width">
      <p className="large-text mob-medium-text text-500 uppercase-text mb-24">
        shiurim & Events
      </p>
      <div className="shul-info-table events-table">
        <div className="table-row table-header-row mobile-hidden">
          <p className="medium-text accent-text-light flex-left-center">
            Title
          </p>
          <p className="medium-text accent-text-light flex-left-center">
            Speaker
          </p>
          <p className="medium-text accent-text-light flex-left-center">Time</p>
          <p className="medium-text accent-text-light flex-left-center">
            Language
          </p>
          <p className="medium-text accent-text-light flex-left-center">Days</p>
          <p className="medium-text accent-text-light flex-left-center">
            Notes
          </p>
          <p />
        </div>
        {!!events.length &&
          events.map(e => (
            <EventRow key={e.id} {...e} shulId={shulInfo.id} editable={true} />
          ))}
      </div>
      <PageLink to={"add-event"}>
        <button className="btn btn-accent btn-small mt-32">Add Event</button>
      </PageLink>
    </div>
  );
};

export const EventsCardCompact = props => {
  const events = useSelector(state => state.event.events) || [];
  return (
    <div className="card full-width mt-40 mobile-full-width">
      <h2 className="large-text mob-medium-text text-500 uppercase-text mb-24">
        shiurim & Events
      </h2>
      {console.log(events)}
      {!!events.length &&
        events.map(e => (
          <EventRow key={events.id} {...e}
          />
        ))}

    </div>
  );
};
