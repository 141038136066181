import React from "react";
import { createToast, Navigation } from "../../lib";
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/contact.css";
import "react-toastify/dist/ReactToastify.css";

export default class AddAccount extends React.PureComponent {
  state = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    submitted: false,
    shulId: null,
    forAppAdmin: false
  }

  async componentDidMount() {
    const {
      actions: { checkUsersAuth },
      pageRoute: { params: { shulId } }
    } = this.props;

    await checkUsersAuth();

    if (!this.props.authorized) {
      Navigation.redirect('/?displayLogin=true');
      return;
    }

    this.setState({
      shulId: shulId ? +shulId : null,
      forAppAdmin: shulId ? false : true
    });
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  }

  handleSubmit = async () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      submitted,
      shulId,
      forAppAdmin
    } = this.state;

    if (submitted) return;
    this.setState({ submitted: true });

    if (!first_name || !last_name || !email) {
      createToast(null, 'Please fill in all required fields!');
      return;
    }

    const accountObject = {
      first_name,
      last_name,
      email,
      phone
    };

    if (shulId) accountObject.shul_id = shulId;

    const success = await this.props.actions.addAccount(accountObject, forAppAdmin);
    if (success) {
      setTimeout(() => this.props.pageRoute.history.goBack(), 5000);
    } else {
      this.setState({ submitted: false });
    }
  }

  render() {
    const { first_name,
      last_name,
      email,
      phone,
      submitted,
      forAppAdmin
    } = this.state;

    return (
      <div className="full-page-modal">
        <h1 className="xl-text mob-large-text text-500 bordered-title desktop-hidden">
          {`Add ${forAppAdmin ? 'Application' : 'Shul'} Admin`}
        </h1>
        <div
          className="full-page-modal-close link"
          onClick={this.props.pageRoute.history.goBack}>
          <i className="material-icons">close</i>
        </div>
        <div className="container">
          <h1 className="xl-text mob-large-text text-500 bordered-title mobile-hidden">
            {`Add ${forAppAdmin ? 'Application' : 'Shul'} Admin`}
          </h1>

          <div className={`mb-16`}>
            <label className="input-label">First Name</label>
            <input
              type="text"
              className={`custom-input ${submitted && !first_name ? 'required' : ''}`}
              value={first_name}
              name="first_name"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-32`}>
            <label className="input-label">Last Name</label>
            <input
              type="text"
              className={`custom-input ${submitted && !last_name ? 'required' : ''}`}
              value={last_name}
              name="last_name"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-32`}>
            <label className="input-label">Email</label>
            <input
              type="email"
              className={`custom-input ${submitted && !email ? 'required' : ''}`}
              value={email}
              name="email"
              onChange={this.handleChange}
            />
          </div>

          <div className={`mb-40`}>
            <label className="input-label">Phone</label>
            <input
              type="text"
              className={`custom-input`}
              value={phone}
              name="phone"
              onChange={this.handleChange}
            />
          </div>
          <hr />

          <div className="contact-buttons-div">
            <button
              className="btn btn-light uppercase-text contact-button"
              onClick={this.props.pageRoute.history.goBack}
            >
              Cancel
            </button>
            <button
              className="btn btn-accent contact-button uppercase-text"
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>

        </div>
        <ToastContainer />
      </div>
    );
  }
}