import axios from 'axios';
import {
  //Navigation,
  createToast
} from '../lib';

const baseURL = process.env.REACT_APP_API_URL || window.location.origin;
/** Axios instance to use for API requests. */
const APIClient = axios.create({
  baseURL: baseURL + '/api/V2',
  headers: {
    'Content-Type': 'application/json',
    'app-id': window.location.host === "www.godaven.com" ? process.env.REACT_APP_API_TOKEN : 'lhhceihvhwefihvowhvoihwgkgkgkgjkjgkjg'
  }
});

APIClient.interceptors.response.use(null, (error) => {
  const { status, data } = error.response;
  if (status !== 404) {
    createToast(data.code);
  }

  return Promise.reject(error);
});

export { APIClient };
