import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  UIAction,
  SponsorActions
} from '../state';
import { FrontLayout } from './FrontLayout';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    ui: {
      sidebarOpen
    },
    account: {
      roles,
      shulIds
    },
    sponsor: {
      dailySponsor,
      weeklySponsor
    },
    home: {
      lat,
      lng
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    sidebarOpen,
    roles,
    shulIds,
    dailySponsor,
    weeklySponsor,
    lat,
    lng
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(UIAction, dispatch),
    sponsorActions: bindActionCreators(SponsorActions, dispatch)
  };
})(FrontLayout);
