import { ShulActions } from './actions';

export const ShulState = {
  name: 'shul',
  persist: false,
  defaults: {
    loading: false,
    total: 0,
    num_of_pages: 0,
    results: {},
    pendingShuls: [],
    sortIds: [],
    shulInfo: {},
    isRoshChodesh: false,
    newEndTime: null,
    newDistance: null,
    recursiveSearchingNextDay: false,
    myShuls: [],
    covidPolicies: [],
    shulCovidPolicy: {loading: true}

  },
  handlers: {
    [ShulActions.SHUL_ADD]: (state, action) => {
      if (action.loading) {
        return {
          ...state,
          loading: true,
        };
      }
      return {
        ...state,
        loading: false,
        shulInfo: action.payload,
      };
    },

    [ShulActions.SHUL_SEARCH_FETCHING]: (state, action) => {
      return {
        ...state,
        loading: true,
        total: 0,
        num_of_pages: 0,
        results: {},
      };
    },

    [ShulActions.SHUL_SEARCH_RESULTS]: (state, action) => {
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    },

    [ShulActions.SHUL_GET_PENDING]: (state, action) => {
      return {
        ...state,
        pendingShuls: action.results
      };
    },

    [ShulActions.SHUL_GET]: (state, action) => {
      const {
        contacts: _removingContacts,
        shacharisMinyanims: _removingShacharisMinyanims,
        shacharisSortedIds: _removingShacharisSortedIds,
        minchaMinyanims: _removingMinchaMinyanims,
        minchaSortedIds: _removingMinchaSortedIds,
        maarivMinyanims: _removingMaarivMinyanims,
        maarivSortedIds: _removingMaarivSortedIds,
        ...shulInfo
      } = action.results;
      const { minyanims, ...shulWithoutMinyanim } = shulInfo;
      return {
        ...state,
        loading: false,
        shulInfo: shulWithoutMinyanim,
      };
    },

    [ShulActions.SHUL_DETAILS]: (state, action) => {
      const {
        groupedContacts: _removingContacts,
        groupedByDayMinyanim: _removing,
        ...shulInfo
      } = action.results;
      return {
        ...state,
        loading: false,
        shulInfo,
      };
    },

    [ShulActions.SHUL_UPDATE]: (state, action) => {
      return {
        ...state,
        shulInfo: {
          ...state.shulInfo,
          ...action.payload,
        },
      };
    },

    [ShulActions.SHUL_DELETE]: (state, action) => {
      return {
        ...state,
        shulInfo: {},
      };
    },

    [ShulActions.SHUL_FAILURE]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },

    [ShulActions.SHUL_REMOVE_PENDING]: (state, action) => {
      const {
        [action.payload.toString()]: value,
        ...newPendingShuls
      } = state.pendingShuls;
      return {
        ...state,
        pendingShuls: {
          ...newPendingShuls
        },
      };
    },

    [ShulActions.SHUL_CLEAR_FROM_REDUX]: (state, action) => {
      return {
        ...state,
        shulInfo: {}
      };
    },

    [ShulActions.SHUL_CLEAR_SEARCH_RESULTS_FROM_REDUX]: (state, action) => {
      return {
        ...state,
        results: {},
        sortIds: [],
        total: 0,
        num_of_pages: 0,
        isRoshChodesh: false,
        newEndTime: null,
        recursiveSearchingNextDay: false
      };
    },

    [ShulActions.SHUL_RESET_NEW_END_TIME]: (state, action) => {
      return {
        ...state,
        newEndTime: null,
      };
    },

    [ShulActions.SHUL_RESET_RECURSIVE_SEARCHING_NEXT_DAY]: (state, action) => {
      return {
        ...state,
        recursiveSearchingNextDay: false,
      };
    },

    [ShulActions.SHUL_RESET_NEW_DISTANCE]: (state, action) => {
      return {
        ...state,
        newDistance: null,
      };
    },

    [ShulActions.SHUL_GET_MINE]: (state, action) => {
      return {
        ...state,
        myShuls: action.results
      };
    },

    [ShulActions.GET_COVID_POLICY_OPTIONS]: (state, action) => {
      return {
        ...state,
        covidPolicies: action.payload
      };
    },

    [ShulActions.SHUL_COVID_POLICY]: (state, action) => {
      return {
        ...state,
        shulCovidPolicy: {loading: false, ...action.payload}
      };
    },
  },
};
