import React from "react";
import { Layout } from "../../components";
import { LocationSearchInput } from "../../components/LocationSearchInput";
import {
  PageLink,
  createToast,
  googlePlaceToAddress,
  Navigation
} from "../../lib";
import Pages from '../';
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/add-edit-shul.css";
import "../../assets/css/pages/view-shul.css";
import "react-toastify/dist/ReactToastify.css";
import { LocationWarningDialog } from "../../components/LocationWarningDialog";

export default class AddShul extends React.PureComponent {
  state = {
    shulId: null,
    name: '',
    website_url: '',
    address: '',
    notes: '',
    email: '',
    phone: '',
    extension: '',
    shul_nusach: '',
    addressDisplay: '',
    apiSuccess: null,
    showLocationWarningDialog: false,
    existingShulNames: []
  }

  async componentDidMount() {
    const {
      accountActions: { checkUsersAuth },
      pageRoute: {
        query: {
          shulId
        },
      },
      actions: { getShulById }
    } = this.props;

    await checkUsersAuth(true);
    if (!this.props.authorized) {
      Navigation.redirect('/?displayLogin=true');
      return;
    }

    if (shulId) {
      await getShulById(+shulId);

      if (Object.keys(this.props.shulInfo).length) {
        const { name, website_url, notes, formatted_address, shul_nusach, email, phone, extension } = this.props.shulInfo;

        this.setState({
          name,
          website_url: website_url ? website_url : '',
          notes: notes ? notes : '',
          addressDisplay: formatted_address,
          shul_nusach: shul_nusach ? shul_nusach : '',
          shulId: +shulId,
          email: email ? email : '',
          phone: phone ? phone : '',
          extension: extension ? extension : '',
        });
      }
    }
  }

  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  }

  handleAddressResults = (googlePlace) => {
    const addressInfo = googlePlaceToAddress(googlePlace[0]);
    const { streetNumber, streetName } = addressInfo;
    addressInfo.address = streetName && streetNumber
      ? `${streetNumber} ${streetName}`
      : null;
    delete addressInfo.streetNumber;
    delete addressInfo.streetName;
    this.setState((state, props) => {
      return { address: addressInfo };
    },
      () => this.handleSubmit(null, true)
    );

  }

  handleSubmit = async (_e, fromAddressInput = false, createWithExistingLocation = false) => {
    const {
      state: {
        shulId,
        name,
        website_url,
        address,
        addressDisplay,
        notes,
        shul_nusach,
        email,
        phone,
        extension
      }
    } = this;
    if (!name || (!address && !addressDisplay)) return;
    if (address && !address.address) {
      return createToast(null, 'A full address is required!');
    }
    const location = { ...address };
    const shul = {
      name,
      website_url: website_url ? website_url : null,
      notes: notes ? notes : null,
      shul_nusach: shul_nusach ? shul_nusach : null,
      email: email ? email : null,
      phone: phone ? phone : null,
      extension: extension ? extension : null,
    };
    if (shulId) shul.id = +shulId;
    const apiObj = { shul, location, createWithExistingLocation };

    if (!shulId) {
      this.addShul(apiObj);
    } else {
      if (fromAddressInput) {
        this.updateShulLocation(shulId, location, createWithExistingLocation);
      } else {
        this.updateShul(shul);
      }
    }
  }

  addShul = async apiObj => {
    const response = await this.props.actions.addShul(apiObj);
    if (response && response.showLocationExistsWarning) {
      this.setState({ existingShulNames: response.existingShulNames, showLocationWarningDialog: true });
    } else if (response && response.shulId) {
      const shulId = response.shulId;
      this.setState({ shulId });
      Navigation.redirect(`/admin/add-shul?shulId=${shulId}`);
      this.updateApiMessage(true);
    } else {
      this.updateApiMessage(false);
    }
  }

  updateShul = async shul => {
    const response = await this.props.actions.updateShul(shul);
    this.updateApiMessage(response && response.shulUpdated ? true : false);
  }

  updateShulLocation = async (shulId, location, createWithExistingLocation) => {
    const response = await this.props.actions.updateShulLocation(shulId, { location, createWithExistingLocation });
    if (response && response.showLocationExistsWarning) {
      this.setState({ existingShulNames: response.existingShulNames, showLocationWarningDialog: true });
    } else {
      this.updateApiMessage(response && response.locationUpdated ? true : false);
    }
  }

  updateApiMessage = (apiSuccess) => {
    this.setState(() => ({ apiSuccess }),
      () => setTimeout(() => this.setState({ apiSuccess: null }), 5000));
  }

  cancelDialog = () => {
    this.setState({ showLocationWarningDialog: false });
  }

  resubmitShul = () => {
    this.setState({ showLocationWarningDialog: false });
    this.handleSubmit(null, true, true);
  }

  render() {
    const {
      props: {
        contacts,
      },
      state: {
        shulId,
        name,
        website_url,
        notes,
        email,
        phone,
        extension,
        addressDisplay,
        shul_nusach,
        apiSuccess,
        showLocationWarningDialog,
        existingShulNames
      }
    } = this;
    const minyanTypes = {
      shachris: { name: 'Shacharis', minyanims: 'shacharisMinyanims' },
      mincha: { name: 'Mincha', minyanims: 'minchaMinyanims' },
      mariv: { name: 'Maariv', minyanims: 'maarivMinyanims' }
    };
    const nusachs = { '': 'Select a Nusach', sefard: 'Sefard', ashkenaz: 'Ashkenaz', ari: 'Ari', edut_mizrach: 'Edut Mizrach', temani: 'Temani' };

    return (
      <Layout>
        {showLocationWarningDialog &&
          <LocationWarningDialog
            existingShulNames={existingShulNames}
            cancelDialog={this.cancelDialog}
            resubmitRequest={this.resubmitShul}
          >
          </LocationWarningDialog>}

        <div className="add-edit-shul-outer-wraper">
          <header className="header-fixed">
            <div className="container flex-justify-space flex-align-center">
              <h1 className="mob-large-text">New Shul</h1>
              {shulId ? (
                <PageLink
                  to={Pages.admin.viewShul}
                  params={{ shulId: shulId }}
                >
                  <button className="btn btn-accent mob-xs-text">Finish Editing</button>
                </PageLink>
              ) :
                <PageLink
                  to={Pages.admin.dashboard}
                >
                  <button className="btn btn-accent mob-xs-text">Cancel</button>
                </PageLink>
              }
            </div>
          </header>
          <div className="header-fixed-page">
            <div className="add-shul">
              <div className="container">

                <div className="flex-justify-space mobile-block">
                  <div className="card column-half column-half-left shul-info-card mobile-full-width">
                    <div className="flex-align-center">
                      <p className="large-text mob-medium-text uppercase-text text-500">
                        Shul Info
                    </p>
                      {renderUpdateMessage(apiSuccess)}
                    </div>
                    <div className="flex-align-center mobile-block">
                      <p className="medium-text">Shul Name</p>
                      <input
                        className="custom-input capitalize-text"
                        type="text"
                        placeholder="Shul Name"
                        value={name}
                        name="name"
                        onChange={this.handleInputChange}
                        onBlur={this.handleSubmit}
                      />
                    </div>
                    <div className="flex-align-center  mobile-block">
                      <p className="medium-text">Website</p>
                      <input
                        className="custom-input"
                        type="text"
                        placeholder="Website (optional)"
                        value={website_url}
                        name="website_url"
                        onChange={this.handleInputChange}
                        onBlur={this.handleSubmit}
                      />
                    </div>
                    <div className="flex-align-center  mobile-block">
                      <p className="medium-text shul-info-card-input-name">Address</p>
                      <LocationSearchInput
                        prevAddress={addressDisplay}
                        handleAddressResults={this.handleAddressResults}
                      />
                    </div>

                    <div className="flex-align-center  mobile-block">
                      <p className="medium-text">Email</p>
                      <input
                        className="custom-input"
                        type="text"
                        value={email}
                        name="email"
                        onChange={this.handleInputChange}
                        onBlur={this.handleSubmit}
                      />
                    </div>

                    <div className="flex-align-center  mobile-block">
                      <p className="medium-text">Phone</p>
                      <input
                        className="custom-input"
                        type="text"
                        value={phone}
                        name="phone"
                        onChange={this.handleInputChange}
                        onBlur={this.handleSubmit}
                      />
                    </div>

                    <div className="flex-align-center  mobile-block">
                      <p className="medium-text">Extension</p>
                      <input
                        className="custom-input"
                        type="number"
                        step="1"
                        min="0"
                        value={extension}
                        name="extension"
                        onChange={this.handleInputChange}
                        onBlur={this.handleSubmit}
                      />
                    </div>

                    <div className="flex-align-center  mobile-block">
                      <p className="medium-text">Nusach</p>
                      <select
                        className="custom-input"
                        value={shul_nusach}
                        name="shul_nusach"
                        onChange={this.handleInputChange}
                        onBlur={this.handleSubmit}
                      >
                        {Object.keys(nusachs).map(nusach =>
                          <option
                            key={nusach}
                            value={nusach}
                            disabled={nusach === ''}
                          >
                            {nusachs[nusach]}
                          </option>)}
                      </select>
                    </div>
                  </div>
                  <div className="card column-half column-half-right mobile-full-width">
                    <p className="large-text uppercase-text text-500 mb-24">
                      Images
                  </p>
                    <div className="upload-box">
                      {/* <PageLink to={`/admin/shul/${shulId}/add-minyan`}> */}
                      <button className="btn btn-accent btn-small">
                        <i
                          className="material-icons mr-8"
                          style={{ verticalAlign: "middle", marginTop: "-4px", fontSize: "18px" }}
                        >
                          publish
                      </i>
                        Upload Images
                    </button>
                      {/* </PageLink> */}
                    </div>
                  </div>
                </div>

                {Object.keys(minyanTypes).map(type => {
                  const minyanim = this.props[minyanTypes[type].minyanims];
                  return (
                    <div key={type} className="card full-width mt-40">
                      <p className="large-text text-500 uppercase-text mb-24">
                        {`${minyanTypes[type].name} Minyanim`}
                      </p>
                      {!Object.keys(minyanim).length ?
                        (<div className="text-center">
                          <p className="accent-text-light large-text mb-16">
                            {`You haven't added any ${minyanTypes[type].name} minyanim yet.`}
                          </p>
                        </div>)
                        :
                        (<div className="view-shul-minyanim">
                          {Object.keys(minyanim).map(minyanId => {
                            return renderMinyan(minyanim[minyanId]);
                          })}
                        </div>)}
                      <div className="text-center">
                        <PageLink
                          to={Pages.admin.addMinyan}
                          params={shulId ? { shulId } : ''}
                          query={{ type }}
                        >
                          <button
                            className="btn btn-accent btn-small mt-8"
                            disabled={!shulId}
                          >
                            Add Minyan
                      </button>
                        </PageLink>
                      </div>
                    </div>
                  );
                })}
                <div className="card full-width mt-40">
                  <p className="large-text text-500 uppercase-text mb-24">Contacts</p>
                  <div className="text-center">
                    {contacts && Object.keys(contacts).length > 0 ? <div className="view-shul-contacts">
                      {Object.keys(contacts).length > 0 &&
                        Object.keys(contacts).map(contactId => {
                          return renderContact(contacts[contactId]);
                        })}
                    </div> :
                      <p className="accent-text-light large-text mb-16">You haven't added any contacts yet.</p>
                    }
                    <PageLink to={`/admin/shul/${shulId}/add-contact`}>
                      <button
                        className="btn btn-accent btn-small"
                        disabled={!shulId}
                      >Add Contact
                  </button>
                    </PageLink>
                  </div>
                </div>
                <div className="card full-width mt-40">
                  <p className="large-text text-500 uppercase-text mb-24">Notes</p>
                  <div>
                    <textarea
                      name="notes"
                      value={notes}
                      className="custom-input full-width"
                      rows="4"
                      placeholder="Enter notes on Shul (optional)"
                      onChange={this.handleInputChange}
                      onBlur={this.handleSubmit}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </Layout >
    );
  }
}

function renderUpdateMessage(apiSuccess) {
  if (apiSuccess === null) return;

  let m = {};
  if (apiSuccess) {
    m.className = 'shul-update-message success-message';
    m.icon = 'check_circle';
    m.text = 'All changes saved';
  }
  else {
    m.className = 'shul-update-message error-message';
    m.icon = 'error_outline';
    m.text = 'Error occurred';
  }
  return (
    <div className={m.className}>
      <i
        className="material-icons"
        style={{ fontSize: "18px", marginRight: "14px" }}>
        {m.icon}
      </i>
      <p className="medium-text">{m.text}</p>
    </div>
  );
}

function renderMinyan(minyan) {
  return (
    <div key={minyan.id} className="table-row">
      <p className="capitalize-text accent-text">{minyan.nusach ? minyan.nusach.replace(/_/, ' ') : ''}</p>
      <div>
        <p className="accent-text"> {minyan.days.join(", ")}</p>
        {minyan.special_days_included.length > 0 && (
          <p className="xs-text mt-4">
            Includes: {minyan.special_days_included.join(", ")}
          </p>
        )}
        {minyan.special_days_only.length > 0 && (
          <p className="xs-text mt-4">
            Only: {minyan.special_days_only.join(", ")}
          </p>
        )}
      </div>
      <p className="accent-text">{getMinyanTime(minyan)}</p>
    </div>
  );
}

function getMinyanTime(minyan) {
  const types = { 1: 'Neitz', 2: 'Shkiah', 3: 'Alos', 4: 'Plag Hamincha', 5: 'Candle Lighting', 6: 'Tzeis', 7: 'Mincha Gedolah' };
  if (minyan.time_at) {
    const suffix = minyan.time_at.slice(0, 2) >= 12 ? "PM" : "AM";
    const hours = minyan.time_at.slice(0, 2) % 12 || 12;
    const minutes = minyan.time_at.slice(2, 5);
    return hours + minutes + ' ' + suffix;
  }

  let offsetText;
  switch (true) {
    case minyan.offset_minutes === 0:
      offsetText = `at ${types[minyan.zman_offset_type_id]}`;
      break;
    case minyan.offset_minutes > 0:
      offsetText = `${minyan.offset_minutes} 
      minutes after ${types[minyan.zman_offset_type_id]}}`;
      break;
    default:
      offsetText = `${-minyan.offset_minutes} 
      minutes before ${types[minyan.zman_offset_type_id]}`;
  }
  return offsetText;
}

function renderContact(contact) {
  const {id, minyan_role, title, first_name, last_name, display_emails, display_phones } = contact;
  return (
    <div key={id} className="table-row">
      <p className="bold-text flex-justify-space-center capitalize-text">
        {title} {first_name} {last_name} &nbsp;&nbsp; - &nbsp;&nbsp; {minyan_role}
      </p>
      <div className="flex-justify-space-center">
        {display_phones && display_phones.length > 0 && display_phones.map(phone => {
          return (
            <p key={phone.id} className="flex-justify-space-center">
              {phone.number} {phone.extension && `x ${phone.extension}`}
            </p>
          );
        })}
      </div>
      <div className="flex-justify-space-center">
        {display_emails && display_emails.length > 0 && display_emails.map(email => {
          return (
            <p key={email.id} className="flex-justify-space-center">
              {email.email}
            </p>
          );
        })}
      </div>
    </div>
  );
}
