import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AccountActions } from '../../state';
import EditAccount from './EditAccount';

export default connect(function mapState(state) {
  // This function gets called every time the global state changes.
  // Pull some data out of the global state and set default values...
  const {
    contact: {
      contactFailed
    },
    account: {
      authorized,
      userId
    }
  } = state;
  // The returned object becomes properties of the connected Component...
  return {
    contactFailed,
    authorized,
    userId
  };
}, function mapDispatch(dispatch) {
  // This function gets called only once when the component instantiates.
  // The returned object becomes properties of the connected Component...
  // Here we are just returning ACTIONs, not state props.
  return {
    actions: bindActionCreators(AccountActions, dispatch),
  };
})(EditAccount);
