import React from "react";
import {
  APIClient,
  createToast,
  Navigation
} from "../../lib";
import { Layout } from "../../components";
import { ToastContainer } from "react-toastify";
import "../../assets/css/pages/minyan-suggestions.css";
import "react-toastify/dist/ReactToastify.css";
const tefillahTypes = { shachris: 'Shacharis', mincha: 'Mincha', mariv: 'Maariv' };
const fields = ['Name', 'Submitted', 'Action', 'Tefillah', 'Nusach', 'Days', 'Time', 'Status', 'notes'];

export default class MinyanSuggestions extends React.PureComponent {
  state = {
    suggestedMinyanim: [],
    getNewSuggestionCount: false
  }

  async componentDidMount() {
    await this.props.accountActions.checkUsersAuth();
    if(!this.props.authorized){
      Navigation.redirect('/?displayLogin=true');
      return;
    }

    const roles = this.props.roles;
    let response;
    if (roles.includes('application_admin')){
      response = await APIClient.get('/minyan-suggestions')
      .catch(console.error);
    }else{
      response = await APIClient.get('/minyan-suggestions/mine')
      .catch(console.error);
    }

    if(response && response.data){
      this.setState({suggestedMinyanim: response.data});
    }else{
      createToast(null, 'Something went wrong pleasse try again later');
    }
  }

  handleApprove = async (shulId, suggestionId) =>{
    const actionSuccess = await this.props.actions.approveMinyanSuggestion(shulId, suggestionId);
    if(actionSuccess){
      this.setState(prevState => ({
        suggestedMinyanim: prevState.suggestedMinyanim.filter(m=> m.id !== suggestionId),
        getNewSuggestionCount: true
      }), () => setTimeout(this.setState({getNewSuggestionCount: false}), 200));
    }
  }

  handleDeny = async (shulId, suggestionId) =>{
    const actionSuccess = await this.props.actions.denyMinyanSuggestion(shulId, suggestionId);
    if(actionSuccess){
      this.setState(prevState => ({
        suggestedMinyanim: prevState.suggestedMinyanim.filter(m=> m.id !== suggestionId),
        getNewSuggestionCount: true
      }), () => setTimeout(this.setState({getNewSuggestionCount: false}), 200));
    }
  }

  toggleSidebar = () => {
    this.props.uiActions.toggleSidebar();
  }

  render() {
    const {
      state: {
        suggestedMinyanim,
        getNewSuggestionCount
      }
    } = this;

    return (
      <Layout getNewSuggestionCount={getNewSuggestionCount} onMinaynSuggestions={true}>
        <div className="minyan-suggestions-container">
        
          <h1 className="mobile-hidden minyan-suggestions-title">Minyan Suggestions</h1>
          <div className="desktop-hidden mobile-minyan-suggestions-header">
          <i className="material-icons" onClick={this.toggleSidebar}>menu</i>
          <h1 className="minyan-suggestions-title">Minyan Suggestions</h1>
          </div>
          {suggestedMinyanim.length > 0 ?
          (
            <div className="minyan-suggestions-table">
              <div className="mobile-hidden minyan-suggestion-row minyan-suggestion-row-container">
                {fields.map(field =>{
                  return(
                    <div key={field} className="flex-left-center">{field}</div>
                  );
                })}
              </div>
              {suggestedMinyanim.map((minyan, index) =>{
                const{
                  id,
                  //minyan_id,
                  action,
                  minyan_location: {name},
                  start_date,
                  end_date,
                  type,
                  minyan_location_id,
                  nusach,
                  days,
                  special_days_included,
                  special_days_only,
                  time_at,
                  zman_type,
                  offset_minutes,
                  offset_calculated_by,
                  offset_rounded_by,
                  notes,
                  not_later_than,
                  not_earlier_than,
                  created_at,
                  currentMinyan                                           
                } = minyan;
                const status = start_date && end_date
                  ? `${formatDate(start_date)} - ${formatDate(end_date)}`
                  : "All year round";
                return(
                  <div key={id} className={`minyan-suggestion-row-container ${index === 0 ? 'mobile-minyan-sugg-first-row' : ''}`}>
                    <div className={`minyan-suggestion-row`}>
                      <div className="bold-text capitalize-text flex-left-center">{name}</div>
                      <div className="accent-text capitalize-text flex-left-center">
                      <span className="desktop-hidden">Submitted: &nbsp;</span> 
                      {formatDateFromISOstring(created_at)}
                      </div>
                      <div className="accent-text capitalize-text flex-left-center">
                        <span className="desktop-hidden">Action: &nbsp;</span> 
                        {action}
                      </div>
                      <div className="accent-text capitalize-text flex-left-center">{tefillahTypes[type]}</div>
                      <div className="accent-text capitalize-text flex-left-center">{nusach ? nusach.replace(/_/, ' ') : ''}</div>
                      <div className="accent-text capitalize-text flex-left-center-columns">
                        <p>{days ? days.join(", ") : ''}</p>
                        {special_days_included.length > 0 && (
                          <p className="xs-text accent-text mt-4 mobile-margin-0">
                            Includes: {special_days_included.join(", ")}
                          </p>
                        )}
                        {special_days_only.length > 0 && (
                          <p className="xs-text accent-text mt-4 mobile-margin-0">
                            Only: {special_days_only.join(", ")}
                          </p>
                        )}
                      </div>
                      <div className="accent-text flex-left-center">
                      {time_at ? getMinyanTime(time_at) : zman_type
                        ? <div>
                          <span>{getOffestText(offset_minutes)}</span>
                          <span className="capitalize-text">{zman_type.name.replace(/_/, ' ')}</span>
                          {offset_calculated_by ? renderCalculatedBy(offset_calculated_by) : ''}
                          {offset_rounded_by ? renderRoundedBy(offset_rounded_by) : ''}
                          {not_earlier_than ? renderNotEarlierAndLater('earlier', not_earlier_than) : ''}
                          {not_later_than ? renderNotEarlierAndLater('later', not_later_than) : ''}
                        </div>
                        : ''}
                      </div>
                      <div className="accent-text flex-left-center">{status}</div>
                      <div className="accent-text flex-left-center">{notes}</div>
                      <div className="flex-right-center">
                        <i
                          className="material-icons approve-suggestion"
                          onClick={()=> this.handleApprove(minyan_location_id, id)}
                        >
                          check
                        </i>
                        <i
                          className="material-icons deny-suggestion"
                          onClick={()=> this.handleDeny(minyan_location_id, id)}
                        >
                          close
                        </i>
                      </div>
                    </div>
                    {currentMinyan && renderCurrentMinyan(currentMinyan)}
                  </div>
                );
              })}
            </div>
          )
        :(<div className="no-minyan-suggestions">
          No Minyan Suggestions
        </div> )}


          <ToastContainer/>
        </div>
      </Layout>
    );
  }
}

function renderCurrentMinyan(currentMinyan){
  const{
    id,
    start_date,
    end_date,
    type,
    nusach,
    days,
    special_days_included,
    special_days_only,
    time_at,
    zman_type,
    offset_minutes,
    offset_calculated_by,
    offset_rounded_by,
    notes,
    not_later_than,
    not_earlier_than,                               
  } = currentMinyan;
  const status = start_date && end_date
    ? `${formatDate(start_date)} - ${formatDate(end_date)}`
    : "All year round";
  return(
    <div key={id} className="minyan-suggestions-current-minyan-row">
      <div className="capitalize-text flex-left-center ml-8 mobile-margin-0">Current Minyan Info<span className="desktop-hidden">:</span></div>
      <div></div>
      <div></div>
      <div className="accent-text capitalize-text flex-left-center">{tefillahTypes[type]}</div>
      <div className="accent-text capitalize-text flex-left-center">{nusach ? nusach.replace(/_/, ' ') : ''}</div>
      <div className="accent-text capitalize-text flex-left-center-columns">
        <p>{days ? days.join(", ") : ''}</p>
        {special_days_included.length > 0 && (
          <p className="xs-text accent-text mt-4 mobile-margin-0">
            Includes: {special_days_included.join(", ")}
          </p>
        )}
        {special_days_only.length > 0 && (
          <p className="xs-text accent-text mt-4 mobile-margin-0">
            Only: {special_days_only.join(", ")}
          </p>
        )}
      </div>
      <div className="accent-text flex-left-center">
      {time_at ? getMinyanTime(time_at) : zman_type
        ? <div>
          <span>{getOffestText(offset_minutes)}</span>
          <span className="capitalize-text">{zman_type.name.replace(/_/, ' ')}</span>
          {offset_calculated_by ? renderCalculatedBy(offset_calculated_by) : ''}
          {offset_rounded_by ? renderRoundedBy(offset_rounded_by) : ''}
          {not_earlier_than ? renderNotEarlierAndLater('earlier', not_earlier_than) : ''}
          {not_later_than ? renderNotEarlierAndLater('later', not_later_than) : ''}
        </div>
        : ''}
      </div>
      <div className="accent-text flex-left-center">{status}</div>
      <div className="accent-text flex-left-center">{notes}</div>
    </div>
  );
}

function formatDate(date) {
  const months = {
    '01': 'Jan', '02': 'Feb', '03': 'Mar', '04': 'Apr', '05': 'May', '06': 'June',
    '07': 'July', '08': 'Aug', '09': 'Sept', '10': 'Oct', '11': 'Nov', '12': 'Dec'
  };
  const day = date.slice(8, 10);
  const month = months[date.slice(5, 7)];
  //const year = date.slice(0, 4);
  return `${month} ${day}`;
}

function formatDateFromISOstring(ISOdate){
  const options = { month: 'long', day: 'numeric' };
  const date = new Date(ISOdate);
  return date.toLocaleDateString('en-US',options);
}


function getMinyanTime(time) {
  const suffix = time.slice(0, 2) >= 12 ? "PM" : "AM";
  const hours = time.slice(0, 2) % 12 || 12;
  const minutes = time.slice(2, 5);
  return hours + minutes + ' ' + suffix;
}

function getOffestText(offsetMinutes) {
  let offsetText;
  switch (true) {
    case offsetMinutes === 0:
      offsetText = `at `;
      break;
    case offsetMinutes > 0:
      offsetText = `${offsetMinutes} minutes after `;
      break;
    default:
      offsetText = `${-offsetMinutes} minutes before `;
  }
  return offsetText;
}

function renderCalculatedBy(calculatedBy) {
  const calcByOptions = {
    today: 'that days zman',
    earliest_of_week: 'the earliest zman of the week',
    latest_of_week: 'the latest zman of the week'
  };
  return <p className="xs-text accent-text">{`Calculated by ${calcByOptions[calculatedBy]}`}</p>;
}

function renderRoundedBy(roundedBy) {
  const roundedByOptions = {
    on_time: 'At the exact time',
    earlier_5: 'Rounded to earlier 5 minutes',
    later_5: 'Rounded to later 5 minutes'
  };

  return (
    <p className="xs-text accent-text">
      {roundedByOptions[roundedBy]}
    </p>
  );
}


function renderNotEarlierAndLater(beforeOrAftertext, time) {
  return <p className="xs-text accent-text">{`Not ${beforeOrAftertext} than ${getMinyanTime(time)}`}</p>;
}

